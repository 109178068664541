import React, { useState } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { DateRangePicker } from "react-dates";
import InfographicTable from "src/components/Infographics/InfographicTable";
import {getDate, getUTCDateTime} from "src/utils/common";
import moment from "moment";
import { fetchAllUserAudits, fetchUserAudits } from "src/api/dashbaord/audit";
import {
  auditData,
  allColumns,
} from "src/containers/Dashboard/AuditInfographics/auditData";
import debounce from "lodash/debounce";

type TInfographicsPropsType = {
  activeTab: string;
};

const AuditInfographics = (props: TInfographicsPropsType) => {
  const [pageIndex, setPageIndex] = React.useState<number>(0);
  const [searchQuery, setSearchQuery] = useState("");

  const [infographics, setInfographics] = React.useState({
    totalItems: 0,
    items: [],
  });

  const [filteredDates, setFilteredDates] = React.useState({
    startDate: null,
    endDate: null,
  });
  const [focusedInput, setFocusedInput] = React.useState(null);

  const data = auditData();

  const fetchAudits = (offset) => {
    fetchUserAudits(
      offset,
      searchQuery,
        getUTCDateTime(filteredDates.startDate),
        getUTCDateTime(filteredDates.endDate, false),
      offset
    )
      .then((audits) => audits && setInfographics(audits))
      .catch((error) => console.error(error));
  };

  React.useEffect(() => {
    if (!filteredDates.startDate && !filteredDates.endDate) {
      fetchAudits(0);
    }
  }, [filteredDates.startDate, filteredDates.endDate]);

  const handleSearch = debounce(async (searchBy) => {
    const data = await fetchUserAudits(
      0,
      searchBy,
        getUTCDateTime(filteredDates.startDate),
        getUTCDateTime(filteredDates.endDate, false)
    );
    setPageIndex(0);
    setSearchQuery(searchBy);
    data && setInfographics(data);
  }, 500);

  const fetchAllInfographics = () => {
    if (filteredDates.startDate && filteredDates.endDate) {
      return fetchAllUserAudits(
        searchQuery,
          getUTCDateTime(filteredDates.startDate),
          getUTCDateTime(filteredDates.endDate, false)
      );
    } else {
      return fetchAllUserAudits(searchQuery);
    }
  };

  React.useEffect(() => {
    if (props.activeTab !== "users") {
      setFilteredDates({ startDate: null, endDate: null });
      setSearchQuery("");
      fetchUserAudits(
        0,
        null,
          getUTCDateTime(filteredDates.startDate),
          getUTCDateTime(filteredDates.endDate, false)
      )
        .then((audits) => audits && setInfographics(audits))
        .catch((error) => console.error(error));
    }
  }, [props.activeTab]);

  const handleDateChange = ({ startDate, endDate }) => {
    const utcStartDate = getUTCDateTime(startDate);
    const utcEndDate = getUTCDateTime(endDate, false);
    if (startDate && endDate) {
      fetchUserAudits(0, searchQuery, utcStartDate, utcEndDate)
        .then((audits) => audits && setInfographics(audits))
        .catch((error) => console.error(error));

      setPageIndex(0);
    }
  };

  const blockFutureDates = (day) => moment(day).diff(moment()) > 0;

  return (
    <div className="infographic">
      <div className="infographic-datepicker">
        <DateRangePicker
          startDateId="startDate"
          endDateId="endDate"
          startDate={filteredDates.startDate}
          endDate={filteredDates.endDate}
          maxDate={moment()}
          onDatesChange={setFilteredDates}
          focusedInput={focusedInput}
          onFocusChange={setFocusedInput}
          showClearDates
          showDefaultInputIcon
          minimumNights={0}
          isOutsideRange={blockFutureDates}
          noBorder
          small
          anchorDirection="right"
          hideKeyboardShortcutsPanel
          onClose={handleDateChange}
        />
      </div>
      <div className="d-flex flex-column">
        <InfographicTable
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          fetchItems={fetchAudits}
          title={data.table.title}
          totalItems={infographics.totalItems}
          items={infographics.items}
          itemColumns={data.table.columns}
          fetchAllTableItems={fetchAllInfographics}
          filteredDates={filteredDates}
          searchBy={searchQuery}
          onSearch={handleSearch}
          activeTab={props.activeTab}
          allColumns={allColumns}
        />
      </div>
    </div>
  );
};

export default AuditInfographics;
