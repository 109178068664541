import React, { useState } from "react";
import { NavLink, useParams, useHistory } from "react-router-dom";
import { TextInput } from "src/components/ui/FormInputs";
import { signInApiCall } from "src/api/auth";
import { Button, InputGroup } from "react-bootstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { setLocalStorageItem } from "src/utils/common";
import {
  USER_VOUCHER_ROUTE,
  FORGOT_PASSWORD_ROUTE,
} from "src/constants/appRoutes";
import authLogo from "src/assets/images/authLogo.svg";
import invisibleIcon from "src/assets/images/invisibleIcon.svg";
import visibleIcon from "src/assets/images/visibleIcon.svg";
import lockIcon from "src/assets/images/lockIcon.svg";
import userIcon from "src/assets/images/userIcon.svg";
import auth from "src/assets/images/auth.png";
import "./LabVoucher.scss";
import TermsAndPrivacySection from "src/components/TermsAndPrivacySection";

const initialValues = { email: "", password: "" };
const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const VoucherLogin = ({ launchLab }) => {
  const { code } = useParams();
  const history = useHistory();
  const navigaeteToForgotPass = () => history.push(FORGOT_PASSWORD_ROUTE);
  const [loginError, setLoginError] = useState("");
  const resetLoginError = () => setLoginError("");
  let [passwordVisible, togglePasswordVisibility] = useState<Boolean>(false);
  const changePasswordVisibility = (e) =>
    togglePasswordVisibility(!passwordVisible);

  const onSubmitHandle = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    let data: { token: string } = await signInApiCall(
      values.email,
      values.password
    );
    if (data && data.token) {
      setLocalStorageItem("token", data.token);
      setLocalStorageItem("lastLoginTimestamp", `${Date.now()}`);
      setLocalStorageItem("showNotifications", "1");
      setLoginError("");
      launchLab();
      resetForm();
    } else {
      setLoginError("Wrong email or password");
    }
    setSubmitting(false);
  };

  return (
    <div className="auth-layout d-flex flex-row justify-content-around">
      <div className="left-section d-flex flex-column justify-content-center">
        <div className="left-section-signin d-flex flex-grow-1 flex-column align-items-center justify-content-center">
          <div className="login-header pb-2">WELCOME TO</div>
          <div className="login-logo d-flex justify-content-center mb-n1">
            <img src={authLogo} alt="authLogo" />
          </div>
          <div className="login-section mt-3 p-4">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmitHandle}
            >
              {({ errors, touched }) => (
                <Form>
                  <div className="d-flex flex-column">
                    <div className="login-section-header mb-4 w-75">
                      Login to redeem your voucher
                    </div>
                    <TextInput
                      name="email"
                      placeholder="Email"
                      required
                      onFocus={resetLoginError}
                      className="border-none"
                      inputGroupProps={{ className: "mb-2" }}
                      prepend={
                        <InputGroup.Text className="input-icon-background">
                          <img src={userIcon} alt="userIcon" />
                        </InputGroup.Text>
                      }
                    />
                    <TextInput
                      name="password"
                      placeholder="Password"
                      type={passwordVisible ? "text" : "password"}
                      className="border-none"
                      required
                      onFocus={resetLoginError}
                      inputGroupProps={{ className: "mb-2" }}
                      prepend={
                        <InputGroup.Text className="input-icon-background">
                          <img src={lockIcon} alt="passwordIcon" />
                        </InputGroup.Text>
                      }
                      append={
                        <InputGroup.Text className="input-icon-background cursor-pointer">
                          <img
                            onClick={changePasswordVisibility}
                            src={passwordVisible ? visibleIcon : invisibleIcon}
                            alt="invisibleIcon"
                          />
                        </InputGroup.Text>
                      }
                    />
                    <div
                      onClick={navigaeteToForgotPass}
                      className={`redeem-voucher-forgot-pass cursor-pointer d-flex flex-row-reverse ${
                        errors.password && touched.password
                          ? "redeem-voucher-pass-err"
                          : "mt-n3"
                      }`}
                    >
                      Forgot Password?
                    </div>
                    {loginError && (
                      <div className="error-message">{loginError}</div>
                    )}
                    <div className="login-section-submit mt-4">
                      <Button
                        variant="outline-secondary"
                        className="w-100"
                        type="submit"
                      >
                        Login
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <div className="redeem-voucher-register my-4 py-2 px-4 font-small">
            Don't have an account?
            <NavLink
              to={`${USER_VOUCHER_ROUTE}/${code}/register`}
              className="text-decoration-none cursor-pointer"
            >
              {" "}
              Register
            </NavLink>
          </div>
        </div>
        <div className="terms-privacy-section mt-auto px-3 mb-4">
          <TermsAndPrivacySection />
        </div>
      </div>
      <div className="right-section">
        <img className="right-section-image w-100" src={auth} alt="auth" />
      </div>
    </div>
  );
};

export { VoucherLogin };
