import React from "react";
import { Editor as ReactEditor } from "@tinymce/tinymce-react";
import {uploadImg} from "src/api/common";

const editorApiKey = process.env.REACT_APP_EDITOR_API_KEY;

type EditorProps = {
  updateEditorContent: (content: string) => void;
  height?: number | string;
  width?: number | string;
  initialContent?: string;
  className?: string;
};

const Editor: React.FC<EditorProps> = ({
  updateEditorContent,
  height = 300,
  width = 400,
  initialContent = "",
  className,
}) => {
  const editContent = (content) => {
    updateEditorContent(content);
  };

  return (
    <div className={className}>
      <ReactEditor
        // value={initialContent}
        initialValue={initialContent}
        apiKey={editorApiKey}
        init={{
          browser_spellcheck: true,
          menubar: false,
          height: height,
          width: width,
          images_upload_url: "",
            images_upload_handler: async (blobInfo, success, failure) => {
                const data = await uploadImg(blobInfo.blob());
                if (data && data.url) {
                    success(data.url);
                } else {
                    failure('');
                }
            },
            automatic_uploads: true,
          plugins: [
            "advlist autolink lists link image charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code help wordcount",
          ],
          toolbar:
            "formatselect | bold italic underline | \
               alignleft aligncenter alignright alignjustify | \
               bullist numlist outdent indent image | removeformat",
        }}
        onEditorChange={editContent}
      />
    </div>
  );
};

export default Editor;
