import React, { useEffect, useState, useMemo } from "react";
import Button from "react-bootstrap/Button";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import { getUserInitials } from "src/helpers/users";
import fullLogoIcon from "src/assets/images/logo.svg";
import { getUser } from "src/state/user/actions";
import { useSelector, useDispatch } from "react-redux";
import { IUser } from "src/state/user/reducer";
import { RootState } from "src/state/rootReducer";
import CustomTooltip from "src/components/ui/CustomTooltip";
import WarningModal from "src/components/shared/WarningModal";
import menuIcon from "src/assets/images/menu.svg";
import whiteMenuIcon from "src/assets/images/menu-white.svg";
import logoWhiteIcon from "src/assets/images/pure-white.svg";
import consoleWhiteIcon from "src/assets/images/console-light.svg";
import presentationWhiteIcon from "src/assets/images/presentation-light.svg";
import deleteWhiteIcon from "src/assets/images/delete-light.svg";
import consoleDarkIcon from "src/assets/images/console-dark.svg";
import presentationDarkIcon from "src/assets/images/presentation-dark.svg";
import deleteDarkIcon from "src/assets/images/delete-dark.svg";
import logoIcon from "src/assets/images/logo-icon.svg";
import timeExpiryIcon from "src/assets/images/time-expiry.svg";
import { deleteUserLabById, extendLabTime } from "src/api/lab";
import "./AppHeader.scss";
import useTimer from "src/hooks/useTimer";
import {
  CONSOLE_ROUTE,
  USERS_ROUTE,
  PRESENTATION_ROUTE,
  LABS_ROUTE,
} from "src/constants/appRoutes";
import { toCamelCaseKeys } from "src/helpers/common";
import PureModal from "src/components/shared/PureModal";
import CustomDropdown from "src/components/AppHeader/CustomDropdown";
import {
  getLocalStorageItem,
  parseJwtToken,
  formatError,
  setLocalStorageItem,
} from "src/utils/common";
import Clock from "../Clock";
import { setSidebar } from "src/state/sidebar/actions";
import { pushNotification } from "../ui/Notification";
import { ReactComponent as FullScreenIcon } from "../../assets/images/fullScreen.svg";
import Select, {
  components,
  OptionProps,
  ValueContainerProps,
} from "react-select";
import { ReactComponent as NewTabIcon } from "../../assets/images/external-link.svg";
import { cx } from "@emotion/css";
import NotificationList from "src/components/AppHeader/NotificationList";
import LoginNotifications from "src/components/LoginNotifications";

export interface DropdownOption {
  label: string;
  value: string;
}

type AppHeaderProps = {
  lab?: any;
  onToggleFullScreen?: () => void;
  isFullScreen?: boolean;
  labVMOptions?: DropdownOption[];
  switchLabVm?: (vm: DropdownOption) => void;
  handleConsoleClick?: () => void;
  handleKeys?: (key: string | string[]) => void
  handleKeyboardLayout?: (layout: string) => void
  handlePresentationClick?: (newState: string) => void
  onDeleteLab?: () => void
};

const AppHeader: React.FC<AppHeaderProps> = ({
  lab,
  isFullScreen,
  onToggleFullScreen,
  labVMOptions = [],
  switchLabVm,
  handleConsoleClick,
  handleKeys,
  handlePresentationClick,
  onDeleteLab
}) => {
  const toggleSidebar = () => {
    return dispatch(setSidebar());
  };
  const [labDetails, setLabDetail] = useState<any>(lab);

  const [isVMMenuOpen, setIsVMMenuOpen] = useState(false);
  const toggleVMMenu = () => setIsVMMenuOpen(!isVMMenuOpen);
  const closeVMMenu = () => setIsVMMenuOpen(false);

  const [timeWarningModal, setTimeWarningModal] = useState(false);
  const toggleTimeWarningModal = () => setTimeWarningModal(!timeWarningModal);
  const [extendBtnVisible, setextendBtnVisibility] = useState(false);
  const [deleteLabWarning, setDeleteLabWarning] = useState<boolean | undefined>(
    false
  );
  const [extendTimeModal, setExtendTimeModal] = useState(false);
  const [extendTimeWarning, setExtendTimeWarning] = useState(false);

  const [labExpiryModal, setLabExpiryModal] = React.useState(false);
  const toggleLabExpiryModal = () => setLabExpiryModal(!labExpiryModal);
  const [cancelledLabExpiryModal, setCancelledLabExpiryModal] = useState(false);
  const cancelAndToggleLabExpiryModal = () => {
    toggleLabExpiryModal();
    setCancelledLabExpiryModal(true);
  };

  const { pathname, search } = useLocation();
  const onConsole = pathname.includes(CONSOLE_ROUTE) || pathname.includes(PRESENTATION_ROUTE);


  const history = useHistory();
  const dispatch = useDispatch();
  const urlParams = new URLSearchParams(search);
  const labId = urlParams.get("id");
  const labName = labDetails?.customerInfo?.name || labDetails?.lab?.name;
  const shutdownAtTime = labDetails?.shutdownAtTime;
  const allowExtensionHours = labDetails?.lab?.allowExtensionHours;
  const allowedExtensionHours = labDetails?.lab?.allowedExtensionHours;
  const extendedHours = labDetails?.extendedHours;
  const allowUsageTimeout = labDetails?.lab?.allowUsageTimeout;

  const toggleExtendBtnVisiblity = () =>
    setextendBtnVisibility(!extendBtnVisible);

  const user: IUser = useSelector((state: RootState) => state.user);
  const token = getLocalStorageItem("token");
  const parsedToken = useMemo(() => parseJwtToken(token), [token]);
  const showUserAdminConsole = parsedToken.admin_access;

  const deleteLab = async (showError = true) => {
    await deleteUserLabById(labId, showError);
    onDeleteLab?.()
    history.push(USERS_ROUTE);
  };

  const vmPollingStatus: boolean = useSelector(
    (state: RootState) => state.ui.vmPollingStatus
  );

  const navigateToLabsPage = async () => {
    toggleTimeWarningModal();
    setLocalStorageItem("lastUserActivityTimestamp", `${Date.now()}`);
    await deleteLab(false);
    history.push(LABS_ROUTE);
  };

  const prepareLabExpiredModal = () => {
    if (labExpiryModal) {
      setLabExpiryModal(false)
    }
    toggleTimeWarningModal()
  }

  const timeRemaining = useTimer(
    shutdownAtTime,
    !shutdownAtTime ? null : 1000,
    !shutdownAtTime ? false : null,
      prepareLabExpiredModal
  );

  const updateUser = (changedObj) => {
    if (changedObj.key === "token") {
      dispatch(getUser());
    }
  };

  useEffect(() => {
    setLabDetail(lab);
  }, [lab]);

  let showNotifications = React.useRef("0")
  useEffect(() => {
    dispatch(getUser());
    showNotifications.current = localStorage.getItem("showNotifications") || "0"
    window.addEventListener("storage", updateUser);
    return () => window.removeEventListener("storage", updateUser);
  }, []);

  const killIntervalPredicate =
    +timeRemaining.hours <= 0 &&
    +timeRemaining.minutes <= 0 &&
    +timeRemaining.seconds <= 0;

  React.useEffect(() => {
    if (
      +timeRemaining.hours < 1 &&
      +timeRemaining.minutes < 16 &&
      !killIntervalPredicate &&
      allowExtensionHours &&
      !cancelledLabExpiryModal
    ) {
      if (extendedHours < allowedExtensionHours) {
        setLabExpiryModal(true);
      }
    }
  }, [timeRemaining.minutes]);

  const navigateToConsole = () => {
    if (!pathname.includes(CONSOLE_ROUTE)) {
      history.push(CONSOLE_ROUTE + search);
    }
    handleConsoleClick?.();
  };
  const toggleWarningModal = () => setDeleteLabWarning(!deleteLabWarning);
  const toggleExtendTimeModal = () => setExtendTimeModal(!extendTimeModal);
  const toggleExtendTimeWarning = () =>
    setExtendTimeWarning(!extendTimeWarning);

  const extendRemainingTime = async () => {
    if (labExpiryModal) {
      toggleLabExpiryModal();
    }
    if (extendedHours >= allowedExtensionHours) {
      toggleExtendTimeWarning();
      if (!labExpiryModal) {
        toggleExtendTimeModal();
      }
    } else if (allowUsageTimeout) {
      setExtendTimeModal(false);
      let lab = await extendLabTime(labId);
      if (lab.isEmpty || lab.hasError) {
        pushNotification({
          type: "error",
          message: formatError(lab),
        });
      } else {
        lab = toCamelCaseKeys(lab);
        setLabDetail(lab);
        setCancelledLabExpiryModal(false);
      }
    }
  };

  const gotoPresentation = () => {
    if (!pathname.includes(PRESENTATION_ROUTE)) {
      handlePresentationClick?.('disconnected')
      history.push(`${PRESENTATION_ROUTE}${window.location.search}`);
    }
  };

  const customSelectStyles = {
    container: (styles) => ({
      ...styles,
      minWidth: 115,
      marginLeft: 15
    }),
    control: (styles) => ({
      ...styles,
      backgroundColor: '#222',
      minHeight: 24,
      borderRadius: 4
    }),
    singleValue: (styles) => ({
      ...styles,
      color: 'white',
      fontSize: 12
    }),
    input: (styles) => ({
      ...styles,
      lineHeight: '16px'
    }),
    indicatorsContainer: (styles) => ({
      ...styles,
      div: {
        padding: '4px 0 4px 2px'
      }
    }),
    valueContainer: (styles) => ({
      ...styles,
      padding: '2px 2px 2px 8px',
    }),
    indicatorSeparator: () => ({
      display: 'none'
    }),
    menu: (styles) => ({
      ...styles,
      zIndex: 10001,
      color: 'black',
      minWidth: 150
    }),
    option: (styles) => ({
      ...styles,
      cursor: 'pointer'
    })
  }

  const sendKeysDropdownOptions = [
    {
      label: 'Ctrl + Alt + Delete',
      value: 'cad'
    },
    {
      label: 'Enter',
      value: [13]
    }
  ]

  const LabVMSelectOption = ({ innerRef, ...props }: OptionProps) => {
    const openConsole = () => {
      window.open(
        `${CONSOLE_ROUTE}?id=${labDetails.gid}&console_vm=${props.value}`,
        "_blank"
      );
      closeVMMenu();
    };

    return (
      <div className="option-container">
        <components.Option {...props} isDisabled={props.isSelected} />
        {!props.isSelected && (
          <Button variant="link" onClick={openConsole}>
            <NewTabIcon />
          </Button>
        )}
      </div>
    );
  };

  const ValueContainer = ({ children, ...props }: ValueContainerProps) => {
    const value = props.getValue()[0]?.label;

    return (
      <components.ValueContainer {...props}>
        <button
          onClick={navigateToConsole}
          className={cx("simple-btn", "value-container-btn", {
            "font-weight-bold":
              !deleteLabWarning && pathname.includes("console"),
          })}
        >
          {value}
        </button>
      </components.ValueContainer>
    );
  };

  const DropdownIndicator = (props) => (
    <components.DropdownIndicator
      {...props}
      innerProps={{ onClick: toggleVMMenu }}
    />
  );

  const defaultLabVMOption = labDetails?.environmentConfig?.consoleVmName
    ? labVMOptions?.find(
        (option) =>
          option.value === labDetails?.environmentConfig?.consoleVmName
      )
    : labVMOptions[0];

  const handleVMMenuChange = (value) => {
    switchLabVm?.(value);
    closeVMMenu();
  };

  const runningSkytap = lab?.lab?.labProviderType === "SKYTAP"

  return (
    <>
      {showNotifications.current === "1" && <LoginNotifications />}
      <PureModal
        title="Alert"
        primaryBtnLabel="Extend"
        secondaryBtnLabel="Cancel"
        showModal={labExpiryModal}
        primaryBtnAction={extendRemainingTime}
        secondaryBtnAction={cancelAndToggleLabExpiryModal}
        closeModal={cancelAndToggleLabExpiryModal}
        centered={true}
        className="highest-ZIndex"
      >
        <div className="d-flex">
          <img
            src={timeExpiryIcon}
            alt="lab expiry warning"
            className="align-self-start mr-4"
          />
          <span>
            Your lab is about to expire. If you wish to continue, please click
            on the Extend button below.{" "}
          </span>
        </div>
      </PureModal>
      {timeWarningModal && (
        <WarningModal
          title="Warning"
          confirmBtnLabel="Ok"
          cancelBtnLabel=""
          onConfirmAction={navigateToLabsPage}
          className="highest-ZIndex"
        >
          Sorry! The lab has been expired. Please re-launch the lab and
          continue.
        </WarningModal>
      )}
      {deleteLabWarning && (
        <WarningModal
          title="Delete Lab"
          confirmBtnLabel={"Yes"}
          cancelBtnLabel={"No"}
          onCancelAction={toggleWarningModal}
          onConfirmAction={deleteLab}
          closeModal={toggleWarningModal}
          className={vmPollingStatus ? "highest-ZIndex" : ""}
        >
          <div>
            <b> Are you sure you want to delete {labName} ? </b>
          </div>
        </WarningModal>
      )}
      {extendTimeWarning && (
        <WarningModal
          title="Extend Remaining Time"
          confirmBtnLabel="Ok"
          cancelBtnLabel=""
          onConfirmAction={toggleExtendTimeWarning}
          closeModal={toggleExtendTimeWarning}
          className="highest-ZIndex"
        >
          <div>
            <b>
              {" "}
              The time remaining for this lab cannot be extended any further{" "}
            </b>
          </div>
        </WarningModal>
      )}
      {extendTimeModal && (
        <PureModal
          size="sm"
          title="Extend Time Remaining"
          primaryBtnLabel="Yes"
          secondaryBtnLabel="No"
          showModal={extendTimeModal}
          primaryBtnAction={extendRemainingTime}
          secondaryBtnAction={toggleExtendTimeModal}
          closeModal={toggleExtendTimeModal}
          centered={true}
          className="highest-ZIndex"
        >
          <div className="time-modal">
            Are you sure you want to extend the time remaining for this lab ?
          </div>
        </PureModal>
      )}
      <header
        className={`header ${
          onConsole ? "header-console" : "header-user"
        } ${runningSkytap ? "header-skytap" : "header-vcd"}`}
      >
        <div className="header-icon">
          {!pathname.includes("admin") && (
            <div className="header-icon_menu" onClick={toggleSidebar}>
              <img
                src={onConsole ? whiteMenuIcon : menuIcon}
                alt="menu"
                className="header-icon_img mr-1 ml-2"
                height="13rem"
              />
            </div>
          )}
          <div className="header-icon_logo">
            <NavLink to="/">
              <img
                src={onConsole ? logoWhiteIcon : fullLogoIcon}
                alt="logo"
                height="22"
                className={`cursor-pointer ${onConsole ? "logo-full" : ""}`}
              />
              <img
                src={logoIcon}
                alt="logo"
                height="22"
                className={`cursor-pointer logo-half ${
                  !pathname.includes(CONSOLE_ROUTE) &&
                  !pathname.includes(PRESENTATION_ROUTE) &&
                  "d-none"
                }`}
              />
            </NavLink>
          </div>
          {pathname.includes("admin") && (
            <>
              <div className="header-icon_separator" />
              <div className="header-icon_user"> Administration </div>
            </>
          )}
          {onConsole && (
            <>
              <div className="header-icon_separator separator-white" />
              <CustomTooltip text={labName}>
                <div className="header-icon_user text-white lab-name overflow-ellipsis">
                  {labName}
                </div>
              </CustomTooltip>
              <div className="header-icon_separator separator-black" />
              <div className="console-btns">
                <div className="console-btn" onClick={gotoPresentation}>
                  <div className="console-btn-img">
                    <img
                      src={
                        !deleteLabWarning && pathname.includes("presentation")
                          ? presentationWhiteIcon
                          : presentationDarkIcon
                      }
                      alt="presentation"
                    />
                  </div>
                  <span
                    className={cx(
                      "console-btn-span",
                      !deleteLabWarning && pathname.includes("presentation")
                        ? "font-weight-bold"
                        : ""
                    )}
                  >
                    Presentation
                  </span>
                </div>
                <div className="console-btn">
                  <div className="console-btn-img" onClick={navigateToConsole}>
                    <img
                      src={
                        !deleteLabWarning && pathname.includes("console")
                          ? consoleWhiteIcon
                          : consoleDarkIcon
                      }
                      alt="console"
                    />
                  </div>
                  {labVMOptions?.length <= 1 ? (
                    <span
                      onClick={navigateToConsole}
                      className={cx(
                        "console-btn-span",
                        !deleteLabWarning && pathname.includes("console")
                          ? "font-weight-bold"
                          : ""
                      )}
                    >
                      Console
                    </span>
                  ) : (
                    <Select
                      className="vm-select-container"
                      classNamePrefix="vm-select"
                      placeholder="Select"
                      options={labVMOptions}
                      value={defaultLabVMOption}
                      menuIsOpen={isVMMenuOpen}
                      onChange={handleVMMenuChange}
                      isSearchable={false}
                      isLoading={!labVMOptions}
                      isDisabled={!labVMOptions}
                      components={{
                        Option: LabVMSelectOption,
                        ValueContainer,
                        DropdownIndicator,
                      }}
                    />
                  )}
                </div>
                <div className="console-btn" onClick={onToggleFullScreen}>
                  <div className="console-btn-img">
                    <FullScreenIcon
                      width="30"
                      height="27"
                      className={isFullScreen ? "" : "full-screen-off"}
                    />
                  </div>
                  <span
                    className={cx(
                      "console-btn-span",
                      { "font-weight-bold": isFullScreen },
                      "full-screen-span"
                    )}
                  >
                    Full Screen
                  </span>
                </div>
                <div className="console-btn" onClick={toggleWarningModal}>
                  <div className="console-btn-img">
                    <img
                      src={deleteLabWarning ? deleteWhiteIcon : deleteDarkIcon}
                      alt="delete"
                    />
                  </div>
                  <span
                    className={cx("console-btn-span", {
                      "font-weight-bold": deleteLabWarning,
                    })}
                  >
                    Delete
                  </span>
                </div>
              </div>
            <div className="header-icon_separator separator-black" />
          </>
          )}
        </div>

        <div className="header-end">
          {onConsole && !!shutdownAtTime && (
            <div
              className={`header-middle align-items-center ${
                extendBtnVisible ? "visible" : "hidden"
              }`}
              onMouseEnter={toggleExtendBtnVisiblity}
              onMouseLeave={toggleExtendBtnVisiblity}
            >
              <div className={extendBtnVisible ? "" : "reorder-clock"}>
                <Clock
                  time={timeRemaining}
                  shutdownAtTime={shutdownAtTime}
                  createdOn={labDetails?.createdOn}
                />
              </div>
              <div className="header-middle-time mx-4">
                <span className="header-middle-time_text">Time Remaining</span>
                {!!timeRemaining.hours && !!timeRemaining.minutes && (
                  <div>
                    <div className="header-middle-time_unit">
                      {+timeRemaining.hours >= 0 ? timeRemaining.hours : '00'}
                    </div>
                    <span> : </span>
                    <div className="header-middle-time_unit">
                      {+timeRemaining.minutes >= 0 ? timeRemaining.minutes : '00'}
                    </div>
                  </div>
                )}
              </div>
              {allowExtensionHours && (
                <div
                  className="header-middle-btn"
                  onClick={toggleExtendTimeModal}
                >
                  <Button className="header-middle-btn_extend center-child">
                    Extend
                  </Button>
                </div>
              )}
            </div>
          )}
          <div className="header-right">
            {onConsole && lab?.lab?.labProviderType && lab?.lab?.labProviderType !== "SKYTAP" && (
              <>
                <Select
                  id="send-keys-dropdown"
                  value={vmPollingStatus ? "Loading" : "Send Keys"}
                  options={sendKeysDropdownOptions}
                  styles={customSelectStyles}
                  isLoading={vmPollingStatus}
                  onChange={key => handleKeys?.(key.value)}
                />
                <div className="header-icon_separator separator-black ml-3" />
              </>
            )}
            <div className="header-right_profile">
              <NotificationList />
              <div className="userprofile-image ml-3">
                <span className="initials"> {getUserInitials(user.name)} </span>
              </div>
              <CustomDropdown
                name={user.name}
                showUserAdminConsole={showUserAdminConsole}
                userInfo={parsedToken}
              />
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default AppHeader;
