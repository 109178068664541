import React from "react";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import "./ErrorPage.scss";
import logo from "src/assets/images/logo.svg";

type ErrorPageProps = {
  title?: string;
  message: string;
  btnLabel: string;
  children?: React.ReactNode;
  hideGoBackButton?: boolean;
};

const ErrorPage: React.FC<ErrorPageProps> = ({
  title,
  message,
  btnLabel,
  children,
  hideGoBackButton,
}) => {
  return (
    <div className="error-container">
      <header className="header header-user error-container-header">
        <Link to="/">
          <img
            src={logo}
            alt="logo"
            height="30rem"
            className="ml-4 cursor-pointer"
          />
        </Link>
      </header>
      <div className="d-flex flex-column h-100 justify-content-center">
        {children}
        <div className="d-flex align-items-center flex-column mt-5 mb-2">
          {title && <h5> {title} </h5>}
          <p> {message} </p>
        </div>
        {!hideGoBackButton && (
          <Link to="/">
            <Button> {btnLabel} </Button>
          </Link>
        )}
      </div>
    </div>
  );
};

export default ErrorPage;
