import { ReactComponent as UserIcon } from "src/assets/images/user-primary.svg";
import { ReactComponent as UsersIcon } from "src/assets/images/users-multiple.svg";
import { ReactComponent as UserPlusIcon } from "src/assets/images/user-plus.svg";
import React from "react";
import CustomToolTip from "src/components/ui/CustomTooltip/CustomTooltip";
import { sortByDate, sortColumn } from "src/utils/common";

export const loginData = (stats) => ({
  table: {
    title: "List of All Logins to the System",
    columns: [
      {
        Header: "Time/Date of login",
        accessor: "createdOn",
        sortType: (rowA, rowB, colId, desc) =>
          sortByDate(rowA, rowB, desc, "createdOn"),
      },
      {
        Header: "Email Address",
        accessor: "email",
      },
      {
        Header: "Name of User",
        accessor: "name",
      },
      {
        Header: "Company",
        id: "company",
        accessor: (row) => row.company,
        sortType: (rowA, rowB, colId, desc) =>
          sortColumn(rowA, rowB, "Company", desc),
      },
      {
        Header: "IP Address",
        accessor: "ip",
      },
      {
        Header: "User Agent",
        id: "agent",
        accessor: (row) => row.agent,
        sortType: (rowA, rowB, colId, desc) =>
          sortColumn(rowA, rowB, "Agent", desc),
        Cell: ({ value }) => (
          <CustomToolTip text={value} className="login-infographic-tooltip">
            <span className="overflow-ellipsis" title="">
              {value}
            </span>
          </CustomToolTip>
        ),
      },
    ],
  },
  charts: {
    radial: {
      title: "Total Number of Users",
      count:
        stats.userStats.corporate +
        stats.userStats.partner +
        stats.userStats.adhoc,
      data: [
        {
          name: "Corporate",
          value: stats.userStats.corporate,
          fill: "#FE5009",
        },
        { name: "Partner", value: stats.userStats.partner, fill: "#55BA9E" },
        { name: "Adhoc", value: stats.userStats.adhoc, fill: "#929497" },
      ],
      dataKey: "value",
    },
    bar: {
      title: "Number of Users Logged In",
      barSize: 5,
      data: stats.barChartStats,
      bars: [
        {
          dataKey: "Corporate",
          fill: "#FE5009",
        },
        {
          dataKey: "Partner",
          fill: "#55BA9E",
        },
        {
          dataKey: "Adhoc",
          fill: "#929497",
        },
      ],
    },
  },
  cards: [
    {
      title: "Corporate Users Login",
      totalItems: stats.userAuditStats.corporate,
      icon: UserIcon,
      iconBackgroundColor: "#ffdccd",
      width: "24%",
    },
    {
      title: "Partner Users Login",
      totalItems: stats.userAuditStats.partner,
      icon: UsersIcon,
      iconBackgroundColor: "#DDF1EC",
      width: "24%",
    },
    {
      title: "Adhoc Users Login",
      totalItems: stats.userAuditStats.adhoc,
      icon: UserPlusIcon,
      iconBackgroundColor: "#E8E8E9",
      width: "24%",
    },
    {
      title: "Adhoc Users Invited",
      totalItems: stats.userAuditStats.adhocInvited,
      icon: UserPlusIcon,
      iconBackgroundColor: "#E8E8E9",
      width: "24%",
    },
  ],
});
