import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import "./CustomTooltip.scss";

type CustomTooltipProps = {
  children: any;
  text: any;
  placement?: any;
  className?: string;
};

const CustomTooltip: React.FC<CustomTooltipProps> = ({
  children,
  text,
  placement = "bottom",
  className,
}) => {
  return (
    <OverlayTrigger
      key={placement}
      placement={placement}
      trigger={["hover", "focus"]}
      overlay={
        <Popover id="tooltip" className={`highest-ZIndex ${className}`}>
          {typeof text === "string" ? (
            text
          ) : (
            <Popover.Content>{text}</Popover.Content>
          )}
        </Popover>
      }
    >
      {children}
    </OverlayTrigger>
  );
};

export default CustomTooltip;
