export const GET_USER = 'GET_USER';

export interface IUser {
	id: string;
	email: string;
	name: string;
	userType: number;
	type: string;
	userRole: number;
	role: string;
	isActive: boolean;
	isInvited: boolean;
	labsLaunched: number;
	status: string;
	company: {
		id: string;
		name: string;
	};
	lastLogin: string;
}

const initialState: IUser = {
	id: "",
	email: "",
	name: "",
	userType: 0,
	type: "",
	userRole: 0,
	role: "",
	isActive: false,
	isInvited: false,
	labsLaunched: 0,
	status: "",
	company: {
		id: "",
		name: "",
	},
	lastLogin: "",
}


export default (state = initialState, action) => {
	switch (action.type) {
		case GET_USER:
			return action.payload;
		default:
			break;
	}
	return state;
};
