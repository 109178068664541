import React from "react";
import PureModal from "src/components/shared/PureModal";
import { Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import InputErrorMessage from "src/components/shared/InputErrorMessage";
import "./UploadFile.scss";
import { isEmpty } from "src/utils/common";
import { uploadFile } from "src/api/presentations";
import { useDispatch } from "react-redux";
import { updateFolder } from "src/state/presentations/actions";
import { SupportedFileFormat } from "src/constants/regex";
import { toCamelCaseKeys } from "src/helpers/common";

type UploadFileProps = {
  setShowModal: (flag: boolean) => void;
  parent?: any;
  currentFolder?: any;
};

const validationSchema = Yup.object({
  file: Yup.mixed()
    .required("A file is required")
    .test("fileFormat", "Unsupported Format", (value) => {
      return !!value && !!value.name && SupportedFileFormat.exec(value.name);
    }),
});

const UploadFile: React.FunctionComponent<UploadFileProps> = ({
  parent,
  currentFolder,
  ...props
}) => {
  const dispatch = useDispatch();

  const onSubmitHandle = async (values, { setSubmitting }) => {
    const formData = new FormData();
    formData.append("upload", values.file);
    !!parent.id && formData.append("parent", parent.id);
    setSubmitting(true);
    let data = await uploadFile(formData);
    setSubmitting(false);
    if (!isEmpty(data) && !data.error) {
      dispatch(
        updateFolder(toCamelCaseKeys(data), parent.id, currentFolder.id)
      );
      props.setShowModal(false);
    }
  };

  return (
    <Formik
      initialValues={{ file: undefined }}
      validationSchema={validationSchema}
      onSubmit={onSubmitHandle}
    >
      {(formik) => (
        <PureModal
          title="Upload File"
          showModal={true}
          closeModal={() => props.setShowModal(false)}
          primaryBtnLabel="Upload"
          primaryBtnAction={formik.handleSubmit}
          size="sm"
        >
          <Form>
            <Form.Group>
              <Form.Control
                type="file"
                id="file"
                onChange={(event: any) => {
                  formik.setFieldTouched("file");
                  formik.setFieldValue("file", event.target?.files[0]);
                }}
              />
              {formik.errors.file && (
                <InputErrorMessage
                  className=""
                  errorMessage={formik.errors.file || ""}
                />
              )}
            </Form.Group>
          </Form>
        </PureModal>
      )}
    </Formik>
  );
};

export default UploadFile;
