import produce from "immer";
import { IUser } from "../users/reducer";

export const ADD_GROUPS = "ADD_GROUPS";
export const ADD_GROUP_ROLES = "ADD_GROUP_ROLES";
export const DELETE_GROUP = "DELETE_GROUP";
export const FETCH_GROUP_DETAIL = "FETCH_GROUP_DETAIL";
export const DELETE_GROUP_USER = "DELETE_GROUP_USER";

export interface IGroups {
  list: Array<IGroup>;
  groupRoles: Array<IGroupRole>;
  groupDetail: any;
}

export interface IGroupRole {
  id: number;
  roleName: string;
}

export interface IGroup {
  id: number;
  name: string;
  role: number;
  groupRole: string;
  userCount: number;
  assignedLabsCount: number;
  isSystem: boolean;
  users: IUser[];
}

const initialState: IGroups = {
  list: [],
  groupRoles: [],
  groupDetail: {},
};

export default (state = initialState, action): IGroups =>
  produce(state, (draft) => {
    switch (action.type) {
      case ADD_GROUPS:
        draft.list = action.payload;
        break;
      case ADD_GROUP_ROLES:
        draft.groupRoles = action.payload;
        break;
      case DELETE_GROUP:
        draft.list = state.list.filter((group) => group.id !== action.payload);
        break;

      case FETCH_GROUP_DETAIL:
        draft.groupDetail = action.payload;
        break;

      case DELETE_GROUP_USER:
        draft.groupDetail.users = draft.groupDetail.users.filter(
          (user) => user.id !== action.payload
        );
        break;

      default:
        break;
    }
  });
