import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={9.113}
      height={15.512}
      viewBox="0 0 9.113 15.512"
      {...props}
    >
      <path
        data-name="Path 3304"
        d="M8.866 8.361l-6.9 6.9a.85.85 0 01-1.2 0l-.508-.508a.85.85 0 010-1.2l5.8-5.8-5.8-5.8a.85.85 0 010-1.2L.756.248a.85.85 0 011.2 0l6.91 6.909a.856.856 0 010 1.2z"
        fill="#fe5000"
      />
    </svg>
  );
}

export default SvgComponent;
