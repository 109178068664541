import produce from "immer";

export const UPDATE_LABS_APPROVAL_STATUS = "UPDATE_LABS_APPROVAL_STATUS";

export interface TLabsApprovalStatus {
  status: Record<string, string>;
}

const initialState: TLabsApprovalStatus = {
  status: {},
};

export default (state = initialState, action): TLabsApprovalStatus =>
  produce(state, (draft) => {
    switch (action.type) {
      case UPDATE_LABS_APPROVAL_STATUS:
        draft.status = action.payload;
        break;
      default:
        break;
    }
  });
