export const userStatusMap = {
  invited: "Invited",
  disabled: "Disabled",
  active: "Active",
};

export const userTypesMap = {
  adhoc: "adhoc",
  partner: "partner",
  corporate: "corporate",
};

export const userNotLoggedIn = "Not logged in";
