import { RefObject } from "react";

import useEventListener from "./useEventListener";

type Handler = (event: MouseEvent) => void;

function useOnClickOutside<T extends HTMLElement = HTMLElement>(
  refs: RefObject<T>[],
  handler: Handler,
  mouseEvent: "mousedown" | "mouseup" = "mousedown"
): void {
  useEventListener(mouseEvent, (event) => {
    const contains = refs.some((ref) => {
      const el = ref?.current;
      // Do nothing if clicking ref's element or descendent elements
      return !el || el.contains(event.target as Node);
    });

    if (!contains) {
      // Explicit type for "mousedown" event.
      handler(event as unknown as MouseEvent);
    }
  });
}

export default useOnClickOutside;
