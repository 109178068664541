import React from "react";
import PureModal from "src/components/shared/PureModal";
import { Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import InputErrorMessage from "src/components/shared/InputErrorMessage";
import "./CreateFolder.scss";
import RequiredFieldSymbol from "src/components/ui/RequiredFieldSymbol";
import { createFolder } from "src/api/presentations";
import { isEmpty } from "src/utils/common";
import { addFolder } from "src/state/presentations/actions";
import { useDispatch } from "react-redux";
import { mapPresentation } from "src/helpers/presentations";

type CreateFolderProps = {
  setShowModal: (boolean) => void;
  parent?: any;
};

const validationSchema = Yup.object({
  folderName: Yup.string().required("Folder Name is required"),
});

const CreateFolder: React.FunctionComponent<CreateFolderProps> = ({
  parent,
  ...props
}) => {
  const dispatch = useDispatch();

  const onSubmitHandle = async (folder, { setSubmitting }) => {
    let payload = {
      filename: folder.folderName,
      type: "folder",
    };
    if (!!parent.id) {
      payload["parent"] = parent.id;
    }
    setSubmitting(true);
    let data = await createFolder(payload);
    setSubmitting(false);
    if (!isEmpty(data) && !data.hasError) {
      data.name = folder.folderName;
      data.type = "FOLDER";
      data.updatedOn = Date.now();
      dispatch(addFolder(mapPresentation(data)));
      props.setShowModal(false);
    }
  };

  return (
    <Formik
      initialValues={{ folderName: "" }}
      validationSchema={validationSchema}
      onSubmit={onSubmitHandle}
    >
      {(formik) => (
        <PureModal
          title="Create Folder"
          showModal={true}
          closeModal={() => props.setShowModal(false)}
          primaryBtnLabel="Add"
          primaryBtnAction={formik.handleSubmit}
          size="sm"
        >
          <Form>
            <Form.Group>
              <Form.Label>
                Folder Name
                <RequiredFieldSymbol />
              </Form.Label>
              <Form.Control
                type="text"
                id="folderName"
                {...formik.getFieldProps("folderName")}
              />
              {formik.touched.folderName && formik.errors.folderName && (
                <InputErrorMessage
                  className={"error-message"}
                  errorMessage={formik.errors.folderName || ""}
                />
              )}
            </Form.Group>
          </Form>
        </PureModal>
      )}
    </Formik>
  );
};

export default CreateFolder;
