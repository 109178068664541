import React, { ReactNode } from "react";
import rightArrowIcon from "src/assets/images/right-arrow.svg";
import dropdownIcon from "src/assets/images/sort-up-white.svg";
import "./LabsDropdown.scss";

type LabsDropdownProps = {
  initiallyOpen: Boolean;
  lab: string;
  options: Array<ReactNode>;
};

const LabsDropdown: React.FC<LabsDropdownProps> = ({
  lab,
  options,
  initiallyOpen,
}) => {
  const [labsOpen, setLabsState] = React.useState<Boolean>(initiallyOpen);
  const toggleLabs = () => setLabsState(!labsOpen);

  const LabOptions = () => {
    return <div className="labs-items">{options}</div>;
  };

  return (
    <>
      <div className="ml-1 labs pb-3" onClick={toggleLabs}>
        {labsOpen ? (
          <img
            src={dropdownIcon}
            alt="active-dropdown"
            className="labs-icon-active"
            width="10"
          />
        ) : (
          <img
            src={rightArrowIcon}
            alt="dropdown"
            className="labs-icon"
            width="10"
          />
        )}
        <a
          href="#"
          className={`labs-link pure-link-decoration-none ${
            labsOpen && "labs-link-active"
          }`}
        >
          {lab}
        </a>
      </div>
      {labsOpen && <LabOptions />}
    </>
  );
};

export default LabsDropdown;
