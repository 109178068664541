import React, { useEffect, useState } from "react";
import PureModal from "src/components/shared/PureModal";
import { Form, Button } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import InputErrorMessage from "src/components/shared/InputErrorMessage";
import Select from "react-select";
import "./AddOrEditUser.scss";
import { addUser, editUser } from "src/api/users";
import { useDispatch, useSelector } from "react-redux";
import { usersAction } from "src/state/users/actions";
import { getCompanies } from "src/state/companies/actions";
import { RootState } from "src/state/rootReducer";
import { IUser } from "src/state/users/reducer";
import RequiredFieldSymbol from "src/components/ui/RequiredFieldSymbol";
import { pushNotification } from "src/components/ui/Notification";
import { checkDuplicates, invalidDomain } from "src/utils/common";
import { toCamelCase } from "src/helpers/common";
import ManageCompany from "../ManageCompany";

type AddOrEditUserProps = {
  setShowAddOrEditUserModal: (boolean) => void;
  isEditMode: boolean;
  selectedEditUserDetails?: { name: string; company: string; email: string };
  selectedUserId: string;
};

const newUser = { email: "", name: "", company: "" };

checkDuplicates(Yup);
invalidDomain(Yup);

const AddOrEditUser: React.FunctionComponent<AddOrEditUserProps> = ({
  isEditMode,
  selectedEditUserDetails = newUser,
  selectedUserId = "",
  ...props
}) => {
  const dispatch = useDispatch();
  const companies: Array<any> = useSelector(
    (state: RootState) => state.companies.list
  );
  const users: Array<IUser> = useSelector(
    (state: RootState) => state.users.list
  );
  const [showManageCompanyModal, setShowManageCompanyModal] = useState(false);
  const toggleCompanyModal = () => {
    setShowManageCompanyModal(!showManageCompanyModal);
  };
  const validationSchema = Yup.object({
    email: Yup.string()
      .required("Email is required")
      .email("Invalid email address")
      .invalidDomain("Domain length should be greater than 1")
      .checkDuplicate("Email already exists", "email", users, isEditMode),
    name: Yup.string().required("Name is required"),
    company: Yup.string(),
  });

  const initialValues = isEditMode ? selectedEditUserDetails : newUser;

  const submitUser = async (user, { setSubmitting }) => {
    let data;
    let payload = {
      name: toCamelCase(user.name),
      email: user.email.toLowerCase(),
    };

    if (user.company) {
      payload["company"] =
        companies.length &&
        companies.find((company) => company.name === user.company).id;
    }

    if (isEditMode) {
      data = await editUser(payload, selectedUserId);
    } else {
      data = await addUser(payload);
    }

    if (data) {
      props.setShowAddOrEditUserModal(false);
      dispatch(usersAction());
    }
  };

  useEffect(() => {
    dispatch(getCompanies({ showLoader: false }));
  }, []);

  return (
    <>
      {showManageCompanyModal && (
        <ManageCompany
          setShowModal={setShowManageCompanyModal}
          isEditMode={false}
        />
      )}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={submitUser}
      >
        {(formik) => (
          <PureModal
            title={isEditMode ? "Edit User" : "Add User"}
            showModal={true}
            closeModal={() => props.setShowAddOrEditUserModal(false)}
            primaryBtnLabel={isEditMode ? "Save" : "Add"}
            primaryBtnAction={formik.handleSubmit}
            secondaryBtnLabel={"Cancel"}
            secondaryBtnAction={() => props.setShowAddOrEditUserModal(false)}
            className={showManageCompanyModal ? "hidden" : "visible"}
          >
            <Form>
              <Form.Group>
                <Form.Label htmlFor="email">
                  Email address
                  <RequiredFieldSymbol />
                </Form.Label>
                <Form.Control
                  type="email"
                  id="email"
                  className="placeholder-custom-size"
                  disabled={isEditMode}
                  placeholder="Enter email"
                  {...formik.getFieldProps("email")}
                  isInvalid={!!(formik.touched.email && formik.errors.email)}
                />
                <Form.Control.Feedback type="invalid">
                  <InputErrorMessage errorMessage={formik.errors.email || ""} />
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label htmlFor="name">
                  Name <RequiredFieldSymbol />
                </Form.Label>
                <Form.Control
                  type="text"
                  id="name"
                  placeholder="Enter name"
                  {...formik.getFieldProps("name")}
                  isInvalid={!!(formik.touched.name && formik.errors.name)}
                />
                <Form.Control.Feedback type="invalid">
                  <InputErrorMessage errorMessage={formik.errors.name || ""} />
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label htmlFor="company">Company</Form.Label>
                <Select
                  id="company"
                  className="select-company"
                  name="company"
                  size={5}
                  defaultValue={{
                    value: formik.values.company,
                    label: formik.values.company,
                  }}
                  isClearable={true}
                  isLoading={!(companies && companies.length)}
                  onChange={(data) => {
                    formik.setFieldTouched("company");
                    formik.setFieldValue(
                      "company",
                      data && data.value ? data.value : ""
                    );
                  }}
                  options={companies.map((company) => {
                    return { value: company.name, label: company.name };
                  })}
                />
                {(formik.touched.company && formik.errors.company) ||
                  (isEditMode && formik.errors.company && (
                    <InputErrorMessage
                      className={"error-message"}
                      errorMessage={formik.errors.company || ""}
                    />
                  ))}
              </Form.Group>
              {!isEditMode && (
                <Button
                  variant="link"
                  onClick={toggleCompanyModal}
                  type="button"
                  className="p-0"
                >
                  Add Company
                </Button>
              )}
            </Form>
          </PureModal>
        )}
      </Formik>
    </>
  );
};

export default AddOrEditUser;
