import React from "react";
import PureModal from "src/components/shared/PureModal";
import warning from "src/assets/images/warning.svg";

type WarningModalProps = {
  title: string;
  size?: "sm" | "lg" | "xl" | undefined;
  className?: string;
  confirmBtnLabel?: string;
  cancelBtnLabel?: string;
  onConfirmAction?: () => void;
  onCancelAction?: () => void;
  closeModal?: () => void;
  showModal?: boolean;
};

const WarningModal: React.FC<WarningModalProps> = ({
  title,
  confirmBtnLabel = "Ok",
  cancelBtnLabel = "Cancel",
  showModal = true,
  onConfirmAction,
  onCancelAction,
  closeModal,
  className,
  children,
  ...props
}) => {
  return (
    <div>
      <PureModal
        {...props}
        showModal={showModal}
        title={title}
        className={className}
        centered={true}
        primaryBtnLabel={confirmBtnLabel}
        secondaryBtnLabel={cancelBtnLabel}
        primaryBtnAction={onConfirmAction}
        secondaryBtnAction={onCancelAction}
        closeModal={closeModal}
      >
        <div className="d-flex align-items-center">
          <img src={warning} alt="warning" />
          {children}
        </div>
      </PureModal>
    </div>
  );
};

export default WarningModal;
