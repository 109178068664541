import Api from "src/helpers/request";
import {
  formatDateToLocal,
  toCamelCase,
  toCamelCaseKeys,
} from "src/helpers/common";
import { pageSize } from "src/constants/dashboard";

const getUserAudits = "dashboard/activity/user_login";

// export const fetchAuditStats = async () => {
//   try {
//     let response = await Api.call({ url: getAuditStats, method: "GET" });
//     response = toCamelCaseKeys(response);
//     let auditStats: any = {};
//     response.userStats.forEach((stat) => {
//       if (stat.userTypeName === "corporate") {
//         auditStats.corporate = stat.count;
//       } else if (stat.userTypeName === "adhoc") {
//         auditStats.adhoc = stat.count;
//       } else if (stat.userTypeName === "partner") {
//         auditStats.partner = stat.count;
//       }
//     });
//     return auditStats;
//   } catch (e) {
//     console.error(e);
//   }
// };

const mapAudits = (audits) =>
  audits.map((audit) => ({
    lastLogin: audit.lastLogin ? formatDateToLocal(audit.lastLogin) : "-",
    name: audit.name || "-",
    email: audit.email || "-",
    company: audit.company?.name || "-",
    type: toCamelCase(audit.type) || "-",
  }));

const mapAuditsForTable = (audits) =>
  audits.map((audit) => ({
    lastLogin: audit.lastLogin ? formatDateToLocal(audit.lastLogin) : "-",
    name: audit.name || "-",
    email: audit.email || "-",
    company: audit.company?.name || "-",
    type: toCamelCase(audit.type) || "-",
    loginCount: {
      total: audit.loginLife || 0,
      lastThirtyDays: audit.login30 || 0,
      lastThirtySixtyDays: audit.login60 || 0,
      lastSixtyNinetyDays: audit.last90 || 0,
    },
    launchedLabsCount: {
      total: audit.labsLaunchedLife || 0,
      lastThirtyDays: audit.labsLaunched30 || 0,
      lastThirtySixtyDays: audit.labsLaunched60 || 0,
      lastSixtyNinetyDays: audit.labsLaunched90 || 0,
    },
  }));

export const fetchAllUserAudits = async (
  searchQuery?,
  from?,
  to?,
  metaConfig = { showLoader: true }
) => {
  try {
    let url = `${getUserAudits}?pagination=false`;
    if (from && to) {
      url += `&last_login_gte=${from}&last_login_lte=${to}`;
    }
    if (searchQuery) {
      url += `&search=${searchQuery}`;
    }
    const response = await Api.call({ url, method: "GET" }, metaConfig);
    let audits = toCamelCaseKeys(response);
    return mapAuditsForTable(audits);
  } catch (e) {
    console.error(e);
  }
};

export const fetchUserAudits = async (
  offset?: number | null,
  searchQuery?,
  from?,
  to?,
  metaConfig = { showLoader: true }
) => {
  try {
    let url = getUserAudits;
    if (offset || offset === 0) {
      url += `?limit=${pageSize}&offset=${offset}`;
    }
    if (from && to) {
      url += `&last_login_gte=${from}&last_login_lte=${to}`;
    }
    if (searchQuery) {
      url += `&search=${searchQuery}`;
    }
    let response = await Api.call({ url, method: "GET" }, metaConfig);
    response = toCamelCaseKeys(response);
    const audits = mapAudits(response.results);
    return { totalItems: response.count, items: audits };
  } catch (e) {
    console.error(e);
  }
};
