import * as React from "react";

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
      <path
        d="M3.529 12a.706.706 0 00.706.706 7.059 7.059 0 017.059 7.059.706.706 0 00.706.706h11.294a.706.706 0 00.706-.706V.706A.706.706 0 0023.294 0H4.235a.706.706 0 00-.706.706zM4.941 1.412h17.647v17.647h-9.911a8.473 8.473 0 00-7.736-7.736zm9.469 9.176l3.03-3.03a.707.707 0 00-1-1l-3.03 3.03V8.469a.706.706 0 00-1.412 0v2.823a.706.706 0 00.706.706h2.824a.706.706 0 100-1.412zM0 15.529v7.765A.706.706 0 00.706 24h7.765a.706.706 0 00.706-.706 8.471 8.471 0 00-8.471-8.471.706.706 0 00-.706.706zm7.73 7.059H1.412V16.27a7.061 7.061 0 016.318 6.318z"
        fill="#c4c4c4"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgComponent;

