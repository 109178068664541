import React, { useEffect } from "react";
import PureModal from "src/components/shared/PureModal";
import { Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import InputErrorMessage from "src/components/shared/InputErrorMessage";
import Select from "react-select";
import "./ManageGroupUser.scss";
import { useDispatch, useSelector } from "react-redux";
import { editGroup } from "src/api/groups";
import { RootState } from "src/state/rootReducer";
import { usersAction } from "src/state/users/actions";
import RequiredFieldSymbol from "src/components/ui/RequiredFieldSymbol";
import { getGroups, getGroupDetails } from "src/state/groups/actions";
import { filterActiveUsers } from "src/helpers/groups";
import { isEmpty } from "src/utils/common";

type ManageGroupUserProps = {
  setShowModal: (boolean) => void;
  selectedGroupsDetails?: { name: string; roleId: number };
  selectedGroupId: string;
  isGroupUser?: boolean;
};

const validationSchema = Yup.object({
  userNames: Yup.string().required("User Name is required"),
});

const ManageGroupUser: React.FunctionComponent<ManageGroupUserProps> = ({
  selectedGroupsDetails = { name: "", roleId: 0 },
  selectedGroupId,
  isGroupUser = false,
  ...props
}) => {
  const dispatch = useDispatch();
  const users: Array<any> = useSelector((state: RootState) => state.users.list);
  const activeUsers = (users && filterActiveUsers(users)) || [];
  const newUser = { groupName: selectedGroupsDetails.name, userNames: "" };
  useEffect(() => {
    dispatch(usersAction());
  }, []);

  const customStyles = {
    multiValue: () => ({
      display: "flex",
      borderRadius: "2rem",
      border: "1px solid #A59CF0",
      background: "#E8E8FE",
      paddingLeft: "0.2rem",
    }),
    multiValueLabel: () => ({ color: "#A59CF0", padding: "0 2px" }),

    multiValueRemove: (styles) => ({
      ...styles,
      color: "#fe5000",
      borderRadius: "50%",
    }),
    dropdownIndicator: () => ({ display: "none" }),
    indicatorSeparator: () => ({ display: "none" }),
  };

  const onSubmitHandle = async (user, { setSubmitting }) => {
    const payload = {
      name: user.groupName,
      users: user.userNames.map((username) => username.value),
      role: selectedGroupsDetails.roleId,
    };
    setSubmitting(true);
    let data = await editGroup(payload, selectedGroupId);
    setSubmitting(false);
    if (!isEmpty(data) && !data.hasError) {
      if (!!isGroupUser) {
        dispatch(getGroupDetails(selectedGroupId));
      } else dispatch(getGroups());
      props.setShowModal(false);
    }
  };

  return (
    <Formik
      initialValues={newUser}
      validationSchema={validationSchema}
      onSubmit={onSubmitHandle}
    >
      {(formik) => (
        <PureModal
          title="Add User To Group"
          showModal={true}
          closeModal={() => props.setShowModal(false)}
          primaryBtnLabel="Add"
          primaryBtnAction={formik.handleSubmit}
        >
          <Form>
            <Form.Group>
              <Form.Label>Group Name</Form.Label>
              <Form.Control
                type="text"
                disabled
                id="groupName"
                {...formik.getFieldProps("groupName")}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>
                User Name or Email <RequiredFieldSymbol />
              </Form.Label>
              <Select
                id="userNames"
                className="select-user-name-group"
                name="userNames"
                size={5}
                isClearable={true}
                isLoading={!activeUsers}
                value={formik.values.userNames}
                onChange={(data) => {
                  formik.setFieldTouched("userNames");
                  formik.setFieldValue("userNames", data);
                }}
                options={activeUsers.map((user) => {
                  return {
                    value: user.id,
                    label: user.name + ", " + user.email,
                  };
                })}
                styles={customStyles}
                isMulti={true}
                closeMenuOnSelect={false}
              />
              {formik.touched.userNames && formik.errors.userNames && (
                <InputErrorMessage
                  className={"error-message"}
                  errorMessage={formik.errors.userNames || ""}
                />
              )}
            </Form.Group>
          </Form>
        </PureModal>
      )}
    </Formik>
  );
};

export default ManageGroupUser;
