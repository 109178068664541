import produce from "immer";

export const ADD_SECTION = "ADD_SECTION";
export const UPDATE_SECTION = "UPDATE_SECTION";
export const UPDATE_SECTION_STORY = "UPDATE_SECTION_STORY";
export const ADD_STORY = "ADD_STORY";
export const UPDATE_STORY_CONTENT = "UPDATE_STORY_CONTENT";
export const GET_STORYLINE_DETAILS = "GET_STORYLINE_DETAILS";
export const UPDATE_INTRODUCTORY_CONTENT = "UPDATE_INTRODUCTORY_CONTENT";
export const ADD_CREDENTIAL = "ADD_CREDENTIAL";
export const UPDATE_CREDENTIAL = "UPDATE_CREDENTIAL";
export const DELETE_CREDENTIAL = "DELETE_CREDENTIAL";
export const UPDATE_WARNING_FLAG = "UPDATE_WARNING_FLAG";

export interface ISection {
  id: number;
  name: string;
  stories: Array<IStory>;
}

export interface IStory {
  id: number;
  name: string;
  content: string;
}

export interface ICredentials {
  id: number;
  name: string;
  username?: string;
  password?: string;
  secretKey?: string;
}

export interface IAssignedLab {
  id: number;
  name: string;
}

export interface IStoryline {
  introductoryContent: string;
  sections: Array<ISection>;
  name: string;
  credentials: Array<ICredentials>;
  id: number;
  version: string;
  isDraft: boolean;
  createdOn: string;
  updatedOn: string;
  parent: number;
  updatedBy: object;
  assignedLabs: Array<IAssignedLab>;
  isUpdated: boolean;
}

const initialState: IStoryline = {
  name: "New Guide",
  id: 1,
  introductoryContent: "",
  version: "New Guide-v1",
  sections: [],
  credentials: [],
  isDraft: false,
  createdOn: "",
  updatedOn: "",
  parent: -1,
  updatedBy: {},
  assignedLabs: [],
  isUpdated: false,
};

export default (state = initialState, action): IStoryline =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_STORYLINE_DETAILS:
        draft.name = action.payload.name;
        draft.id = action.payload.id;
        draft.introductoryContent = action.payload.introductoryContent;
        draft.sections = action.payload.sections;
        draft.version = action.payload.version;
        draft.isDraft = action.payload.isDraft;
        draft.createdOn = action.payload.createdOn;
        draft.updatedOn = action.payload.updatedOn;
        draft.credentials = action.payload.credentials;
        draft.parent = action.payload.parent;
        draft.updatedBy = action.payload.updatedBy;
        draft.assignedLabs = action.payload.assignedLabs;
        draft.isUpdated = false;
        break;

      case ADD_SECTION:
        let sections = [...state.sections];
        sections.push(action.payload);
        draft.sections = sections;
        draft.isUpdated = true;
        break;

      case UPDATE_SECTION:
        draft.sections = action.payload;
        draft.isUpdated = true;
        break;

      case UPDATE_SECTION_STORY:
        let sectionIdx = state.sections.findIndex(
          (section) => section.id === action.payload.sectionId
        );
        let storyIdx = state.sections[sectionIdx].stories.findIndex(
          (story) => story.id === action.payload.story.id
        );
        draft.sections[sectionIdx].stories[storyIdx] = action.payload.story;
        draft.isUpdated = true;
        break;

      case ADD_STORY:
        // let sections = [...state.sections];
        // sections.push(action.payload);
        // draft.sections = sections;
        break;

      case UPDATE_STORY_CONTENT:
        let sectionIndex = state.sections.findIndex(
          (section) => section.id === action.payload.sectionId
        );
        let storyIndex = state.sections[sectionIndex].stories.findIndex(
          (story) => story.id === action.payload.storyId
        );

        let storyCopy = { ...state.sections[sectionIndex].stories[storyIndex] };
        storyCopy.content = action.payload.content;
        draft.sections[sectionIndex].stories[storyIndex] = storyCopy;
        draft.isUpdated = true;
        break;

      case UPDATE_INTRODUCTORY_CONTENT:
        draft.introductoryContent = action.payload;
        draft.isUpdated = true;
        break;

      case ADD_CREDENTIAL:
        draft.credentials = [...state.credentials, action.payload];
        draft.isUpdated = true;

        break;

      case UPDATE_CREDENTIAL:
        let credentialIndex = state.credentials.findIndex(
          (credential) => credential.id === action.payload.id
        );
        draft.credentials[credentialIndex] = action.payload;
        draft.isUpdated = true;
        break;

      case DELETE_CREDENTIAL:
        let credIdx = state.credentials.findIndex(
          (credential) => credential.id === action.payload
        );
        draft.credentials.splice(credIdx, 1);
        draft.isUpdated = true;

        break;

      case UPDATE_WARNING_FLAG:
        draft.isUpdated = !!action.payload;
        break;

      default:
        break;
    }
  });
