import React from "react";
import { InputGroup, Button } from "react-bootstrap";
import { TextInput } from "src/components/ui/FormInputs";

type SectionLayoutProps = {
  credTitle?: string | React.ReactNode;
  errClassName?: string;
  children?: React.ReactNode;
  toggle: () => void;
};

const SectionLayout: React.FC<SectionLayoutProps> = ({
  credTitle = "Credentials",
  errClassName,
  toggle,
  children,
}) => {
  return (
    <>
      <div className="row d-flex justify-content-between mt-4">
        <TextInput
          name="name"
          required
          groupProps={{ className: "col-sm-6 mb-0" }}
          prepend={<InputGroup.Text> {`${credTitle} :`}</InputGroup.Text>}
          errClassName={errClassName}
        />
        <div className="creds-btns">
          <Button
            size="sm"
            className="creds-btns_btn"
            variant="outline-primary"
            onClick={toggle}
          >
            Cancel
          </Button>
          <Button size="sm" type="submit" className="creds-btns_btn">
            Save
          </Button>
        </div>
      </div>
      {children && <div className="pt-3 pb-4 creds-section">{children}</div>}
    </>
  );
};

export default SectionLayout;
