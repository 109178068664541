import React from "react";
import "./NoSearchResultFound.scss";

type NoSearchResultFoundProps = {
  message: string;
  detail?: string;
  errImgSrc?: string;
  className?: string;
};

const NoSearchResultFound: React.FC<NoSearchResultFoundProps> = (props) => {
  const ShowErrorImg = () => {
    if (props.errImgSrc) {
      return (
        <img
          src={require(`src/assets/images/${props.errImgSrc}`)}
          alt="error"
          height="150px"
        />
      );
    }
    return null;
  };

  return (
    <div className={`${props.className} no-result-found`}>
      <div className="d-flex flex-column align-items-center error">
        <ShowErrorImg />
        <p className="no-result-msg"> {props.message} </p>
        <p> {props.detail ? props.detail : null} </p>
      </div>
    </div>
  );
};

export default NoSearchResultFound;
