import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={10.664}
      height={6.582}
      viewBox="0 0 10.664 6.582"
      {...props}
    >
      <defs>
        <clipPath id="prefix__a">
          <path
            d="M9.414 0l1.25 1.25-5.332 5.332L0 1.25 1.25 0l4.082 4.082z"
            fill="#fe5000"
          />
        </clipPath>
      </defs>
      <g clipPath="url(#prefix__a)">
        <path
          fill="#fe5000"
          d="M-42.656-45.322h95.975v95.975h-95.975z"
          data-name="\u21B3\uD83C\uDFA8icon-color"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
