import api from "src/helpers/request";
import { isUserAuthenticated } from "src/utils/common";
import { pushNotification } from "src/components/ui/Notification/index";

export const signInEndpoint = "/signin";
export const logoutEndPoint = "/signout";
const refreshSignInEndpoint = "/token-refresh";

export const signInApiCall = async (email, password) =>
  api.call(
    {
      url: signInEndpoint,
      method: "POST",
      data: {
        email: email,
        password: password,
      },
    },
    { showLoader: true, noAuthhHeader: true, showErrorNotification: false }
  );

export const refreshSignInApiCall = async (currentToken) =>
  api.call(
    {
      url: refreshSignInEndpoint,
      method: "POST",
      data: {
        token: currentToken,
      },
    },
    { showLoader: false, noAuthhHeader: false, showErrorNotification: false }
  );

export const logoutUser = () => {
  const isAuthenticated = isUserAuthenticated();
  if (isAuthenticated) {
    return api.call(
      { url: logoutEndPoint, method: "GET" },
      { showLoader: true }
    );
  } else {
    pushNotification({
      type: "error",
      message: "You are not logged in.",
    });
  }
};
