import produce from "immer";
import { IStoryline } from "../storyline/reducer";

export const GET_STORYLINE_LIST = "GET_STORYLINE_LIST";

export interface IStorylineList {
  list: Array<IStoryline> | null;
}

const initialState: IStorylineList = {
  list: null,
};

export default (state = initialState, action): IStorylineList =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_STORYLINE_LIST:
        draft.list = action.payload;
        break;

      default:
        break;
    }
  });
