import React from "react";
import { formatDateToLocal } from "src/helpers/common";
import visibleIcon from "src/assets/images/visibleIcon.svg";
import edit from "src/assets/images/edit.svg";
import deleteIcon from "src/assets/images/delete.svg";
import CustomTooltip from "src/components/ui/CustomTooltip";
import presentationFile from "src/assets/images/presentation-inactive.svg";
import addFile from "src/assets/images/add-user.svg";
import folderIcon from "src/assets/images/folder.svg";
import { OverlayTrigger, Popover } from "react-bootstrap";

export const getPresentationColumns = (
  viewPresentation,
  displayDeleteModal,
  toggleFileUploader,
  getFilesByFolder
) => [
  {
    Header: "Name",
    accessor: "name",
    percentWidth: 25,
    Cell: ({ row }) => {
      return row.original.type.toLowerCase() === "folder" ? (
        <span>
          <div
            className="pl-1"
            onClick={(e) => {
              getFilesByFolder(row.original);
            }}
          >
            <img src={folderIcon} alt="folderIcon" width="16" height="16" />
            <span className="color-primary ml-2 cursor-pointer">
              {row.original.name}
            </span>
          </div>
        </span>
      ) : (
        <div className="pl-1" style={{ paddingLeft: `${row.depth * 1.75}rem` }}>
          <img src={presentationFile} alt="fileIcon" width="12" height="12" />
          <span className="ml-2">{row.original.name}</span>
        </div>
      );
    },
  },
  {
    Header: "Owner",
    accessor: "owner",
    percentWidth: 20,
    Cell: ({ row, value }) => {
      return (
        <span>{row.original.createdBy && row.original.createdBy.name}</span>
      );
    },
  },
  {
    Header: "Last Modified",
    accessor: "updatedOn",
    percentWidth: 20,
    Cell: ({ row, value }) => {
      return (
        <span>
          {row.original.updatedOn && formatDateToLocal(row.original.updatedOn)}
        </span>
      );
    },
  },
  {
    Header: "Used In",
    accessor: "assignedLabs",
    Cell: ({ row }) => {
      return (
        <div title="">
          {!row.canExpand && getUsageLabs(row.original.assignedLabs)}
        </div>
      );
    },
    percentWidth: 23,
  },
  {
    Header: "Action",
    disableSortBy: true,
    accessor: (row) => {
      return (
        <>
          {row?.type?.toLowerCase() === "folder" ? (
            <div className="d-flex" title="">
              <div className="action-icon p-0">
                <CustomTooltip text="Add File">
                  <img
                    src={addFile}
                    alt="addFile"
                    data-id={row.id}
                    onClick={toggleFileUploader}
                  />
                </CustomTooltip>
              </div>

              <div className="ml-2 action-icon">
                <CustomTooltip text="Delete Folder">
                  <img
                    src={deleteIcon}
                    alt="deleteIcon"
                    onClick={() => {
                      displayDeleteModal(row);
                    }}
                  />
                </CustomTooltip>
              </div>
            </div>
          ) : (
            <div className="d-flex" title="">
              <a
                href={row.webEditLink}
                target="_blank"
                className="action-icon"
                title=""
              >
                <CustomTooltip text="Edit Presentation">
                  <img src={edit} alt="editIcon" />
                </CustomTooltip>
              </a>
              <div className="action-icon p-1 ml-2">
                <CustomTooltip text="View Presentation">
                  <img
                    title=""
                    src={visibleIcon}
                    alt="visibleIcon"
                    height="16"
                    width="16"
                    onClick={() => {
                      viewPresentation(row);
                    }}
                  />
                </CustomTooltip>
              </div>

              <div className="ml-2 action-icon">
                <CustomTooltip text="Delete Presentation">
                  <img
                    src={deleteIcon}
                    alt="deleteIcon"
                    onClick={() => {
                      displayDeleteModal(row);
                    }}
                  />
                </CustomTooltip>
              </div>
            </div>
          )}
        </>
      );
    },
    percentWidth: 12,
  },
];

const getUsageLabs = (labs) => {
  return labs && labs.length ? (
    <div className={`d-flex `}>
      <span>{labs[0].name}</span>
      <div className="ml-1">
        {labs.length > 1 ? (
          <a className="cursor-pointer">
            <OverlayTrigger
              trigger={["hover", "focus"]}
              placement={"bottom"}
              overlay={
                <Popover id={`popover-positioned-bottom`}>
                  <>
                    <Popover.Content>
                      <div>
                        {labs.map((lab, id) => (
                          <div className="mt-1" key={id}>
                            <div>{lab.name}</div>
                          </div>
                        ))}
                      </div>
                    </Popover.Content>
                  </>
                </Popover>
              }
            >
              <div className="color-primary"> +{labs.length - 1}</div>
            </OverlayTrigger>
          </a>
        ) : null}
      </div>
    </div>
  ) : (
    "--"
  );
};
