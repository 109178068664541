import React from "react";
import PureModal from "src/components/shared/PureModal";
import "./VoucherUsers.scss";
import data from "./data";

type VoucherUserProps = {
  setShowModal: (boolean) => void;
  item: any;
};

const VoucherUser: React.FunctionComponent<VoucherUserProps> = ({
  item,
  ...props
}) => {
  const userData =
    data.length &&
    data.map((user) => (
      <li className="StepProgress-item step-circle">
        {user.email + " ( " + user.created + " )"}
        <span className="ml-auto">{user.state}</span>
      </li>
    ));

  return (
    <PureModal
      title={item.name}
      showModal={true}
      closeModal={() => props.setShowModal(false)}
      primaryBtnLabel="Ok"
      primaryBtnAction={() => props.setShowModal(false)}
    >
      <div className="wrapper">
        <ul className="StepProgress">
          <li className="StepProgress-item is-done">Users</li>
          {userData}
        </ul>
      </div>
    </PureModal>
  );
};

export default VoucherUser;
