import {
  GET_USER_NOTIFICATIONS_FAILURE,
  GET_USER_NOTIFICATIONS_SUCCESS,
  READ_USER_NOTIFICATIONS_FAILURE,
  READ_USER_NOTIFICATIONS_SUCCESS,
} from "./reducer";
import {
  fetchUserNotifications,
  readNotificationApi,
} from "src/api/notifications";
import { toCamelCaseKeys } from "src/helpers/common";

export const getUserNotifications =
  (metaConfig = { showLoader: true, showErrorNotification: false }) =>
  async (dispatch) => {
    let data = await fetchUserNotifications(metaConfig);
    if (data) {
      const notifications = toCamelCaseKeys(data) || [];
      dispatch({
        type: GET_USER_NOTIFICATIONS_SUCCESS,
        payload: notifications,
      });
      return notifications;
    } else {
      dispatch({
        type: GET_USER_NOTIFICATIONS_FAILURE,
      });
    }
  };

export const readNotificationThunk =
  (id, incrementCount = true, metaConfig = { showLoader: false, showErrorNotification: false }) =>
  (dispatch) => {
    readNotificationApi(id, metaConfig)
      .then(() => {
        dispatch({ type: READ_USER_NOTIFICATIONS_SUCCESS, payload: { id, incrementCount } });
      })
      .catch(() => dispatch({ type: READ_USER_NOTIFICATIONS_FAILURE }));
  };
