import React, { useState, useEffect } from "react";
import "./StorylineHeader.scss";
import { Button } from "react-bootstrap";
import { IStoryline, UPDATE_WARNING_FLAG } from "src/state/storyline/reducer";
import { getPayload, getUpdatedVersionName } from "src/helpers/stories";
import { updateStorylineVersion, saveStorylineVersion } from "src/api/stories";
import { useParams } from "react-router-dom";
import PureModal from "src/components/shared/PureModal";
import { Form } from "react-bootstrap";
import edit from "src/assets/images/edit.svg";
import checkIcon from "src/assets/images/check.svg";
import cancelIcon from "src/assets/images/cross.svg";
import CustomTooltip from "src/components/ui/CustomTooltip";
import { useDispatch } from "react-redux";
import { updateStorylineVersionDetails } from "src/state/storyline/actions";
import { mode } from "src/constants/storylineList";

type StorylineHeaderProps = {
  storyLine: IStoryline;
  storylineId: number;
  showStorylineListing: () => void;
  setEditStorylineName: (flag: boolean) => void;
  //setStorylineEditedName: (name: string) => void;
};

const StorylineHeader: React.FC<StorylineHeaderProps> = ({
  storyLine,
  storylineId,
  showStorylineListing,
  setEditStorylineName,
  //setStorylineEditedName,
}) => {
  const dispatch = useDispatch();
  const { storylineMode } = useParams();
  const [editName, setEditName] = useState(storyLine.name);
  const [editMode, setEditMode] = useState(false);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [comment, setComment] = useState("");
  const [publishAction, setPublishAction] = useState("");
  // const [versionError, setVersionError] = useState("");
  const [storylineNameError, setStorylineNameError] = useState(false);

  useEffect(() => {
    setEditName(storyLine.name);
  }, [storyLine.name]);

  const toggleCommentModal = () => {
    setComment("");
    setShowCommentModal(!showCommentModal);
  };

  const setCurrentAction = (action) => {
    setPublishAction(action);
    toggleCommentModal();
  };

  const handleComment = (e) => {
    const value = e.target.value;
    setComment(value);
  };

  // const validateVersionName = () => {
  //   if (!newVersion) {
  //     setVersionError("Version name can not be empty");
  //     return false;
  //   }
  //   if (newVersion.toLowerCase() === storyLine.version.toLocaleLowerCase()) {
  //     setVersionError("Version name can not be duplicate");
  //     return false;
  //   }
  //   return true;
  // };

  const publishToExistingVersion = async () => {
    const payload = getPayload(storyLine, comment);
    payload.is_draft = false;
    //payload.name = editName;
    const response = await updateStorylineVersion(payload);
    !response.isAxiosError && showStorylineListing();
  };

  const publishToNewVersion = async () => {
    //if (validateVersionName()) {
    dispatch({ type: UPDATE_WARNING_FLAG });
    const payload = getPayload(storyLine, comment);
    // payload.version =
    payload.is_draft = false;
    let response;
    if (!storylineId || (!!storyLine.parent && storyLine.parent === -1)) {
      delete payload.parent;
    }
    response = await saveStorylineVersion(payload);

    !response.isAxiosError && showStorylineListing();
    //}
  };

  const handleStorylineNameChange = (e) => {
    let value = e.target.value;
    setEditName(value);
    if (!value) {
      setStorylineNameError(true);
      return;
    }
    setStorylineNameError(false);
  };

  const toggleEditMode = () => {
    setEditStorylineName(!editMode);
    setEditMode(!editMode);
  };

  const saveStorylineVersionName = () => {
    if (!storylineNameError) {
      const payload = getPayload(storyLine, comment);
      payload.name = editName;
      payload.version = getUpdatedVersionName(editName, payload.version);
      dispatch(updateStorylineVersionDetails(payload));
      toggleEditMode();
      //setStorylineEditedName(editName);
    }
  };

  const publishVersion = () => {
    if (publishAction === "publishExisting") {
      publishToExistingVersion();
    } else {
      publishToNewVersion();
    }
    setComment("");
  };

  const viewModeClass = storylineMode === mode.view ? "hidden" : "visible";

  return (
    <div className="">
      {showCommentModal && (
        <PureModal
          title="Add Comment"
          showModal={true}
          closeModal={toggleCommentModal}
          primaryBtnLabel="Save"
          primaryBtnAction={publishVersion}
          secondaryBtnLabel="Cancel"
          secondaryBtnAction={toggleCommentModal}
        >
          <Form.Group>
            <Form.Label>Comment</Form.Label>
            <Form.Control
              onChange={handleComment}
              as="textarea"
              id="comment"
              rows={4}
              cols={50}
            />
          </Form.Group>
        </PureModal>
      )}
      <div className="d-flex flex-row justify-content-sm-between mb-2">
        <div className="content-heading" style={{ color: "#212529" }}>
          {editName}
        </div>
        {/* <div className={`d-flex storyline-header-editable-input`}>
          <input
            type="text"
            className="form-control storyline-name"
            value={editName}
            disabled={!editMode}
            onChange={handleStorylineNameChange}
            placeholder="Storyline Name is required"
          />
          {!editMode ? (
            <div className={`d-flex align-items-center ${viewModeClass}`}>
              <CustomTooltip text="Edit Storyline Name">
                <img
                  src={edit}
                  alt="editIcon"
                  height="15"
                  width="15"
                  className="storyline-action"
                  onClick={toggleEditMode}
                />
              </CustomTooltip>
            </div>
          ) : (
            <div className="d-flex align-items-center">
              <CustomTooltip text="Cancel">
                <img
                  src={cancelIcon}
                  alt="cancel"
                  height="12"
                  width="12"
                  className="storyline-action"
                  onClick={() => {
                    !storylineNameError && toggleEditMode();
                  }}
                />
              </CustomTooltip>
              <CustomTooltip text="Save">
                <img
                  src={checkIcon}
                  alt="save"
                  height="15"
                  width="15"
                  className="storyline-action"
                  onClick={saveStorylineVersionName}
                />
              </CustomTooltip>
            </div>
          )}
        </div> */}
        <div className="d-flex justify-content-end ">
          <div className={`align-items-center ${viewModeClass}`}>
            {/* <Button
              type="submit"
              size="sm"
              variant="primary"
              className="storyline-section-add-existing"
              // onClick={publishToExistingVersion}
              onClick={() => setCurrentAction("publishExisting")}
              disabled={!storylineId || editMode}
            >
              <div className="d-flex align-items-center pl-2 pr-2">
                <span> Publish To Existing Version </span>
              </div>
            </Button> */}
          </div>
          <div className={`align-self-end ml-2 mb-2 ${viewModeClass}`}>
            <Button
              type="submit"
              size="sm"
              variant="primary"
              className="storyline-section-add"
              onClick={() => setCurrentAction("publishNew")}
              disabled={editMode}
            >
              <div className="d-flex align-items-center pl-2 pr-2">
                <span> Publish To New Version </span>
              </div>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StorylineHeader;
