import Api from "src/helpers/request";
import {toCamelCaseKeys} from "src/helpers/common";

export const tokenEndpoint = (userId) => `/users/${userId}/user-token`;

export const generateToken = async (userId, metaConfig = { showLoader: true }) => {
    const data = await Api.call(
        {
            url: tokenEndpoint(userId),
            method: "POST",
        },
        metaConfig
    );
    return toCamelCaseKeys(data)
};
