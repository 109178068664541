import React from "react";
import { Form } from "react-bootstrap";
import { useField } from "formik";
import InputErrorMessage from "src/components/shared/InputErrorMessage";

type CheckInputProps = {
  name: string;
  label?: string | React.ReactNode;
  className?: string;
  custom?: boolean;
  onFocus?: () => void;
};

const CheckInput: React.FC<CheckInputProps> = ({ name, ...props }) => {
  const [field, meta] = useField({ name, type: "checkbox" });
  return (
    <Form.Group>
      <Form.Check id={name} type="checkbox" {...field} {...props} />
      {meta.touched && meta.error && (
        <InputErrorMessage
          className="error-message"
          errorMessage={meta.error || ""}
        />
      )}
    </Form.Group>
  );
};

export { CheckInput };
