import React from "react";
import Spinner from "react-bootstrap/Spinner";
import "./Loader.scss";
import { useSelector } from "react-redux";
import { RootState } from "src/state/rootReducer";

type Props = {};

const Loader: React.FC<Props> = () => {
  const loaderCount = useSelector((state: RootState) => state.ui.loaderCount);
  const message = useSelector((state: RootState) => state.ui.message);
  const isLoading = loaderCount > 0;

  return isLoading ? (
    <div className="loader">
      <Spinner
        animation="border"
        style={{ color: "var(--color-primary)" }}
        role="status"
      ></Spinner>
      <span style={{ fontSize: "1.3rem" }} className="color-primary">
        {message}
      </span>
    </div>
  ) : null;
};

export default Loader;
