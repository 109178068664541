import { ReactComponent as AllLabsIcon } from "src/assets/images/all-lab.svg";
import { ReactComponent as ActiveLabIcon } from "src/assets/images/active-lab.svg";
import { ReactComponent as DraftLabsIcon } from "src/assets/images/lab-inactive.svg";
import React from "react";
import CustomToolTip from "src/components/ui/CustomTooltip/CustomTooltip";
import { sortByDate, sortColumn } from "src/utils/common";

export const labData = (stats) => ({
  table: {
    title: "List of All Labs Launched",
    columns: [
      {
        Header: "Created On",
        accessor: "time",
        sortType: (rowA, rowB, colId, desc) =>
          sortByDate(rowA, rowB, desc, "time"),
      },
      {
        Header: "User Name",
        accessor: "name",
      },
      {
        Header: "Email Address",
        accessor: "email",
      },
      {
        Header: "Company",
        id: "company",
        accessor: (row) => row.company,
        sortType: (rowA, rowB, colId, desc) =>
          sortColumn(rowA, rowB, "Company", desc),
      },
      {
        Header: "Lab Name",
        accessor: "labName",
        Cell: ({ value }) => (
          <CustomToolTip text={value}>
            <span className="overflow-ellipsis" title="">
              {value}
            </span>
          </CustomToolTip>
        ),
      },
      {
        Header: "Shutdown On",
        accessor: "dateTime",
        sortType: (rowA, rowB, colId, desc) =>
          sortByDate(rowA, rowB, desc, "dateTime"),
      },
    ],
  },
  charts: {
    radial: {
      title: "Total Number of Labs Launched",
      count: stats.launched,
      data: [
        { name: "Launched Labs", value: stats.launched, fill: "#FE5009" },
        { name: "Active Labs", value: stats.active, fill: "#55BA9E" },
        { name: "Inactive Labs", value: stats.inactive, fill: "#929497" },
      ],
      dataKey: "value",
    },
    bar: {
      title: "Number of Launched Labs",
      barSize: 20,
      data: stats.barChartStats,
      bars: [
        {
          dataKey: "Labs",
          fill: "#FE5009",
        },
      ],
    },
  },
  cards: [
    {
      title: "Total Labs Launched",
      totalItems: stats.launched,
      icon: AllLabsIcon,
      iconBackgroundColor: "#ffdccd",
      iconFillColor: "#fe5009",
      width: "32%",
    },
    {
      title: "Active Labs",
      totalItems: stats.active,
      icon: ActiveLabIcon,
      iconBackgroundColor: "#DDF1EC",
      iconFillColor: "#55ba9e",
      width: "32%",
    },
    {
      title: "Inactive Labs",
      totalItems: stats.inactive,
      icon: DraftLabsIcon,
      iconBackgroundColor: "#E8E8E9",
      iconFillColor: "#8b8d90",
      width: "32%",
    },
  ],
});
