import React, { useState, useEffect } from "react";
import "./Vouchers.scss";
import Table from "src/components/shared/Table";
import { ReactComponent as AddIcon } from "src/assets/images/add.svg";
import { fetchColumns } from "./voucherColumns";
import { Button } from "react-bootstrap";
import SearchBox from "src/components/shared/SearchBox";
import UserVoucherModal from "src/components/user/UserVoucherModal";
import NoSearchResultFound from "src/components/ui/NoSearchResultFound";
import { fetchUserVouchers } from "src/api/vouchers";
import { mapUserVouchers } from "src/helpers/vouchers";
import { useSelector } from "react-redux";
import { RootState } from "src/state/rootReducer";

type UserVoucherProps = {};

const UserVouchers: React.FC<UserVoucherProps> = () => {
  const [userVoucherModal, setUserVoucherModal] = useState(false);
  const [vouchers, setVouchers] = useState<Array<any>>([]);
  const [vouchersList, setVouchersList] = useState<Array<any>>([]);
  const [searchBy, setSearchBy] = useState("");
  const loaderCount = useSelector((state: RootState) => state.ui.loaderCount);
  const isLoading = loaderCount > 0;

  const getVouchers = async () => {
    const data = await fetchUserVouchers();
    setVouchers(mapUserVouchers(data));
  };

  useEffect(() => {
    getVouchers();
  }, [userVoucherModal]);

  useEffect(() => {
    filterVouchers(searchBy);
  }, [vouchers]);

  const generateVoucher = () => {
    setUserVoucherModal(true);
  };

  const filterVouchers = (searchQuery) => {
    let filteredVouchers = vouchers.filter((voucher) => {
      if (!searchQuery) return true;
      const name = voucher.name && voucher.name.toLowerCase();
      const email = voucher.email && voucher.email.toLowerCase();
      const query = searchQuery && searchQuery.toLowerCase();
      return (name && name.includes(query)) || (email && email.includes(query));
    });
    setVouchersList(filteredVouchers);
  };

  const handleSearchByChange = (updatedSearchQuery) => {
    setSearchBy(updatedSearchQuery);
    filterVouchers(updatedSearchQuery);
  };

  const voucherColumns = fetchColumns();

  return (
    <div className="vouchers-section d-flex flex-column flex-grow-1">
      <div className="d-flex flex-row justify-content-sm-between mb-2">
        <div className={`vouchers-section-header`}>
          <h5 className="">
            <b>My Vouchers</b>
          </h5>
        </div>
        {userVoucherModal && (
          <UserVoucherModal setShowModal={setUserVoucherModal} />
        )}
        <div className="d-flex justify-content-end">
          <div className="vouchers-section-search d-flex align-items-center">
            <SearchBox
              updateSearchQuery={handleSearchByChange}
              placeholder="Search by voucher name or invited email"
            />
          </div>
          <div className={` align-self-end ml-2 d-inline`}>
            <Button
              type="submit"
              size="sm"
              variant="primary"
              className="vouchers-section-add"
              onClick={generateVoucher}
            >
              <div className="d-flex align-items-center pl-2 pr-2">
                <AddIcon height="10" width="10" />
                <span className="ml-2"> Generate Voucher </span>
              </div>
            </Button>
          </div>
        </div>
      </div>
      {vouchersList.length ? (
        <Table
          searchBy={searchBy}
          columns={voucherColumns}
          data={vouchersList}
        />
      ) : !!searchBy ? (
        <NoSearchResultFound
          message="Your search was not successful!"
          detail="Try some relevant name or email"
          errImgSrc="no-users.svg"
        />
      ) : (
        !isLoading && (
          <NoSearchResultFound
            message="No Vouchers Found!"
            errImgSrc="no-users.svg"
          />
        )
      )}
    </div>
  );
};

export default UserVouchers;
