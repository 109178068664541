import React from "react";
import FormLayout from "src/components/shared/FormLayout";
import labAccess from "src/assets/images/labAccess.svg";
import { Button, Form } from "react-bootstrap";
import Table from "src/components/shared/Table";
import noLabAccess from "src/assets/images/no-lab-access.svg";
import { useFormik } from "formik";
import PureModal from "src/components/shared/PureModal";
import RequiredFieldSymbol from "src/components/ui/RequiredFieldSymbol";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/state/rootReducer";
import { usersAction } from "src/state/users/actions";
import { getGroups } from "src/state/groups/actions";
import { getCompanies } from "src/state/companies/actions";
import CustomToolTip from "src/components/ui/CustomTooltip/CustomTooltip";
import deleteIcon from "src/assets/images/delete.svg";
import WarningModal from "src/components/shared/WarningModal";
import { Formik } from "src/containers/admin/Notifications/Configuration";
import * as Yup from "yup";
import Input from "src/containers/admin/Notifications/Input";

declare interface AccessProps extends Formik {}

const initialAsset = { id: 0, title: "", subtitle: "" };

const getAccessColumns = (setAsset) => {
  const triggerResource = (row) => () => {
    setAsset({
      name: row.original.name,
      id: row.original.resourceId,
      value: row.original.id,
    });
  };

  return [
    {
      Header: "Name",
      accessor: "name",
      percentWidth: 24,
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: ({ value }) => {
        return value || "-";
      },
      percentWidth: 24,
    },
    {
      Header: "Type",
      accessor: "resourceId",
      Cell: ({ value }) => {
        return value === 0 ? "User" : value === 1 ? "Group" : "Company";
      },
      percentWidth: 14,
    },
    {
      Header: "No. Of Users",
      accessor: "numberOfUsers",
      percentWidth: 14,
    },
    {
      Header: "Action",
      disableSortBy: true,
      accessor: "",
      Cell: ({ row }) => {
        return (
          <div className="d-flex" title="">
            <CustomToolTip text="Delete">
              <button className="simple-btn" onClick={triggerResource(row)}>
                <img src={deleteIcon} height="20" width="20" alt="delete" />
              </button>
            </CustomToolTip>
          </div>
        );
      },
      percentWidth: 14,
    },
  ];
};

const Access: React.FC<AccessProps> = ({ values, setFieldValue }) => {
  const dispatch = useDispatch();
  const userList = React.useMemo(
    () =>
      values.assignedUsers.map(({ user }: any) => ({
        id: user.id,
        name: user.name,
        email: user.email,
        resourceId: 0,
        numberOfUsers: 1,
      })),
    [values.assignedUsers.length]
  );

  const groupList = React.useMemo(
    () =>
      values.assignedGroups.map(({ group }: any) => ({
        id: group.id,
        name: group.name,
        resourceId: 1,
        numberOfUsers: group.userCount,
      })),
    [values.assignedGroups.length]
  );

  const companyList = React.useMemo(
    () =>
      values.assignedCompanies.map(({ company }: any) => ({
        id: company.id,
        name: company.name,
        resourceId: 2,
        numberOfUsers: company.userCount,
      })),
    [values.assignedCompanies.length]
  );

  const assetList = [...userList, ...groupList, ...companyList];

  const { current: initialized } = React.useRef([false, false, false]);
  const [asset, setAsset] = React.useState<any>(initialAsset);
  const resetAsset = () => setAsset(initialAsset);
  const triggerAsset = (assetId: number) => () => {
    setAsset(resources[assetId]);
    if (!initialized[assetId]) {
      dispatch(resources[assetId].fetchData());
      initialized[assetId] = true;
    }
  };

  const closeAssetPopup = () => {
    formik.resetForm();
    resetAsset();
  };

  const users = useSelector((state: RootState) => state.users.list);
  const groups = useSelector((state: RootState) => state.groups.list);
  const companies = useSelector((state: RootState) => state.companies.list);

  const resources = [
    {
      id: 0,
      title: "User",
      subtitle: "User Name or Email",
      placeholder: "name or email",
      data: users.filter((user) =>
        values.assignedUsers.every(
          (assignedUser: any) => assignedUser.user?.id !== user.id
        )
      ),
      field: "assignedUsers",
      fetchData: usersAction,
    },
    {
      id: 1,
      title: "Group",
      subtitle: "Group Name",
      placeholder: "group",
      data: groups.filter((group) =>
        values.assignedGroups.every(
          (assignedGroup: any) => assignedGroup.group?.id !== group.id
        )
      ),
      field: "assignedGroups",
      fetchData: getGroups,
    },
    {
      id: 2,
      title: "Company",
      subtitle: "Company Name",
      placeholder: "company",
      data: companies.filter((company: any) =>
        values.assignedCompanies.every(
          (assignedCompany: any) => assignedCompany.company?.id !== company.id
        )
      ),
      field: "assignedCompanies",
      fetchData: getCompanies,
    },
  ] as const;

  const currRes = resources[asset.id];

  const onSubmit = ({ accessList: res }) => {
    const currResId = currRes.id;
    // @ts-ignore
    const matchedResource = currRes.data.find(
      (resource) => resource.id === res.value
    );
    let prop = "totalUsers";
    if (currResId === 1) {
      prop = "userCount";
    }
    const totalUsers = matchedResource[prop] ?? matchedResource.numberOfUsers;

    const mappedResource = {
      id: res.value,
      name: matchedResource.name,
      email: matchedResource.email,
      userCount: totalUsers || 1,
    };

    const field = currRes.field;
    const type = currRes.title.toLowerCase();
    setFieldValue(field, [...values[field], { [type]: mappedResource }]);
    closeAssetPopup();
  };

  const formik = useFormik<any>({
    enableReinitialize: true,
    initialValues: { accessList: '' },
    validationSchema: Yup.object({
      accessList: Yup.string().required(`${asset.subtitle} is required`),
    }),
    onSubmit,
  });

  // @ts-ignore
  let accessListOptions = currRes.data.map((option) => ({
    value: option.id,
    label:
      option.role === "user" ? `${option.name}, ${option.email}` : option.name,
    id: asset.id,
  }));

  const updateAccess = (data) => {
    formik.setFieldTouched("accessList");
    formik.setFieldValue("accessList", data ?? "");
  };

  const removeAccess = () => {
    const list = [...values[currRes.field]];
    const itemIdx = list.findIndex(
      (item) => item[currRes.title.toLowerCase()].id === asset.value
    );
    list.splice(itemIdx, 1);
    setFieldValue(currRes.field, [...list]);
    resetAsset();
  };

  const accessColumns = getAccessColumns(setAsset);

  return (
    <FormLayout headerImage={labAccess} headerTitle="Notifiers">
      <div className="access-section">
        {assetList.length ? (
          <div>
            <div className="d-flex justify-content-center">
              <div className="width-95 pt-2 d-flex justify-content-end">
                <Button
                  type="submit"
                  size="sm"
                  variant="outline-primary"
                  className="btn-min-width"
                  onClick={triggerAsset(0)}
                >
                  Add User
                </Button>
                <Button
                  type="submit"
                  size="sm"
                  variant="outline-primary"
                  className="btn-min-width ml-2"
                  onClick={triggerAsset(1)}
                >
                  Add Group
                </Button>
                <Button
                  type="submit"
                  size="sm"
                  variant="outline-primary"
                  className="btn-min-width ml-2"
                  onClick={triggerAsset(2)}
                >
                  Add Company
                </Button>
              </div>
            </div>
            <div className="d-flex justify-content-center ">
              <div className="mt-3 mb-5 width-95 custom-border">
                <Table
                  theadStyle={{ backgroundColor: "#f8f9fc" }}
                  columns={accessColumns}
                  data={assetList}
                  paginationOff
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="no-result pt-5 pb-5">
            <div className="d-flex flex-column align-items-center error">
              <img src={noLabAccess} alt="error" height="100px" />
              <p className="mb-0"> No Notifier </p>
              <p>
                Click on{" "}
                <a
                  href="javascript:void(0)"
                  className="underline"
                  onClick={triggerAsset(0)}
                >
                  Add User
                </a>{" "}
                ,{" "}
                <a
                  href="javascript:void(0)"
                  className="underline"
                  onClick={triggerAsset(1)}
                >
                  Add Group
                </a>{" "}
                or{" "}
                <a
                  href="javascript:void(0)"
                  className="underline"
                  onClick={triggerAsset(2)}
                >
                  Add Company
                </a>
              </p>
            </div>
          </div>
        )}
      </div>
      <PureModal
        showModal={asset.title}
        title={`Add ${asset.title}`}
        closeModal={closeAssetPopup}
        primaryBtnLabel="Add"
        primaryBtnAction={formik.handleSubmit}
      >
        <Form>
          <Input
            type="select"
            label={asset.subtitle}
            name="accessList"
            touched={formik.touched}
            errors={formik.errors}
            row={false}
          >
            <Select
              isClearable
              size={5}
              options={accessListOptions}
              value={formik.values.accessList}
              onChange={updateAccess}
              placeholder={`Search ${asset.placeholder || ""}`}
            />
          </Input>
        </Form>
      </PureModal>
      <WarningModal
        showModal={!!asset.value}
        title="Warning"
        onCancelAction={resetAsset}
        onConfirmAction={removeAccess}
      >
        <div className="ml-2">
          <div>
            Are you sure you want to delete{" "}
            <b>{asset.name}</b> Access?
          </div>
        </div>
      </WarningModal>
    </FormLayout>
  );
};

export default Access;
