import React, { useState, useEffect } from "react";
import PureModal from "src/components/shared/PureModal";
import { Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import InputErrorMessage from "src/components/shared/InputErrorMessage";
import Select from "react-select";
import "./ModifyResource.scss";
import { useSelector } from "react-redux";
import { RootState } from "src/state/rootReducer";
import RequiredFieldSymbol from "src/components/ui/RequiredFieldSymbol";
import DTPicker from "src/components/shared/DateTimePicker/index";
import calender from "src/assets/images/calender.svg";
import { getResourcePayload } from "src/helpers/labs";
import { capitalizeFirstLetter } from "src/helpers/common";

type ModifyResourceProps = {
  modifyList: (details: any) => void;
  setShowModifyResourceModal: (bool: boolean) => void;
  selectedResource?: Object;
};

const ModifyResource: React.FunctionComponent<ModifyResourceProps> = ({
  ...props
}) => {
  const resource: any = props.selectedResource;

  const [selectedDate, setSelectedDate] = useState<any>(
    resource.expiryDate ? new Date(resource.expiryDate) : ""
  );
  const [expiry, setExpiry] = useState("Never");

  const resourceName = capitalizeFirstLetter(resource.fieldType);

  const resourcesList: Array<any> = useSelector((state: RootState) => {
    switch (resource.fieldType) {
      case "user":
        return state.users.list;
      case "group":
        return state.groups.list;
      case "company":
        return state.companies.list;
      default:
        return [];
    }
  });
  const isEditMode = Object.keys(resource).length > 1;

  const initialValues = {
    resourceVal: isEditMode
      ? resourceName === "User"
        ? `${resource.name}, ${resource.email}`
        : resource.name
      : "",
    expiry: !!resource.expiryDate ? "Custom" : "Never",
    expiryDate: "",
  };

  const submitResource = async (data, { setSubmitting }) => {
    const submittedResource = isEditMode
      ? resource
      : resourcesList.find((user) => data.resourceVal === user.id);

    const payload = getResourcePayload(data, resource, submittedResource);
    props.modifyList(payload);
    props.setShowModifyResourceModal(false);
  };

  useEffect(() => {
    if (
      !!selectedDate &&
      new Date().toDateString() === selectedDate?.toDateString() &&
      selectedDate?.getTime() <= new Date().getTime()
    ) {
      setSelectedDate("");
    }
  }, [selectedDate]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object({
        resourceVal: Yup.string().required(`${resourceName} is required`),
        expiry: Yup.string(),
        expiryDate:
          expiry === "Custom"
            ? Yup.string()
                .required("Date is required for Custom Date")
                .nullable()
            : Yup.string().nullable(),
      })}
      onSubmit={submitResource}
    >
      {(formik) => (
        <PureModal
          title={`${isEditMode ? "Edit" : "Add"} ${resourceName}`}
          showModal={true}
          closeModal={() => props.setShowModifyResourceModal(false)}
          primaryBtnLabel={isEditMode ? "Edit" : "Add"}
          primaryBtnAction={formik.handleSubmit}
          secondaryBtnLabel={"Cancel"}
          secondaryBtnAction={() => props.setShowModifyResourceModal(false)}
        >
          <Form>
            <Form.Group controlId={resourceName}>
              <Form.Label htmlFor={resourceName}>
                {resourceName === "User"
                  ? "User Name or Email"
                  : `${resourceName} Name`}
                <RequiredFieldSymbol />
              </Form.Label>
              <Select
                isDisabled={isEditMode}
                id={resourceName}
                className="select-nameEmail"
                size={5}
                placeholder={`Search ${
                  !resource.type ? "name or email" : resource.type
                }`}
                defaultValue={{
                  value: formik.values.resourceVal,
                  label: formik.values.resourceVal,
                }}
                isClearable={true}
                onChange={(data) => {
                  formik.setFieldTouched("resourceVal");
                  formik.setFieldValue(
                    "resourceVal",
                    data && data.value ? data.value : ""
                  );
                }}
                options={resourcesList.map((resource) => {
                  return {
                    value: resource.id,
                    label:
                      resource.role === "user"
                        ? `${resource.name}, ${resource.email}`
                        : resource.name,
                  };
                })}
              />
              {formik.touched.resourceVal && formik.errors.resourceVal && (
                <InputErrorMessage
                  className={"error-message"}
                  errorMessage={formik.errors.resourceVal || ""}
                />
              )}
            </Form.Group>
            <Form.Group>
              <Form.Label>Expiry</Form.Label>
              <div className="pure-radio w-50 d-flex mb-4">
                <label className="container">
                  Never
                  <input
                    type="radio"
                    defaultChecked={!resource.expiryDate}
                    name="expiryOption"
                    value="Never"
                    onChange={(event: any) => {
                      const value = event.target.value
                        ? event.target.value
                        : "";
                      formik.setFieldTouched("expiry");
                      formik.setFieldValue("expiry", value);
                      setExpiry(value);
                    }}
                  />
                  <span className="checkmark"></span>
                </label>
                <label className="container">
                  Custom
                  <input
                    type="radio"
                    defaultChecked={!!resource.expiryDate}
                    name="expiryOption"
                    value="Custom"
                    onChange={(event: any) => {
                      const value = event.target.value
                        ? event.target.value
                        : "";
                      formik.setFieldTouched("expiry");
                      formik.setFieldValue("expiry", value);
                      setExpiry(value);
                    }}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              <Form.Label className="ml-2 color-gray">
                Expiry Date/Time
              </Form.Label>
              <div className="d-flex ml-2">
                <img
                  src={calender}
                  alt="calenderIcon"
                  title="Choose Date"
                  className="mr-2"
                />
                <DTPicker
                  selectedDate={selectedDate}
                  setSelectedDate={(date: string) => {
                    setSelectedDate(date);
                    formik.setFieldTouched("expiryDate");
                    formik.setFieldValue("expiryDate", date);
                  }}
                  showTimeSelect
                  timeFormat="HH:mm"
                  dateFormat="dd MMM yyyy hh:mm aa"
                  disabled={formik.values.expiry !== "Custom"}
                  minDate={new Date()}
                  minTime={
                    (!!selectedDate &&
                      new Date().toDateString() ===
                        selectedDate?.toDateString()) ||
                    !selectedDate
                      ? new Date().setHours(
                          new Date().getHours(),
                          new Date().getMinutes()
                        )
                      : new Date().setHours(0, 0)
                  }
                  className="date-picker-container form-control"
                />
              </div>
              {formik.values.expiry === "Custom" &&
                formik.touched.expiryDate &&
                formik.errors.expiryDate && (
                  <InputErrorMessage
                    className={"error-message"}
                    errorMessage={formik.errors.expiryDate || ""}
                  />
                )}
              {formik.touched.expiry && formik.errors.expiry && (
                <InputErrorMessage
                  className={"error-message"}
                  errorMessage={formik.errors.expiry || ""}
                />
              )}
            </Form.Group>
          </Form>
        </PureModal>
      )}
    </Formik>
  );
};

export default ModifyResource;
