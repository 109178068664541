import { camelCase, union, snakeCase, capitalize } from "lodash";
import moment from "moment";
import {
  setLocalStorageItem,
  getLocalStorageItem,
  parseJwtToken,
} from "src/utils/common";
import { refreshSignInApiCall } from "src/api/auth";
import { CONSOLE_ROUTE, PRESENTATION_ROUTE } from "src/constants/appRoutes";

export function toSnakeCaseKeys(o) {
  var newO, origKey, newKey, value;
  if (o instanceof Array) {
    return o.map(function (value) {
      if (typeof value === "object") {
        value = toSnakeCaseKeys(value);
      }
      return value;
    });
  } else {
    newO = {};
    for (origKey in o) {
      if (o.hasOwnProperty(origKey)) {
        newKey = snakeCase(origKey);
        value = o[origKey];
        if (
          value instanceof Array ||
          (!!value && value.constructor === Object)
        ) {
          value = toSnakeCaseKeys(value);
        }
        newO[newKey] = value;
      }
    }
  }
  return newO;
}

export const formatDate = (date) =>
  moment.utc(date).format("DD MMM YYYY, hh:mm a");

export const formatDay = (date) => moment.utc(date).format("DD MMM YYYY");

export const formatDateByDefault = (date) => moment.utc(date).format();

export const formatDateToLocal = (date) =>
  moment.utc(date).local().format("DD MMM YYYY, hh:mm a");

export function toCamelCaseKeys(o) {
  var newO, origKey, newKey, value;
  if (o instanceof Array) {
    return o.map(function (value) {
      if (typeof value === "object") {
        value = toCamelCaseKeys(value);
      }
      return value;
    });
  } else {
    newO = {};
    for (origKey in o) {
      if (o.hasOwnProperty(origKey)) {
        newKey = camelCase(origKey);
        value = o[origKey];
        if (
          value instanceof Array ||
          (value !== null && value.constructor === Object)
        ) {
          value = toCamelCaseKeys(value);
        }
        newO[newKey] = value;
      }
    }
  }
  return newO;
}

export const toCamelCase = (item: string | Array<string>) => {
  if (!item) {
    return "";
  }
  if (item instanceof Array) {
    return item
      .map((name) => name[0].toUpperCase() + name.substring(1))
      .join(" ");
  } else if (!item) {
    return;
  }
  return item
    .split(" ")
    .map((name) => name[0].toUpperCase() + name.substring(1))
    .join(" ");
};

// export const toCamelCaseKeys = (obj) =>
//   mapKeys(obj, (value, key) => {

//     return isPlainObject(value) ? toCamelCaseKeys(value) : camelCase(key);
//   });

export const prefixZero = (timeUnit) => {
  const timeUnitString = Math.abs(timeUnit).toString();
  if (timeUnitString.length <= 1) {
    return `0${timeUnitString}`;
  }
  const prefixedTimeUnit = Number(timeUnitString) * Math.sign(timeUnit);
  return prefixedTimeUnit.toString();
};

export const computeRemainingTime = (shutdownTime) => {
  const datesDiff = moment(moment(shutdownTime).local()).diff(moment());
  const remainingTime = moment.duration(datesDiff, "ms");
  return {
    hours: prefixZero(remainingTime.get("hours")),
    minutes: prefixZero(remainingTime.get("minutes")),
    seconds: prefixZero(remainingTime.get("seconds")),
  };
};

export const removeDuplicates = (items) => union(items);

export const capitalizeFirstLetter = (value: string) => capitalize(value);

export const registerForRefreshToken = () => {
  setInterval(async () => {
    const lastLoginTimestamp = getLocalStorageItem("lastLoginTimestamp");
    const currentToken = getLocalStorageItem("token");
    const decodedToken = parseJwtToken(currentToken);

    if (lastLoginTimestamp && currentToken) {
      if ((decodedToken.exp - new Date().getTime() / 1000) / 60 <= 15) {
        let data = await refreshSignInApiCall(currentToken);
        if (data && data.token) {
          setLocalStorageItem("token", data.token);
          setLocalStorageItem("lastLoginTimestamp", `${Date.now()}`);
          setLocalStorageItem("showNotifications", "1");
        }
      }
    }
  }, 300000); // Check every five minutes if 15 minutes are left for token validity
};

export const registerForUserInactivitySession = () => {
  setInterval(() => {
    const lastUserActivityTimestamp = getLocalStorageItem(
      "lastUserActivityTimestamp"
    );
    const currentToken = getLocalStorageItem("token");
    const onLabConsole =
      window.location.pathname.includes(CONSOLE_ROUTE) ||
      window.location.pathname.includes(PRESENTATION_ROUTE);

    if (lastUserActivityTimestamp && currentToken && !onLabConsole) {
      if (Math.ceil((Date.now() - +lastUserActivityTimestamp) / 60000) > 60) {
        setLocalStorageItem("token", "");
        setLocalStorageItem("lastLoginTimestamp", ``);
        setLocalStorageItem("showNotifications", "0");
        window.location.href = "/";
      }
    }
  }, 60000);
};

export const registerForUserActivityTracking = () => {
  const setUserActivityTimeStamp = () => {
    setLocalStorageItem("lastUserActivityTimestamp", `${Date.now()}`);
  };
  window.document.addEventListener("click", setUserActivityTimeStamp);
  window.document.addEventListener("keypress", setUserActivityTimeStamp);
};
