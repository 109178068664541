import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import InputErrorMessage from "src/components/shared/InputErrorMessage";
import authLogo from "src/assets/images/authLogo.svg";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import FormGroup from "react-bootstrap/FormGroup";
import okta from "src/assets/images/okta.svg";
import invisibleIcon from "src/assets/images/invisibleIcon.svg";
import lockIcon from "src/assets/images/lockIcon.svg";
import userIcon from "src/assets/images/userIcon.svg";
import "./Login.scss";
import { oktaUrl, partnerUrl } from "src/constants/urls";
import visibleIcon from "src/assets/images/visibleIcon.svg";
import { setLocalStorageItem } from "src/utils/common";
import { RouteComponentProps } from "react-router-dom";
import { signInApiCall } from "src/api/auth";
import { LABS_ROUTE } from "src/constants/appRoutes";
import { formatError } from "src/utils/common";
import { useLocation, useHistory } from "react-router-dom";
import { isUserAuthenticated } from "src/utils/common";

type LoginProps = {} & RouteComponentProps;

const initialValues = { email: "", password: "" };
const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const Login: React.FC<LoginProps> = (props) => {
  const isAuthenticated = isUserAuthenticated();
  let [passwordVisible, togglePasswordVisibility] = useState<Boolean>(false);
  const [loginError, setLoginError] = useState("");
  const resetLoginError = () => setLoginError("");
  const history = useHistory();
  const { search } = useLocation();
  let redirectUrl = "";
  if (search) {
    redirectUrl = search.slice(1); // "Removing ? mark at start of query string"
  }

  const logInWithOkta = (): void => {
    window.location.href = oktaUrl;
  };

  const loginWithPartner = () => {
    window.location.href = partnerUrl;
  };

  React.useEffect(() => {
    if (isAuthenticated) {
      history.push(LABS_ROUTE);
    }
  }, [isAuthenticated]);

  const signIn = async (user, { setSubmitting }) => {
    let data = await signInApiCall(user.email, user.password);
    if (data && data.token) {
      setLocalStorageItem("token", data.token);
      setLocalStorageItem("lastLoginTimestamp", `${Date.now()}`);
      setLocalStorageItem("showNotifications", "1");
      setLoginError("");
      if (redirectUrl) {
        window.location.href = redirectUrl;
      } else {
        props.history.push(LABS_ROUTE);
      }
    } else {
      setLoginError(formatError(data));
    }
  };

  const changePasswordVisibility = (e) =>
    togglePasswordVisibility(!passwordVisible);

  return (
    <>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={signIn}
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit} className="h-100">
            <div className="left-section-signin d-flex flex-grow-1 flex-column align-items-center justify-content-center">
              <div className="login-header pb-2">WELCOME TO</div>
              <div className="login-logo d-flex justify-content-center">
                <img src={authLogo} alt="authLogo" />
              </div>
              <div className="login-section mt-3 p-4">
                <div className="login-section-header mb-3">
                  Test Drive Login
                </div>
                <FormGroup controlId="forEmail" className="section-form-input">
                  <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text className="input-icon-background">
                        <img src={userIcon} alt="userIcon" />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      type="email"
                      id="email"
                      placeholder="Email"
                      className="border-none"
                      aria-describedby="basic-addon1"
                      onFocus={resetLoginError}
                      onKeyDown={resetLoginError}
                      {...formik.getFieldProps("email")}
                    />
                    {formik.errors.email && formik.touched.email ? (
                      <div className="w-100">
                        <InputErrorMessage
                          errorMessage={formik.errors.email || ""}
                        />
                      </div>
                    ) : null}
                  </InputGroup>
                </FormGroup>
                <div>
                  <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text className="input-icon-background">
                        <img src={lockIcon} alt="lockIcon" />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      id="password"
                      placeholder="Password"
                      className="border-none"
                      aria-describedby="basic-addon1"
                      type={passwordVisible ? "text" : "password"}
                      onFocus={resetLoginError}
                      onKeyDown={resetLoginError}
                      {...formik.getFieldProps("password")}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text className="input-icon-background cursor-pointer">
                        <img
                          onClick={changePasswordVisibility}
                          src={passwordVisible ? visibleIcon : invisibleIcon}
                          alt="invisibleIcon"
                        />
                      </InputGroup.Text>
                    </InputGroup.Append>
                    {formik.errors.password && formik.touched.password ? (
                      <div className="w-75 mt-1">
                        <InputErrorMessage
                          errorMessage={formik.errors.password || ""}
                        />
                      </div>
                    ) : null}
                  </InputGroup>
                </div>
                <NavLink
                  to="/auth/forgot-password"
                  className="text-decoration-none forgot-password-link"
                >
                  <div
                    className={`login-section-forgot-password d-flex flex-row-reverse 
                   ${
                     formik.errors.password && formik.touched.password
                       ? "mt-n35"
                       : "mt-n10"
                   }`}
                  >
                    Forgot Password?
                  </div>
                </NavLink>
                <div
                  className={`error-message login-error-message ${
                    loginError ? "visible" : "hidden"
                  }`}
                >
                  {loginError}
                </div>
                <div className="login-section-submit mt-3">
                  <Button
                    variant="outline-primary"
                    className="w-100 btn-bg-white"
                    type="submit"
                  >
                    Login
                  </Button>
                </div>
              </div>
              <div className="seperator seperator-text mt-4">
                Or sign in with
              </div>
              <div className="okta-signin mt-4 mb-2 d-flex justify-content-center">
                <Button
                  variant="dark"
                  className="w-100"
                  onClick={logInWithOkta}
                >
                  <div className="d-flex justify-content-center">
                    <img className="mr-3" src={okta} alt="okta" />
                    <div className="mt-1">Sign in with Okta</div>
                  </div>
                </Button>
              </div>
              <div className="partner-signin  mt-2 d-flex justify-content-center">
                <Button
                  variant="primary"
                  className="w-100"
                  type="button"
                  onClick={loginWithPartner}
                >
                  <div className="d-flex justify-content-center">
                    <div className="mt-1">Partner Login</div>
                  </div>
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Login;
