import produce from "immer";
import { addFilesToList } from "src/helpers/presentations";

export const GET_PRESENTATION_LIST = "GET_PRESENTATION_LIST";
export const UPDATE_FILES_BY_FOLDER = "UPDATE_FILES_BY_FOLDER";
export const DELETE_PRESENTATION = "DELETE_PRESENTATION";
export const ADD_FOLDER = "ADD_FOLDER";

export interface IPresentation {
  name: "";
  id: -1;
  subRows: [];
}

export interface IPresentationList {
  list: Array<IPresentation>;
}

const initialState: IPresentationList = {
  list: [],
};

export default (state = initialState, action): IPresentationList =>
  produce(state, (draft: any) => {
    switch (action.type) {
      case GET_PRESENTATION_LIST:
        draft.list = action.payload;
        break;

      case UPDATE_FILES_BY_FOLDER:
        if (!!action.payload.folderId) {
          if (action.payload.folderId === action.payload.currentFolderId) {
            draft.list = addFilesToList(action.payload.file, state.list);
          } else {
            draft.list
              .find((folder: any) => action.payload.folderId === folder.id)
              .subRows.unshift(action.payload.file);
          }
        } else {
          draft.list = addFilesToList(action.payload.file, state.list);
        }
        break;

      case DELETE_PRESENTATION:
        let presentations = [...state.list];
        const targetItemIndex =
          state.list &&
          state.list.findIndex((folder) => folder.id === action.payload.id);

        if (targetItemIndex > -1) {
          presentations.splice(targetItemIndex, 1);
        } else {
          presentations = state.list.map((folder: any) => {
            let folderCopy = { ...folder };
            folderCopy.subRows =
              folder.subRows &&
              folder.subRows.filter((file) => file.id !== action.payload.id);
            return folderCopy;
          });
        }

        draft.list = presentations;
        break;

      case ADD_FOLDER:
        draft.list.unshift(action.payload);
        break;

      default:
        break;
    }
  });
