import React, { useEffect } from "react";
import { TNC_ROUTE } from "src/constants/appRoutes";
import { Link } from "react-router-dom";
import { PRIVACY_URL } from "src/constants/common";

type TermsAndPrivacySectionProps = {};

const TermsAndPrivacySection: React.FunctionComponent<TermsAndPrivacySectionProps> = () => {
  return (
    <div className="d-flex justify-content-between">
      <Link target="_blank" to={TNC_ROUTE}>
        Test Drive Terms{" "}
      </Link>
      <a href={PRIVACY_URL} target="_blank">
        Privacy Policy
      </a>
    </div>
  );
};

export default TermsAndPrivacySection;
