import React from "react";
import PureModal from "src/components/shared/PureModal";
import { Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import InputErrorMessage from "src/components/shared/InputErrorMessage";
import { useDispatch } from "react-redux";
import RequiredFieldSymbol from "src/components/ui/RequiredFieldSymbol";
import { isEmpty } from "src/utils/common";
import { getStorylineList } from "src/state/storylineList/actions";
import { updateStoryline } from "src/api/stories";

type EditStorylineNameProps = {
  setShowModal: (boolean) => void;
  storylineId?: string;
  storylineName?: string;
};

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
});

const EditStorylineName: React.FunctionComponent<EditStorylineNameProps> = ({
  storylineId,
  setShowModal,
  storylineName,
  ...props
}) => {
  const dispatch = useDispatch();
  const onSubmitHandle = async (formik, { setSubmitting }) => {
    const payload = {
      id: storylineId,
      name: formik.name,
    };
    setSubmitting(true);
    const response = await updateStoryline(payload);
    setSubmitting(false);
    if (!isEmpty(response) && !response.hasError) {
      setShowModal(false);
      dispatch(getStorylineList());
    }
  };

  return (
    <Formik
      initialValues={{ name: storylineName }}
      validationSchema={validationSchema}
      onSubmit={onSubmitHandle}
    >
      {(formik) => (
        <PureModal
          title={"Edit Guide Name"}
          showModal={true}
          closeModal={() => setShowModal(false)}
          primaryBtnLabel={"Save"}
          primaryBtnAction={formik.handleSubmit}
          secondaryBtnLabel="Cancel"
          secondaryBtnAction={() => setShowModal(false)}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <Form.Group>
              <Form.Label>
                Name
                <RequiredFieldSymbol />
              </Form.Label>
              <Form.Control
                type="text"
                id="name"
                placeholder="Enter name"
                {...formik.getFieldProps("name")}
                isInvalid={!!(formik.touched.name && formik.errors.name)}
              />
              <Form.Control.Feedback type="invalid">
                <InputErrorMessage errorMessage={formik.errors.name || ""} />
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </PureModal>
      )}
    </Formik>
  );
};

export default EditStorylineName;
