import React from "react";
import { useHistory } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import InputErrorMessage from "src/components/shared/InputErrorMessage";
import authLogo from "src/assets/images/authLogo.svg";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import FormGroup from "react-bootstrap/FormGroup";
import { resetPassword } from "src/api/users";
import sendPasswordResetLinkIcon from "src/assets/images/resendPassword.svg";
import { LOGIN_ROUTE } from "src/constants/appRoutes";
import { formatError, isEmpty } from "src/utils/common";
import "./ForgotPassword.scss";

type ForgotPasswordProps = {};

const initialValues = { email: "" };
const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid Email Address")
    .required("Email is required"),
});

const ForgotPassword: React.FC<ForgotPasswordProps> = () => {
  const history = useHistory();
  const [submitError, setSubmitError] = React.useState("");
  const resetSubmitError = () => setSubmitError("");

  const sendPasswordResetLink = async (values, { setSubmitting }) => {
    const data = await resetPassword(values.email);
    if (data.success) {
      history.push(LOGIN_ROUTE);
    } else if (!isEmpty(data) && data.hasError) {
      setSubmitError(formatError(data));
    }
  };

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={sendPasswordResetLink}
    >
      {(formik) => (
        <div className="left-section-signin d-flex flex-grow-1 flex-column align-items-center justify-content-center">
          <div className="login-header pb-2">WELCOME TO</div>
          <div className="login-logo d-flex justify-content-center">
            <img src={authLogo} alt="authLogo" />
          </div>
          <div className="login-section mt-4">
            <div className="login-section-header mb-3">Forgot Password</div>
            <p className="login-section-text">
              Enter your registered email address. We’ll send you an email with
              a link to confirm your identity and reset your password.
            </p>
            <Form onSubmit={formik.handleSubmit}>
              <FormGroup controlId="forEmail">
                <InputGroup className="mb-3 mt-4">
                  <InputGroup.Prepend>
                    <InputGroup.Text className="input-icon-background">
                      <img
                        src={sendPasswordResetLinkIcon}
                        alt="sendPasswordResetLinkIcon"
                      />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    type="email"
                    id="email"
                    placeholder="user@example.com"
                    className="border-none"
                    aria-describedby="basic-addon1"
                    onFocus={resetSubmitError}
                    {...formik.getFieldProps("email")}
                  />
                  {formik.errors.email && formik.touched.email ? (
                    <div className="w-100 mt-1">
                      <InputErrorMessage
                        errorMessage={formik.errors.email || ""}
                      />
                    </div>
                  ) : null}
                </InputGroup>
                {submitError && (
                  <div className="error-message">{submitError}</div>
                )}
                <div className="mt-5 ">
                  <Button
                    variant="outline-primary"
                    className="w-100 btn-bg-white"
                    type="submit"
                  >
                    Reset Password
                  </Button>
                </div>
              </FormGroup>
            </Form>
          </div>
        </div>
      )}
    </Formik>
  );
};

export default ForgotPassword;
