import React from "react";
import revoke from "src/assets/images/revoke.svg";
import { formatDateToLocal, formatDay } from "src/helpers/common";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CustomTooltip from "src/components/ui/CustomTooltip";
import copy from "src/assets/images/copy-code.svg";
import { sortColumn } from "src/utils/common";
import { pushNotification } from "src/components/ui/Notification";

const pushVoucherCopyNotification = () =>
  pushNotification({
    type: "info",
    message: "Voucher URL Copied",
  });

export const adminVouchersColumns = (revokeVoucherHandler) => [
  {
    Header: "Voucher Name",
    accessor: "name",
    percentWidth: 19,
    sortType: (rowA, rowB) => sortColumn(rowA, rowB, "name"),
    // Cell: ({ row, value }) => {
    //   return !!row.original.isUsed ? (
    //     <span className="voucher-name" onClick={() => showUsers(row.original)}>
    //       {value}
    //     </span>
    //   ) : (
    //     <span>{value}</span>
    //   );
    // },
  },
  {
    Header: "Created By",
    accessor: "createdBy",
    percentWidth: 16,
    sortType: (rowA, rowB) => {
      return sortColumn(rowA, rowB, "createdBy.name");
    },
    Cell: ({ value }) => {
      return <span>{value.name}</span>;
    },
  },
  {
    Header: "Created On",
    accessor: "createdOn",
    percentWidth: 16,
    Cell: ({ value }) => {
      return <span> {formatDateToLocal(value)} </span>;
    },
  },

  {
    Header: "Expiry",
    accessor: "expiresOn",
    percentWidth: 10,
    Cell: ({ value }) => {
      return <span> {!!value ? formatDay(value) : "Never"} </span>;
    },
  },
  {
    Header: "Usage",
    Cell: ({ row }) => {
      return (
        <span>{row.original.usageCount + "/" + row.original.usageAllowed}</span>
      );
    },
    percentWidth: 8,
  },
  {
    Header: "Lab",
    accessor: "labAssigned",
    percentWidth: 15,
    sortType: (rowA, rowB) => sortColumn(rowA, rowB, "labAssigned.name"),
    Cell: ({ value }) => {
      return <span> {value.name} </span>;
    },
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: ({ value }) => {
      return (
        <div
          className={`d-flex justify-content-center status status-${
            value && value.toLowerCase()
          }`}
        >
          {value}
        </div>
      );
    },
    percentWidth: 10,
  },
  {
    Header: "Action",
    disableSortBy: true,
    accessor: (row) => {
      return (
        <div className="d-flex" title="">
          {!!row.isActive && !row.isExpired && !row.isUsed && (
            <div className="action-icon p-0" title="">
              <CustomTooltip text="Revoke">
                <img
                  src={revoke}
                  alt="revokeIcon"
                  onClick={() => revokeVoucherHandler(row)}
                />
              </CustomTooltip>
            </div>
          )}
          {row.status === "Enabled" && row.usageCount !== row.usageAllowed && (
            <CustomTooltip text="Copy Voucher URL">
              <CopyToClipboard
                text={`${window.location.origin}/voucher/${row.code}`}
                onCopy={pushVoucherCopyNotification}
              >
                <img
                  width="13"
                  src={copy}
                  alt="copy-url"
                  className="cursor-pointer ml-3"
                />
              </CopyToClipboard>
            </CustomTooltip>
          )}
        </div>
      );
    },
    percentWidth: 6,
  },
];

export const userVouchersColumns = () => [
  {
    Header: "Voucher Name",
    accessor: "name",
    percentWidth: 14,
    sortType: (rowA, rowB) => sortColumn(rowA, rowB, "name"),
  },
  {
    Header: "Invited Email",
    accessor: "email",
    percentWidth: 16,
    sortType: (rowA, rowB) => sortColumn(rowA, rowB, "email"),
  },
  {
    Header: "Invited By",
    accessor: (row) => row.invitedBy.name,
    percentWidth: 16,
    sortType: (rowA, rowB) => sortColumn(rowA, rowB, "Invited By"),
  },
  {
    Header: "Created On",
    accessor: "createdOn",
    percentWidth: 15,
    Cell: ({ value }) => {
      return <span> {formatDateToLocal(value)} </span>;
    },
  },
  {
    Header: "Enabled",
    accessor: "isActive",
    percentWidth: 9,
    Cell: ({ value }) => {
      return <span> {!!value ? "Yes" : "No"} </span>;
    },
  },
  {
    Header: "Usage",
    Cell: ({ row }) => {
      return (
        <span>{row.original.usageCount + "/" + row.original.totalCount}</span>
      );
    },
    percentWidth: 9,
  },
  {
    Header: "Lab",
    accessor: "labAssigned",
    percentWidth: 15,
    sortType: (rowA, rowB) => sortColumn(rowA, rowB, "labAssigned.name"),
    Cell: ({ value }) => {
      return <span> {value.name} </span>;
    },
  },
  {
    Header: "Action",
    disableSortBy: true,
    percentWidth: 10,
    Cell: ({ row }) => {
      return (
        <div title="" className="voucher-container">
          {row.original.usageCount !== row.original.totalCount && (
            <CustomTooltip text="Copy Voucher URL">
              <CopyToClipboard
                text={`${window.location.origin}/voucher/${row.original.code}`}
                onCopy={pushVoucherCopyNotification}
              >
                <img
                  width="13"
                  src={copy}
                  alt="copy-url"
                  className="cursor-pointer"
                />
              </CopyToClipboard>
            </CustomTooltip>
          )}
        </div>
      );
    },
  },
];
