import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import { logoutUser } from "src/api/auth";
import { setLocalStorageItem } from "src/utils/common";
import whiteDropdownIcon from "src/assets/images/dropdown-white.svg";
import dropdownIcon from "src/assets/images/dropdown.svg";
import checkIcon from "src/assets/images/check.svg";
import {
  CONSOLE_ROUTE,
  PRESENTATION_ROUTE,
  ADMIN_ROUTE,
  USERS_ROUTE,
  LOGIN_ROUTE,
  CHANGE_PASSWORD_ROUTE,
} from "src/constants/appRoutes";
import "./CustomDropdown.scss";
import { isEmpty } from "src/utils/common";
import { PRIVACY_URL } from "src/constants/common";

const CustomDropdown = ({ name, showUserAdminConsole, userInfo }) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const CustomToggle = React.forwardRef<any, any>(
    ({ children, onClick }, ref) => (
      <div
        className="text-decoration-none custom-dropdown-toggle cursor-pointer"
        ref={ref}
        onClick={onClick}
      >
        {children}
      </div>
    )
  );

  const signoutUser = async () => {
    const data = await logoutUser();
    if (!isEmpty(data) && !data.hasError) {
      setLocalStorageItem("token", "");
      setLocalStorageItem("lastLoginTimestamp", ``);
      setLocalStorageItem("showNotifications", "0");
      history.push(LOGIN_ROUTE);
    }
  };

  return (
    <Dropdown>
      <Dropdown.Toggle id="user-custom-dropdown" as={CustomToggle}>
        <div className="ml-2">
          <span
            className={`mr-2 dropdown-name ${
              (pathname.includes(CONSOLE_ROUTE) ||
                pathname.includes(PRESENTATION_ROUTE)) &&
              "text-white dropdown-name-visibility"
            }`}
          >
            {name}
          </span>
          {pathname.includes(CONSOLE_ROUTE) ||
          pathname.includes(PRESENTATION_ROUTE) ? (
            <img src={whiteDropdownIcon} alt="dropdown" />
          ) : (
            <img src={dropdownIcon} alt="dropdown" />
          )}
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {showUserAdminConsole && (
          <>
            <Dropdown.Item className="pure-link-decoration-none">
              <div className="d-flex">
                <NavLink
                  activeClassName="selected-route"
                  className="pure-link-decoration-none w-100 d-flex justify-content-between"
                  to={ADMIN_ROUTE}
                >
                  Admin Console
                  <img
                    src={checkIcon}
                    alt="dropdown"
                    className="ml-2 menu-option-checked"
                    width="10rem"
                  />
                </NavLink>
              </div>
            </Dropdown.Item>

            <Dropdown.Item className="pure-link-decoration-none">
              <div className="d-flex">
                <NavLink
                  activeClassName="selected-route"
                  className="pure-link-decoration-none w-100 d-flex justify-content-between"
                  to={USERS_ROUTE}
                >
                  User Section
                  <img
                    src={checkIcon}
                    alt="dropdown"
                    className="ml-2 menu-option-checked"
                    width="10rem"
                  />
                </NavLink>
              </div>
            </Dropdown.Item>
          </>
        )}
        {userInfo.scopes?.type === "ADHOC" && (
          <Dropdown.Item className="pure-link-decoration-none">
            <div className="d-flex">
              <NavLink
                activeClassName="selected-route"
                className="pure-link-decoration-none w-100 d-flex justify-content-between"
                to={CHANGE_PASSWORD_ROUTE}
              >
                Change Password
                <img
                  src={checkIcon}
                  alt="dropdown"
                  className="ml-2 menu-option-checked"
                  width="10rem"
                />
              </NavLink>
            </div>
          </Dropdown.Item>
        )}
        <Dropdown.Item href={PRIVACY_URL} target="_blank">
          <span>Privacy Policy</span>
        </Dropdown.Item>
        <Dropdown.Item onClick={signoutUser}>
          <span> Logout </span>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default CustomDropdown;
