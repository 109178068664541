import produce from "immer";

export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS";
export const ADD_NOTIFICATIONS_SUCCESS = "ADD_NOTIFICATIONS_SUCCESS";
export const ADD_NOTIFICATIONS_FAILURE = "ADD_NOTIFICATIONS_FAILURE";
export const NOTIFICATIONS_ACTION = "NOTIFICATIONS_ACTION";

export interface INotifications {
  list: Array<INotification>;
  notificationSaveSuccessful: boolean;
  notificationSaveFailure: boolean;
  notificationAction: number
}

declare interface AssignedUser {
  expiry: string;
  user: string
}

declare interface AssignedGroup {
  expiry: string;
  group: string
}

declare interface AssignedCompany {
  expiry: string;
  company: string
}

export interface INotification {
  id: string;
  header: string;
  body: string;
  displayLocation: string;
  startTime: string;
  endTime: string;
  isEnabled: boolean;
  isDeleted: boolean;
  assignedUsers: Array<AssignedUser>
  assignedGroups: Array<AssignedGroup>
  assignedCompanies: Array<AssignedCompany>
}

const initialState: INotifications = {
  list: [],
  notificationSaveSuccessful: false,
  notificationSaveFailure: false,
  notificationAction: 0
};

export default (state = initialState, action): INotifications =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_NOTIFICATIONS_SUCCESS:
        draft.notificationSaveSuccessful = true;
        draft.list = action.payload;
        break;

      case NOTIFICATIONS_ACTION:
        draft.notificationAction = state.notificationAction + 1
        break;

      default:
        break;
    }
  });
