import { toCamelCaseKeys } from "../common";

export const mapVouchers = (vouchers) =>
  vouchers &&
  vouchers.map(toCamelCaseKeys).map((voucher) => {
    voucher.status =
      (!!voucher.isUsed && "Used") ||
      (voucher.isExpired && !!voucher.isActive && "Expired") ||
      (!!voucher.isActive && !voucher.isExpired && "Enabled") ||
      (!voucher.isActive && "Disabled");
    return voucher;
  });

export const mapUserVouchers = (vouchers = []) => vouchers.map(toCamelCaseKeys);

export const mapVoucherLabs = (labs = []) => labs.map(toCamelCaseKeys);
