import React, { useState } from "react";
import voucherConfigurationIcon from "src/assets/images/voucherConfiguration.svg";
import FormLayout from "src/components/shared/FormLayout";
import ModifyResource from "./../ModifyResource";
import Table from "src/components/shared/Table";
import getVoucherConfigColumns from "./VoucherConfigColumn";
import { Button } from "react-bootstrap";
import WarningModal from "src/components/shared/WarningModal";
import noVoucherConfiguration from "src/assets/images/no-lab-configuration.svg";

type VoucherConfigProps = {
  voucherConfigList: any[];
  modifyVoucherConfigList: (voucher) => void;
  deleteConfig: (voucherType, id) => void;
};

const VoucherConfig: React.FC<VoucherConfigProps> = ({
  voucherConfigList,
  modifyVoucherConfigList,
  deleteConfig,
  ...props
}) => {
  const [selectedResource, setSelectedResource] = useState({});
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showAddGroupModal, setShowGroupModal] = useState(false);
  const [showAddCompanyModal, setShowCompanyModal] = useState(false);
  const toggleUserModal = () => setShowAddUserModal(!showAddUserModal);
  const toggleGroupModal = () => setShowGroupModal(!showAddGroupModal);
  const toggleCompanyModal = () => setShowCompanyModal(!showAddCompanyModal);

  const [deleteVoucherConfigId, setDeleteVoucherConfigId] = useState("");
  const [deleteVoucherConfigType, setDeleteVoucherConfigType] = useState("");
  const [
    showDeleteVoucherConfigWarning,
    setShowDeleteVoucherConfigWarning,
  ] = useState(false);

  const deleteVoucherConfig = (type, id) => {
    setDeleteVoucherConfigId(id);
    setDeleteVoucherConfigType(type);
    setShowDeleteVoucherConfigWarning(true);
  };

  const deleteVoucherConfigConfirm = () => {
    setShowDeleteVoucherConfigWarning(false);
    deleteConfig(deleteVoucherConfigType, deleteVoucherConfigId);
  };

  const getVoucherConfigNameById = (
    selectedVoucherConfigId,
    selectedVoucherConfigType
  ) => {
    let voucher = voucherConfigList.find(
      (voucher) =>
        voucher.id === selectedVoucherConfigId &&
        voucher.type === selectedVoucherConfigType
    );
    return voucher && voucher.name;
  };

  const toggleModal = (event) => {
    const resource = JSON.parse(event.target.dataset.resource);
    const { type } = resource;
    setSelectedResource(resource);
    if (type === "Group") {
      toggleGroupModal();
    } else if (type === "Company") {
      toggleCompanyModal();
    } else {
      toggleUserModal();
    }
  };

  const voucherConfigColumns = getVoucherConfigColumns(
    deleteVoucherConfig,
    toggleModal
  );

  return (
    <FormLayout
      headerImage={voucherConfigurationIcon}
      headerTitle="Voucher Creation Configuration"
    >
      <div className="access-section">
        {voucherConfigList.length ? (
          <div>
            <div className="d-flex justify-content-center">
              <div className="width-95 pt-2 d-flex justify-content-end">
                <Button
                  type="submit"
                  size="sm"
                  variant="outline-primary"
                  className="btn-min-width"
                  onClick={() => {
                    setSelectedResource({ fieldType: "user" });
                    setShowAddUserModal(true);
                  }}
                >
                  Add User
                </Button>
                <Button
                  type="submit"
                  size="sm"
                  variant="outline-primary"
                  className="btn-min-width ml-2"
                  onClick={() => {
                    setSelectedResource({ fieldType: "group" });
                    setShowGroupModal(true);
                  }}
                >
                  Add Group
                </Button>
                <Button
                  type="submit"
                  size="sm"
                  variant="outline-primary"
                  className="btn-min-width ml-2"
                  onClick={() => {
                    setSelectedResource({ fieldType: "company" });
                    setShowCompanyModal(true);
                  }}
                >
                  Add Company
                </Button>
              </div>
            </div>
            <div className="d-flex justify-content-center ">
              <div className="mt-3 mb-5 width-95 custom-border">
                <Table
                  theadStyle={{ backgroundColor: "#f8f9fc" }}
                  columns={voucherConfigColumns}
                  data={voucherConfigList}
                  paginationOff
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="no-result pt-5 pb-5">
            <div className="d-flex flex-column align-items-center error">
              <img src={noVoucherConfiguration} alt="error" height="100px" />
              <p className="mb-0"> No Configuration Yet </p>
              <p>
                Click on{" "}
                <a
                  href="javascript:void(0)"
                  className="underline"
                  onClick={() => {
                    setSelectedResource({ fieldType: "user" });
                    setShowAddUserModal(true);
                  }}
                >
                  Add User
                </a>{" "}
                ,{" "}
                <a
                  href="javascript:void(0)"
                  className="underline"
                  onClick={() => {
                    setSelectedResource({ fieldType: "group" });
                    setShowGroupModal(true);
                  }}
                >
                  Add Group
                </a>{" "}
                or{" "}
                <a
                  href="javascript:void(0)"
                  className="underline"
                  onClick={() => {
                    setSelectedResource({ fieldType: "company" });
                    setShowCompanyModal(true);
                  }}
                >
                  Add Company
                </a>
              </p>
            </div>
          </div>
        )}
        {showAddUserModal && (
          <ModifyResource
            selectedResource={selectedResource}
            modifyList={modifyVoucherConfigList}
            setShowModifyResourceModal={setShowAddUserModal}
          />
        )}
        {showAddGroupModal && (
          <ModifyResource
            selectedResource={selectedResource}
            modifyList={modifyVoucherConfigList}
            setShowModifyResourceModal={setShowGroupModal}
          />
        )}
        {showAddCompanyModal && (
          <ModifyResource
            selectedResource={selectedResource}
            modifyList={modifyVoucherConfigList}
            setShowModifyResourceModal={setShowCompanyModal}
          />
        )}
        {showDeleteVoucherConfigWarning && (
          <WarningModal
            title="Warning"
            onCancelAction={() => setShowDeleteVoucherConfigWarning(false)}
            onConfirmAction={deleteVoucherConfigConfirm}
          >
            <div className="ml-2">
              <div>Are you sure you want to delete</div>
              <div>
                <b>
                  {getVoucherConfigNameById(
                    deleteVoucherConfigId,
                    deleteVoucherConfigType
                  )}
                </b>{" "}
                Voucher Config ?
              </div>
            </div>
          </WarningModal>
        )}
      </div>
    </FormLayout>
  );
};

export default VoucherConfig;
