import React, { useEffect, useState } from "react";
import {
  Switch,
  Route,
  useParams,
  useHistory,
  Redirect,
} from "react-router-dom";
import { isUserAuthenticated } from "src/utils/common";
import { verifyVoucherCode, activateVoucherCode } from "src/api/vouchers";
import { launchConsole } from "src/helpers/labs";
import {
  VOUCHER_LOGIN_ROUTE,
  VOUCHER_REGISTER_ROUTE,
  PRESENTATION_ROUTE,
} from "src/constants/appRoutes";
import ErrorPage from "src/components/ErrorPage";
import noVoucherAccessIcon from "src/assets/images/no-voucher-access.svg";
import { VoucherLogin, VoucherRegister } from "src/containers/user/LabVoucher/";
import { isEmpty, formatError } from "src/utils/common";
import { toCamelCaseKeys } from "src/helpers/common";

const LabVoucher = () => {
  const [error, setError] = useState<any>({});
  const { code } = useParams();
  const history = useHistory();
  const isAuthenticated = isUserAuthenticated();
  const setVoucherError = (error) =>
    setError({
      title: "",
      message: formatError(error),
      btnLabel: "Go Back",
    });

  const prepareLaunchLab = async () => {
    const data = await verifyVoucherCode(code);
    if (isEmpty(data) || data.hasError) {
      setVoucherError(data);
    } else {
      if (isAuthenticated) {
        launchLab();
      }
    }
  };

  const launchLab = async () => {
    let data = await activateVoucherCode({ code });
    if (isEmpty(data) || data.hasError) {
      setVoucherError(data);
    } else {
      const lab = toCamelCaseKeys(data);
      launchConsole(PRESENTATION_ROUTE, history, lab, 'from_login');
    }
  };

  useEffect(() => {
    prepareLaunchLab();
  }, []);

  const LabVoucherLogin = () => <VoucherLogin launchLab={launchLab} />;
  const LabVoucherRegister = () => (
    <VoucherRegister launchLab={launchLab} setVoucherError={setVoucherError} />
  );

  return !isEmpty(error) ? (
    <ErrorPage
      title={error.title}
      message={error.message}
      btnLabel={error.btnLabel}
      hideGoBackButton
    >
      <img
        height={200}
        src={noVoucherAccessIcon}
        alt="no-vouchr-access"
        className="mt-5"
      />
    </ErrorPage>
  ) : (
    <>
      <Switch>
        <Route path={VOUCHER_LOGIN_ROUTE} component={LabVoucherLogin} />
        <Route path={VOUCHER_REGISTER_ROUTE} component={LabVoucherRegister} />
        <Redirect to={VOUCHER_REGISTER_ROUTE} />
      </Switch>
    </>
  );
};

export default LabVoucher;
