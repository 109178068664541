import Api from "src/helpers/request";

export const uploadImgEndPoint = "/image/upload";

export const uploadImg = (file) => {
  const formData = new FormData();
  formData.append("upload", file);
  return Api.call(
    { url: uploadImgEndPoint, method: "POST", data: formData },
    { showLoader: true }
  );
};
