import {
  UPDATE_STORY_CONTENT,
  ADD_SECTION,
  UPDATE_SECTION,
  UPDATE_SECTION_STORY,
  GET_STORYLINE_DETAILS,
  UPDATE_INTRODUCTORY_CONTENT,
  ADD_CREDENTIAL,
  UPDATE_CREDENTIAL,
  DELETE_CREDENTIAL,
  IStoryline,
  IStory,
  UPDATE_WARNING_FLAG,
} from "./reducer";
import { fetchStorylineVersionById } from "src/api/stories";
import { mapStorylineDetails, mapStorylineSections } from "src/helpers/stories";

export const storyContentUpdateAction = (sectionId, storyId, content) => {
  return {
    type: UPDATE_STORY_CONTENT,
    payload: {
      sectionId,
      storyId,
      content,
    },
  };
};

export const addSection = (section) => {
  return {
    type: ADD_SECTION,
    payload: section,
  };
};

export const updateSection = (sections) => {
  return {
    type: UPDATE_SECTION,
    payload: sections,
  };
};

export const getStorylineVersionDetails = (storylineId: number) => {
  return async (dispatch) => {
    let data = await fetchStorylineVersionById(storylineId);
    if (data) {
      const storyline = mapStorylineDetails(data);
      const mappedSections = mapStorylineSections(storyline.sections);
      storyline.sections = mappedSections;
      dispatch({ type: GET_STORYLINE_DETAILS, payload: storyline });
    }
  };
};

export const updateIntroductoryContentAction = (introductoryContent) => {
  return {
    type: UPDATE_INTRODUCTORY_CONTENT,
    payload: introductoryContent,
  };
};

export const addCredentialAction = (credential) => {
  return {
    type: ADD_CREDENTIAL,
    payload: credential,
  };
};

export const updateCredentialAction = (credential) => {
  return {
    type: UPDATE_CREDENTIAL,
    payload: credential,
  };
};

export const deleteCredentialAction = (credentialId: number) => {
  return {
    type: DELETE_CREDENTIAL,
    payload: credentialId,
  };
};

export const updateStorylineVersionDetails = (storyline: IStoryline) => ({
  type: GET_STORYLINE_DETAILS,
  payload: mapStorylineDetails(storyline),
});

export const updateSectionStory = (sectionId, story: IStory) => ({
  type: UPDATE_SECTION_STORY,
  payload: { sectionId, story },
});
