import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import PureModal from "src/components/shared/PureModal";
import { PRESENTATION_ROUTE, CONSOLE_ROUTE } from "src/constants/appRoutes";

type ViewPresentationProps = {
  data: any;
  children?: (param) => void;
  toggleSideBar?: () => void;
};

const ViewPresentation: React.FC<ViewPresentationProps> = ({
  data,
  children,
}) => {
  const [presentationModal, setPresentationModal] = useState(false);
  const history = useHistory();
  const { pathname, search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const labGID = urlParams.get("id");
  const togglePresentationModal = () => {
    setPresentationModal(!presentationModal);
  };
  const launchPresentation = () => {
    if (
      pathname.includes(CONSOLE_ROUTE) ||
      pathname.includes(PRESENTATION_ROUTE)
    ) {
      if (data.isActive) {
        history.push(`${PRESENTATION_ROUTE}?id=${data.gid}`);
      } else {
        togglePresentationModal();
      }
    } else {
      closeModal();
    }
  };

  const closeModal = () => {
    // toggleSideBar && toggleSideBar();
    togglePresentationModal();
  };

  return (
    <>
      {children ? (
        children(launchPresentation)
      ) : (
        <button
          className="card-footer-secondary"
          onClick={togglePresentationModal}
        >
          <span className="card-footer-primary-text"> Presentation </span>
        </button>
      )}
      <PureModal
        size="xl"
        centered
        title={data.name || data.lab?.name || "View Presentation"}
        showModal={presentationModal}
        closeModal={closeModal}
        className={`${
          (pathname.includes("/user/console") ||
            pathname.includes("/user/presentation")) &&
          "highest-ZIndex"
        }`}
      >
        <iframe
          title="googleSlides"
          src={
            data.isActive
              ? data.lab.presentation.webViewLink
              : data.presentation.webViewLink
          }
          frameBorder="0"
          width="100%"
          height="550"
          allowFullScreen={true}
        ></iframe>
      </PureModal>
    </>
  );
};

export default ViewPresentation;
