import React, { useState, useEffect } from "react";
import "./Vouchers.scss";
import Table from "src/components/shared/Table";
import {Tabs, Tab, Form} from "react-bootstrap";
import { ReactComponent as AddIcon } from "src/assets/images/add.svg";
import { adminVouchersColumns, userVouchersColumns } from "./voucherColumns";
import { IAdminVoucher } from "src/state/vouchers/reducer";
import { getVouchers } from "src/state/vouchers/actions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/state/rootReducer";
import { Button } from "react-bootstrap";
import SearchBox from "src/components/shared/SearchBox";
import ManageAdminVoucher from "src/components/admin/ManageAdminVoucher";
import {
  revokeAdminVoucher,
  fetchUserVouchersForAdmin,
} from "src/api/vouchers";
import NoSearchResultFound from "src/components/ui/NoSearchResultFound";
import VoucherUsers from "src/components/admin/AdminVoucherUsers";
import { mapVouchers } from "src/helpers/vouchers";
import { pushNotification } from "src/components/ui/Notification";
import {generateToken} from "src/api/token";

type VoucherProps = {};

const Vouchers: React.FC<VoucherProps> = () => {
  const [showManageVoucherModal, setShowManageVoucherModal] = useState(false);
  const [showUsersModal, setShowUsersModal] = useState(false);
  const [searchBy, setSearchBy] = useState("");
  const [selectedVoucher, setSelectedVoucher] = useState({});
  const dispatch = useDispatch();
  const vouchers: Array<IAdminVoucher> = useSelector(
    (state: RootState) => state.vouchers.list
  );
  let [vouchersList, setVouchersList] = useState<IAdminVoucher[]>([]);
  const [userVouchersList, setUserVouchersList] = useState([]);
  const [filteredUserVouchers, setFilteredUserVouchers] = useState(
    userVouchersList
  );
  const loaderCount = useSelector((state: RootState) => state.ui.loaderCount);
  const isLoading = loaderCount > 0;

  const fetchAllVouchers = async () => {
    const data = await fetchUserVouchersForAdmin();
    if (!!data.length) {
      setUserVouchersList(mapVouchers(data));
    }
    await dispatch(getVouchers());
  };

  useEffect(() => {
    fetchAllVouchers();
  }, []);

  useEffect(() => {
    filterVouchers(searchBy);
  }, [vouchers]);

  const filterVoucherTypes = (theseVouchers, searchQuery) => {
    return theseVouchers.filter((voucher) => {
      if (!searchQuery) return true;

      const name = voucher.name && voucher.name.toLowerCase();
      const creator = voucher.createdBy && voucher.createdBy.name.toLowerCase();
      const query = searchQuery && searchQuery.toLowerCase();
      return (
        (name && name.includes(query)) || (creator && creator.includes(query))
      );
    });
  };

  const filterVouchers = (searchQuery) => {
    setVouchersList(filterVoucherTypes(vouchers, searchQuery));
    setFilteredUserVouchers(filterVoucherTypes(userVouchersList, searchQuery));
  };

  const handleSearchByChange = (updatedSearchQuery) => {
    setSearchBy(updatedSearchQuery);
    filterVouchers(updatedSearchQuery);
  };

  const createVoucher = () => {
    setShowManageVoucherModal(true);
  };

  const revokeVoucherHandler = async (voucher) => {
    let response = await revokeAdminVoucher({ is_active: false }, voucher.id);
    if (response && !response.isAxiosError) {
      dispatch(getVouchers());
    }
  };

  const adminVoucherColumns = adminVouchersColumns(revokeVoucherHandler);
  const userVoucherColumns = userVouchersColumns();

  return (
    <div className="vouchers-section d-flex flex-column flex-grow-1">
      <div className="d-flex flex-row justify-content-sm-between mb-2">
        <div className={`vouchers-section-header`}>
          <h5 className="">
            <b>Vouchers</b>
          </h5>
        </div>
        {showUsersModal && (
          <VoucherUsers
            setShowModal={setShowUsersModal}
            item={selectedVoucher}
          />
        )}
        {showManageVoucherModal && (
          <ManageAdminVoucher setShowModal={setShowManageVoucherModal} />
        )}
        <div className="d-flex justify-content-end ">
          <div className="vouchers-section-search d-flex align-items-center">
            <SearchBox
              updateSearchQuery={handleSearchByChange}
              placeholder="Search by voucher name or creator"
            />
          </div>
          <div className={` align-self-end ml-2 d-inline`}>
            <Button
              type="submit"
              size="sm"
              variant="primary"
              className="vouchers-section-add"
              onClick={createVoucher}
            >
              <div className="d-flex align-items-center pl-2 pr-2">
                <AddIcon height="10" width="10" />
                <span className="ml-2"> Create Voucher </span>
              </div>
            </Button>
          </div>
        </div>
      </div>
      <Tabs defaultActiveKey="adminVouchers" id="vouchers" transition={false}>
        <Tab
          eventKey="adminVouchers"
          title={
            <div className="d-flex align-items-center">
              <div className="ml-1"> Admin Vouchers</div>
            </div>
          }
        >
          {!!vouchersList.length ? (
            <Table
              theadStyle={{ backgroundColor: "var(--color-white)" }}
              searchBy={searchBy}
              columns={adminVoucherColumns}
              data={vouchersList}
            />
          ) : !!searchBy ? (
            <NoSearchResultFound
              message="Your search was not successful!"
              detail="Try some relevant name or creator"
              errImgSrc="no-users.svg"
            />
          ) : (
            !isLoading && (
              <NoSearchResultFound
                message="No Admin Vouchers Found!"
                errImgSrc="no-users.svg"
              />
            )
          )}
        </Tab>
        <Tab
          eventKey="userVouchers"
          title={
            <div className="d-flex align-items-center">
              <div className="ml-1  "> User Vouchers</div>
            </div>
          }
        >
          {!!filteredUserVouchers.length ? (
            <Table
              theadStyle={{ backgroundColor: "var(--color-white)" }}
              searchBy={searchBy}
              columns={userVoucherColumns}
              data={filteredUserVouchers}
            />
          ) : !!searchBy ? (
            <NoSearchResultFound
              message="Your search was not successful!"
              detail="Try some relevant name or creator"
              errImgSrc="no-users.svg"
            />
          ) : (
            !isLoading && (
              <NoSearchResultFound
                message="No Vouchers Found!"
                errImgSrc="no-users.svg"
              />
            )
          )}
        </Tab>
      </Tabs>
    </div>
  );
};

export default Vouchers;
