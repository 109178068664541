import Api, { MetaConfig } from "src/helpers/request";
import { toSnakeCaseKeys } from "src/helpers/common";

export const getNotificationsEndpoint = "/notifications";
export const getUserNotificationsEndpoint = "/user/notifications";
export const readAllNotificationsEndpoint = "/user/notifications/_read-all";

export const fetchNotifications = (
  metaConfig: MetaConfig = { showLoader: true }
) =>
  Api.call(
    {
      url: `${getNotificationsEndpoint}?pagination=false`,
      method: "GET",
    },
    metaConfig
  );

export const fetchUserNotifications = (
  metaConfig = { showLoader: true, showErrorNotification: false }
) =>
  Api.call(
    {
      url: `${getUserNotificationsEndpoint}?pagination=false`,
      method: "GET",
    },
    metaConfig
  );

export const readNotificationApi = (
  id,
  metaConfig = { showLoader: false, showErrorNotification: false }
) => {
  return Api.call(
    { url: `${getUserNotificationsEndpoint}/${id}/_read`, method: "put" },
    metaConfig
  );
};

export const readAllNotificationsApi = () => {
  return Api.call(
    { url: readAllNotificationsEndpoint, method: "put" },
    { showLoader: false }
  );
};

export const fetchNotification = (id) => {
  return Api.call(
    { url: `${getNotificationsEndpoint}/${id}`, method: "get" },
    { showLoader: true }
  );
};

export const createNotification = (data) => {
  return Api.call(
    {
      url: `${getNotificationsEndpoint}`,
      method: "post",
      data: toSnakeCaseKeys(data),
    },
    { showLoader: true }
  );
};

export const updateNotification = (
  id,
  data,
  metaConfig = { showLoader: true, showErrorNotification: false }
) => {
  return Api.call(
    {
      url: `${getNotificationsEndpoint}/${id}`,
      method: "patch",
      data: toSnakeCaseKeys(data),
    },
    metaConfig
  );
};

export const removeNotification = (id) => {
  return Api.call(
    { url: `${getNotificationsEndpoint}/${id}`, method: "delete" },
    { showLoader: true }
  );
};
