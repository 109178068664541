import React from "react";
import { formatDate, toCamelCase } from "src/helpers/common";
import { userNotLoggedIn } from "src/constants/users";
import { userStatusMap } from "src/constants/users";
import inactiveIcon from "src/assets/images/inactiveIcon.svg";
import visibleIcon from "src/assets/images/visibleIcon.svg";
import resetPasswordIcon from "src/assets/images/resetPasswordIcon.svg";
import resendEmailIcon from "src/assets/images/resendEmailIcon.svg";
import addIcon from "src/assets/images/add-user.svg";
import editIcon from "src/assets/images/editIcon.svg";
import { reverifyUser, resetPassword, toggleUserStatus } from "src/api/users";
import CustomToolTip from "src/components/ui/CustomTooltip";
import { sortColumn, sortByDate } from "src/utils/common";


const handleToggle = async (event) => {
  const isActive = event.target.dataset.active;
  const id = event.target.dataset.id;
  const res = await toggleUserStatus({ is_active: isActive }, id);
  if (res && res.id) {
    window.location.href = window.location.pathname;
  }
};

const ToggleActivationStatus = ({ isActive, id }) => {
  return isActive ? (
    <CustomToolTip text="Deactivate User">
      <img
        src={inactiveIcon}
        alt="inactiveIcon"
        height="30"
        width="30"
        data-active={false}
        data-id={id}
        onClick={handleToggle}
      />
    </CustomToolTip>
  ) : (
    <CustomToolTip text="Activate User">
      <img
        src={visibleIcon}
        alt="visibleIcon"
        height="20"
        width="18"
        data-active={true}
        data-id={id}
        onClick={handleToggle}
      />
    </CustomToolTip>
  );
};

export const getCorporateUserColumns = (loggedInUser) => [
  {
    Header: "Email",
    accessor: "email",
    percentWidth: 25,
    sortType: (rowA, rowB) => sortColumn(rowA, rowB, "email"),
    Cell: ({ value }) => <span> {value.toLowerCase()} </span>,
  },
  {
    Header: "Name",
    accessor: "name",
    sortType: (rowA, rowB) => sortColumn(rowA, rowB, "name"),
    Cell: ({ value }) => <span> {toCamelCase(value)} </span>,
    percentWidth: 20,
  },
  {
    Header: "Status",
    accessor: "status",
    sortType: (rowA, rowB) => sortColumn(rowA, rowB, "Company"),
    Cell: ({ value }) => {
      return (
        <div
          className={`d-flex justify-content-center status status-${
            value && value.toLowerCase()
          }`}
        >
          {value}
        </div>
      );
    },
    percentWidth: 15,
  },
  {
    Header: "Last Sign In",
    accessor: "lastLogin",
    percentWidth: 15,
    Cell: ({ row, value }) => {
      return <span> {value ? formatDate(value) : userNotLoggedIn} </span>;
    },
  },
  {
    Header: "Labs Launched",
    accessor: "labsLaunched",
    Cell: ({ row, value }) => {
      return <span>{row.original.isInvited ? "-" : value}</span>;
    },
    percentWidth: 15,
  },
  {
    Header: "Action",
    disableSortBy: true,
    accessor: (row) => {
      return (
        <div className="d-flex" title="">
          {row.id !== loggedInUser.id && (
            <div
              className={
                row.status === userStatusMap.active
                  ? "ml-2 cursor-pointer"
                  : "action-icon action-icon-lab-list ml-2 cursor-pointer"
              }
            >
              <ToggleActivationStatus
                isActive={row.status === userStatusMap.active}
                id={row.id}
              />
            </div>
          )}
        </div>
      );
    },
    percentWidth: 10,
  },
];

export const getPartnerUserColumns = (loggedInUser) => [
  {
    Header: "Email",
    accessor: "email",
    percentWidth: 20,
    sortType: (rowA, rowB) => sortColumn(rowA, rowB, "email"),
    Cell: ({ value }) => <span> {value.toLowerCase()} </span>,
  },
  {
    Header: "Name",
    accessor: "name",
    sortType: (rowA, rowB) => sortColumn(rowA, rowB, "name"),
    Cell: ({ value }) => <span> {value} </span>,
    percentWidth: 15,
  },
  {
    Header: "Company",
    sortType: (rowA, rowB) => sortColumn(rowA, rowB, "Company"),
    accessor: (row) => row.company && row.company.name,
    percentWidth: 15,
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: ({ value }) => {
      return (
        <div
          className={`d-flex justify-content-center status status-${
            value && value.toLowerCase()
          }`}
        >
          {value}
        </div>
      );
    },
    percentWidth: 15,
  },
  {
    Header: "Last Sign In",
    accessor: "lastLogin",
    percentWidth: 15,
    Cell: ({ row, value }) => {
      return <span> {value ? formatDate(value) : userNotLoggedIn} </span>;
    },
  },
  {
    Header: "Labs Launched",
    accessor: "labsLaunched",
    Cell: ({ row, value }) => {
      return <span>{row.original.isInvited ? "-" : value}</span>;
    },
    percentWidth: 12,
  },
  {
    Header: "Action",
    disableSortBy: true,
    accessor: (row) => {
      return (
        <div className="d-flex" title="">
          {row.id !== loggedInUser.id && (
            <div
              className={
                row.status === userStatusMap.active
                  ? "ml-2 cursor-pointer"
                  : "action-icon action-icon-lab-list ml-2 cursor-pointer"
              }
            >
              <ToggleActivationStatus
                isActive={row.status === userStatusMap.active}
                id={row.id}
              />
            </div>
          )}
        </div>
      );
    },
    percentWidth: 8,
  },
];

export const getAdhocUsersColumns = (editUser, loggedInUser, prepareTokenGeneration) => [
  {
    Header: "Email",
    accessor: "email",
    percentWidth: 20,
    sortType: (rowA, rowB, colId, desc) =>
      sortColumn(rowA, rowB, "email", desc),
    Cell: ({ value }) => <span> {value.toLowerCase()} </span>,
  },
  {
    Header: "Name",
    accessor: "name",
    sortType: (rowA, rowB, colId, desc) => sortColumn(rowA, rowB, "name", desc),
    Cell: ({ value }) => <span> {value} </span>,
    percentWidth: 10,
  },
  {
    Header: "Company",
    accessor: (row) => row.company && row.company.name,
    sortType: (rowA, rowB, colId, desc) =>
      sortColumn(rowA, rowB, "Company", desc),
    percentWidth: 8,
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: ({ value }) => {
      return (
        <div
          className={`d-flex justify-content-center status status-${
            value && value.toLowerCase()
          }`}
        >
          {value}
        </div>
      );
    },
    percentWidth: 10,
  },
  {
    Header: "Last Sign In",
    accessor: "lastLogin",
    percentWidth: 10,
    sortType: (rowA, rowB, colId, desc) => sortByDate(rowA, rowB, desc),
    Cell: ({ row, value }) => {
      return <span> {value ? formatDate(value) : userNotLoggedIn} </span>;
    },
  },
  {
    Header: "Labs Launched",
    accessor: "labsLaunched",
    percentWidth: 8,
  },
  {
    Header: "Action",
    disableSortBy: true,
    percentWidth: 18,
    accessor: (row) => {
      return (
        <div className="d-flex" title="">
          <div className="cursor-pointer">
            <CustomToolTip text="Edit User">
              <img
                src={editIcon}
                alt="editIcon"
                height="30"
                width="30"
                onClick={() => {
                  editUser(row);
                }}
              />
            </CustomToolTip>
          </div>
          {row.id !== loggedInUser.id && (
            <div
              className={`${
                row.status === userStatusMap.active
                  ? "ml-2"
                  : "action-icon action-icon-lab-list ml-2"
              } cursor-pointer`}
            >
              <ToggleActivationStatus
                isActive={row.status === userStatusMap.active}
                id={row.id}
              />
            </div>
          )}
          {row.id !== loggedInUser.id && (
            <div className="ml-2 cursor-pointer">
              <CustomToolTip text="Reset Password">
                <img
                  height="30"
                  width="30"
                  src={resetPasswordIcon}
                  onClick={async () => await resetPassword(row.email)}
                  alt="resetPasswordIcon"
                />
              </CustomToolTip>
            </div>
          )}
          {row.status === "Invited" && (
            <div className="ml-2 cursor-pointer">
              <CustomToolTip text="Resend Activation Email">
                <img
                  height="30"
                  width="30"
                  src={resendEmailIcon}
                  onClick={async () => await reverifyUser(row.email)}
                  alt="resendEmailIcon"
                />
              </CustomToolTip>
            </div>
          )}
          {row.status !== "Disabled" && (
          <div className="ml-2 cursor-pointer">
            <CustomToolTip text="Generate API Token">
              <img
                  height="30"
                  width="30"
                  src={addIcon}
                  onClick={() => prepareTokenGeneration(row.id)}
                  alt="generate token"
              />
            </CustomToolTip>
          </div>
          )}
        </div>
      );
    },
  },
];
