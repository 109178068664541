import { useEffect } from "react";
import useInterval from "../useInterval";

const usePolling = (
  pollingStartCallback,
  pollingCallback,
  pollingInterval,
  killInterval
) => {
  useInterval(pollingCallback, pollingInterval, killInterval);

  useEffect(() => {
    pollingStartCallback?.();
  }, []);
};

export default usePolling;
