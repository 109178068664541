import React from "react";
import copy from "src/assets/images/copy-code.svg";
import { formatDateToLocal } from "src/helpers/common";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { sortColumn } from "src/utils/common";
import "./Vouchers.scss";
import CustomTooltip from "src/components/ui/CustomTooltip";
import { pushNotification } from "src/components/ui/Notification/index";

const pushVoucherCopyNotification = () =>
  pushNotification({
    type: "info",
    message: "Voucher URL Copied",
  });

export const fetchColumns = () => [
  {
    Header: "Voucher Name",
    accessor: "name",
    percentWidth: 12,
    sortType: (rowA, rowB) => sortColumn(rowA, rowB, "name"),
  },
  {
    Header: "Invited Email",
    accessor: "email",
    percentWidth: 15,
    sortType: (rowA, rowB, colId, desc) =>
      sortColumn(rowA, rowB, "email", desc),
  },
  {
    Header: "Created On",
    accessor: "createdOn",
    percentWidth: 15,
    Cell: ({ value }) => {
      return <span> {formatDateToLocal(value)} </span>;
    },
  },
  {
    Header: "Enabled",
    accessor: "isActive",
    percentWidth: 9,
    Cell: ({ value }) => {
      return <span> {!!value ? "Yes" : "No"} </span>;
    },
  },
  {
    Header: "Usage",
    Cell: ({ row }) => {
      return (
        <span>{row.original.usageCount + "/" + row.original.totalCount}</span>
      );
    },
    percentWidth: 9,
  },
  {
    Header: "Lab",
    accessor: "labAssigned",
    percentWidth: 17,
    sortType: (rowA, rowB) => sortColumn(rowA, rowB, "labAssigned.name"),
    Cell: ({ value }) => {
      return <span> {value.name} </span>;
    },
  },
  {
    Header: "Voucher Code",
    disableSortBy: true,
    accessor: "code",
    Cell: ({ row }) => {
      return (
        <div className="voucher-container" title="">
          <div className="voucher-code overflow-ellipsis">
            {row.original.code}
          </div>
          {row.original.usageCount !== row.original.totalCount && (
            <CustomTooltip text="Copy Voucher URL">
              <CopyToClipboard
                text={`${window.location.origin}/voucher/${row.original.code}`}
                onCopy={pushVoucherCopyNotification}
              >
                <img
                  width="13"
                  src={copy}
                  alt="copy-url"
                  className="cursor-pointer d-flex align-items-center"
                />
              </CopyToClipboard>
            </CustomTooltip>
          )}
        </div>
      );
    },
    percentWidth: 22,
  },
];
