import produce from "immer";

export const GET_LABS_SUCCESS = "GET_LABS_SUCCESS";

export interface ILabs {
  list: Array<ILab>;
}

export interface ILab {
  id: number;
  name: string;
  tags: string[];
  alias: any
}

const initialState: ILabs = {
  list: [],
};

export default (state = initialState, action): ILabs =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_LABS_SUCCESS:
        draft.list = action.payload;
        break;

      default:
        break;
    }
  });
