import { toast } from "react-toastify";
import "./Notification.scss";

const notify = (type, message, timer = 2000) => {
  switch (type) {
    case "info":
      toast.info(message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: timer,
      });
      break;
    case "success":
      toast.success(message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: timer,
        // className: "toast-container-success",
      });
      break;
    case "warning":
      toast.warn(message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: timer,
      });
      break;
    case "error":
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: timer,
      });
      break;
  }
};

export const pushNotification = (params) => {
  return notify(params.type, params.message, params?.timer);
};
