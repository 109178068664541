import React from "react";
import "./PaginatedStories.scss";
import Pagination from "react-bootstrap/Pagination";
import CustomTooltip from "src/components/ui/CustomTooltip";
import { useLocation } from "react-router-dom";

type PaginatedStoriesProps = {
  storyContent: string;
  active: number;
  storyIdx: number;
  setActive: (arg: number) => void;
  decrementStoryIdx: () => void;
  incrementStoryIdx: () => void;
  totalStoriesInSectionsArray: Array<number>;
  pageSet: number;
  setPageSet: (pageSet: number) => void;
  sectionIdx: number;
  setSectionIdx: (number) => void;
};

const PaginatedStories: React.FC<PaginatedStoriesProps> = ({
  storyContent,
  active,
  setActive,
  storyIdx,
  decrementStoryIdx,
  incrementStoryIdx,
  totalStoriesInSectionsArray,
  pageSet,
  setPageSet,
  sectionIdx,
  setSectionIdx,
}) => {
  let items: any = [];
  const totalPages = storyContent.length;
  const pagesPerSet = 3;
  const maxPageSets = Math.ceil(totalPages / pagesPerSet);
  const maxStories = totalStoriesInSectionsArray.reduce(
    (totalStories, stories) => stories + totalStories,
    0
  );
  let currentSectionMaxStories: number = 0;
  if (sectionIdx < totalStoriesInSectionsArray.length) {
    currentSectionMaxStories = totalStoriesInSectionsArray[sectionIdx];
  }
  const { pathname } = useLocation();
  const currentSectionStoryIdx =
    storyIdx -
    totalStoriesInSectionsArray.reduce((acc, stories, index) => {
      if (index < sectionIdx) {
        acc += stories;
      }
      return acc;
    }, 0);

  const preSectionPredicate =
    (sectionIdx < totalStoriesInSectionsArray.length &&
      storyIdx === totalStoriesInSectionsArray[sectionIdx - 1]) ||
    // storyIdx === currentSectionMaxStories ||
    currentSectionStoryIdx === 0;

  const nextSectionPredicate =
    sectionIdx < totalStoriesInSectionsArray.length &&
    // storyIdx === currentSectionMaxStories - 1 &&
    currentSectionStoryIdx === currentSectionMaxStories - 1 &&
    active >= storyContent.length - 1;

  const scrollToStory = () => {
    const storyEl = document.getElementById("story_content");
    if (!!storyEl) {
      // storyEl.scrollIntoView({
      //   behavior: "smooth",
      //   block: "start",
      //   inline: "start",
      // });
      storyEl.scrollTop = 0;
    }
  };

  const decrementItem = () => {
    if (active === 0) {
      setActive(0);
      setPageSet(0);
      decrementStoryIdx();
      if (preSectionPredicate) {
        setSectionIdx(sectionIdx - 1);
        decrementStoryIdx();
      }
    } else {
      const newActivePage = active - 1;
      const newPageSet = pageSet - 1;
      if (newActivePage < pageSet * pagesPerSet) {
        setPageSet(newPageSet);
      }
      setActive(newActivePage);
    }
    scrollToStory();
  };

  const incrementItem = () => {
    if (nextSectionPredicate) {
      setSectionIdx(sectionIdx + 1);
      incrementStoryIdx();
    } else {
      const newActivePage = active + 1;
      const newPageSet = pageSet + 1;

      if (active < storyContent.length - 1 && pageSet < maxPageSets) {
        if (
          newActivePage >= newPageSet * pagesPerSet &&
          pageSet < maxPageSets
        ) {
          setPageSet(newPageSet);
        }
        setActive(newActivePage);
      } else {
        setActive(0);
        setPageSet(0);
        incrementStoryIdx();
      }
    }
    scrollToStory();
  };

  const pagePreviousItem = () => <Pagination.Item
    className={`${
      storyIdx > 0 || (storyIdx === 0 && active > 0)
        ? "cursor-pointer"
        : "disabled-page-button"
    } page-item-left`}
    active={storyIdx > 0 || (storyIdx === 0 && active > 0)}
    onClick={() => {
      if (storyIdx > 0 || (storyIdx === 0 && active > 0)) {
        decrementItem()
      }}
    }
  >
    &lt;
  </Pagination.Item>

  storyIdx > 0 || (storyIdx === 0 && active > 0) ?
  items.push(
    <CustomTooltip
      placement={pathname.includes("console") ? "top" : "bottom"}
      text={active === 0
      ? preSectionPredicate || storyIdx === 0
        ? "Previous Chapter"
        : "Previous Section"
      : "Previous Page"}>
      {pagePreviousItem()}
    </CustomTooltip>
  ) : items.push(pagePreviousItem())

  // let thisPage = 0;
  // if (active > pagesPerSet - 1) {
  //   items.push(
  //     <Pagination.Item
  //       className="cursor-pointer"
  //       onClick={() => {
  //         setActive((pageSet - 1) * pagesPerSet);
  //         setPageSet(pageSet - 1);
  //       }}
  //     >
  //       ...
  //     </Pagination.Item>
  //   );
  // }

  // let maxPageIterations;
  // if (totalPages < pagesPerSet) {
  //   maxPageIterations = totalPages;
  // } else {
  //   if (totalPages - pagesPerSet * pageSet < pagesPerSet) {
  //     maxPageIterations = totalPages - pagesPerSet * pageSet;
  //   } else {
  //     maxPageIterations = pagesPerSet;
  //   }
  // }

    items.push(
      <Pagination.Item className="page-item-middle">
        {`Page ${active + 1} of ${storyContent.length}`}
      </Pagination.Item>
    );

  // if (pageSet < maxPageSets - 1) {
  //   items.push(
  //     <Pagination.Item
  //       className="cursor-pointer"
  //       onClick={() => {
  //         if (pageSet < maxPageSets) {
  //           setActive((pageSet + 1) * pagesPerSet);
  //           setPageSet(pageSet + 1);
  //         }
  //       }}
  //     >
  //       ...
  //     </Pagination.Item>
  //   );
  // }

  const pageNextItem = () => <Pagination.Item
    className={`${
      storyIdx === maxStories - 1 &&
      active === storyContent.length - 1 &&
      "disabled-page-button"
    } cursor-pointer page-item-right`}
    active={
      storyIdx !== maxStories - 1 ||
      (storyIdx === maxStories - 1 && active < storyContent.length - 1)
    }
    onClick={() => {
      if((storyIdx < maxStories - 1 || (storyIdx === maxStories - 1 && active < storyContent.length - 1))) {
        incrementItem()
      }
    }}
  >
    {'>'}
  </Pagination.Item>

  storyIdx !== maxStories - 1 ||
  (storyIdx === maxStories - 1 && active < storyContent.length - 1) ?
  items.push(
    <CustomTooltip
      placement={pathname.includes("console") ? "top" : "bottom"}
      text={
        nextSectionPredicate ||
        (storyIdx === maxStories - 1 && active === storyContent.length - 1)
          ? "Next Chapter"
          : active < storyContent.length - 1
          ? "Next Page"
          : "Next Section"
      }
    >
      {pageNextItem()}
    </CustomTooltip>
  ) : items.push(pageNextItem())

  return (
    <div className="page-story d-flex flex-direction-column justify-content-center">
      {/* <div
        className="story-preview p-1"
        dangerouslySetInnerHTML={{
          __html: storyContent[active],
        }}
      ></div> */}
      <div className="d-flex page-items align-item-end">
        <Pagination size="sm">{items}</Pagination>
      </div>
    </div>
  );
};

export default PaginatedStories;
