import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./LabCard.scss";
import { ReactComponent as InfoBadge } from "src/assets/images/info-icon.svg";
import { ReactComponent as Clock } from "src/assets/images/clock.svg";
import { ReactComponent as ActiveStatus } from "src/assets/images/active-status.svg";
import { ReactComponent as Close } from "src/assets/images/close.svg";
import { deleteUserLabById } from "src/api/lab";
import WarningModal from "src/components/shared/WarningModal";
import LaunchLab from "src/components/Labs/LaunchLab";
import ViewPresentation from "src/components/Labs/Presentation";
import { getLabInitials } from "src/helpers/labs";
import useTimer from "src/hooks/useTimer";

type LabCardProps = {
  activeTab: string;
  data?: any;
  approvalStatus?: string;
  fetchLabs: (subdescription?: string) => void;
};

const LabCard: React.FC<LabCardProps> = (props) => {
  const history = useHistory();
  const [deleteLabWarning, setDeleteLabWarning] = useState<Boolean>(false);
  const [activeTab, setActiveTab] = useState("activeLab");
  const [showInfo, setShowInfo] = useState(false);

  useEffect(() => {
    setActiveTab(props.activeTab);
  }, [props.activeTab]);

  const toggleDeleteLabWarning = () => setDeleteLabWarning(!deleteLabWarning);

  const getTimeRemaining = () => {
    const { hours, minutes, seconds } = timeRemaining;
    return `${hours}:${minutes}:${seconds}`;
  };

  const deleteLab = async () => {
    await deleteUserLabById(props.data.gid);
    toggleDeleteLabWarning();
    window.location.href = history.location.pathname;
  };

  // const deleteLabWithoutAsking = async () => {
  //   await deleteUserLabById(props.data.gid);
  //   window.location.href = history.location.pathname;
  // };

  const timeRemaining = useTimer(
    props.data.shutdownAtTime,
    props.data.isActive && !props.data.shutdownAtTime ? null : 1000,
    props.data.isActive && !props.data.shutdownAtTime ? false : null,
    null
  );

  const getFooter = (activeTab: any) => {
    const isApprovalRequired = props?.data?.environmentConfig?.isApprovalRequired
    const isVisited = props?.data?.environmentConfig?.isVisited
    return props.data.isActive ? (
      <div className="card-footer d-flex justify-content-center">
        <LaunchLab
          approvalStatus={props.approvalStatus}
          lab={props.data}
          launchPresentation={isApprovalRequired && !isVisited}
          btnLabel={isApprovalRequired && !isVisited ? "Launch Lab" : "Connect to Lab"}
        />
        <button className="card-footer-delete" onClick={toggleDeleteLabWarning}>
          <span className="card-footer-btn-text">Delete</span>
        </button>
      </div>
    ) : (
      <div className="card-footer d-flex justify-content-center">
        <LaunchLab
          fetchLabs={props.fetchLabs}
          approvalStatus={props.approvalStatus}
          lab={props.data}
          btnLabel={
            props.data.labProviderType === "VRA" &&
            props.data.labProviderConfig?.isApprovalRequired
              ? "Request Lab"
              : "Launch Lab"
          }
        />
        <ViewPresentation data={props.data} />
      </div>
    );
  };

  const labInitials = getLabInitials(props.data.name || props.data.lab?.name);

  return (
    <div className={!props.data.isActive ? "card card-draft" : "card"}>
      {deleteLabWarning && (
        <WarningModal
          title="Delete Lab"
          confirmBtnLabel={"Yes"}
          cancelBtnLabel={"No"}
          onCancelAction={toggleDeleteLabWarning}
          onConfirmAction={deleteLab}
          closeModal={toggleDeleteLabWarning}
          // className={vmPollingStatus ? "highest-ZIndex" : ""}
        >
          <div>
            <b>
              Are you sure you want to delete{" "}
              {(props.data.customerInfo && props.data.customerInfo.name) ||
                props.data.lab.name}
              {""}?
            </b>
          </div>
        </WarningModal>
      )}
      {!showInfo ? (
        <div>
          <div className="d-flex flex-column card-box">
            {props.data.image || props.data.lab?.image ? (
              <img
                src={props.data.image || props.data.lab?.image}
                alt="editIcon"
                className="card-img"
              />
            ) : (
              <div className="card-img card-lab-initials">
                {" "}
                <span className="overflow-ellipsis">{labInitials}</span>{" "}
              </div>
            )}
            {props.data.about || props.data.lab?.about ? (
              <InfoBadge
                className="icon-info"
                onClick={() => {
                  setShowInfo(true);
                }}
              />
            ) : null}
          </div>
          <div className="card-header-user-labs">
            <span
              className={`card-header-user-labs-timestamp ${
                props.data.isActive && props.data.shutdownAtTime
                  ? "visible"
                  : "invisible"
              }`}
            >
              <Clock className="icon-clock mr-2" />
              <span className="timestamp-text mr-2">Time Remaining</span>
              <span className="timestamp-time">
                {" "}
                {props.data.isActive && getTimeRemaining()}{" "}
              </span>
            </span>
            {props.data.isActive && (
              <ActiveStatus className="icon-active-status" />
            )}
          </div>

          <div className="card-body mb-2 p-1">
            <div className="card-title mt-3 d-flex justify-content-center">
              <div
                className="line-clamp"
                title={
                  props.data.name ||
                  (props.data.customerInfo && props.data.customerInfo.name) ||
                  (props.data.lab && props.data.lab.name)
                }
              >
                {props.data.name ||
                  (props.data.customerInfo && props.data.customerInfo.name) ||
                  (props.data.lab && props.data.lab.name)}{" "}
              </div>
            </div>
            <p
              className="card-description px-3 flex-grow-0 text-center"
              title=""
            >
              {props.data.description || props.data.lab?.description
                ? props.data.description || props.data.lab?.description
                : ""}
            </p>
          </div>
          {getFooter(activeTab)}
        </div>
      ) : (
        <div className="scale-out card-body d-flex flex-column card-back">
          <h5 className="card-title-back">
            About Lab
            <Close
              className="icon-close"
              onClick={() => {
                setShowInfo(false);
              }}
            />
          </h5>
          <div
            className="mt-3"
            dangerouslySetInnerHTML={{
              __html: props.data.about || props.data.lab?.about,
            }}
          />
        </div>
      )}
    </div>
  );
};
export default LabCard;
