import React from "react";
import { useParams } from "react-router-dom";
import { Editor as ReactEditor } from "@tinymce/tinymce-react";
import "./Editor.scss";
import { uploadImg } from "src/api/common";
import { mode } from "src/constants/storylineList";

type EditorProps = {
  updateEditorContent: (content: string) => void;
  height?: number | string;
  initialContent?: string;
};

const Editor: React.FC<EditorProps> = ({
  updateEditorContent,
  height = 300,
  initialContent = "",
}) => {
  const { storylineMode } = useParams();
  const editContent = (content) => {
    updateEditorContent(content);
  };

  const isViewMode = storylineMode === mode.view;

  return (
    <div>
      <ReactEditor
        disabled={isViewMode}
        value={initialContent}
        apiKey="83ch2vvxnbwsdw3xjlomo6a4rfciw75grabm2cq3paqszsev"
        init={{
          browser_spellcheck: true,
          // contextmenu: true,
          menubar: false,
          height: height,
          // file_picker_types: "image",
          image_advtab: true,
          images_upload_url: "postAcceptor.php",
          // image_class_list: [
          //   {
          //     title: "Image Fixup",
          //     value: "editor-img",
          //   },
          // ],
          images_upload_handler: async (blobInfo, success, failure) => {
            const data = await uploadImg(blobInfo.blob());
            if (data && data.url) {
              success(data.url);
            } else {
              failure('');
            }
          },
          automatic_uploads: true,
          pagebreak_separator: "<!-- my page break -->",
          // pagebreak_split_block: true,
          plugins: [
            "advlist pagebreak autolink lists link image charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code help wordcount",
          ],
          toolbar:
            "undo redo | pagebreak | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent image link | removeformat | help ",
        }}
        onEditorChange={editContent}
      />
    </div>
  );
};

export default Editor;
