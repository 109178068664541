import React from "react";
import PureModal from "src/components/shared/PureModal";
import { Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import InputErrorMessage from "src/components/shared/InputErrorMessage";
import { useDispatch } from "react-redux";
import RequiredFieldSymbol from "src/components/ui/RequiredFieldSymbol";
import { isEmpty } from "src/utils/common";
import { requestStoryLineClone } from "src/api/stories";
import { getStorylineList } from "src/state/storylineList/actions";
import { checkDuplicates } from "src/utils/common";
import { IStoryline } from "src/state/storyline/reducer";

type EditStorylineCloneNameProps = {
  setShowModal: (boolean) => void;
  storylineId?: string;
  storylines: Array<IStoryline>;
};
checkDuplicates(Yup);

const EditStorylineCloneName: React.FunctionComponent<EditStorylineCloneNameProps> = ({
  storylineId,
  setShowModal,
  storylines,
  ...props
}) => {
  const validationSchema = Yup.object({
    name: Yup.string()
      .required("Clone name is required")
      .checkDuplicate("Clone Name already exists", "name", storylines, false),
  });
  const dispatch = useDispatch();
  const onSubmitHandle = async (formik, actions) => {
    actions.setSubmitting(true);
    const response = await requestStoryLineClone(storylineId, formik.name);
    actions.setSubmitting(false);
    if (!isEmpty(response) && !response.hasError) {
      setShowModal(false);
      dispatch(getStorylineList());
    }
  };

  return (
    <Formik
      initialValues={{ name: "" }}
      validationSchema={validationSchema}
      onSubmit={onSubmitHandle}
    >
      {(formik) => (
        <PureModal
          title="Enter Clone Guide Name"
          showModal={true}
          closeModal={() => setShowModal(false)}
          primaryBtnLabel="Clone"
          primaryBtnAction={formik.handleSubmit}
          secondaryBtnLabel={"Cancel"}
          secondaryBtnAction={() => setShowModal(false)}
        >
          <Form>
            <Form.Group controlId="forName">
              <Form.Label htmlFor="name">
                Clone Name
                <RequiredFieldSymbol />
              </Form.Label>
              <Form.Control
                type="text"
                id="name"
                className="placeholder-custom-size"
                placeholder="Enter clone name"
                {...formik.getFieldProps("name")}
                isInvalid={!!(formik.touched.name && formik.errors.name)}
              />
              <Form.Control.Feedback type="invalid">
                <InputErrorMessage errorMessage={formik.errors.name || ""} />
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </PureModal>
      )}
    </Formik>
  );
};

export default EditStorylineCloneName;
