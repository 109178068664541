import React, { useMemo } from "react";
import "./InputErrorMessage.scss";

type InputErrorMessageProps = { errorMessage: any; className?: string };

const InputErrorMessage: React.FC<InputErrorMessageProps> = (props) => {
  return (
    <span className={`${props.className} error-message`}>
      {props.errorMessage}
    </span>
  );
};

export default InputErrorMessage;
