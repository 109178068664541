import React from "react";
import edit from "src/assets/images/edit.svg";
import CustomTooltip from "src/components/ui/CustomTooltip";

export const fetchColumns = (editCompany) => [
  {
    Header: "Name",
    accessor: "name",
    percentWidth: 22,
  },
  { Header: "Domain", accessor: "domain", percentWidth: 22 },
  { Header: "Type", accessor: "typeName", percentWidth: 22 },
  {
    Header: "Total Users",
    accessor: "totalUsers",
    percentWidth: 13,
  },
  {
    Header: "Labs Launched",
    accessor: "labsLaunched",
    Cell: ({ row, value }) => {
      return <span>{row.original.isInvited ? "-" : value}</span>;
    },
    percentWidth: 13,
  },
  {
    Header: "Action",
    disableSortBy: true,
    accessor: (row) => {
      return (
        <div className="d-flex" title="">
          <div className="action-icon">
            <CustomTooltip text="Edit Company">
              <img
                src={edit}
                alt="editIcon"
                onClick={() => editCompany(row)}
              />
            </CustomTooltip>
          </div>
        </div>
      );
    },
    percentWidth: 8,
  },
];
