import React from "react";
import { TextInput } from "src/components/ui/FormInputs";
import "./AddCreds.scss";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import SectionLayout from "src/containers/admin/StoryLine/SectionLayout";
import { useDispatch, useSelector } from "react-redux";
import { addCredentialAction } from "src/state/storyline/actions";
import { RootState } from "src/state/rootReducer";

const initialValues = { name: "", username: "", password: "" };
const validationSchema = Yup.object({
  name: Yup.string().required("Credential name is required"),
  username: Yup.string().required("Username is required"),
  password: Yup.string().required("Password is required"),
});

const AddCreds = ({ toggle }) => {
  const dispatch = useDispatch();
  const credentials = useSelector(
    (state: RootState) => state.storyline.credentials
  );

  const addCreds = (creds, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    dispatch(
      addCredentialAction({
        id: credentials.length,
        name: creds.name,
        username: creds.username,
        password: creds.password,
      })
    );
    toggle();
    resetForm();
    setSubmitting(false);
  };

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={addCreds}
    >
      <Form>
        <SectionLayout toggle={toggle} errClassName="d-flex creds-sectionError">
          <TextInput
            name="username"
            placeholder="Username"
            className="creds-field col-sm-5"
            errClassName="creds-error"
          />
          <TextInput
            name="password"
            placeholder="Password"
            className="creds-field col-sm-5"
            errClassName="creds-error"
          />
        </SectionLayout>
      </Form>
    </Formik>
  );
};

export default AddCreds;
