import React from "react";
import { formatDateToLocal } from "src/helpers/common";
import CustomToolTip from "src/components/ui/CustomTooltip";
import deleteIcon from "src/assets/images/delete.svg";
import editIcon from "src/assets/images/edit.svg";

const getVoucherConfigColumns = (deleteAccess, toggleModal) => [
  {
    Header: "Name",
    accessor: "name",
    percentWidth: 25,
  },
  {
    Header: "Email",
    accessor: "email",
    Cell: ({ value }) => {
      return value || "-";
    },
    percentWidth: 25,
  },
  {
    Header: "Type",
    accessor: "type",
    Cell: ({ value }) => {
      return value || "User";
    },
    percentWidth: 10,
  },
  {
    Header: "Expires",
    disableSortBy: true,
    accessor: "expiryDate",
    Cell: ({ value }) => {
      return value ? formatDateToLocal(value) : "-";
    },
    percentWidth: 20,
  },
  {
    Header: "Action",
    disableSortBy: true,
    accessor: "",
    Cell: ({ row }) => {
      return (
        <div className="d-flex" title="">
          <CustomToolTip text="Edit">
            <div className="cursor-pointer">
              <img
                src={editIcon}
                height="15"
                width="15"
                className="cursor-pointer mr-3 mt-1"
                alt="delete"
                data-resource={JSON.stringify(row.original)}
                onClick={toggleModal}
              />
            </div>
          </CustomToolTip>
          <CustomToolTip text="Delete">
            <div className="cursor-pointer">
              <img
                src={deleteIcon}
                height="20"
                className="cursor-pointer"
                width="20"
                alt="delete"
                onClick={() => deleteAccess(row.original.type, row.original.id)}
              />
            </div>
          </CustomToolTip>
        </div>
      );
    },
    percentWidth: 10,
  },
];

export default getVoucherConfigColumns;
