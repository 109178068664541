import React from "react";

const getNextPagesInSet = (nextPage: number, totalPages: number) => {
  let pages: number[] = [];
  for (let i = nextPage; i < nextPage + 3 && i <= totalPages; i++) {
    pages.push(i);
  }
  return pages;
};

const getPrevPagesInSet = (prevPage: number) => {
  let pages: number[] = [];
  for (let i = prevPage; i > prevPage - 3 && i > 0; i--) {
    pages.unshift(i);
  }
  return pages;
};

const usePagination = (totalItems: number, itemsPerPage: number) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const [page, setPage] = React.useState(1);
  const firstPagesInSet = getNextPagesInSet(1, totalPages);
  const lastPagesInSet = getPrevPagesInSet(totalPages);
  const [pagesInSet, setPagesInSet] = React.useState<number[]>([]);

  React.useEffect(() => {
    setPagesInSet(firstPagesInSet);
  }, [firstPagesInSet.length]);

  const canOpenPrevPage = page - 1 > 0;
  const canOpenNextPage = page < totalPages;
  const canOpenPrevPageSet = pagesInSet[0] !== 1;
  const canOpenNextPageSet = pagesInSet[2] ? pagesInSet[2] !== totalPages : false;

  const openFirstPage = () => {
    if (page !== 1) {
      setPage(1);
      if (canOpenPrevPageSet) {
        setPagesInSet(firstPagesInSet);
      }
    }
  };

  const openLastPage = () => {
    if (page !== totalPages) {
      setPage(totalPages);
      if (canOpenNextPageSet) {
        setPagesInSet(lastPagesInSet);
      }
    }
  };

  const openNextPage = () => {
    if (canOpenNextPage) {
      const nextPage = page + 1;
      setPage(nextPage);
      if (page === pagesInSet[2]) {
        setPagesInSet(
          getNextPagesInSet(page + 1, totalPages)
        );
      }
    }
  };

  const openPrevPage = () => {
    if (canOpenPrevPage) {
      setPage(page - 1);
      if (page === pagesInSet[0]) {
        setPagesInSet(getPrevPagesInSet(page - 1));
      }
    }
  };

  const openPrevPageSet = () => {
    if (canOpenPrevPageSet) {
      setPage(page - 3);
      setPagesInSet(getPrevPagesInSet(page - 1));
    }
  };

  const openNextPageSet = () => {
    if (canOpenNextPageSet) {
      setPage(page + 3);
      setPagesInSet(getNextPagesInSet(page + 3, totalPages));
    }
  };

  const goToPage = (page: number) => {
    setPage(page);
  };

  return {
    page,
    setPage,
    pagesInSet,
    setPagesInSet,
    handlers: {
      openFirstPage,
      openLastPage,
      openPrevPage,
      openNextPage,
      openPrevPageSet,
      openNextPageSet,
      goToPage,
    },
    limits: {
      canOpenNextPage,
      canOpenPrevPage,
      canOpenPrevPageSet,
      canOpenNextPageSet,
    },
  };
};

export default usePagination;
