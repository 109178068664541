import Api from "src/helpers/request";

export const attachmentsEndPoint = "/attachment/upload";
export const submitTicketEndPoint = "/user/ticket";
export const adminUsersEndPoint = "/users?pagination=false";
export const storageUploadEndPoint = "/storage/upload";

export const postTicket = (data) => {
  return Api.call(
    { url: `${submitTicketEndPoint}`, method: "POST", data },
    {
      showLoader: true,
      successNotificationMessage: "Ticket submitted successfully",
    }
  );
};

export const uploadAttachments = (data) => {
  return Api.call(
    { url: attachmentsEndPoint, method: "POST", data },
    { showLoader: true }
  );
};

export const getEmails = () =>
  Api.call({ url: adminUsersEndPoint, method: "GET" }, { showLoader: true });

export const uploadFile = (data) =>
  Api.call(
    { url: storageUploadEndPoint, method: "POST", data },
    { showLoader: true }
  );

export const uploadToS3 = (url, data) =>
  Api.call(
    { url, method: "PUT", data },
    { showLoader: true, noAuthhHeader: true }
  );
