import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

type DTPickerProps = {
  selectedDate: string;
  setSelectedDate: (date: string) => void;
  minDate?: Date;
  showTimeSelect?: boolean;
  timeFormat?: string;
  dateFormat?: string;
  className?: string;
  disabled?: boolean;
  timeInterval?: number;
  timeCaption?: string;
  minTime?: any;
  placeholderText?: string
  showTimeInput?: boolean
  timeInputLabel?: string
  filterTime?: any
  customTimeInput?: any
  shouldCloseOnSelect?: boolean
};

const DTPicker: React.FC<DTPickerProps> = ({
  selectedDate,
  setSelectedDate,
  minDate,
  timeFormat,
  dateFormat,
  disabled,
  className,
  timeInterval,
  timeCaption,
  minTime,
 placeholderText = "Select Expiry Date",
  ...rest
}) => {
  return (
    <DatePicker
      selected={selectedDate}
      onChange={(date: string) => setSelectedDate(date)}
      minDate={minDate}
      timeFormat={!!timeFormat ? timeFormat : "HH:mm"}
      timeIntervals={timeInterval ? timeInterval : 15}
      timeCaption={!!timeCaption ? timeCaption : "time"}
      dateFormat={!!dateFormat ? dateFormat : "dd MMM yyyy hh:mm aa"}
      disabled={disabled}
      className={`${className}`}
      placeholderText={placeholderText}
      minTime={minTime ? minTime : new Date().setHours(0, 0)}
      maxTime={new Date().setHours(23, 45)}
      {...rest}
    />
  );
};

export default DTPicker;
