import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Tabs, Tab, Button, Accordion, Card } from "react-bootstrap";
import "./PreviewSection.scss";
import { pageBreak } from "src/constants/storylineList";
import LockIcon from "src/assets/svgrImages/LockIcon";
import EmailIcon from "src/assets/svgrImages/InvitationIcon";
import SortDescIcon from "src/assets/svgrImages/SortDescIcon";
import RightArrowIcon from "src/assets/svgrImages/RightArrowIcon";
import PaginatedStories from "./PaginatedStories";
import { useSelector } from "react-redux";
import { RootState } from "src/state/rootReducer";
import NextStoryEnabledIcon from "src/assets/svgrImages/NextStoryEnabledIcon";
import NextStoryDisabledIcon from "src/assets/svgrImages/NextStoryDisabledIcon";
import PrevStoryEnabledIcon from "src/assets/svgrImages/PrevStoryEnabledIcon";
import PrevStoryDisabledIcon from "src/assets/svgrImages/PrevStoryDisabledIcon";
import TogglePortalIcon from "src/assets/svgrImages/minWidgetIcon";
import MinimiseIcon from "src/assets/svgrImages/MinimiseIcon";
import SearchBox from "src/components/shared/SearchBox";
import SearchStoryLine from "./SearchStoryLine";
import { sortBy } from "lodash";
import Mark from "mark.js";
import { isEmpty } from "src/utils/common";
import PureModal from "src/components/shared/PureModal";
import { css } from "@emotion/css";

type PreviewSectionProps = {
  includeMinimise?: boolean;
  toggleWidgetState?: () => void;
  onTogglePortal?: () => void;
  widgetPortalState?: boolean;
  showStory?: boolean;
  currentLeftTab?: string;
  selectedSection?: any;
  selectedStory?: any;
  storyLine?: any;
  height?: string;
};

const PreviewSection: React.FC<PreviewSectionProps> = ({
  height = "calc(100vh - 290px)",
  includeMinimise = false,
  toggleWidgetState,
  showStory = false,
  currentLeftTab = "index",
  selectedSection,
  selectedStory,
  storyLine,
  ...rest
}) => {
  const { pathname } = useLocation();
  const onConsole = pathname.includes("console");
  const [active, setActive] = useState(0);
  const [pageSet, setPageSet] = useState(0);
  const [sectionIdx, setSectionIdx] = useState(0);
  const [imageElement, setImageElement] = React.useState(false);
  const toggleImageElement = () => setImageElement(!imageElement);
  const imageElementRef = React.useRef({ src: "" });
  const introContentRef = React.useRef(null);
  const storyContentRef = React.useRef(null);
  const storyLineDetails = useSelector((state: RootState) => state.storyline);
  const storyLineData = !isEmpty(storyLine)
    ? storyLine
    : { ...storyLineDetails };
  storyLineData.sections = sortBy(
    storyLineData.sections,
    (section) => section.id
  );

  const totalStoriesInSectionsArray = storyLineData?.sections?.map(
    (section) => section?.stories.length
  );

  const stories: any = [];
  const [storyIdx, setStoryIdx] = useState(0);
  const [tabKey, setTabKey] = useState("index");

  const incrementStoryIdx = () => {
    if (
      storyIdx + 1 < stories.length ||
      (storyIdx + 1 === stories.length - 1 && active < storyContent.length)
    ) {
      setActive(0);
      setPageSet(0);
      storyIdx < stories.length && setStoryIdx(storyIdx + 1);
      const currentSectionStoryIdx =
        storyIdx +
        1 -
        totalStoriesInSectionsArray.reduce((acc, stories, index) => {
          if (index < sectionIdx) {
            acc += stories;
          }
          return acc;
        }, 0);

      if (
        currentSectionStoryIdx === totalStoriesInSectionsArray[sectionIdx] &&
        storyIdx < stories.length
      ) {
        setSectionIdx(sectionIdx + 1);
        setStoryIdx(storyIdx + 1);
      }
    }
  };

  const decrementStoryIdx = () => {
    if (storyIdx > 0 || (storyIdx === 0 && active > 0)) {
      setActive(0);
      setPageSet(0);
      storyIdx > 0 && setStoryIdx(storyIdx - 1);
      const currentSectionStoryIdx =
        storyIdx -
        totalStoriesInSectionsArray.reduce((acc, stories, index) => {
          if (index < sectionIdx) {
            acc += stories;
          }
          return acc;
        }, 0);

      if (currentSectionStoryIdx === 0) {
        setSectionIdx(sectionIdx - 1);
        setStoryIdx(storyIdx - 1);
      }
    }
  };

  const [searchBy, setSearchBy] = useState("");
  const [lastSearchBy, setLastSearchBy] = React.useState("");
  const [matchedStoryContent, setMatchedStoryContent] = useState("");
  const [shouldScrollFlag, setShouldScrollFlag] = useState(false);
  const [scrollElId, setScrollElId] = useState("");

  storyLineData.sections.forEach((section) =>
    sortBy(section.stories, (story) => story.id).forEach((story) =>
      stories.push({
        sectionName: section.name,
        sectionId: section.id,
        ...story,
      })
    )
  );

  const getInitialStoryIndex = (selectedSection, selectedStory) =>
    stories.length
      ? stories.findIndex(
          (story: any) =>
            story?.name === selectedStory?.name &&
            story?.sectionName === selectedSection?.name &&
            story?.id === selectedStory?.id &&
            story?.sectionId === selectedSection?.id
        )
      : -1;

  const initialStoryIdx = getInitialStoryIndex(selectedSection, selectedStory);

  useEffect(() => {
    initialStoryIdx >= 0 && setStoryIdx(initialStoryIdx);
  }, [initialStoryIdx]);

  const handleTabChange = (key: string | null) => {
    if (key) {
      setTabKey(key);
    }
  };

  const currentPreviewTab =
    currentLeftTab === "index"
      ? showStory
        ? "story"
        : "index"
      : "credentials";

  useEffect(() => {
    handleTabChange(currentPreviewTab);
  }, [currentPreviewTab]);

  useEffect(() => {
    if (shouldScrollFlag) {
      scrollToSection(scrollElId);
    }
  }, [shouldScrollFlag]);

  const goToStory = (section, story) => {
    const storyIdx = getInitialStoryIndex(section, story);
    storyIdx !== -1 && setStoryIdx(storyIdx);
    handleTabChange("story");
  };

  const scrollToSection = (id: string) => {
    const sectionEl = document.getElementById(id);
    const targetEl = document.getElementById("introductory_content");
    if (!!sectionEl && targetEl) {
      // sectionEl.scrollIntoView({
      //   // behavior: "smooth",
      //   // block: "start",
      //   behavior: "smooth",
      //   block: "nearest",
      //   inline: "start",
      // });
      // targetEl.scrollTop = sectionEl.offsetTop + 25;
      targetEl.scrollTo({
        top: sectionEl.offsetTop + 10,
        left: 0,
        behavior: "smooth",
      });
    }
    setShouldScrollFlag(false);
  };

  const getCurrentStoryIdx = (sectionIndex) =>
    totalStoriesInSectionsArray.reduce((storyIndex, currentStories, index) => {
      if (index < sectionIndex) {
        storyIndex += currentStories;
      }
      return storyIndex;
    }, 0);

  const getSectionStories = (section, sectionIndex) =>
    section.stories.length
      ? sortBy(section.stories, (story) => story.id).map(
          (story, storyIndex) => (
            <span
              onClick={() => {
                setSectionIdx(sectionIndex);
                setActive(0);
                setStoryIdx(getCurrentStoryIdx(sectionIndex) + storyIndex);
                goToStory(section, story);
              }}
              className="preview-section-label accordian-story-option"
            >
              {story.name}
            </span>
          )
        )
      : "No Sections Available";

  const getSections =
    !!storyLineData.sections.length &&
    storyLineData.sections.map((section, idx) => (
      <Accordion>
        <div className="mt-1 preview-section-dropdown">
          <Card.Header id={`section-header-${idx}`}>
            <div className="d-flex">
              <span className="preview-section-number mr-2">{idx + 1}</span>
              <div className="d-flex flex-column">
                <span
                  onClick={() => {
                    if (section.stories.length) {
                      setSectionIdx(idx);
                      setActive(0);
                      setStoryIdx(getCurrentStoryIdx(idx));
                      goToStory(section, section.stories[0]);
                    }
                  }}
                  className={
                    section.stories.length > 0 ? "accordian-story-option" : ""
                  }
                >
                  {section.name}
                </span>
                <label className="preview-section-label">
                  {section.stories.length}{" "}
                  {section.stories.length === 1 ? "Section" : "Sections"}
                </label>
              </div>
              <Accordion.Toggle
                as={Button}
                variant="link"
                eventKey="0"
                className="p-0 ml-auto accordion-toggle"
                onClick={() => {
                  // if (
                  //   !pathname.includes("console") &&
                  //   !pathname.includes("presentation")
                  // ) {
                  const id = "section-header-" + idx;
                  setScrollElId(id);
                  setShouldScrollFlag(true);
                  // }
                }}
                id={idx}
              >
                {" "}
                <SortDescIcon
                  height="18"
                  width="18"
                  className="preview-section-label-icon"
                />
                {/*<img*/}
                {/*  src={sortDesc}*/}
                {/*  className="preview-section-label-icon"*/}
                {/*  alt="sortDesc"*/}
                {/*  height="18"*/}
                {/*  width="18"*/}
                {/*/>*/}
              </Accordion.Toggle>
            </div>
          </Card.Header>
          <Accordion.Collapse
            eventKey="0"
            className="preview-section-dropdown-body"
          >
            <Card.Body className="d-flex flex-column">
              {getSectionStories(section, idx)}
            </Card.Body>
          </Accordion.Collapse>
        </div>
      </Accordion>
    ));

  const getCredentials = storyLineData?.credentials?.length ? (
    storyLineData.credentials.map((cred) => (
      <div className="d-flex flex-column mb-4 mr-3">
        <div className="intro-banner mx-2 mt-3 d-flex overflow-ellipsis">
          {cred.name}
        </div>
        {cred.secretKey ? (
          <div className="mx-2 mt-2">
            <div className="section-field_input">
              <EmailIcon height="12" width="12" />
              {/*<img src={emailIcon} alt="editIcon" height="12" width="12" />*/}
              <span className="ml-1">Secret Key</span>
            </div>
            <span className="mr-1"> : </span>
            <span className="section-field-btn_val">{cred.secretKey}</span>
          </div>
        ) : (
          <>
            <div className="mx-2 mt-2">
              <div className="section-field_input">
                <EmailIcon height="12" width="12" />
                {/*<img src={emailIcon} alt="editIcon" height="12" width="12" />*/}
                <span className="ml-1">Username</span>
              </div>
              <span className="mr-1"> : </span>
              <span className="section-field-btn_val">{cred.username}</span>
            </div>
            <div className="mx-2 mt-2">
              <div className="section-field_input">
                <LockIcon height="12" width="12" />
                {/*<img src={lockIcon} alt="editIcon" height="12" width="12" />*/}
                <span className="ml-1"> Password</span>
              </div>
              <span className="mr-1"> : </span>
              <span className="section-field-btn_val">{cred.password}</span>
            </div>
          </>
        )}
        {/* <Button
          type="button"
          size="sm"
          variant="primary"
          className="storyline-section-add mx-2 mt-2"
          onClick={() => {}}
        >
          <div className="d-flex align-items-center pl-2 pr-2">
            <span>Send To Console</span>
          </div>
        </Button> */}
      </div>
    ))
  ) : (
    <div className="no-creds">No Credentials Available</div>
  );

  const storyContent =
    storyIdx > -1 && storyIdx < stories.length
      ? stories[storyIdx]?.content.split(pageBreak)
      : ["No Content Available"];

  const handleSearch = (searchBy: string) => {
    setSearchBy(searchBy);
    setLastSearchBy(searchBy);
  };

  // const format = () => {
  // 			const nodesList: any = document
  // 				.getElementById("intro-content")
  // 				?.querySelectorAll("*");

  // 			console.log(nodesList);

  // 			if (nodesList && !!lastSearchBy) {
  // 				[].forEach.call(nodesList, function (node: any) {
  // 					if (!node?.children?.length) {
  // 						const nodeVal =
  // 							node.innerHTML || node.innerHTML === ""
  // 								? node.innerHTML
  // 								: node.data;

  // 						const isMatch = nodeVal
  // 							.toLowerCase()
  // 							.includes(lastSearchBy.toLowerCase());
  // 						if (isMatch) {
  // 							node.classList.add("highlight");
  // 						}
  // 					} else {

  // 					}
  // 					[].forEach.call(node.childNodes, function (childNode: any) {
  // 						// console.log(childNode, childNode.length, childNode.nodeName)
  // 						if (childNode.textContent) {
  // 							console.dir(childNode)
  // 							console.log(childNode, childNode.textContent.toLowerCase().includes(lastSearchBy.toLowerCase()),
  // 								childNode.wholeText.toLowerCase().includes(lastSearchBy.toLowerCase(),
  // 									childNode.nodeValue.toLowerCase().includes(lastSearchBy.toLowerCase())))
  // 						}
  // 						// if (childNode.textContent.toLowerCase().includes(lastSearchBy.toLowerCase())) {
  // 						// 	console.log(childNode.hasOwnProperty('data'));
  // 						// 	// console.log(node, node.nodeName, node.nodeType);
  // 						// 	node.classList.add("highlight");
  // 						// }
  // 					});
  // 				});
  // 			}
  // 		};

  // This is ugly workaround which highlights dom elements using mark.js by going outside react context
  setTimeout(() => {
    const element =
      tabKey === "index" ? introContentRef.current : storyContentRef.current;
    const markInstance = new Mark(element);
    // if (!lastSearchBy) {
    //   markInstance.unmark();
    // }
    markInstance.unmark();
    markInstance.mark(lastSearchBy, {
      element: "span",
      className: "highlight-content",
      acrossElements: true,
      separateWordSearch: false,
      ignoreJoiners: true,
      wildcards: true,
      // ignorePunctuation: [":;.,-–—‒_(){}[]!'\"+=".split("")],
      // accuracy: "exactly",
    });
  }, 100);

  const processSearchResult = (section, data) => {
    if (!section) {
      handleTabChange("index");
    } else {
      goToStory(section, data);
    }
    setSearchBy("");
  };

  React.useEffect(() => {
    const element =
      tabKey === "index" ? introContentRef.current : storyContentRef.current;
    // @ts-ignore
    const images = element?.getElementsByTagName("img");
    const onImageClick = (imageElement) => {
      imageElementRef.current = imageElement;
      setImageElement(true);
    };
    if (images) {
      for (const image of images) {
        image.addEventListener("click", () => onImageClick(image));
      }
    }
    return () => {
      if (images) {
        for (const image of images) {
          image.removeEventListener("click", () => onImageClick(image));
        }
      }
    };
  }, [tabKey, active, storyIdx]);

  return (
    <div className="view" style={{ height: height }}>
      <PureModal
        title=""
        // primaryBtnLabel="Close"
        showModal={imageElement}
        // primaryBtnAction={toggleImageElement}
        closeModal={toggleImageElement}
        className={
          "highest-ZIndex preview-section-modal " +
          css`
            .pure-modal-footer {
              display: none;
            }
            .modal-body {
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .modal-title {
              min-width: 150px;
            }
            .modal-dialog {
              min-width: unset;
              max-width: calc(
                ${imageElementRef.current["naturalWidth"]}px + 200px
              );
            }
            .modal-content {
              // min-width: calc(
              //   ${imageElementRef.current["naturalWidth"]}px + 200px
              // );
              // min-height: ${imageElementRef.current["naturalHeight"]}px;
              max-width: 90vw;
              max-height: 100vh;
            }
          `
        }
      >
        <div className="time-modal">
          <img
            src={imageElementRef.current.src}
            alt="image clicked"
            className="preview-section-image"
          />
        </div>
      </PureModal>
      <div className="h-100 d-flex flex-column">
        <div className="w-100 view-header d-flex flex-row-reverse">
          {includeMinimise && !rest.widgetPortalState && (
            <MinimiseIcon
              className="cursor-pointer preview-section-minimise-icon"
              onClick={toggleWidgetState}
            />
          )}
          {!!rest.onTogglePortal && (
            <TogglePortalIcon
              className="cursor-pointer mr-3"
              onClick={rest.onTogglePortal}
            />
          )}
        </div>
        <div className="w-100">
          <div className="d-flex align-items-center">
            <SearchBox
              searchBy={searchBy}
              updateSearchQuery={handleSearch}
              placeholder={"Search with text"}
            />
          </div>
          {tabKey.toLocaleLowerCase() === "story" && (
            <div className="text-wrap my-2 mt-2 px-2 story-title">
              <span
                role="button"
                onClick={() => setTabKey("index")}
                className="view-breadcrumb-text cursor-pointer"
              >
                Index
              </span>
              {!!stories.length && (
                <>
                  <RightArrowIcon
                    height="10"
                    width="10"
                    className="ml-1 mr-1"
                  />
                  {/*<img*/}
                  {/*  height="10"*/}
                  {/*  width="10"*/}
                  {/*  src={rightArrow}*/}
                  {/*  alt="InfoIcon"*/}
                  {/*  className="ml-1 mr-1"*/}
                  {/*/>*/}
                  <span
                    title={stories[storyIdx]?.sectionName}
                    onClick={() => {
                      const sectionIdx = storyLineData.sections.findIndex(
                        (section) => section.id === stories[storyIdx]?.sectionId
                      );
                      const eleId = "section-header-" + sectionIdx;
                      setTabKey("index");
                      // if (
                      //   !pathname.includes("console") &&
                      //   !pathname.includes("presentation")
                      // ) {
                      setScrollElId(eleId);
                      setShouldScrollFlag(true);
                      // }
                    }}
                    className="view-breadcrumb-text cursor-pointer overflow-ellipsis story-middle-span"
                  >
                    {stories[storyIdx]?.sectionName}
                  </span>
                  <RightArrowIcon
                    height="10"
                    width="10"
                    className="story-arrow-icon mr-1"
                  />
                  {/*<img*/}
                  {/*  height="10"*/}
                  {/*  width="10"*/}
                  {/*  src={rightArrow}*/}
                  {/*  alt="InfoIcon"*/}
                  {/*  className="ml-1 mr-1"*/}
                  {/*/>*/}
                  <span
                    className="secondary-text-color overflow-ellipsis"
                    title={stories[storyIdx]?.name}
                  >
                    {stories[storyIdx]?.name}
                  </span>
                </>
              )}
            </div>
          )}
        </div>
        <div
          className={`overflow-hidden view-tabs-container ${
            rest.widgetPortalState
              ? tabKey === "index" || tabKey === "credentials"
                ? "tab-portal-index"
                : "tab-portal-story"
              : onConsole
              ? tabKey === "index" || tabKey === "credentials"
                ? "tab-console-index"
                : "tab-console-story"
              : tabKey === "index" || tabKey === "credentials"
              ? "tab-guide-index"
              : "tab-guide-story"
          }`}
        >
          {!searchBy ? (
            <Tabs
              activeKey={tabKey}
              onSelect={handleTabChange}
              transition={false}
              className="view-tabs"
              id="view"
            >
              <Tab eventKey="index" title={<div>Index</div>}>
                <div
                  className={`view-tabs-content ${
                    onConsole ? "page-console" : "page-storyline"
                  }`}
                >
                  <div className="d-flex justify-content-center mx-2 my-3">
                    <div className="intro-banner overflow-header mt-3 d-flex align-items-center justify-content-center">
                      {storyLineData?.name}
                    </div>
                  </div>
                  <div
                    id="introductory_content"
                    className="view-tabs-content-main"
                    style={{
                      height: `calc(${height} - 2rem)`,
                    }}
                  >
                    <div
                      ref={introContentRef}
                      id="intro-content"
                      className="tab-index intro-preview mx-3"
                      dangerouslySetInnerHTML={{
                        __html: storyLineData?.introductoryContent,
                      }}
                    />
                    {getSections}
                  </div>
                </div>
              </Tab>
              <Tab eventKey="story" title={<div>Guide</div>}>
                {!!stories.length && (
                  <div className="story-tabs">
                    <div
                      className={`view-tabs-content mx-2 ${
                        onConsole ? "page-console" : "page-storyline"
                      }`}
                    >
                      <div className="d-flex justify-content-center">
                        <div className="story-header overflow-header mt-3 d-flex align-items-center justify-content-between">
                          {storyIdx > 0 ? (
                            <PrevStoryEnabledIcon
                              onClick={decrementStoryIdx}
                              className="cursor-pointer"
                            />
                          ) : (
                            <PrevStoryDisabledIcon
                              onClick={decrementStoryIdx}
                              className="cursor-pointer"
                            />
                          )}
                          {/*<img*/}
                          {/*  src={*/}
                          {/*    storyIdx > 0*/}
                          {/*      ? previousStoryEnabledIcon*/}
                          {/*      : previousStoryDisabledIcon*/}
                          {/*  }*/}
                          {/*  alt="Previous Story"*/}
                          {/*  onClick={decrementStoryIdx}*/}
                          {/*  className="cursor-pointer"*/}
                          {/*/>*/}
                          {!!stories[storyIdx]?.name && (
                            <b title={stories[storyIdx]?.name.toUpperCase()}>
                              {stories[storyIdx]?.name.toUpperCase()}
                            </b>
                          )}
                          {storyIdx < stories.length - 1 ? (
                            <NextStoryEnabledIcon
                              onClick={incrementStoryIdx}
                              className="cursor-pointer"
                            />
                          ) : (
                            <NextStoryDisabledIcon
                              onClick={incrementStoryIdx}
                              className="cursor-pointer"
                            />
                          )}
                          {/*<img*/}
                          {/*  src={*/}
                          {/*    storyIdx < stories.length - 1*/}
                          {/*      ? nextStoryEnabledIcon*/}
                          {/*      : nextStoryDisabledIcon*/}
                          {/*  }*/}
                          {/*  alt="Next Story"*/}
                          {/*  onClick={incrementStoryIdx}*/}
                          {/*  className="cursor-pointer"*/}
                          {/*/>*/}
                        </div>
                      </div>
                      <div
                        id="story_content"
                        className={`view-tabs-content-main ${
                          rest.widgetPortalState
                            ? "story-content"
                            : css`
                                @include media(">=1368px") {
                                  height: calc(100vh - 370px);
                                }
                                @include media("<1368px", ">=1145px") {
                                  height: calc(100vh - 390px);
                                }
                                @include media("<1145px") {
                                  height: calc(100vh - 420px);
                                }
                              `
                        }`}
                      >
                        <div
                          ref={storyContentRef}
                          id="story-content"
                          className="story-preview mx-3 mt-3"
                          dangerouslySetInnerHTML={{
                            __html: matchedStoryContent
                              ? matchedStoryContent
                              : storyContent[active],
                          }}
                        />
                      </div>
                      <div className="story-pagination pt-2">
                        <PaginatedStories
                          storyContent={storyContent}
                          storyIdx={storyIdx}
                          active={active}
                          setActive={setActive}
                          decrementStoryIdx={decrementStoryIdx}
                          incrementStoryIdx={incrementStoryIdx}
                          totalStoriesInSectionsArray={
                            totalStoriesInSectionsArray
                          }
                          pageSet={pageSet}
                          setPageSet={setPageSet}
                          sectionIdx={sectionIdx}
                          setSectionIdx={setSectionIdx}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </Tab>
              <Tab
                className={`view-tabs-content view-tabs-content-main ${
                  onConsole ? "page-console" : "page-storyline"
                }`}
                eventKey="credentials"
                title={<div>Credentials</div>}
              >
                <div className="">
                  <div
                  // style={{
                  //   height: `calc(${height} - 2rem)`,
                  // }}
                  >
                    {getCredentials}
                  </div>
                </div>
              </Tab>
            </Tabs>
          ) : (
            <div className="tab-index view-tabs-content mx-3 mt-3 search-storyline">
              <SearchStoryLine
                data={{
                  content: storyLineData.introductoryContent,
                  name: "Introductory Content",
                }}
                searchBy={searchBy}
                processSearchResult={processSearchResult}
              />
              {storyLineData.sections.map((section) =>
                section.stories.map((story) => (
                  <SearchStoryLine
                    section={section}
                    data={story}
                    searchBy={searchBy}
                    processSearchResult={processSearchResult}
                  />
                ))
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PreviewSection;
