import React, { useState, useEffect } from "react";
import "./Companies.scss";
import Table from "src/components/shared/Table";
import { ReactComponent as AddIcon } from "src/assets/images/add.svg";
import { fetchColumns } from "./companyColumns";
import { ICompany } from "src/state/companies/reducer";
import { getCompanies } from "src/state/companies/actions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/state/rootReducer";
import { Button } from "react-bootstrap";
import SearchBox from "src/components/shared/SearchBox";
import ManageCompany from "src/components/ManageCompany";
import NoSearchResultFound from "src/components/ui/NoSearchResultFound";

type UsersProps = {};

const Users: React.FC<UsersProps> = () => {
  const [showManageCompanyModal, setShowManageCompanyModal] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const initialCompany = {
    name: "",
    domain: "",
    type: "",
  };
  const [selectedCompany, setSelectedCompany] = useState(initialCompany);
  const [selectCompanyId, setSelectedCompanyId] = useState(0);
  const [searchBy, setSearchBy] = useState("");
  let [companyList, setCompanyList] = useState<ICompany[]>([]);
  const dispatch = useDispatch();
  const companies: Array<ICompany> = useSelector(
    (state: RootState) => state.companies.list
  );
  const loaderCount = useSelector((state: RootState) => state.ui.loaderCount);
  const isLoading = loaderCount > 0;

  useEffect(() => {
    dispatch(getCompanies());
  }, []);

  useEffect(() => {
    filterCompanies(searchBy);
  }, [companies]);

  const handleSearchByChange = (updatedSearchQuery) => {
    setSearchBy(updatedSearchQuery);
    filterCompanies(updatedSearchQuery);
  };

  const filterCompanies = (searchQuery) => {
    let filteredCompanies = companies.filter((company) => {
      if (!searchQuery) return true;
      const name = company.name && company.name.toLowerCase();
      const query = searchQuery && searchQuery.toLowerCase();
      return name && name.includes(query);
    });
    setCompanyList(filteredCompanies);
  };
  const addCompany = () => {
    setEditMode(false);
    setShowManageCompanyModal(true);
  };

  const editCompany = (company) => {
    const { name, domain, typeName, id } = company;
    const companyToEdit = { name, domain, type: typeName };
    setSelectedCompanyId(id);
    setSelectedCompany(companyToEdit);
    setEditMode(true);
    setShowManageCompanyModal(true);
  };
  const companyColumns = fetchColumns(editCompany);

  return (
    <div className="companies-section d-flex flex-column flex-grow-1">
      <div className="d-flex flex-row justify-content-sm-between">
        <div className={`companies-section-header`}>
          <h5 className="">
            <b>Companies</b>
          </h5>
        </div>
        {showManageCompanyModal && (
          <ManageCompany
            setShowModal={setShowManageCompanyModal}
            isEditMode={isEditMode}
            selectedCompany={selectedCompany}
            selectCompanyId={selectCompanyId}
          />
        )}
        <div className="d-flex justify-content-end ">
          <div className="companies-section-search d-flex align-items-baseline">
            <SearchBox
              updateSearchQuery={handleSearchByChange}
              placeholder="Search by company name"
            />
          </div>
          {companyList.length ? (
            <div
              className={`companies-section-add align-self-end ml-2 d-inline`}
            >
              <Button
                type="submit"
                size="sm"
                variant="primary"
                onClick={addCompany}
              >
                <div className="d-flex align-items-center pl-2 pr-2">
                  <AddIcon height="10" width="10" />
                  <span className="ml-2"> Add Company </span>
                </div>
              </Button>
            </div>
          ) : null}
        </div>
      </div>
      {companyList.length ? (
        <Table
          searchBy={searchBy}
          columns={companyColumns}
          data={companyList}
        />
      ) : !!searchBy ? (
        <NoSearchResultFound
          message="Your search was not successful!"
          detail="Try some relevant company name"
          errImgSrc="no-users.svg"
        />
      ) : (
        !isLoading && (
          <>
            <NoSearchResultFound
              message="No Companies Added!"
              detail="Click below to add a new company"
              errImgSrc="no-companies.svg"
            />
            <Button
              type="submit"
              size="sm"
              variant="primary"
              onClick={addCompany}
              className="no-result-add-btn"
            >
              <div className="d-flex align-items-center pl-2 pr-2">
                <AddIcon height="10" width="10" />
                <span className="ml-2"> Add Company </span>
              </div>
            </Button>
          </>
        )
      )}
    </div>
  );
};

export default Users;
