import React from "react";
import deleteIcon from "src/assets/images/cross.svg";
import { formatDate } from "src/helpers/common";
import { userNotLoggedIn } from "src/constants/users";
import CustomTooltip from "src/components/ui/CustomTooltip/CustomTooltip";

export const getGroupUserColumns = (onDeleteUser) => [
  {
    Header: "Email",
    accessor: "email",
    percentWidth: 20,
  },

  {
    Header: "Name",
    accessor: "name",
    percentWidth: 16,
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: ({ value }) => {
      return (
        <div
          className={`d-flex justify-content-center status status-${
            value && value.toLowerCase()
          }`}
        >
          {value}
        </div>
      );
    },
    percentWidth: 13,
  },
  {
    Header: "Type",
    accessor: "type",
    percentWidth: 13,
  },
  {
    Header: "Last Sign In",
    accessor: "lastLogin",
    percentWidth: 17,
    Cell: ({ value }) => {
      return <span> {value ? formatDate(value) : userNotLoggedIn} </span>;
    },
  },
  {
    Header: "Labs Launched",
    accessor: "labsLaunched",
    percentWidth: 15,
  },
  {
    Header: "Action",
    disableSortBy: true,
    accessor: (row) => {
      return (
        <div className="d-flex">
          {!row.isSystem && (
            <div className="ml-2 action-icon" title="">
              <CustomTooltip text="Remove User">
                <img
                  onClick={() => onDeleteUser(row)}
                  src={deleteIcon}
                  alt="deleteIcon"
                />
              </CustomTooltip>
            </div>
          )}
        </div>
      );
    },
    percentWidth: 8,
  },
];
