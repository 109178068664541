import {
  toCamelCaseKeys,
  formatDateToLocal,
  toSnakeCaseKeys,
  formatDateByDefault,
} from "../common";
import { IStoryline } from "src/state/storyline/reducer";

export const mapStorylineDetails = (storyline) => {
  const returnObj = toCamelCaseKeys(storyline);
  storyline.created_on &&
    (returnObj.createdOn = formatDateToLocal(storyline.created_on));
  storyline.updated_on &&
    (returnObj.updatedOn = formatDateToLocal(storyline.updated_on));
  return returnObj;
};

export const mapStorylineSections = (sections = []) =>
  sections && sections.map(toCamelCaseKeys).map((section) => section);

export const mapStoryOptions = (stories = []) =>
  stories && stories.map(toCamelCaseKeys).map((story) => story);

export const getNewId = (list) => {
  if (list.length > 0) {
    const lastItem = list.reduce((prev, current) => {
      return prev.id > current.id ? prev : current;
    });
    return lastItem.id + 1;
  } else return 1;
};

export const getPayload = (storyLine: IStoryline, comment: string = "") => {
  let payload = toSnakeCaseKeys(storyLine);
  comment && (payload.comment = comment);
  payload.created_on = formatDateByDefault(payload.created_on);
  payload.updated_on = formatDateByDefault(payload.updated_on);
  return payload;
};

export const mapStorylines = (storylines = [] as any) =>
  storylines.map(toCamelCaseKeys).map((storyline: any) => {
    let storylineList = {} as any;
    storylineList.name = storyline.name;
    storylineList.id = storyline.id;
    storylineList.editors = storyline.editors;
    storylineList.subRows =
      (storyline.versions &&
        storyline.versions.length &&
        storyline.versions.map((v) => {
          let vCopy = { ...v };
          vCopy["isParentDraftPresent"] = storyline.hasDraftVersion;
          return vCopy;
        })) ||
      [];

    return storylineList;
  });

export const getUpdatedVersionName = (newName: string, version: string) => {
  const versionArr = version.split(" ");
  const versionId = versionArr.length && versionArr[versionArr.length - 1];
  return newName + " " + versionId;
};

export const storyLineHistoryMapper = ({ history, name, assignedLabs }) => {
  let historyList = history.map((version, index) => {
    let stories = version.sections.reduce((acc, section) => {
      let stories = section.stories.map((story) => {
        story["sectionStoryId"] = `${section.id}-${story.id}`;
        return story;
      });
      return [...acc, ...(stories || [])];
    }, []);

    let mappedVersion = {
      stories,
      updatedOn: version.updatedOn,
      updatedBy: version.updatedBy,
      version: `${version.version}`,
      comment: version.comment ? version.comment : "",
      id: index,
    };
    return mappedVersion;
  });

  return [
    {
      subRows: historyList,
      name,
      assignedLabs,
    },
  ];
};

export const reorderList = (source, target, list) => {
  let sourceIndex = source.index;
  let targetIndex = target.index;
  if (sourceIndex > targetIndex) {
    const targetIndexId = list[targetIndex].id;
    let i = targetIndex;
    while (i < sourceIndex) {
      list[i].id = list[i + 1].id;
      i++;
    }
    list[sourceIndex].id = targetIndexId;
  } else {
    const targetIndexId = list[targetIndex].id;
    let i = targetIndex;
    while (i > sourceIndex) {
      list[i].id = list[i - 1].id;
      i--;
    }
    list[sourceIndex].id = targetIndexId;
  }
  return list;
};
