import React from "react";
import Button from "react-bootstrap/Button";
import { useHistory } from "react-router-dom";
import authenticationImg from "src/assets/images/authentication.svg";
import "./OktaUnauthorizedUser.scss";

type OktaUnauthorizedUserProps = {
  message?: "string";
  btnLabel?: "string";
};

const OktaUnauthorizedUser: React.FC<OktaUnauthorizedUserProps> = ({
  message = "",
  btnLabel = "Back to Login",
}) => {
  const history = useHistory();

  const goBack = () => {
    window.location.href = "/";
  };

  return (
    <div className="okta-unauthorized-user-container d-flex flex-column justify-content-center">
      <img
        height="400"
        className="mb-4"
        src={authenticationImg}
        alt="Page Not Found"
      />
      <h4 style={{ width: "40rem" }}>
        {" "}
        It look like there's some problem with Okta. Please reach out to
        customer support at{" "}
        <a href="mailto:testdrive@purestorage.com">testdrive@purestorage.com</a>
      </h4>
      <Button onClick={goBack}> {btnLabel} </Button>
    </div>
  );
};

export default OktaUnauthorizedUser;
