import React, { useState, useEffect } from "react";
import "./IndexSection.scss";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  storyContentUpdateAction,
  addSection,
  updateIntroductoryContentAction,
  updateSection,
} from "src/state/storyline/actions";
import { fetchStoryOptions } from "src/api/stories";
import {
  mapStoryOptions,
  getPayload,
  getNewId,
  reorderList,
} from "src/helpers/stories";
import Editor from "../Editor";
import Section from "../StoryFormLayout";
import StoryPage from "../../StoryPage";
import { ISection, IStoryline } from "src/state/storyline/reducer";
import { Droppable, Draggable, DragDropContext } from "react-beautiful-dnd";
import styled from "styled-components";
import { mode } from "src/constants/storylineList";
import openToc from "src/assets/images/open-toc.svg";

const ItemList = styled.div``;

const Item = styled.div``;

type IndexSectionProps = {
  storyLine: IStoryline;
  sections: Array<ISection>;
  showStory: boolean;
  setShowStory: (flag: boolean) => void;
  setSelectedStoryId: (storyId) => void;
  setSelectedStorySectionId: (sectionId) => void;
  selectedStory: any;
  selectedStorySectionId?: number;
  selectedStoryId?: number;
};

const IndexSection: React.FC<IndexSectionProps> = ({
  storyLine,
  sections,
  showStory,
  setShowStory,
  selectedStory,
  setSelectedStoryId,
  setSelectedStorySectionId,
  selectedStorySectionId,
  selectedStoryId,
}) => {
  const { storylineMode } = useParams();
  const dispatch = useDispatch();
  const [storyOtions, setStoryOptions] = useState([] as any);

  const getStoryOptions = async () => {
    const response = await fetchStoryOptions();
    const mappedStories = mapStoryOptions(response);
    setStoryOptions(mappedStories);
  };

  useEffect(() => {
    getStoryOptions();
  }, []);

  const addStorySection = async () => {
    const newSection = {
      id: getNewId(sections),
      name: "New Chapter",
      stories: [],
    };

    const payload = getPayload(storyLine);
    payload.sections = [...sections, newSection];
    dispatch(addSection(newSection));
  };

  const updateStorySection = async (section) => {
    const selectedSectionIndex = sections.findIndex(
      (val) => section.id === val.id
    );
    let sectionsArr = [...sections];
    sectionsArr.splice(selectedSectionIndex, 1, section);
    const payload = getPayload(storyLine);
    payload.sections = sectionsArr;
    dispatch(updateSection(sectionsArr));
  };

  const removeStorySection = (section) => {
    let sectionsArr = [...sections];
    const selectedSectionIndex = sections.findIndex(
      (val) => section.id === val.id
    );
    sectionsArr.splice(selectedSectionIndex, 1);
    setSelectedStorySectionId(0);
    dispatch(updateSection(sectionsArr));
  };

  const updateIntroductoryContent = (content) => {
    dispatch(updateIntroductoryContentAction(content));
  };

  const updateStoryContent = (content) => {
    dispatch(
      storyContentUpdateAction(selectedStorySectionId, selectedStoryId, content)
    );
  };

  const toggleShowStoryPage = (flag) => {
    setShowStory(flag);
  };

  const onDragEnd = (result) => {
    const { source, destination, draggableId } = result;

    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    let sectionsCopy = [...sections];

    let reorderedSections = reorderList(source, destination, sectionsCopy);
    dispatch(updateSection(reorderedSections));
  };

  const viewModeClass = storylineMode === mode.view ? "hidden" : "visible";

  return (
    <>
      {!showStory ? (
        <>
          <div className="content-heading mt-2">Introductory Content</div>
          <div className="mt-2 mb-5">
            <Editor
              updateEditorContent={updateIntroductoryContent}
              initialContent={storyLine.introductoryContent}
            />
          </div>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={"defaultColumn"}>
              {(provided) => {
                return (
                  <div>
                    <ItemList
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {sections.length
                        ? sections.map((section, index) => (
                            <Draggable
                              key={`${section.id}`}
                              draggableId={`${section.id}`}
                              index={index}
                              isDragDisabled={storylineMode === mode.view}
                            >
                              {(provided) => {
                                return (
                                  <Item
                                    {...provided.draggableProps}
                                    ref={provided.innerRef}
                                  >
                                    <Section
                                      storyOtions={storyOtions}
                                      setShowStory={toggleShowStoryPage}
                                      section={section}
                                      dragHandleProps={provided.dragHandleProps}
                                      updateStorySection={updateStorySection}
                                      deleteStorySection={removeStorySection}
                                      setSelectedStoryId={setSelectedStoryId}
                                      setSelectedStorySectionId={
                                        setSelectedStorySectionId
                                      }
                                    />
                                  </Item>
                                );
                              }}
                            </Draggable>
                          ))
                        : null}
                    </ItemList>
                  </div>
                );
              }}
            </Droppable>
          </DragDropContext>

          <div className="clearfix"></div>
          <div
            className={`d-flex justify-content-center storyline-section-add-section ${viewModeClass}`}
            onClick={addStorySection}
          >
            <span className="ml-2"> CLICK HERE TO ADD A CHAPTER </span>
          </div>
        </>
      ) : (
        <div>
          <StoryPage
            changeStoryContent={updateStoryContent}
            selectedStory={selectedStory}
            setShowStory={toggleShowStoryPage}
            selectedStorySectionId={selectedStorySectionId}
          />
        </div>
      )}
    </>
  );
};

export default IndexSection;
