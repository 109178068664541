import api from "src/helpers/request";
export const groupsEndpoint = "/groups";
export const getGroupRolesEndpoint = "/group-roles";

export const fetchGroups = () => {
  return api.call(
    {
      url: `${groupsEndpoint}?limit=12`,
      method: "GET",
    },
    { showLoader: true }
  );
};

export const fetchRoles = () => {
  return api.call(
    {
      url: getGroupRolesEndpoint,
      method: "GET",
    },
    { showLoader: true }
  );
};

export const addGroup = (payload) => {
  return api.call(
    {
      url: groupsEndpoint,
      method: "POST",
      data: payload,
    },
    { showLoader: true }
  );
};

export const editGroup = (payload, groupId) => {
  return api.call(
    {
      url: `${groupsEndpoint}/${groupId}`,
      method: "PUT",
      data: payload,
    },
    { showLoader: true }
  );
};

export const deleteGroupById = (groupId) =>
  api.call(
    {
      url: `${groupsEndpoint}/${groupId}`,
      method: "DELETE",
    },
    { showLoader: true }
  );

export const fetchGroupById = (groupId) =>
  api.call(
    {
      url: `${groupsEndpoint}/${groupId}`,
      method: "GET",
    },
    { showLoader: true }
  );

export const deleteGroupUserById = (groupId, userId) =>
  api.call(
    {
      url: `${groupsEndpoint}/${groupId}/users/${userId}`,
      method: "DELETE",
    },
    { showLoader: true }
  );
