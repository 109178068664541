import { mapAdminLabs } from "src/helpers/labs/index";
import { GET_LABS_SUCCESS } from "./reducer";
import { fetchAdminLabs } from "src/api/lab";

export const getLabs = (metaConfig = { showLoader: true }) => async (
  dispatch
) => {
  let data = await fetchAdminLabs(metaConfig);
  if (data) {
    let labs = mapAdminLabs(data);
    dispatch({ type: GET_LABS_SUCCESS, payload: labs });
  }
};
