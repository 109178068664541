import React, { useState } from "react";
import "./TableOfContent.scss";
import rightArrow from "src/assets/images/right-arrow-color.svg";
import { getHeaderElementsList } from "./../StoryPage";
import { flatten } from "lodash";

type TableOfContentProps = {
  tocList: any[];
};

const TableOfContent: React.FC<TableOfContentProps> = ({ tocList }) => {
  let [selectedTocItemId, setSelectedTocItemId] = useState("");

  const selectAndScrollToElement = (elmId) => {
    setSelectedTocItemId(elmId);
    let selectedElm = getHeaderElementsList()[elmId];
    selectedElm.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest",
    });
  };

  const getTocList = () => {
    return (
      <ul>
        {tocList.map((item) => {
          return (
            <>
              <li className="overflow-ellipsis" title={item.name}>
                {selectedTocItemId === item.id && (
                  <img
                    height="10"
                    width="10"
                    className="mb-1 "
                    src={rightArrow}
                  />
                )}{" "}
                <span
                  onClick={() => selectAndScrollToElement(item.id)}
                  className={
                    selectedTocItemId === item.id ? "selected-item" : "ml-3 "
                  }
                >
                  {item.name}
                </span>
                <ul>
                  {item.subItems.map((item) => {
                    return (
                      <li className="overflow-ellipsis" title={item.name}>
                        {selectedTocItemId === item.id && (
                          <img
                            height="10"
                            width="10"
                            className="mb-1"
                            src={rightArrow}
                          />
                        )}{" "}
                        <span
                          onClick={() => selectAndScrollToElement(item.id)}
                          className={
                            selectedTocItemId === item.id
                              ? "selected-item"
                              : "ml-3"
                          }
                        >
                          {item.name}
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </li>
            </>
          );
        })}
      </ul>
    );
  };
  return (
    <div className="toc mr-1">
      <h6 className="toc-heading">Table of Content</h6>
      {getTocList()}
    </div>
  );
};

export default TableOfContent;
