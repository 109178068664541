import React, { useMemo } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import { useExportData } from "./useExportData";
import Papa from "papaparse";
import "./InfographicBaseTable.scss";
import paginationLeft from "src/assets/images/paginationLeft.svg";
import paginationRight from "src/assets/images/paginationRight.svg";
import sortAsc from "src/assets/images/sort-up.svg";
import sortDesc from "src/assets/images/sort-down.svg";
import { pageSize as itemsPerPage } from "src/constants/dashboard";
import { ReactComponent as ExportIcon } from "src/assets/images/export.svg";
import {
  TColumns,
  TFilteredDates,
} from "src/components/Infographics/InfographicTable/InfographicTable";
import { getDate } from "src/utils/common";
import omit from "lodash/omit"

type TableProps = {
  columns: Array<any>;
  data: Array<any>;
  theadStyle?: any;
  paginationOff?: boolean;
  totalItems: number;
  fetchItems: (offset: number) => void;
  fetchAllTableItems: () => Promise<Array<any>>;
  pageIndex: number;
  setPageIndex: (number) => void;
  searchBy: string;
  filteredDates: TFilteredDates;
  activeTab: string;
  allColumns?: Array<TColumns>;
};

function getExportFileBlob({ columns, data, fileType, fileName }) {
  if (fileType === "csv") {
    const headerNames = columns.map((col) => col.exportValue);
    const csvString = Papa.unparse({ fields: headerNames, data });
    return new Blob([csvString], { type: "text/csv" });
  }
  return false;
}

const InfographicBaseTable: React.FC<TableProps> = (props) => {
  const size = itemsPerPage;
  const memoizedColumns = useMemo(() => props.columns, [props.columns]);
  const memoizedData = useMemo(() => props.data, [props.data]);

  function getExportFileName({ fileType, all }) {
    let fileName = `${props.activeTab}`;
    if (props.searchBy) {
      fileName += `-${props.searchBy}`;
    }
    if (props.filteredDates.startDate && props.filteredDates.endDate) {
      fileName += `-(${getDate(props.filteredDates.startDate)} to ${getDate(
        props.filteredDates.endDate
      )})`;
    }
    if (
      !props.searchBy &&
      !props.filteredDates.startDate &&
      !props.filteredDates.endDate
    ) {
      fileName += "-all-data";
    }
    return fileName;
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state: { pageSize },
    previousPage,
    nextPage,
    page,
    exportData,
  } = useTable(
    {
      columns: memoizedColumns,
      data: memoizedData,
      initialState: props.paginationOff ? {} : { pageIndex: 0, pageSize: size },
      // state: { props.pageIndex: props.pageIndex, pageSize: 25 },
      manualPagination: true,
      getExportFileBlob,
      getExportFileName,
    },
    useSortBy,
    usePagination,
    useExportData
  );

  const totalPages = Math.ceil(props.totalItems / pageSize);

  const exportTableData = async () => {
    const data = await props.fetchAllTableItems();
    exportData("csv", true, data, props.allColumns);
  };

  const ShowTableData = () => (
    <>
      <div className="export cursor-pointer" onClick={exportTableData}>
        <span data-hover="Export">
          <ExportIcon className="export-icon cursor-pointer" height="30" />
        </span>
      </div>
      <div className="pure-table">
        <div>
          <table {...getTableProps()} className="table">
            <colgroup>
              {props.columns.map((column, index) => {
                return (
                  <col
                    key={index}
                    className={`table-col-${column.percentWidth}`}
                  />
                );
              })}
            </colgroup>
            <thead>
              {headerGroups.map((headerGroup, index) => (
                <tr
                  key={index}
                  style={props.theadStyle}
                  {...omit(headerGroup.getHeaderGroupProps(), 'key')}
                >
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <img
                              src={sortDesc}
                              className="ml-1"
                              alt="sortDesc"
                            />
                          ) : (
                            <img src={sortAsc} className="ml-1" alt="sortAsc" />
                          )
                        ) : (
                          ""
                        )}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>
                          <span title={cell.value}>{cell.render("Cell")}</span>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {!props.paginationOff && (
          <div className="pagination-container mt-2 mr-4">
            <div className="d-flex justify-content-end align-items-center mb-1">
              <div>
                Viewing <b>{props.pageIndex * pageSize + 1}</b> -{" "}
                <b>{props.pageIndex * pageSize + page.length}</b> of{" "}
                <b>{props.totalItems}</b>
              </div>
              <div>
                <img
                  src={paginationLeft}
                  onClick={() => {
                    if (props.pageIndex > 0) {
                      previousPage();
                      props.setPageIndex(props.pageIndex - 1);
                      props.fetchItems((props.pageIndex - 1) * pageSize);
                    }
                  }}
                  className="previous-page ml-3"
                  alt="leftArrow"
                  height="30"
                  width="30"
                />
                <img
                  src={paginationRight}
                  onClick={() => {
                    if (props.pageIndex + 1 < totalPages) {
                      nextPage();
                      props.setPageIndex(props.pageIndex + 1);
                      props.fetchItems((props.pageIndex + 1) * pageSize);
                    }
                  }}
                  className="next-page"
                  alt="rightArrow"
                  height="30"
                  width="30"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );

  return (
    <div className="pure-table">
      {memoizedData.length ? <ShowTableData /> : null}
    </div>
  );
};

export default InfographicBaseTable;
