import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={15.939}
      height={11.937}
      viewBox="0 0 15.939 11.937"
      {...props}
    >
      <g fill="#6e8a95" data-name="user (4)">
        <path
          data-name="Path 2997"
          d="M7.188 5.887a1.571 1.571 0 001.563 0l7.094-4.729A1.72 1.72 0 0014.222 0H1.717A1.72 1.72 0 00.094 1.158l7.094 4.729z"
        />
        <path
          data-name="Path 2998"
          d="M9.269 6.663a2.5 2.5 0 01-2.6 0L0 2.217v8a1.719 1.719 0 001.717 1.717h12.5a1.719 1.719 0 001.717-1.717v-8z"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
