import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserNotifications,
  readNotificationThunk,
} from "src/state/userNotifications/actions";
import { RootState } from "src/state/rootReducer";
import { IUserNotification } from "src/state/userNotifications/reducer";
import { useHistory } from "react-router-dom";
import PureModal from "src/components/shared/PureModal";

import "./styles.scss";
import {setLocalStorageItem} from "src/utils/common";

const LoginNotifications = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [notifications, setNotifications] = React.useState<IUserNotification[]>(
    []
  );

  const { notificationSaveSuccessful } = useSelector(
    (state: RootState) => state.userNotifications
  );

  const [popupIdx, setPopupIdx] = React.useState(0);
  const notification = notifications[popupIdx];

  const transformNotifications = (items) =>
    items.filter(
      (item) =>
        (item.displayLocation === "on_every_login" ||
          (item.displayLocation === "on_next_login" && !item.isRead)) &&
        item.isEnabled &&
        new Date(item.startTime).getTime() <= new Date().getTime() &&
        new Date().getTime() <= new Date(item.endTime).getTime()
    );

  const setupNotifications = async () => {
    // @ts-ignore
    const items: IUserNotification[] = await dispatch(getUserNotifications());
    setNotifications(transformNotifications(items));
  };

  React.useEffect(() => {
    setupNotifications();
  }, []);

  const closePopup = () => {
    setPopupIdx(popupIdx + 1);
    if (notification.displayLocation === "on_next_login") {
      dispatch(readNotificationThunk(notification.id));
    }
    if (notifications.length !== 0 && popupIdx + 1 >= notifications.length) {
      setLocalStorageItem("showNotifications", "0");
    }
  };

  if (popupIdx >= notifications.length || !notificationSaveSuccessful) {
    return null;
  }

  return (
    <PureModal
      showModal
      title={notification?.header}
      primaryBtnLabel="Ok"
      primaryBtnAction={closePopup}
      closeModal={closePopup}
      centered={true}
      className="login-modal"
    >
      <div className="d-flex align-items-center">
        <span className="login-modal-img">
          {" "}
          <strong> ! </strong>{" "}
        </span>
        <span className="body">{notification?.body}</span>
      </div>
    </PureModal>
  );
};

export default LoginNotifications;
