import React from "react";
import FormLayout from "src/components/shared/FormLayout";
import labAccess from "src/assets/images/lab-access.svg";
import Input from "src/containers/admin/Notifications/Input";
import { Form } from "react-bootstrap";
import DTPicker from "src/components/shared/DateTimePicker";
import { FormikHandlers, FormikHelpers, FormikState } from "formik";
import Select from "react-select";
import { Notification } from "src/containers/admin/Notifications/ManageNotification";

import "./Notifications.scss";

export type Formik = FormikHelpers<Notification> &
  FormikHandlers &
  FormikState<Notification>;

declare interface ConfigurationProps extends Formik {}

const displayLocationOptions = [
  {
    label: "Display on every login",
    value: "on_every_login",
    info: "",
  },
  {
    label: "Display on next login",
    value: "on_next_login",
    info: "(after which will be displayed in the notification area only)",
  },
  {
    label: "Display in the notification area only",
    value: "notification_area",
    info: "",
  },
];

const Configuration: React.FC<ConfigurationProps> = (formik) => {
  const updateStartDate = (date: string) => {
    formik.setFieldTouched("startTime");
    formik.setFieldValue("startTime", date);
  };

  const updateEndDate = (date: string) => {
    formik.setFieldTouched("endTime");
    formik.setFieldValue("endTime", date);
  };

  const selectDisplayLocation = (location) => {
    formik.setFieldTouched("displayLocation");
    formik.setFieldValue("displayLocation", location);
  };

  const formatOptionLabel = (props) => (
    <div>
      {props.label}
      {props.info && (
        <span className="display-location-info"> {props.info} </span>
      )}
    </div>
  );

  const formikProps = {
    getFieldProps: formik.getFieldProps,
    touched: formik.touched,
    errors: formik.errors,
  };

  const startTime: any = formik.values.startTime
    ? new Date(formik.values.startTime)
    : "";
  const endTime: any = formik.values.endTime
    ? new Date(formik.values.endTime)
    : "";

  const displayLocation = displayLocationOptions.find(
    (loc) => loc.value === formik.values.displayLocation
  );

  const toggleNotification = (event) => {
    const isChecked = event.target.checked;
    formik.setFieldTouched("isEnabled");
    formik.setFieldValue("isEnabled", isChecked);
  };

  const startDate = new Date(formik.values.startTime);

  return (
    <FormLayout
      headerImage={labAccess}
      headerTitle="Notification Configuration"
      className="notification-configuration"
    >
      <form onSubmit={formik.handleSubmit}>
        <div className="lab-container">
          <Input
            name="header"
            label="Notification Heading"
            placeholder="Enter notification heading"
            id="notification-heading"
            {...formikProps}
          />
          <Input
            name="isEnabled"
            label="Enabled/Disabled"
            type="switch"
            required={false}
            {...formikProps}
          >
            <Form.Check
              id="notification-toggle"
              type="switch"
              className="cursor-pointer"
              label=""
              checked={formik.values.isEnabled}
              onChange={toggleNotification}
            />
          </Input>
        </div>
        <div className="lab-container">
          <Input
            name="body"
            as="textarea"
            rows={3}
            label="Notification Text"
            placeholder="Enter description"
            id="notification-text"
            {...formikProps}
          />
          <Input
            type="datetime"
            name="startTime"
            label="Start Date & Time"
            {...formikProps}
          >
            <DTPicker
              selectedDate={startTime}
              setSelectedDate={updateStartDate}
              timeFormat="HH:mm"
              showTimeSelect
              timeInputLabel="Time:"
              placeholderText="Schedule date & time"
              className="date-picker-container form-control"
            />
          </Input>
        </div>
        <div className="lab-container">
          <Input
            type="select"
            label="Display Location"
            name="displayLocation"
            {...formikProps}
          >
            <Select
              options={displayLocationOptions}
              formatOptionLabel={formatOptionLabel}
              value={displayLocation}
              onChange={selectDisplayLocation}
            />
          </Input>
          <Input
            type="datetime"
            label="End Date & Time"
            name="endTime"
            {...formikProps}
          >
            <DTPicker
              selectedDate={endTime}
              setSelectedDate={updateEndDate}
              showTimeSelect
              placeholderText="Schedule date & time"
              className="date-picker-container form-control"
              disabled={!formik.values.startTime}
            />
          </Input>
        </div>
      </form>
    </FormLayout>
  );
};

export default Configuration;
