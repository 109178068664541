import Api from "src/helpers/request";
import {
  formatDateToLocal,
  toCamelCase,
  toCamelCaseKeys,
} from "src/helpers/common";
import { sortMonthlyStats } from "./index";
import { pageSize } from "src/constants/dashboard";

const getLabsLaunched = "dashboard/activity/lab_launch";
const getLabStats = "dashboard/activity/lab_launch/stats";

const getStats = (monthlyStats) => {
  const keys = Object.keys(monthlyStats).sort(sortMonthlyStats);
  let year;
  let stats: any = {};
  return keys.map((key) => {
    stats = {};
    year = key.substr(-2);
    stats.year = year;
    stats.name = `${toCamelCase(key.substr(0, 3))}'${year}`;
    stats.Labs = monthlyStats[key];
    return stats;
  });
};

const mapLabs = (labs) =>
  labs.map((lab) => ({
    time: lab.createdOn ? formatDateToLocal(lab.createdOn) : "-",
    name: lab?.launchedBy?.name || "-",
    email: lab?.launchedBy?.email || "-",
    company: lab?.launchedBy?.company?.name || "-",
    labName: lab?.lab?.name || "-",
    dateTime: lab?.shutdownAtTime
      ? formatDateToLocal(lab?.shutdownAtTime)
      : "-",
  }));

const mapLabStats = (response) => {
  let labStats: any = {};
  const formattedResponse = toCamelCaseKeys(response);
  labStats.active = formattedResponse.totalActiveLabs || 0;
  labStats.inactive = formattedResponse.totalInactiveLabs || 0;
  labStats.launched = formattedResponse.totalLabsLaunched || 0;
  labStats.barChartStats = getStats(formattedResponse.monthlyStats);
  return labStats;
};

export const fetchLabStats = async (
  from?: string,
  to?: string,
  metaConfig = { showLoader: true }
) => {
  try {
    let url;
    if (from && to) {
      url = `${getLabStats}?created_on_gte=${from}&created_on_lte=${to}`;
    } else {
      url = getLabStats;
    }
    let response = await Api.call({ url, method: "GET" }, metaConfig);
    return mapLabStats(response);
  } catch (e) {
    console.error(e);
  }
};

export const fetchAllLabsLaunched = async (
  searchQuery?,
  from?,
  to?,
  metaConfig = { showLoader: true }
) => {
  try {
    let url = `${getLabsLaunched}?pagination=false`;
    if (from && to) {
      url += `&created_on_gte=${from}&created_on_lte=${to}`;
    }
    if (searchQuery) {
      url += `&search=${searchQuery}`;
    }
    const response = await Api.call({ url, method: "GET" }, metaConfig);
    const labs = toCamelCaseKeys(response);
    return mapLabs(labs);
  } catch (e) {
    console.error(e);
  }
};

export const fetchLabsLaunched = async (
  offset?: number | null,
  searchQuery?,
  from?,
  to?,
  metaConfig = { showLoader: true }
) => {
  try {
    let url = getLabsLaunched;
    if (offset || offset === 0) {
      url += `?limit=${pageSize}&offset=${offset}`;
    }
    if (from && to) {
      url += `&created_on_gte=${from}&created_on_lte=${to}`;
    }
    if (searchQuery) {
      url += `&search=${searchQuery}`;
    }
    let response = await Api.call({ url, method: "GET" }, metaConfig);
    response = toCamelCaseKeys(response);
    const labs = mapLabs(response.results);
    return { totalItems: response.count, items: labs };
  } catch (e) {
    console.error(e);
  }
};
