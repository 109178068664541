import {
  GET_PRESENTATION_LIST,
  UPDATE_FILES_BY_FOLDER,
  DELETE_PRESENTATION,
  ADD_FOLDER,
} from "./reducer";
import { fetchFolders, fetchFilesByFolder } from "src/api/presentations";
import { mapPresentations } from "src/helpers/presentations";

export const getRootPresentations = (
  metaConfig = { showLoader: true }
) => async (dispatch) => {
  let data = await fetchFolders(metaConfig);
  if (data.length) {
    let presentations = mapPresentations(data);
    dispatch({ type: GET_PRESENTATION_LIST, payload: presentations });
  }
};

export const getChildrenFilesAction = (data = []) => async (dispatch) => {
  let presentations = data.length ? mapPresentations(data) : [];
  dispatch({ type: GET_PRESENTATION_LIST, payload: presentations });
};

export const updateFolder = (file, folderId, currentFolderId) => ({
  type: UPDATE_FILES_BY_FOLDER,
  payload: { file, folderId, currentFolderId },
});

export const deletePresentation = (item) => ({
  type: DELETE_PRESENTATION,
  payload: item,
});

export const addFolder = (folder) => ({
  type: ADD_FOLDER,
  payload: folder,
});
