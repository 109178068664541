import React from "react";
import "./Layout.scss";
import {Route, Redirect, useLocation, useHistory} from "react-router-dom";
import { isUserAuthenticated } from "src/utils/common";
import { LOGIN_ROUTE } from "src/constants/appRoutes";
import { useSelector } from "react-redux";
import SideBar from "src/components/SideBar";
import { RootState } from "src/state/rootReducer";

type LayoutProps = {
  exact?: boolean;
  path: string;
  header?: React.FC<any>;
  component: React.FC<any>;
};

const Layout: React.FC<LayoutProps> = ({ header: Header, ...props }) => {
  const { pathname, search } = useLocation()

  const isAuthenticated = isUserAuthenticated();
  const sidebar = useSelector((state: RootState) => state.sidebar);
  const onConsole =
    pathname.includes("/user/console") ||
    pathname.includes("/user/presentation");

  let redirectUrl = LOGIN_ROUTE;
  if (pathname) {
    redirectUrl = `${LOGIN_ROUTE}?${pathname}${search}`;
  }

  return isAuthenticated ? (
    <div className="outer-layout">
      {Header && <Header />}
      <div
        className="panel"
        style={{ height: onConsole ? "100vh" : "calc(100vh - 3.8rem)" }}
      >
        {sidebar.sidebarOpen && (
          <div
            className={`side-panel ${
              onConsole && "side-panel_fixed highest-ZIndex"
            }`}
          >
            <SideBar />
          </div>
        )}
        <div
          className={`main-panel ${
            !pathname.includes("/user/console") && "y-scroll"
          }`}
        >
          <Route
            path={props.path}
            component={props.component}
            exact={props.exact}
          />
        </div>
      </div>
    </div>
  ) : (
    <Redirect to={redirectUrl} />
  );
};

export default Layout;
