import React, { useEffect, useState } from "react";
import PureModal from "src/components/shared/PureModal";
import { Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import InputErrorMessage from "src/components/shared/InputErrorMessage";
import "./ManageAdminVoucher.scss";
import { addAdminVoucher } from "src/api/vouchers";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/state/rootReducer";
import { getVouchers, getVoucherLabs } from "src/state/vouchers/actions";
import RequiredFieldSymbol from "src/components/ui/RequiredFieldSymbol";
import DTPicker from "src/components/shared/DateTimePicker/index";
import calender from "src/assets/images/calender.svg";
import { formatDateByDefault } from "src/helpers/common";
import { isEmpty } from "src/utils/common";
import CustomTooltip from "src/components/ui/CustomTooltip";
import { CopyToClipboard } from "react-copy-to-clipboard";
import copy from "src/assets/images/copy-code.svg";
import { pushNotification } from "src/components/ui/Notification";

type ManageAdminVoucherProps = {
  setShowModal: (boolean) => void;
};

const pushVoucherCopyNotification = () =>
  pushNotification({
    type: "info",
    message: "Voucher URL Copied",
  });

const ManageAdminVoucher: React.FunctionComponent<ManageAdminVoucherProps> = ({
  ...props
}) => {
  const newVoucher = {
    name: "",
    lab: "",
    expiry: "Never",
    usageCount: 1,
    voucherCode: "",
    expiryDate: "",
  };
  const initialValues = newVoucher;
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState("" as any);
  const [expiry, setExpiry] = useState("Never");
  const [voucherCodes, setVoucherCodes] = useState<
    Array<{ name: String; code: String }>
  >([]);
  const labs: Array<any> = useSelector(
    (state: RootState) => state.vouchers.labList
  );

  useEffect(() => {
    dispatch(getVoucherLabs());
  }, []);

  useEffect(() => {
    if (
      !!selectedDate &&
      new Date().toDateString() === selectedDate?.toDateString() &&
      selectedDate?.getTime() <= new Date().getTime()
    ) {
      setSelectedDate("");
    }
  }, [selectedDate]);

  const getPayload = (voucher) => {
    // TODO: dynamic payload construction
    const payload = {
      name: voucher.name,
      lab_assigned: labs.find((val) => val.name === voucher.lab).id,
      usage_allowed: voucher.usageCount,
    };
    if (!!voucher.voucherCode) {
      payload["code"] = voucher.voucherCode;
    }
    if (voucher.expiry === "Custom") {
      payload["expires_on"] = formatDateByDefault(voucher.expiryDate);
    }
    return payload;
  };

  const onSubmitHandle = async (voucher, actions) => {
    actions.setSubmitting(true);
    const payload = getPayload(voucher);
    let data = await addAdminVoucher(payload);
    actions.setSubmitting(false);
    if (!isEmpty(data) && !data.hasError) {
      setVoucherCodes([
        ...voucherCodes,
        {
          name: payload.name,
          code: data.code,
        },
      ]);
      actions.resetForm();
      const labAssigned = labs.find((val) => val.id === payload.lab_assigned)
        ?.name;
      actions.setFieldTouched("lab");
      actions.setFieldValue("lab", labAssigned);
    }
    dispatch(getVouchers());
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object({
        name: Yup.string().required("Name is required"),
        lab: Yup.string().required("Lab is required"),
        expiry: Yup.string().required("Expiry is required"),
        usageCount: Yup.number()
          .min(1)
          .round("floor")
          .required("Usage count is required"),
        expiryDate:
          expiry === "Custom"
            ? Yup.string()
                .required("Date is required for Custom Date")
                .nullable()
            : Yup.string().nullable(),
      })}
      onSubmit={onSubmitHandle}
    >
      {(formik) => (
        <PureModal
          title="Create Voucher"
          showModal={true}
          closeModal={() => props.setShowModal(false)}
          primaryBtnLabel="Create"
          primaryBtnAction={formik.handleSubmit}
          secondaryBtnLabel="Cancel"
          secondaryBtnAction={() => props.setShowModal(false)}
        >
          <Form>
            <Form.Group>
              <Form.Label>
                Name
                <RequiredFieldSymbol />
              </Form.Label>
              <Form.Control
                type="text"
                id="name"
                placeholder="Enter name"
                {...formik.getFieldProps("name")}
                isInvalid={!!(formik.touched.name && formik.errors.name)}
              />
              <Form.Control.Feedback type="invalid">
                <InputErrorMessage errorMessage={formik.errors.name || ""} />
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Select Lab <RequiredFieldSymbol />
              </Form.Label>
              <select
                className="form-control"
                onChange={(event) => {
                  event.preventDefault();
                  const data = event.target.selectedOptions[0];
                  formik.setFieldTouched("lab");
                  formik.setFieldValue("lab", data.id ? data.value : "");
                }}
              >
                {labs ? (
                  [<option key="default_option">Select</option>].concat(
                    labs.map((val) => (
                      <option key={val.id} value={val.name} id={val.id}>
                        {val.name}
                      </option>
                    ))
                  )
                ) : (
                  <option key="no_option">No Lab available</option>
                )}
              </select>

              {formik.touched.lab && formik.errors.lab && (
                <InputErrorMessage
                  className={"error-message"}
                  errorMessage={formik.errors.lab || ""}
                />
              )}
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Expiry <RequiredFieldSymbol />
              </Form.Label>
              <div className="radio d-flex">
                <label
                  className="radio-inline mr-5"
                  onChange={(event: any) => {
                    const value = event.target.value ? event.target.value : "";
                    formik.setFieldTouched("expiry");
                    formik.setFieldValue("expiry", value);
                    setExpiry(value);
                  }}
                >
                  <input
                    type="radio"
                    name="expiryOption"
                    value="Never"
                    defaultChecked
                  />
                  <span className="ml-1">Never</span>
                </label>
                <label
                  className="radio-inline"
                  onChange={(event: any) => {
                    const value = event.target.value ? event.target.value : "";
                    formik.setFieldTouched("expiry");
                    formik.setFieldValue("expiry", value);
                    setExpiry(value);
                  }}
                >
                  <input type="radio" name="expiryOption" value="Custom" />
                  <span className="ml-1">Custom</span>
                </label>
              </div>
              <div className="d-flex">
                <img
                  src={calender}
                  alt="calenderIcon"
                  title="Choose Date"
                  className="mr-2"
                />
                <DTPicker
                  selectedDate={selectedDate}
                  setSelectedDate={(date: string) => {
                    setSelectedDate(date);
                    formik.setFieldTouched("expiryDate");
                    formik.setFieldValue("expiryDate", date);
                  }}
                  showTimeSelect
                  timeFormat="HH:mm"
                  dateFormat="dd MMM yyyy hh:mm aa"
                  disabled={formik.values.expiry !== "Custom"}
                  minDate={new Date()}
                  minTime={
                    (!!selectedDate &&
                      new Date().toDateString() ===
                        selectedDate?.toDateString()) ||
                    !selectedDate
                      ? new Date().setHours(
                          new Date().getHours(),
                          new Date().getMinutes()
                        )
                      : new Date().setHours(0, 0)
                  }
                  className="date-picker-container form-control"
                />
              </div>
              {formik.values.expiry === "Custom" &&
                formik.errors.expiryDate && (
                  <InputErrorMessage
                    className={"error-message"}
                    errorMessage={formik.errors.expiryDate || ""}
                  />
                )}
              {formik.touched.expiry && formik.errors.expiry && (
                <InputErrorMessage
                  className={"error-message"}
                  errorMessage={formik.errors.expiry || ""}
                />
              )}
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Usage Count
                <RequiredFieldSymbol />
              </Form.Label>
              <Form.Control
                type="text"
                id="usageCount"
                {...formik.getFieldProps("usageCount")}
                isInvalid={
                  !!(formik.touched.usageCount && formik.errors.usageCount)
                }
              />
              <Form.Control.Feedback type="invalid">
                <InputErrorMessage
                  errorMessage={formik.errors.usageCount || ""}
                />
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Voucher Code</Form.Label>
              <Form.Control
                type="text"
                id="voucherCode"
                {...formik.getFieldProps("voucherCode")}
              />
            </Form.Group>
            {!!voucherCodes.length &&
              voucherCodes.map((item, index) => (
                <Form.Group key={`${index}`}>
                  <Form.Label className="voucher-form-label">
                    {item.name}
                  </Form.Label>
                  <div className="d-flex voucher-form-row">
                    <Form.Control
                      disabled
                      type="text"
                      id="voucherCode"
                      readOnly
                      defaultValue={`${window.location.origin}/voucher/${item.code}`}
                    />
                    <CustomTooltip text="Copy Voucher URL">
                      <CopyToClipboard
                        text={`${window.location.origin}/voucher/${item.code}`}
                        onCopy={pushVoucherCopyNotification}
                      >
                        <img
                          src={copy}
                          alt="copy-url"
                          className="cursor-pointer"
                        />
                      </CopyToClipboard>
                    </CustomTooltip>
                  </div>
                </Form.Group>
              ))}
          </Form>
        </PureModal>
      )}
    </Formik>
  );
};

export default ManageAdminVoucher;
