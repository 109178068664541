import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "./PureModal.scss";
import closeIcon from "src/assets/images/close.svg";

type PureModalProps = {
  size?: "sm" | "lg" | "xl" | undefined;
  primaryBtnLabel?: string;
  secondaryBtnLabel?: string;
  primaryBtnAction?: () => void;
  secondaryBtnAction?: () => void;
  closeModal?: () => void;
  children?: React.ReactNode;
  showModal: boolean;
  centered?: boolean;
  title: string;
  className?: string;
  disablePrimaryBtn?: boolean;
};

const PureModal: React.FC<PureModalProps> = ({
  size,
  primaryBtnLabel,
  secondaryBtnLabel,
  primaryBtnAction,
  secondaryBtnAction,
  closeModal,
  showModal,
  centered = false,
  title,
  className,
  disablePrimaryBtn,
  children,
  ...props
}) => {
  return (
    <div className="pure-modal">
      <Modal
        show={showModal}
        size={size ? size : undefined}
        aria-labelledby="contained-modal-title-vcenter"
        centered={centered}
        className={className}
        {...props}
      >
        <Modal.Header bsPrefix="pure-modal-header d-flex align-items-center justify-content-between">
          <Modal.Title>{title}</Modal.Title>
          {closeModal && (
            <div onClick={closeModal}>
              <img src={closeIcon} alt="closeIcon" />
            </div>
          )}
        </Modal.Header>
        <Modal.Body>
          {typeof children === "function"
            ? children(primaryBtnAction)
            : children}
        </Modal.Body>
        <Modal.Footer bsPrefix="pure-modal-footer">
          {secondaryBtnLabel && (
            <Button
              onClick={secondaryBtnAction}
              size="sm"
              variant="outline-secondary"
              className="pure-modal-footer-btn"
            >
              {secondaryBtnLabel}
            </Button>
          )}
          {primaryBtnLabel && (
            <Button
              onClick={primaryBtnAction}
              className="pure-modal-footer-btn ml-2"
              size="sm"
              variant="primary"
              disabled={disablePrimaryBtn}
            >
              {primaryBtnLabel}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PureModal;
