import React, { useState, useEffect } from "react";
import "./Presentations.scss";
import { getPresentationColumns } from "./presentationColumns";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/state/rootReducer";
import { Button, Modal } from "react-bootstrap";
import SearchBox from "src/components/shared/SearchBox";
import NoSearchResultFound from "src/components/ui/NoSearchResultFound";
import WarningModal from "src/components/shared/WarningModal";
import { IPresentation } from "src/state/presentations/reducer";
import {
  getRootPresentations,
  getChildrenFilesAction,
  deletePresentation,
} from "src/state/presentations/actions";
import Table from "src/components/shared/Table";
import {
  deletePresentationById,
  fetchFilesByFolder,
} from "src/api/presentations";
import { isEmpty } from "src/utils/common";
import CreateFolder from "./CreateFolder";
import UploadFile from "./FileUploader";
import rightArrow from "src/assets/images/right-arrow.svg";

const NewPresentationItem = {
  name: "",
  id: "",
  type: "",
};

const defaultBreadcrumb = {
  id: "",
  name: "",
  type: "Folder",
};

type PresentationProps = {};

const Presentations: React.FC<PresentationProps> = () => {
  const [selectedItem, setSelectedItem] = useState(NewPresentationItem);
  const [filteredPresentations, setFilteredPresentations] = useState([] as any);
  const [searchBy, setSearchBy] = useState("");
  const [showDeleteWarningModal, setShowDeleteWarningModal] = useState(false);
  const [slidesSrc, setSlidesSrc] = useState("");
  const [showViewPresentationModal, setShowViewPresentationModal] = useState(
    false
  );
  const [showCreateFolderModal, setShowCreateFolderModal] = useState(false);
  const [showFileUploader, setShowFileUploader] = useState(false);
  const toggleFileUploader = (event) => {
    setSelectedItem({ ...selectedItem, id: event.target.dataset.id });
    setShowFileUploader(!showFileUploader);
  };
  const dispatch = useDispatch();
  const presentations: Array<IPresentation> = useSelector(
    (state: RootState) => state.presentationList.list
  );
  const loaderCount = useSelector((state: RootState) => state.ui.loaderCount);
  const isLoading = loaderCount > 0;
  const [breadCrumbs, setBreadCrumbs] = useState([defaultBreadcrumb]);
  const [currentBreadcrumb, setCurrentBreadcrumb] = useState(defaultBreadcrumb);

  useEffect(() => {
    dispatch(getRootPresentations());
  }, []);

  const handleSearchByChange = (updatedSearchQuery) => {
    setSearchBy(updatedSearchQuery);
    filterPresentations(updatedSearchQuery);
  };

  const filterPresentations = (searchQuery: string) => {
    let filteredPresentations = presentations.filter((presentation: any) => {
      if (!searchQuery) return true;
      const name = presentation.name && presentation.name.toLowerCase();
      const query = searchQuery && searchQuery.toLowerCase();
      const filterByFileName =
        presentation.subRows &&
        presentation.subRows.filter((file) =>
          file.name.toLowerCase().includes(query)
        );

      let filterByLabs = [] as any;
      presentation.subRows &&
        presentation.subRows.forEach((file: any) => {
          const filtered = file.assignedLabs.filter((lab) =>
            lab.name.toLowerCase().includes(query)
          );
          if (filtered.length) filterByLabs.push(file);
        });

      return (
        (name && name.includes(query)) ||
        filterByFileName.length ||
        filterByLabs.length
      );
    });
    setFilteredPresentations(filteredPresentations);
  };

  useEffect(() => {
    filterPresentations(searchBy);
  }, [presentations]);

  const displayDeleteModal = (item) => {
    setSelectedItem({ name: item.name, id: item.id, type: item.type });
    setShowDeleteWarningModal(true);
  };

  const closeDeleteModal = () => {
    setSelectedItem(NewPresentationItem);
    setShowDeleteWarningModal(false);
  };

  const viewPresentation = (file) => {
    setSlidesSrc(file.webViewLink);
    toggleViewPresentationModal();
  };

  const getFilesByFolder = async (parent) => {
    let data = await fetchFilesByFolder(parent.id);
    if (data.length) {
      dispatch(getChildrenFilesAction(data));
    } else {
      dispatch(getChildrenFilesAction([]));
    }
    const { id, name, type } = parent;
    const newBreadcrumbItem = { id, name, type };
    const breadcrumbArr = [...breadCrumbs, newBreadcrumbItem];
    breadcrumbArr[0].name = "All";
    setBreadCrumbs(breadcrumbArr);
    setCurrentBreadcrumb(newBreadcrumbItem);
  };

  const presentationColumns = getPresentationColumns(
    viewPresentation,
    displayDeleteModal,
    toggleFileUploader,
    getFilesByFolder
  );

  const toggleViewPresentationModal = () => {
    setShowViewPresentationModal(!showViewPresentationModal);
  };

  const onDeleteHandler = async () => {
    const data = await deletePresentationById(selectedItem.id);
    if (!isEmpty(data) && !data.hasError) {
      dispatch(deletePresentation(selectedItem));
    }
    closeDeleteModal();
  };

  const loadPresentationsFromBreadcrumb = async (item) => {
    if (!!item.id) {
      let data = await fetchFilesByFolder(item.id);
      if (data.length) {
        const itemIdx = breadCrumbs.findIndex((val) => val.id === item.id);
        const breadcrumbArr = breadCrumbs.slice(0, itemIdx + 1);
        setBreadCrumbs(breadcrumbArr);
        setCurrentBreadcrumb(item);
        dispatch(getChildrenFilesAction(data));
      }
    } else {
      if (breadCrumbs.length > 1) {
        defaultBreadcrumb.name = "";
        setCurrentBreadcrumb(defaultBreadcrumb);
        setBreadCrumbs([defaultBreadcrumb]);
        dispatch(getRootPresentations());
      }
    }
  };

  const getBreadcrumbs = () =>
    breadCrumbs.map((val) => (
      <>
        <span
          className={
            val.name === currentBreadcrumb.name
              ? "breadcrumb-label color-primary"
              : "breadcrumb-label"
          }
          onClick={() => loadPresentationsFromBreadcrumb(val)}
        >
          {val.name}
        </span>
        {breadCrumbs.indexOf(val) < breadCrumbs.length - 1 && (
          <img
            height="12"
            width="12"
            src={rightArrow}
            alt="InfoIcon"
            className="mr-1 ml-1"
          />
        )}
      </>
    ));

  return (
    <div className="presentations-section d-flex flex-column flex-grow-1">
      {showCreateFolderModal && (
        <CreateFolder
          setShowModal={setShowCreateFolderModal}
          parent={currentBreadcrumb}
        />
      )}

      {showFileUploader && (
        <UploadFile
          setShowModal={setShowFileUploader}
          parent={selectedItem}
          currentFolder={currentBreadcrumb}
        />
      )}

      <Modal
        size="xl"
        show={showViewPresentationModal}
        onHide={toggleViewPresentationModal}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>View Presentation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe
            title="googleSlides"
            src={slidesSrc}
            frameBorder="0"
            width="1100"
            height="550"
            allowFullScreen={true}
          ></iframe>
        </Modal.Body>
      </Modal>
      {showDeleteWarningModal && (
        <WarningModal
          title="Warning"
          onCancelAction={closeDeleteModal}
          onConfirmAction={onDeleteHandler}
        >
          <div className="ml-2">
            <div>
              Are you sure you want to delete <b>{selectedItem.name}</b> ?
            </div>
          </div>
        </WarningModal>
      )}
      <div className="d-flex flex-row justify-content-sm-between mb-2">
        <div className={`presentations-section-header`}>
          <span>
            <b>Presentations</b>
          </span>
        </div>

        <div className="d-flex justify-content-end ">
          <div className="presentations-section-search d-flex align-items-center">
            <SearchBox
              updateSearchQuery={handleSearchByChange}
              placeholder="Search by name or lab name"
            />
          </div>
          <div className={`align-self-end ml-2 d-inline`}>
            <Button
              type="submit"
              size="sm"
              variant="primary"
              className="presentations-section-add"
              onClick={() => {
                setShowCreateFolderModal(true);
              }}
            >
              <div className="d-flex align-items-center pl-2 pr-2">
                <span> Create Folder </span>
              </div>
            </Button>
          </div>
          <div className={`align-self-end ml-2 d-inline`}>
            <Button
              type="submit"
              size="sm"
              variant="outline-primary"
              className="presentations-section-add bg-white"
              onClick={() => {
                setSelectedItem(currentBreadcrumb);
                setShowFileUploader(true);
              }}
            >
              <div className="d-flex align-items-center pl-2 pr-2">
                <span> Upload File </span>
              </div>
            </Button>
          </div>
        </div>
      </div>
      <div className="ml-1 mb-2">{getBreadcrumbs()}</div>
      {filteredPresentations.length ? (
        <Table
          theadStyle={{ backgroundColor: "var(--color-white)" }}
          columns={presentationColumns}
          data={filteredPresentations}
        />
      ) : !!searchBy ? (
        <NoSearchResultFound
          message="Your search was not successful!"
          detail="Try some relevant name or owner"
          errImgSrc="no-users.svg"
        />
      ) : (
        !isLoading && (
          <NoSearchResultFound
            message="No Presentations found!"
            errImgSrc="no-users.svg"
          />
        )
      )}
    </div>
  );
};

export default Presentations;
