import { mapUser } from 'src/helpers/users/';
import { GET_USER } from './reducer';
import { getLoggedInUser } from 'src/api/users';

export const getUser = () => async (dispatch) => {
	let data = await getLoggedInUser();
	if (data) {
		let user = mapUser(data);
		dispatch({ type: GET_USER, payload: user });
	}
};
