import produce from "immer";

export const GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS";
export const GET_TYPE_SUCCESS = "GET_TYPE_SUCCESS";
export const ADD_COMPANY_SUCCESS = "ADD_COMPANY_SUCCESS";
export const ADD_COMPANY_FAILURE = "ADD_COMPANY_FAILURE";

export interface ICompanies {
  list: Array<ICompany>;
  typeList: Array<any>;
  companySaveSuccessful: boolean;
  companySaveFailure: boolean;
}

export interface ICompany {
  id: string;
  name: string;
  type: string;
  totalUsers: number;
  labsLaunched: number;
  lastLogin: string;
}

const initialState: ICompanies = {
  list: [],
  typeList: [],
  companySaveSuccessful: false,
  companySaveFailure: false,
};

export default (state = initialState, action): ICompanies =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_COMPANIES_SUCCESS:
        draft.list = action.payload;
        break;

      case GET_TYPE_SUCCESS:
        draft.typeList = action.payload;
        break;

      case ADD_COMPANY_SUCCESS:
        draft.companySaveSuccessful = action.payload;
        draft.companySaveFailure = false;
        break;

      case ADD_COMPANY_FAILURE:
        draft.companySaveFailure = action.payload;
        draft.companySaveSuccessful = false;
        break;

      default:
        break;
    }
  });
