import React from "react";
import { getVMApprovalStatus } from "src/api/lab";
import useInterval from "src/hooks/useInterval";
import {useDispatch} from "react-redux";
import {updateLabsApprovalStatus} from "src/state/labsApprovalStatus/actions";

const useLabApprovalPolling = (labIds, pollingInterval) => {
  const dispatch = useDispatch()

  const [pollingQueue, setPollingQueue] = React.useState<string[]>(
    () => labIds
  );
  const [approvalStatus, setApprovalStatus] = React.useState<Object>({});

  const pollingCallback = async () => {
    const statusSet = await getVMApprovalStatus(labIds);

    const pendingQueue = Object.keys(statusSet).reduce((newQueue, gid) => {
      const status = statusSet[gid] || "pending";
      if (status === "pending" || status === "provisioning" || !status) {
        newQueue.push(gid);
      }

      return newQueue;
    }, [] as string[]);

    setApprovalStatus(statusSet);
    dispatch(updateLabsApprovalStatus(statusSet))
    setPollingQueue(pendingQueue);
  };

  const shouldPolling = pollingQueue.length > 0;

  useInterval(
    pollingCallback,
    shouldPolling ? pollingInterval : null,
    !shouldPolling
  );

  React.useEffect(() => {
    if (labIds.length > 0) {
      pollingCallback();
    }
  }, [labIds.length]);

  return { approvalStatus, pollingQueue };
};

export default useLabApprovalPolling;
