import moment from "moment/moment";

export const computePastTime = (passedTime: string) => {
  let minutes = 0;
  let hours = 0;
  let days = 0;
  const seconds: any = moment().diff(moment(new Date(passedTime)), "seconds");
  let time = { unit: "sec", count: seconds };
  if (seconds >= 60) {
    minutes = Math.floor(seconds / 60);
    time = { unit: "min", count: minutes };
  }
  if (minutes >= 60) {
    hours = Math.floor(minutes / 60);
    time = { unit: "hr", count: hours };
  }
  if (hours >= 24) {
    days = Math.floor(hours / 24);
    time = { unit: "day", count: days };
  }

  time = {
    unit: time.count <= 1 ? time.unit : `${time.unit}s`,
    count: time.count,
  };
  return `${time.count} ${time.unit} ago`;
};

export const sortNotifications = (notification1, notification2) => {
  if (notification1.isRead && !notification2.isRead) {
    return 1;
  } else if (!notification1.isRead && notification2.isRead) {
    return -1;
  } else {
    const time1 = new Date(notification1?.createdOn)?.getTime()
    const time2 = new Date(notification2?.createdOn)?.getTime()
    return time1 < time2 ? 1 : time1 > time2 ? -1 : 0
  }
};

export const readNotificationHelper = (list, id) => {
  const dupList = list.map((item) => ({ ...item }));
  let matchedItem = dupList.find((item) => item.id === id);
  if (matchedItem) {
    matchedItem.isRead = true;
  }
  return dupList.sort(sortNotifications);
};
