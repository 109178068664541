import React, { useState, useEffect } from "react";
import "./ManageLab.scss";
import labAccess from "src/assets/images/lab-access.svg";
import FormLayout from "src/components/shared/FormLayout";
import { Form, Button } from "react-bootstrap";
import { Formik } from "formik";
import DragAndDrop from "src/components/ui/DragAndDrop";
import * as Yup from "yup";
import InputErrorMessage from "src/components/shared/InputErrorMessage";
import RequiredFieldSymbol from "src/components/ui/RequiredFieldSymbol";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchLabTemplates,
  createAdminLab,
  getLabDetails,
  saveAdminLab,
  uploadLabImage,
} from "src/api/lab";
import LabAccess from "./../LabAccess";
import VoucherConfig from "./../VoucherConfig";
import { remove } from "lodash";
import { usersAction } from "src/state/users/actions";
import { getGroups } from "src/state/groups/actions";
import { getCompanies } from "src/state/companies/actions";
import InfoIcon from "src/assets/images/info-icon.svg";
import CloseIcon from "src/assets/images/cross.svg";
import {
  mapTemplate,
  mapLabDetails,
  getPayload,
  mapStorylinesToVersion,
  mapVersions,
  getActiveVersions,
} from "src/helpers/labs/index";
import CustomTooltip from "src/components/ui/CustomTooltip";
import Editor from "src/components/Editor";
import { ADMIN_ROUTE, ADMIN_LABS_ROUTE } from "src/constants/appRoutes";
import { toCamelCaseKeys } from "src/helpers/common";
import { useHistory, useParams, Link } from "react-router-dom";
import rightArrow from "src/assets/images/right-arrow.svg";
import addTagIcon from "src/assets/images/add-red.svg";
import { fetchStorylines } from "src/api/stories";
import { SupportedImageFileFormats } from "src/constants/regex";
import { isEmpty } from "src/utils/common";
import PureModal from "src/components/shared/PureModal";
import { fetchFilesByFolder } from "src/api/presentations";
import {
  getRootPresentations,
  getChildrenFilesAction,
} from "src/state/presentations/actions";
import { RootState } from "src/state/rootReducer";
import folderIcon from "src/assets/images/folder-small.svg";
import selectedFolderGrey from "src/assets/images/selected-folder-grey.svg";
import presentationFile from "src/assets/images/slides.svg";
import refreshIcon from "src/assets/images/time.svg";
import { ILab } from "src/state/labs/reducer";
import { getLabs } from "src/state/labs/actions";
import Select from "react-select";
import Templates from "src/containers/admin/labs/ManageLab/Templates";

type ManageLabProps = {};

const MAX_IMG_SIZE = 1024 * 1024 * 3;

const newLab = {
  labName: "",
  labKey: "",
  labTemplate: "",
  labDescription: "",
  labAbout: "",
  isApprovalRequired: false,
  subdescription: "Approval request sent.",
  labImage: "" as any,
  labConsoleAccessVM: "",
  labProviderType: "SKYTAP",
  labSwitchVMs: [],
  vraProjectId: "",
  vraVersionId: "",
  vdcId: "",
  vdcName: "",
  selectedStoryline: { name: "", id: -1 },
  latestStoryline: false,
  labStoryline: -1,
  sortOrderWt: "",
  labPresentation: {} as any,
  tags: [] as any,
  tagName: "",
  duplicateTag: "",
  customerInfoRequired: false,
  labUsageTimeout: true,
  labUsageTimeoutValue: "4",
  allowExtentions: false,
  allowExtentionHours: "",
  accessList: [] as any,
  voucherConfigList: [] as any,
  isActive: false,
  preLaunch: false,
};

const defaultBreadcrumb = {
  id: "",
  name: "",
  type: "Folder",
};

const ManageLab: React.FC<ManageLabProps> = () => {
  const history = useHistory();
  const { labId } = useParams();

  const dispatch = useDispatch();
  const [VCDPolicies, setVCDPolicies] = useState([] as any);

  const [skyTapLabTemplates, setSkyTapLabTemplates] = useState({
    templates: [] as any,
    initialized: false,
  });
  const [vcdLabTemplates, setVcdLabTemplates] = useState({
    templates: [] as any,
    initialized: false,
  });
  const [vraLabTemplates, setVraLabTemplates] = useState({
    templates: [] as any,
    initialized: false,
  });

  const [consoleVms, setConsoleVms] = useState([] as any);
  const [storylines, setStorylines] = useState([] as any);
  const [labDetails, setLabDetails] = useState(newLab);
  const [selectedStoryline, setSelectedStoryline] = useState(
    labDetails.selectedStoryline
  );
  const [showLabPage, setShowLabPage] = useState(labId ? false : true);
  const [showPresentationModal, setShowPresentationModal] = useState(false);
  const [breadCrumbs, setBreadCrumbs] = useState([defaultBreadcrumb]);
  const [currentBreadcrumb, setCurrentBreadcrumb] = useState(defaultBreadcrumb);
  const [selectedPresentation, setSelectedPresentation] = useState({} as any);

  const labs: ILab[] = useSelector((state: RootState) => state.labs.list);
  const presentations: Array<any> = useSelector(
    (state: RootState) => state.presentationList.list
  );

  const validationSchema = Yup.object({
    labName: Yup.string().required("Lab name is required"),
    labKey: Yup.string()
      .required("Lab alias is required")
      .test("labKey", "Lab with this alias already exists", (value) =>
        labs.every(
          (lab) => lab.alias !== value || Number(labId || -Infinity) === lab.id
        )
      ),
    labTemplate: Yup.string().required("Lab template is required"),
    labConsoleAccessVM: Yup.string().required("Lab Console is required"),
    vdcName: Yup.string().test(
      "vcdPolicy",
      "VCD Storage Policy is required",
      (value) =>
        labDetails?.labProviderType === "SKYTAP" ||
        labDetails?.labProviderType === "VRA" ||
        (!!value && labDetails?.labProviderType !== "SKYTAP")
    ),
    allowExtentions: Yup.boolean(),
    allowExtentionHours: Yup.string()
      .when("allowExtentions", {
        is: true,
        then: Yup.string().required("Extention Hours is required"),
      })
      .nullable(),
    labUsageTimeout: Yup.boolean(),
    labUsageTimeoutValue: Yup.string().when("labUsageTimeout", {
      is: true,
      then: Yup.string().required("Lab Usage timeout is required"),
    }),
    subdescription: Yup.string().when("isApprovalRequired", {
      is: true,
      then: Yup.string().required("Approval popup text is required"),
    }),
    sortOrderWt: Yup.number()
      .min(1, "Minimum weight should be greater than 0")
      .max(100, "Max weight should be less than/equal to 100")
      .integer({ message: "Order weight should be numeric" })
      .nullable(),
    selectedStoryline: Yup.object().test(
      "selectedStoryline",
      "Guide is required",
      (value) => !!value.name && value.name !== "Select" && value.id !== 0
    ),
    labStoryline: Yup.number()
      .test(
        "labStorylineId",
        "Guide Version is required",
        (value) => (!!value || value === 0) && value !== -1
      )
      .required("Guide is required")
      .integer({ message: "Guide id should be numeric" }),
    labPresentation: Yup.object()
      .test(
        "labPresentation",
        "Presentation is required",
        (value) => !isEmpty(value)
      )
      .required("Presentation is required"),
    labImage: Yup.mixed()
      .test(
        "fileSize",
        `File size is too large, max file size allowed is ${Math.trunc(
          MAX_IMG_SIZE / 1024 / 1024
        )} Mb`,
        (value) => (value && value.size ? value.size < MAX_IMG_SIZE : true)
      )
      .test(
        "fileType",
        "Unsupported image file format, supported formats are png/jpg/jpeg",
        (value) =>
          value && value.name
            ? !!value.name && SupportedImageFileFormats.exec(value.name)
            : true
      )
      .nullable(),
  });

  const getFilesByFolder = async (parent) => {
    const data = await fetchFilesByFolder(parent.id);
    if (data.length) {
      dispatch(getChildrenFilesAction(data));
    } else {
      dispatch(getChildrenFilesAction([]));
    }
    const { id, name, type } = parent;
    const newBreadcrumbItem = { id, name, type };
    const breadcrumbArr = [...breadCrumbs, newBreadcrumbItem];
    breadcrumbArr[0].name = "All";
    setBreadCrumbs(breadcrumbArr);
    setCurrentBreadcrumb(newBreadcrumbItem);
  };

  const loadPresentationsFromBreadcrumb = async (item) => {
    if (item.id) {
      const data = await fetchFilesByFolder(item.id);
      if (data.length) {
        const itemIdx = breadCrumbs.findIndex((val) => val.id === item.id);
        const breadcrumbArr = breadCrumbs.slice(0, itemIdx + 1);
        setBreadCrumbs(breadcrumbArr);
        setCurrentBreadcrumb(item);
        dispatch(getChildrenFilesAction(data));
      }
    } else {
      if (breadCrumbs.length > 1) {
        defaultBreadcrumb.name = "";
        setCurrentBreadcrumb(defaultBreadcrumb);
        setBreadCrumbs([defaultBreadcrumb]);
        dispatch(getRootPresentations());
      }
    }
  };

  const getBreadcrumbs = () =>
    breadCrumbs.map((val) => (
      <>
        <span
          className={
            val.name === currentBreadcrumb.name
              ? "breadcrumb-label"
              : "breadcrumb-label color-primary"
          }
          onClick={() => {
            setSelectedPresentation({});
            loadPresentationsFromBreadcrumb(val);
          }}
        >
          {val.name}
        </span>
        {breadCrumbs.indexOf(val) < breadCrumbs.length - 1 && (
          <img
            height="10"
            width="10"
            src={rightArrow}
            alt="InfoIcon"
            className="mr-1 ml-1 mb-1 color-primary"
          />
        )}
      </>
    ));

  const getStorylines = async () => {
    const storylines = await fetchStorylines();
    const activeStorylineVersions = getActiveVersions(
      mapVersions(mapStorylinesToVersion(storylines))
    );
    setStorylines(activeStorylineVersions);
    return activeStorylineVersions;
  };

  const getTemplates = async (cache: boolean, provider: string) => {
    const data = await fetchLabTemplates(provider, cache);
    let templates = data.templates;
    let storagePolicies = [];
    if (templates?.length > 0) {
      const mappedTemplates = mapTemplate(templates);
      if (provider === "SKYTAP" && !skyTapLabTemplates.initialized) {
        setSkyTapLabTemplates({
          templates: mappedTemplates,
          initialized: true,
        });
      } else if (provider === "VCD" && !vcdLabTemplates.initialized) {
        setVcdLabTemplates({ templates: mappedTemplates, initialized: true });
      } else if (provider === "VRA" && !vraLabTemplates.initialized) {
        setVraLabTemplates({ templates: mappedTemplates, initialized: true });
      }
      templates = mappedTemplates;
    }

    if (
      provider === "VCD" &&
      !!data.vdc_storages &&
      data.vdc_storages instanceof Array
    ) {
      storagePolicies = toCamelCaseKeys(data.vdc_storages).filter(
        (storage) => !isEmpty(storage)
      );
      setVCDPolicies(storagePolicies);
    }

    return { templates, storagePolicies };
  };

  useEffect(() => {
    const fetchLabDetails = async () => {
      const storylines = await getStorylines();
      if (labId) {
        const data = await getLabDetails(labId);
        if (data) {
          const labDetails = toCamelCaseKeys(data);
          const matchedStoryline = storylines.find(
            (storyline) => storyline.id === labDetails.storyline.id
          );
          const selectedStoryline = {
            name: matchedStoryline?.name,
            id: matchedStoryline?.parent,
          };
          setSelectedStoryline(selectedStoryline);

          const { templates, storagePolicies } = await getTemplates(
            true,
            labDetails?.labProviderType
          );

          const consoleVms = (templates as any).find(
            (template) => template.id === data.lab_provider_config?.template_id
          )?.vms;

          let mappedLapDetails = {
            ...mapLabDetails(
              labDetails,
              templates,
              consoleVms,
              storagePolicies
            ),
            selectedStoryline,
          };

          setConsoleVms(consoleVms);
          setLabDetails(mappedLapDetails);
          setShowLabPage(true);
        }
      } else {
        await getTemplates(true, labDetails?.labProviderType);
      }
    };

    fetchLabDetails();
    dispatch(usersAction());
    dispatch(getGroups());
    dispatch(getCompanies());
    dispatch(getRootPresentations());
    if (labs.length === 0) {
      dispatch(getLabs());
    }
  }, []);

  const getTags = (formik) => {
    const tags = [...formik.values.tags];
    return tags.length
      ? tags.map((tag, id) => (
          <div key={id} className="tags-pill lab-tags mt-2 mr-1">
            <span className="mr-3">{tag}</span>
            <img
              height="10"
              width="10"
              src={CloseIcon}
              alt="CloseIcon"
              className="delete-tag"
              onClick={() => {
                const tagsArr = [...formik.values.tags];
                tagsArr.splice(tagsArr.indexOf(tag), 1);
                formik.setFieldValue("tags", tagsArr);
              }}
            />
          </div>
        ))
      : null;
  };

  const addTags = (formik, value, tags) => {
    if (tags.indexOf(value) === -1) {
      tags.push(value);
      formik.setFieldValue("tagName", "");
      formik.setFieldValue("tags", tags);
      formik.setFieldValue("duplicateTag", "");
    } else {
      formik.setFieldValue("duplicateTag", value);
    }
  };

  const onSubmitHandle = async (lab, { setSubmitting }) => {
    setSubmitting(true);

    let uploadedLabImg;
    if (lab?.labImage && lab.labImage?.name) {
      const formData = new FormData();
      formData.append("upload", lab.labImage);
      uploadedLabImg = await uploadLabImage(formData);
    }

    const payload = getPayload(
      lab,
      lab?.labProviderType === "SKYTAP"
        ? skyTapLabTemplates.templates
        : lab?.labProviderType === "VCD"
        ? vcdLabTemplates.templates
        : vraLabTemplates.templates,
      consoleVms,
      uploadedLabImg ? uploadedLabImg.url : lab.labImage
    );

    let response;
    if (labId) {
      response = await saveAdminLab(payload, labId);
    } else {
      response = await createAdminLab(payload);
    }

    setSubmitting(false);
    if (!!response && !response.isAxiosError) {
      history.push(`${ADMIN_ROUTE}${ADMIN_LABS_ROUTE}`);
    }
  };

  const scrollToError = () => {
    setTimeout(function () {
      const errorElementList = document.getElementsByClassName("error-message");
      if (errorElementList.length) {
        errorElementList[0].scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }, 1000);
  };

  const uniqueStorylines = storylines?.reduce(
    (allStorylines, currStoryline) => {
      if (
        !allStorylines.some(
          (storyline) =>
            storyline.id === currStoryline.parent &&
            storyline.name === currStoryline.name
        )
      ) {
        allStorylines.push({
          name: currStoryline.name,
          id: currStoryline.parent,
        });
      }
      return allStorylines;
    },
    []
  );

  const storylineVersions = storylines
    .filter(
      (storyline) =>
        selectedStoryline.name === storyline.name &&
        selectedStoryline.id === storyline.parent
    )
    .sort((v1, v2) => v1.versionCode - v2.versionCode >= 0);

  const getGuideVersionBaseOptions = (selected) => {
    const guideVersionBaseOptions = [
      <option key="default_option">Select</option>,
    ];
    if (selectedStoryline.name !== "Select") {
      guideVersionBaseOptions.push(
        <option key="latest" id="latest" value="latest" selected={selected}>
          Latest
        </option>
      );
    }
    return guideVersionBaseOptions;
  };

  return showLabPage ? (
    <div className="labs-manage-section d-flex flex-column">
      <div className={`labs-list-section-header mb-3`}>
        <b>
          <Link className="group-user-header" to="/admin/labs">
            Labs
          </Link>
        </b>
        <img
          height="15"
          width="15"
          src={rightArrow}
          alt="InfoIcon"
          className="mr-1 ml-1"
        />
        <span className="h4">{labId ? `Edit Lab` : `Create Lab`}</span>
      </div>
      <Formik
        initialValues={labDetails}
        validationSchema={validationSchema}
        onSubmit={onSubmitHandle}
      >
        {(formik) => (
          <Form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <FormLayout headerImage={labAccess} headerTitle="Lab Configuration">
              <PureModal
                title={"Select presentation"}
                className="presentation-modal"
                showModal={showPresentationModal}
                closeModal={() => {
                  setSelectedPresentation({});
                  setShowPresentationModal(false);
                }}
                primaryBtnLabel="Select"
                primaryBtnAction={() => {
                  formik.setFieldTouched("labPresentation");
                  formik.setFieldValue("labPresentation", selectedPresentation);
                  setShowPresentationModal(false);
                }}
                secondaryBtnLabel="Cancel"
                secondaryBtnAction={() => {
                  setSelectedPresentation({});
                  setShowPresentationModal(false);
                }}
                disablePrimaryBtn={isEmpty(selectedPresentation)}
              >
                <div className="ml-1 mb-2">{getBreadcrumbs()}</div>
                {breadCrumbs.length > 1 && (
                  <div className="ml-1 pl-2 pt-1 h-2 separator presentation-selected">
                    <img
                      src={selectedFolderGrey}
                      alt="selectedFolderGrey"
                      width="16"
                      height="16"
                    />
                    <span className="disable-color ml-2 ">
                      {breadCrumbs[breadCrumbs.length - 1].name}
                    </span>
                  </div>
                )}
                {presentations.map((presentation) => {
                  return (
                    <ul className="pl-1 mb-0">
                      {presentation.type.toLowerCase() === "folder" ? (
                        <span>
                          <div
                            className="h-2 pt-1 pl-2 color-primary separator"
                            onClick={(e) => {
                              setSelectedPresentation({});
                              getFilesByFolder(presentation);
                            }}
                          >
                            <img
                              src={folderIcon}
                              alt="folderIcon"
                              width="16"
                              height="16"
                            />
                            <span className="color-primary ml-2 cursor-pointer">
                              {presentation.name}
                            </span>
                          </div>
                        </span>
                      ) : (
                        <div
                          className={
                            presentation.id === selectedPresentation.id
                              ? "separator h-2 pt-1 pl-2 cursor-pointer presentation-selected color-primary"
                              : "separator h-2 pt-1 pl-2 cursor-pointer color-primary"
                          }
                          onClick={() => {
                            if (isEmpty(selectedPresentation)) {
                              setSelectedPresentation(presentation);
                            } else {
                              setSelectedPresentation({});
                            }
                          }}
                        >
                          <img
                            src={presentationFile}
                            alt="fileIcon"
                            width="12"
                            height="12"
                          />
                          <span className="ml-2">{presentation.name}</span>
                        </div>
                      )}
                    </ul>
                  );
                })}
              </PureModal>
              <Form className="pt-4 pl-5">
                <div className="lab-container">
                  <div className="">
                    <Form.Group className="lab-container-inner align-items-center mb-4">
                      <Form.Label>
                        Lab Name
                        <RequiredFieldSymbol />
                      </Form.Label>
                      <div>
                        <Form.Control
                          placeholder="Enter lab name"
                          type="text"
                          id="labName"
                          {...formik.getFieldProps("labName")}
                        />
                        {formik.touched.labName && formik.errors.labName && (
                          <InputErrorMessage
                            className={"error-message ml-1"}
                            errorMessage={formik.errors.labName || ""}
                          />
                        )}
                      </div>
                    </Form.Group>
                    <Form.Group className="lab-container-inner align-items-center mb-4">
                      <Form.Label>
                        Lab Alias
                        <CustomTooltip text="Lab alias would be used for external integration">
                          <img
                            height="15"
                            width="15"
                            src={InfoIcon}
                            alt="InfoIcon"
                            className="ml-1"
                          />
                        </CustomTooltip>
                        <RequiredFieldSymbol />
                      </Form.Label>
                      <div>
                        <Form.Control
                          type="text"
                          id="labKey"
                          {...formik.getFieldProps("labKey")}
                        />
                        {formik.touched.labKey && formik.errors.labKey && (
                          <InputErrorMessage
                            className={"error-message ml-1"}
                            errorMessage={formik.errors.labKey || ""}
                          />
                        )}
                      </div>
                    </Form.Group>
                    <Form.Group className="lab-container-inner">
                      <Form.Label className="mt-2">Lab Description</Form.Label>
                      <div>
                        <Form.Control
                          placeholder="Enter description"
                          id="labName"
                          as="textarea"
                          rows={6}
                          {...formik.getFieldProps("labDescription")}
                        />
                        {formik.touched.labDescription &&
                          formik.errors.labDescription && (
                            <InputErrorMessage
                              className={"error-message ml-1"}
                              errorMessage={formik.errors.labDescription || ""}
                            />
                          )}
                      </div>
                    </Form.Group>
                  </div>
                  <Form.Group className="lab-container-inner">
                    <Form.Label className="mt-2">Lab Image</Form.Label>
                    <div className="d-flex flex-column">
                      <DragAndDrop
                        value={
                          labDetails.labImage
                            ? labDetails.labImage
                            : formik.values.labImage
                        }
                        setInitialValue={() => {
                          setLabDetails({ ...labDetails, labImage: "" });
                          formik.setFieldValue("labImage", "");
                          formik.setFieldError("labImage", "");
                        }}
                        handleDragAndDrop={(files) => {
                          formik.setFieldTouched("labImage");
                          formik.setFieldValue("labImage", files[0]);
                        }}
                        handleFileBrowse={(event) => {
                          formik.setFieldTouched("labImage");
                          formik.setFieldValue(
                            "labImage",
                            event.target.files[0]
                          );
                        }}
                      ></DragAndDrop>
                      {formik.errors.labImage && formik.values.labImage && (
                        <InputErrorMessage
                          className="error-message ml-1"
                          errorMessage={formik.errors.labImage || ""}
                        />
                      )}
                    </div>
                  </Form.Group>
                </div>
                <div className="clearfix"></div>
                <div className="lab-container">
                  <Form.Group className="lab-container-inner">
                    <Form.Label className="mt-3">About Lab</Form.Label>
                    <div>
                      <Editor
                        initialContent={labDetails.labAbout}
                        updateEditorContent={(content) => {
                          formik.setFieldTouched("labAbout");
                          formik.setFieldValue("labAbout", content);
                        }}
                        width="100%"
                        height="180"
                      />
                    </div>
                  </Form.Group>
                  <div>
                    <Form.Group className="lab-container-inner mt-3">
                      <Form.Label>
                        Guide
                        <RequiredFieldSymbol />
                      </Form.Label>
                      <div>
                        <select
                          className="form-control"
                          defaultValue={formik.values.selectedStoryline.name}
                          onChange={(event) => {
                            const data = event.target.selectedOptions[0];
                            setSelectedStoryline({
                              id: +data.id,
                              name: data.value,
                            });
                            formik.setFieldTouched("selectedStoryline");
                            formik.setFieldValue("latestStoryline", false);
                            formik.setFieldValue("labStoryline", -1);
                            formik.setFieldValue(
                              "selectedStoryline",
                              !!data && { id: +data.id, name: data.value }
                            );
                          }}
                        >
                          {uniqueStorylines ? (
                            [
                              <option key="default_option">Select</option>,
                            ].concat(
                              uniqueStorylines?.map((story) => (
                                <option
                                  selected={
                                    story.id ===
                                    formik.values.selectedStoryline?.id
                                  }
                                  key={story.id}
                                  value={story.name}
                                  id={story.id}
                                >
                                  {story.name}
                                </option>
                              ))
                            )
                          ) : (
                            <option key="no_option">No Guides available</option>
                          )}
                        </select>
                        {formik.touched.selectedStoryline &&
                          formik.errors.selectedStoryline && (
                            <InputErrorMessage
                              className={"error-message ml-1"}
                              errorMessage={
                                formik.errors.selectedStoryline || ""
                              }
                            />
                          )}
                      </div>
                    </Form.Group>
                    <Form.Group className="lab-container-inner mt-3">
                      <Form.Label>
                        Guide Version
                        <RequiredFieldSymbol />
                      </Form.Label>
                      <div>
                        <select
                          className="form-control"
                          disabled={!formik.values.selectedStoryline.name}
                          defaultValue={
                            storylines.length &&
                            storylines.filter(
                              (val) => val.id === formik.values.labStoryline
                            ).version
                          }
                          onChange={(event) => {
                            const data = event.target.selectedOptions[0];
                            formik.setFieldTouched("labStoryline");
                            if (data.value === "latest") {
                              const storyline = storylineVersions[0];
                              formik.setFieldValue("latestStoryline", true);
                              formik.setFieldValue(
                                "labStoryline",
                                !!storyline?.id || Number(storyline?.id) === 0
                                  ? storyline?.id
                                  : -1
                              );
                            } else {
                              formik.setFieldValue("latestStoryline", false);
                              formik.setFieldValue(
                                "labStoryline",
                                !!data.id || Number(data.id) === 0
                                  ? data.id
                                  : -1
                              );
                            }
                          }}
                        >
                          {storylineVersions ? (
                            getGuideVersionBaseOptions(
                              formik.values.latestStoryline
                            ).concat(
                              storylineVersions.map((story) => (
                                <option
                                  selected={
                                    !formik.values.latestStoryline &&
                                    story.id === formik.values.labStoryline
                                  }
                                  key={story.id}
                                  value={story.version}
                                  id={story.id}
                                >
                                  {story.version}
                                </option>
                              ))
                            )
                          ) : (
                            <option key="no_option">No Guides available</option>
                          )}
                        </select>
                        {formik.touched.labStoryline &&
                          formik.errors.labStoryline && (
                            <InputErrorMessage
                              className={"error-message ml-1"}
                              errorMessage={formik.errors.labStoryline || ""}
                            />
                          )}
                      </div>
                    </Form.Group>
                    <Form.Group className="lab-container-inner mt-3">
                      <Form.Label>
                        Provider
                        <RequiredFieldSymbol />
                      </Form.Label>
                      <div>
                        <select
                          className="form-control"
                          defaultValue={labDetails.labProviderType}
                          onChange={async (event) => {
                            const data = event.target.selectedOptions[0];
                            formik.setFieldValue("labProviderType", data.value);
                            formik.setFieldValue("labTemplate", "");
                            formik.setFieldTouched("labTemplate");
                            formik.setFieldValue("labConsoleAccessVM", "");
                            if (
                              (data.value === "SKYTAP" &&
                                !skyTapLabTemplates.initialized) ||
                              (data.value === "VCD" &&
                                !vcdLabTemplates.initialized) ||
                              (data.value === "VRA" &&
                                !vraLabTemplates.initialized)
                            ) {
                              await getTemplates(true, data.value);
                            }
                          }}
                        >
                          <option
                            value="SKYTAP"
                            selected={
                              formik.values.labProviderType === "SKYTAP"
                            }
                          >
                            SkyTap
                          </option>
                          <option
                            value="VCD"
                            selected={formik.values.labProviderType === "VCD"}
                          >
                            CSC SOL vCD
                          </option>
                          <option
                            value="VRA"
                            selected={formik.values.labProviderType === "VRA"}
                          >
                            CSC SOL vRA
                          </option>
                        </select>
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="clearfix" />
                <Templates
                  formik={formik}
                  getTemplates={getTemplates}
                  setConsoleVms={setConsoleVms}
                  skyTapLabTemplates={skyTapLabTemplates}
                  consoleVms={consoleVms}
                  vcdLabTemplates={vcdLabTemplates}
                  VCDPolicies={VCDPolicies}
                  labDetails={labDetails}
                  vraLabTemplates={vraLabTemplates}
                />
                <div className="clearfix" />
                <div className="lab-container">
                  {formik.values.labProviderType === "SKYTAP" && (
                    <Form.Group className="lab-container-inner align-items-center">
                      <Form.Label>Switch VMs</Form.Label>
                      <Select
                        isMulti
                        classNamePrefix="react-select"
                        placeholder="Select"
                        isDisabled={!formik.values.labTemplate || !formik.values.labConsoleAccessVM}
                        value={formik.values.labSwitchVMs}
                        options={consoleVms?.filter(
                          (vm) => vm.name !== formik.values.labConsoleAccessVM
                        )
                          .map((vm) => ({
                            label: vm.name,
                            value: vm.name
                          }))}
                        onChange={(selectedVMs) => {
                          formik.setFieldTouched("labSwitchVMs");
                          formik.setFieldValue("labSwitchVMs", selectedVMs || []);
                        }}
                      />
                    </Form.Group>
                  )}
                  <Form.Group className="lab-container-inner align-items-center">
                    <Form.Label>
                      Presentation
                      <RequiredFieldSymbol />
                    </Form.Label>
                    <div>
                      <div className="d-flex align-items-baseline">
                        <Form.Label>
                          {!isEmpty(formik.values.labPresentation)
                            ? formik.values.labPresentation.name
                            : "No Presentation Selected"}
                        </Form.Label>
                        <Button
                          type="button"
                          size="sm"
                          variant="link"
                          className="ml-2 footer-btn"
                          onClick={() => {
                            setShowPresentationModal(true);
                          }}
                        >
                          Select
                        </Button>
                      </div>

                      {formik.touched.labPresentation &&
                        formik.errors.labPresentation && (
                          <InputErrorMessage
                            className={"error-message ml-1"}
                            errorMessage={formik.errors.labPresentation || ""}
                          />
                        )}
                    </div>
                  </Form.Group>
                </div>
                <div className="clearfix"></div>
                <div className="lab-container">
                  <Form.Group className="lab-container-inner align-items-center">
                    <Form.Label>Tags</Form.Label>
                      <div>
                        <Form.Control
                          placeholder="Fill tag name and hit enter"
                          type="text"
                          id="tagName"
                          {...formik.getFieldProps("tagName")}
                          onKeyPress={(event) => {
                            const inputValue = event.target.value;
                            if (!inputValue) {
                              return;
                            }
                            const value = inputValue.trim();
                            formik.setFieldValue("duplicateTag", "");
                            const tags = [...formik.values.tags];
                            if (event.which === 13 || event.keyCode === 13) {
                              addTags(formik, value, tags);
                            }
                          }}
                        />
                        {!!formik.values.duplicateTag && (
                          <InputErrorMessage
                            className={"error-message ml-1"}
                            errorMessage="Tag already present with same name"
                          />
                        )}
                      </div>
                      <CustomTooltip text="Click to add tag">
                        <img
                          height="15"
                          width="15"
                          src={addTagIcon}
                          alt="addTagIcon"
                          className={
                            formik.values.duplicateTag ? "mb-4 ml-2" : "mb-2 ml-2"
                          }
                          onClick={() => {
                            const tags = [...formik.values.tags];
                            const value = formik.values.tagName.trim();
                            !!value && addTags(formik, value, tags);
                          }}
                        />
                      </CustomTooltip>
                  </Form.Group>
                  <Form.Group className="lab-container-inner align-items-center">
                    <Form.Label>Sort Order Weight</Form.Label>
                    <div>
                      <Form.Control
                        placeholder="Enter weight"
                        type="text"
                        id="sortOrderWt"
                        {...formik.getFieldProps("sortOrderWt")}
                      />
                      {formik.errors.sortOrderWt && (
                        <InputErrorMessage
                          className={"error-message ml-1"}
                          errorMessage={formik.errors.sortOrderWt || ""}
                        />
                      )}
                    </div>
                    <div className="">
                      <CustomTooltip text="Enter weightage between 0 - 100">
                        <img
                          height="15"
                          width="15"
                          src={InfoIcon}
                          alt="InfoIcon"
                          className={
                            formik.errors.sortOrderWt
                              ? "sort-order-weight-error ml-n4"
                              : "ml-n4"
                          }
                        />
                      </CustomTooltip>
                    </div>
                  </Form.Group>
                  <div className="d-flex lab-tags-input">
                    {getTags(formik)}
                  </div>
                </div>
                <div className="clearfix"></div>
                <div className="lab-container">
                  <Form.Group className="lab-container-inner align-items-center">
                    <Form.Label>Lab Usage Timeout</Form.Label>
                    <Form.Check
                      className="cursor-pointer"
                      type="switch"
                      id="labUsageTimeout"
                      label=""
                      defaultChecked={formik.values.labUsageTimeout}
                      onChange={(event) => {
                        const isChecked = event.target.checked;
                        formik.setFieldValue("labUsageTimeout", isChecked);
                        // Check if the switch is disabled
                        if (!isChecked) {
                          // Check labProviderType
                          const maxTimeout = labDetails.labProviderType === "SKYTAP" ? "12" : "1080";
                          formik.setFieldValue("labUsageTimeoutValue", maxTimeout);
                        }
                      }}
                    />
                  </Form.Group>

                  <Form.Group className="lab-container-inner align-items-center">
                    <Form.Label>Lab Usage Timeout</Form.Label>
                    <div>
                      <Form.Control
                        placeholder="Enter hours"
                        type="text"
                        id="labUsageTimeoutValue"
                        disabled={!formik.values.labUsageTimeout}
                        {...formik.getFieldProps("labUsageTimeoutValue")}
                      />
                      {formik.errors.labUsageTimeoutValue && (
                        <InputErrorMessage
                          className="error-message ml-1"
                          errorMessage={
                            formik.errors.labUsageTimeoutValue || ""
                          }
                        />
                      )}
                      {/* {formik.values.labUsageTimeout &&
                        formik.touched.labUsageTimeoutValue &&
                        !formik.values.labUsageTimeoutValue && (
                          <InputErrorMessage
                            className={"error-message ml-1"}
                            errorMessage="Lab Usage timeout is required"
                          />
                        )} */}
                    </div>
                    <CustomTooltip text="Enter hours">
                      <img
                        height="15"
                        width="15"
                        src={InfoIcon}
                        alt="InfoIcon"
                        className={
                          !!formik.values.labUsageTimeout &&
                          // formik.touched.labUsageTimeoutValue &&
                          !formik.values.labUsageTimeoutValue
                            ? "ml-n4 mb-3"
                            : "ml-n4"
                        }
                      />
                    </CustomTooltip>
                  </Form.Group>
                </div>
                <div className="clearfix"></div>
                <div className="lab-container">
                  <Form.Group className="lab-container-inner align-items-center">
                    <Form.Label>Allow Extention</Form.Label>
                    <div>
                      <Form.Check
                        className="cursor-pointer"
                        type="switch"
                        id="allowExtentions"
                        label=""
                        defaultChecked={formik.values.allowExtentions}
                        onChange={(event) => {
                          const isChecked = event.target.checked;
                          formik.setFieldValue("allowExtentions", isChecked);
                        }}
                      />
                    </div>
                  </Form.Group>

                  <Form.Group className="lab-container-inner align-items-center">
                    <Form.Label>
                      Extention Hours <RequiredFieldSymbol />
                    </Form.Label>
                    <div>
                      <Form.Control
                        placeholder="Enter hours"
                        type="text"
                        id="allowExtentionHours"
                        {...formik.getFieldProps("allowExtentionHours")}
                        disabled={!formik.values.allowExtentions}
                      />

                      {formik.errors.allowExtentionHours && (
                        <InputErrorMessage
                          className="error-message ml-1"
                          errorMessage={formik.errors.allowExtentionHours || ""}
                        />
                      )}
                      {/* {!!formik.values.allowExtentions &&
                        // formik.touched.allowExtentionHours &&
                        !formik.values.allowExtentionHours && (
                          <InputErrorMessage
                            className={"error-message ml-1"}
                            errorMessage={"Extension hours is required" || ""}
                          />
                        )} */}
                    </div>
                    <CustomTooltip text="Enter hours">
                      <img
                        height="15"
                        width="15"
                        src={InfoIcon}
                        alt="InfoIcon"
                        className={
                          !!formik.values.allowExtentions &&
                          !formik.values.allowExtentionHours
                            ? "ml-n4 mb-3"
                            : "ml-n4"
                        }
                      />
                    </CustomTooltip>
                  </Form.Group>
                </div>
                <div className="clearfix"></div>
                <div className="lab-container">
                  <Form.Group className="lab-container-inner align-items-center">
                    <Form.Label>Activate Lab</Form.Label>
                    <Form.Check
                      className="cursor-pointer"
                      type="switch"
                      id="isActive"
                      label=""
                      defaultChecked={formik.values.isActive}
                      onChange={(event) => {
                        const isChecked = event.target.checked;
                        formik.setFieldValue("isActive", isChecked);
                      }}
                    />
                  </Form.Group>
                  <Form.Group className="lab-container-inner align-items-center">
                    <Form.Label>Customer Info Required</Form.Label>
                    <Form.Check
                      className="cursor-pointer"
                      type="switch"
                      id="customerInfoRequired"
                      label=""
                      defaultChecked={formik.values.customerInfoRequired}
                      onChange={(event) => {
                        const isChecked = event.target.checked;
                        formik.setFieldValue("customerInfoRequired", isChecked);
                      }}
                    />
                  </Form.Group>
                  {/*<Form.Group className="lab-container-inner align-items-center">*/}
                  {/*  <Form.Label>Pre Launch Lab</Form.Label>*/}
                  {/*  <Form.Check*/}
                  {/*    className="cursor-pointer"*/}
                  {/*    type="switch"*/}
                  {/*    id="prelaunch-lab"*/}
                  {/*    label=""*/}
                  {/*    defaultChecked={formik.values.preLaunch}*/}
                  {/*    onChange={(event) => {*/}
                  {/*      const preLaunch = event.target.checked;*/}
                  {/*      formik.setFieldValue("preLaunch", preLaunch);*/}
                  {/*    }}*/}
                  {/*  />*/}
                  {/*</Form.Group>*/}
                </div>
                <div className="clearfix"></div>
                <div className="lab-container">
                  {formik.values.labProviderType === "VRA" && (
                    <>
                      <Form.Group className="lab-container-inner align-items-center">
                        <Form.Label>Approval Required</Form.Label>
                        <Form.Check
                          className="cursor-pointer"
                          type="switch"
                          id="is-approval-required"
                          label=""
                          defaultChecked={formik.values.isApprovalRequired}
                          onChange={(event) => {
                            const labApproved = event.target.checked;
                            formik.setFieldValue(
                              "isApprovalRequired",
                              labApproved
                            );
                          }}
                        />
                      </Form.Group>
                    </>
                  )}
                  {formik.values.isApprovalRequired && (
                    <Form.Group className="lab-container-inner">
                      <Form.Label className="mt-3">
                        Approval Popup Text
                      </Form.Label>
                      <div>
                        <Editor
                          initialContent={labDetails.subdescription}
                          updateEditorContent={(content) => {
                            formik.setFieldTouched("subdescription");
                            formik.setFieldValue("subdescription", content);
                          }}
                          width="100%"
                          height="180"
                        />
                        {formik.errors.subdescription && (
                          <InputErrorMessage
                            className="error-message ml-1"
                            errorMessage={formik.errors.subdescription || ""}
                          />
                        )}
                      </div>
                    </Form.Group>
                  )}
                </div>
              </Form>
            </FormLayout>
            <LabAccess
              accessList={formik.values.accessList}
              modifyAccessList={(item) => {
                const accessList = [...formik.values.accessList];
                const itemIdx = accessList.findIndex(
                  (resource) => item.id === resource.id
                );
                if (itemIdx !== -1) {
                  accessList[itemIdx] = item;
                } else {
                  accessList.push(item);
                }
                formik.setFieldValue("accessList", accessList);
              }}
              deleteAccess={(type, id) => {
                const accessList = [...formik.values.accessList];
                remove(
                  accessList,
                  (access) => access.id === id && access.type === type
                );
                formik.setFieldValue("accessList", accessList);
              }}
            />
            <div className="mt-2">
              <VoucherConfig
                voucherConfigList={formik.values.voucherConfigList}
                modifyVoucherConfigList={(item) => {
                  const voucherConfigList = [
                    ...formik.values.voucherConfigList,
                  ];
                  const itemIdx = voucherConfigList.findIndex(
                    (resource) => item.id === resource.id
                  );
                  if (itemIdx !== -1) {
                    voucherConfigList[itemIdx] = item;
                  } else {
                    voucherConfigList.push(item);
                  }
                  formik.setFieldValue("voucherConfigList", voucherConfigList);
                }}
                deleteConfig={(type, id) => {
                  const voucherConfigList = [
                    ...formik.values.voucherConfigList,
                  ];
                  remove(
                    voucherConfigList,
                    (voucherConfig) =>
                      voucherConfig.id === id && voucherConfig.type === type
                  );
                  formik.setFieldValue("voucherConfigList", voucherConfigList);
                }}
              />
            </div>
            <div className="d-flex justify-content-end mt-2">
              {/* <div className="btn btn-outline-primary btn-sm footer-btn">
                <Link
                  className="pure-link-decoration-none"
                  to={`${ADMIN_ROUTE}${ADMIN_LABS_ROUTE}`}
                >
                  Cancel
                </Link>
              </div> */}
              <Button
                type="submit"
                size="sm"
                variant="outline-secondary"
                className="footer-btn lab-cancel color-primary border-primary"
                onClick={() => {
                  history.push(`${ADMIN_ROUTE}${ADMIN_LABS_ROUTE}`);
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                size="sm"
                variant="primary"
                className="ml-2 footer-btn"
                onClick={() => {
                  formik.handleSubmit();
                  scrollToError();
                }}
              >
                Save
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  ) : null;
};

export default ManageLab;
