import { sortByDate, sortColumn } from "src/utils/common";
import React from "react";

export const auditData = () => ({
  table: {
    title: "List of All Users with a Summary of Login Activity",
    columns: [
      {
        Header: "Email Address",
        accessor: "email",
      },
      {
        Header: "Name of User",
        accessor: "name",
      },
      {
        Header: "Company",
        id: "company",
        accessor: (row) => row.company,
        sortType: (rowA, rowB, colId, desc) =>
          sortColumn(rowA, rowB, "Company", desc),
      },
      {
        Header: "User Type",
        accessor: "type",
      },
      {
        Header: "Last Login",
        accessor: "lastLogin",
        sortType: (rowA, rowB, colId, desc) => sortByDate(rowA, rowB, desc),
      },
    ],
  },
});

export const allColumns = [
  {
    Header: "Email Address",
    id: "email",
    exportValue: "Email Address",
  },
  {
    Header: "Name of User",
    id: "name",
    exportValue: "Name of User",
  },
  {
    Header: "Company",
    id: "company",
    exportValue: "Company",
  },
  {
    Header: "User Type",
    id: "type",
    exportValue: "User Type",
  },
  {
    Header: "Last Login",
    id: "lastLogin",
    exportValue: "Last Login",
  },
  {
    Header: "Login Count Total",
    id: "loginCount.total",
    exportValue: "Login Count Total",
  },
  {
    Header: "Login Count Last 30 Days",
    id: "loginCount.lastThirtyDays",
    exportValue: "Login Count Last 30 Days",
  },
  {
    Header: "Login Count Last 30 - 60 Days",
    id: "loginCount.lastThirtySixtyDays",
    exportValue: "Login Count 30-60 Days Ago",
  },
  {
    Header: "Login Count Last 60 - 90 Days",
    id: "loginCount.lastSixtyNinetyDays",
    exportValue: "Login Count 60-90 Days Ago",
  },
  {
    Header: "Instance Count Total",
    id: "launchedLabsCount.total",
    exportValue: "Instance Count (Total)",
  },
  {
    Header: "Instance Count Last 30 Days",
    id: "launchedLabsCount.lastThirtyDays",
    exportValue: "Instance Count (Total) Last 30 Days",
  },
  {
    Header: "Instance Count Last 30 - 60 Days",
    id: "launchedLabsCount.lastThirtySixtyDays",
    exportValue: "Instance Count (Total) 30-60 Days Ago",
  },
  {
    Header: "Instance Count Last 60 - 90 Days",
    id: "launchedLabsCount.lastSixtyNinetyDays",
    exportValue: "Instance Count (Total) 60-90 Days Ago",
  },
];
