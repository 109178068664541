import { toCamelCaseKeys } from "../common";

export const mapPresentations = (presentations = [] as any) =>
  presentations.length &&
  presentations
    .map(toCamelCaseKeys)
    .map((presentation: any) => mapPresentation(presentation));

export const mapPresentation = (presentation) => {
  let presentationList = { ...presentation };
  presentationList.subRows = presentation.children || [];
  return presentationList;
};

export const addFilesToList = (file, list) => [mapPresentation(file), ...list];
