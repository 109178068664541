import { useLayoutEffect, useState } from "react";

// const throttle = (func, delay) => {
//   let inProgress = false;
//   return (...args) => {
//     if (inProgress) {
//       return;
//     }
//     inProgress = true;
//     func(...args); // Consider moving this line before the set timeout if you want the very first one to be immediate
//     setTimeout(() => {
//       inProgress = false;
//     }, delay);
//   };
// };

// debouncing
const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);

  useLayoutEffect(() => {
    let timer;
    function updateSize() {
      clearTimeout(timer);
      timer = setTimeout(
        () => setSize([window.innerWidth, window.innerHeight]),
        1000
      );
    }
    window.addEventListener("resize", updateSize);
    updateSize();

    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
};

// throttling
// function useWindowSize() {
//   const [size, setSize] = useState([0, 0]);
//
//   useLayoutEffect(() => {
//     const handleResize = throttle(
//       () => setSize([window.innerWidth, window.innerHeight]),
//       3000
//     );
//     window.addEventListener("resize", handleResize);
//
//     return () => window.removeEventListener("resize", handleResize);
//   }, []);
//   return size;
// }

export default useWindowSize;
