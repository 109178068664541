import React from "react";
import addUser from "src/assets/images/add-user.svg";
import viewUser from "src/assets/images/view-user.svg";
import edit from "src/assets/images/edit.svg";
import deleteIcon from "src/assets/images/delete.svg";
import { Link } from "react-router-dom";
import { ADMIN_ROUTE, GROUPS_ROUTE } from "src/constants/appRoutes";
import CustomTooltip from "src/components/ui/CustomTooltip";
import { capitalizeFirstLetter } from "src/helpers/common";

export const getGroupColumns = (editGroup, deleteGroup, addGroupUser) => [
  {
    Header: "Group Name",
    accessor: "name",
    Cell: ({ row }) => {
      return (
        <div>
          {row.original.name}
          {row.original.isSystem && (
            <span className="system_group"> (System Defined)</span>
          )}
        </div>
      );
    },
    percentWidth: 35,
  },

  {
    Header: "Role",
    accessor: "groupRole",
    percentWidth: 20,
    Cell: ({ value }) => {
      return <>{capitalizeFirstLetter(value)}</>;
    },
  },
  {
    Header: "Total Users",
    percentWidth: 15,
    accessor: "userCount",
  },
  {
    Header: "No. of Labs Assigned",
    accessor: "assignedLabsCount",
    percentWidth: 20,
  },
  {
    Header: "Action",
    disableSortBy: true,
    accessor: (row) => {
      return (
        <div className="d-flex" title="">
          <div className="action-icon p-0">
            <CustomTooltip text="Add User">
              <img
                onClick={() => addGroupUser(row)}
                src={addUser}
                alt="addUserIcon"
              />
            </CustomTooltip>
          </div>
          <Link
            to={{
              pathname: `${ADMIN_ROUTE}${GROUPS_ROUTE}/${row.id}/users`,
              state: row.id,
            }}
            className="ml-2 action-icon p-0"
            title="Show Users"
          >
            <CustomTooltip text="Show Users">
              <img src={viewUser} alt="viewUserIcon" />
            </CustomTooltip>
          </Link>
          {!row.isSystem && (
            <>
              <div className="ml-2 action-icon">
                <CustomTooltip text="Edit Group">
                  <img
                    onClick={() => editGroup(row)}
                    src={edit}
                    alt="editIcon"
                  />
                </CustomTooltip>
              </div>
              <div className="ml-2 action-icon">
                <CustomTooltip text="Delete Group">
                  <img
                    onClick={() => deleteGroup(row)}
                    src={deleteIcon}
                    alt="deleteIcon"
                  />
                </CustomTooltip>
              </div>
            </>
          )}
        </div>
      );
    },
    percentWidth: 15,
  },
];
