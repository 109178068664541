import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={32}
      viewBox="0 0 32 32"
      {...props}
    >
      <g data-name="Group 3744">
        <g data-name="Rectangle 2698" fill="#fff" stroke="#cecece">
          <rect width={32} height={32} rx={4} stroke="none" />
          <rect x={0.5} y={0.5} width={31} height={31} rx={3.5} fill="none" />
        </g>
        <path
          data-name="Path 1"
          d="M19.155 16.033l-4.99-4.991a.615.615 0 00-.868 0l-.367.368a.614.614 0 000 .868l4.19 4.185-4.195 4.2a.615.615 0 000 .868l.367.367a.615.615 0 00.868 0l4.995-4.995a.619.619 0 000-.871z"
          fill="#cecece"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
