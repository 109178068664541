const data = [
  { email: "abc@yml.com", created: "12 Mar 2020", state: "Launched" },
  { email: "abc@yml.com", created: "12 Mar 2020", state: "Launched" },
  { email: "abc@yml.com", created: "12 Mar 2020", state: "Launched" },
  { email: "abc@yml.com", created: "12 Mar 2020", state: "Launched" },
  { email: "abc@yml.com", created: "12 Mar 2020", state: "Launched" },
  { email: "abc@yml.com", created: "12 Mar 2020", state: "Launched" },
  { email: "abc@yml.com", created: "12 Mar 2020", state: "Launched" },
  { email: "abc@yml.com", created: "12 Mar 2020", state: "Launched" },
  { email: "abc@yml.com", created: "12 Mar 2020", state: "Launched" },
];
export default data;
