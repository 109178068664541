import Api from "src/helpers/request";
import {
  formatDateToLocal,
  toCamelCase,
  toCamelCaseKeys,
} from "src/helpers/common";
import { sortMonthlyStats } from "./index";
import { pageSize } from "src/constants/dashboard";

const getUserLogins = "dashboard/activity/user_audit";
const getLoginStats = "dashboard/activity/user_audit/stats";

const getStats = (monthlyStats) => {
  const keys = Object.keys(monthlyStats).sort(sortMonthlyStats);
  let year;
  let stats: any = {};
  return keys.map((key) => {
    stats = {};
    year = key.substr(-2);
    stats.year = year;
    stats.name = `${toCamelCase(key.substr(0, 3))}'${year}`;
    monthlyStats[key].forEach((stat) => {
      if (stat.userTypeName === "corporate") {
        stats.Corporate = stat.count;
      } else if (stat.userTypeName === "adhoc") {
        stats.Adhoc = stat.count;
      } else if (stat.userTypeName === "partner") {
        stats.Partner = stat.count;
      }
    });
    stats.Corporate = stats.Corporate || 0;
    stats.Adhoc = stats.Adhoc || 0;
    stats.Partner = stats.Partner || 0;
    return stats;
  });
};

const mapLogins = (logins) =>
  logins.map((login) => ({
    email: login?.user?.email || "-",
    name: login?.user?.name || "-",
    company: toCamelCase(login.user?.company?.name) || "-",
    ip: login?.ipAddress || "-",
    createdOn: login.createdOn ? formatDateToLocal(login.createdOn) : "-",
    agent: login.agent || "-",
  }));

export const fetchLoginStats = async (
  from?: string,
  to?: string,
  metaConfig = { showLoader: true }
) => {
  try {
    let url;
    if (from && to) {
      url = `${getLoginStats}?created_on_gte=${from}&created_on_lte=${to}`;
    } else {
      url = getLoginStats;
    }
    let response = await Api.call({ url, method: "GET" }, metaConfig);
    response = toCamelCaseKeys(response);
    let loginStats: any = {};
    loginStats.userStats = {
      corporate: response.userStats.corporate || 0,
      adhoc: response.userStats.adhoc || 0,
      partner: response.userStats.partner || 0,
    };
    loginStats.userAuditStats = {
      corporate: response.userAuditStats.corporate || 0,
      adhoc: response.userAuditStats.adhoc || 0,
      partner: response.userAuditStats.partner || 0,
      adhocInvited: response.totalInvited || 0,
    };
    loginStats.barChartStats = getStats(response.monthlyStats);
    return loginStats;
  } catch (e) {
    console.error(e);
  }
};

export const fetchAllUserLogins = async (
  searchQuery?,
  from?,
  to?,
  metaConfig = { showLoader: true }
) => {
  try {
    let url = `${getUserLogins}?pagination=false`;
    if (from && to) {
      url += `&created_on_gte=${from}&created_on_lte=${to}`;
    }
    if (searchQuery) {
      url += `&search=${searchQuery}`;
    }
    const response = await Api.call({ url, method: "GET" }, metaConfig);
    const logins = toCamelCaseKeys(response);
    return mapLogins(logins);
  } catch (e) {
    console.error(e);
  }
};

export const fetchUserLogins = async (
  offset?: number | null,
  searchQuery?,
  from?,
  to?,
  metaConfig = { showLoader: true }
) => {
  try {
    let url = getUserLogins;
    if (offset || offset === 0) {
      url += `?limit=${pageSize}&offset=${offset}`;
    }
    if (from && to) {
      url += `&created_on_gte=${from}&created_on_lte=${to}`;
    }
    if (searchQuery) {
      url += `&search=${searchQuery}`;
    }
    let response = await Api.call({ url, method: "GET" }, metaConfig);
    response = toCamelCaseKeys(response);
    const logins = mapLogins(response.results);
    return { totalItems: response.count, items: logins };
  } catch (e) {
    console.error(e);
  }
};
