import React from "react";
import "./AuthLayout.scss";
import auth from "src/assets/images/auth.png";
import Login from "src/components/Login";
import { RouteComponentProps } from "react-router-dom";
import { Route, Switch, Redirect } from "react-router-dom";
import ForgotPassword from "src/components/ForgotPassword";
import { LOGIN_ROUTE, FORGOT_PASSWORD_ROUTE } from "src/constants/appRoutes";
import TermsAndPrivacySection from "src/components/TermsAndPrivacySection";

type AuthLayoutProps = {} & RouteComponentProps;

const AuthLayout: React.FC<AuthLayoutProps> = (props) => {
  return (
    <div className="auth-layout d-flex flex-row justify-content-around">
      <div className="left-section d-flex flex-column justify-content-center">
        <Switch>
          <Route path={LOGIN_ROUTE} component={Login} />
          <Route path={FORGOT_PASSWORD_ROUTE} component={ForgotPassword} />
          <Redirect to={LOGIN_ROUTE} />
        </Switch>
        <div className="terms-privacy-section mt-auto px-3 mb-4">
          <TermsAndPrivacySection />
        </div>
      </div>
      <div className="right-section">
        <img className="right-section-image w-100" src={auth} alt="auth" />
      </div>
    </div>
  );
};

export default AuthLayout;
