import axios, { AxiosRequestConfig } from "axios";
import { getLocalStorageItem } from "src/utils/common";
import { showLoaderAction, hideLoaderAction } from "src/state/ui/actions";
import store from "src/state/store";
import { pushNotification } from "src/components/ui/Notification/index";
import { LOGIN_ROUTE } from "src/constants/appRoutes";
import { setLocalStorageItem } from "src/utils/common";

export type MetaConfig = {
  showLoader?: boolean;
  noAuthhHeader?: boolean;
  showErrorNotification?: boolean;
  successNotificationMessage?: string;
  loadingMessage?: string;
};

class API {
  config: AxiosRequestConfig;
  constructor() {
    this.config = { baseURL: process.env.REACT_APP_BASE_URL };
  }

  async call(
    config: AxiosRequestConfig = {},
    metaConfig: MetaConfig = {
      showErrorNotification: true,
      successNotificationMessage: "",
    }
  ) {
    const {
      showLoader = false,
      showErrorNotification = true,
      successNotificationMessage = "",
      noAuthhHeader = false,
      loadingMessage = "",
    } = metaConfig;

    let headers = noAuthhHeader
      ? {}
      : {
          Authorization: `Bearer ${getLocalStorageItem("token")}`,
        };

    let axiosConfig: AxiosRequestConfig = {
      ...this.config,
      headers,
      ...config,
    };

    if (showLoader) {
      store.dispatch(showLoaderAction(loadingMessage));
    }

    let data = await axios
      .request(axiosConfig)
      .then(({ data }) => {
        successNotificationMessage &&
          pushNotification({
            type: "success",
            message: successNotificationMessage,
          });
        if (showLoader) {
          store.dispatch(hideLoaderAction());
        }

        return data ? data : { success: true };
      })
      .catch((error) => {
        const apiError =
          error.response && error.response.data
            ? { ...error.response.data, hasError: true }
            : { message: "Something went wrong! Please try again later" };

        let errorMsg =
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error[0];

        if (!errorMsg) {
          errorMsg = error?.response?.data?.name?.[0]
        }

        if (showLoader) {
          store.dispatch(hideLoaderAction());
        }

        if (error.response && error.response.status === 401) {
          setLocalStorageItem("token", "");
          setLocalStorageItem("lastLoginTimestamp", ``);
          setLocalStorageItem("showNotifications", "0");
          window.location.href = `${window.location.origin}${LOGIN_ROUTE}?${window.location.href}`;
        }
        if (
          //Show error unless we explicitly set showErrorNotification to false while calling `Api.call()`
          // metaConfig.showErrorNotification === undefined ||
          showErrorNotification
        ) {
          pushNotification({
            type: "error",
            message: errorMsg
              ? errorMsg
              : "Something went wrong! Please try again.",
          });
        }

        return apiError;
      });
    return data;
  }
}

export default new API();
