import React, { useState } from "react";
import "./LabAccess.scss";
import labAccess from "src/assets/images/labAccess.svg";
import FormLayout from "src/components/shared/FormLayout";
import ModifyResource from "./../ModifyResource";
import Table from "src/components/shared/Table";
import getLabAccessColumns from "./accessColumns";
import { Button } from "react-bootstrap";
import WarningModal from "src/components/shared/WarningModal";
import noLabAccess from "src/assets/images/no-lab-access.svg";

type LabAccessProps = {
  accessList: any[];
  modifyAccessList: (access) => void;
  deleteAccess: (accessType, id) => void;
};

const LabAccess: React.FC<LabAccessProps> = ({
  accessList,
  modifyAccessList,
  deleteAccess,
  ...props
}) => {
  const [selectedResource, setSelectedResource] = useState({});
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showAddGroupModal, setShowGroupModal] = useState(false);
  const [showAddCompanyModal, setShowCompanyModal] = useState(false);
  const toggleUserModal = () => setShowAddUserModal(!showAddUserModal);
  const toggleGroupModal = () => setShowGroupModal(!showAddGroupModal);
  const toggleCompanyModal = () => setShowCompanyModal(!showAddCompanyModal);

  const [deleteAccessId, setDeleteAccessId] = useState("");
  const [deleteAccessType, setDeleteAccessType] = useState("");
  const [showDeleteAccessWarning, setShowDeleteAccessWarning] = useState(false);

  const deleteLabAccess = (type, id) => {
    setDeleteAccessId(id);
    setDeleteAccessType(type);
    setShowDeleteAccessWarning(true);
  };

  const deleteAccessConfirm = () => {
    setShowDeleteAccessWarning(false);
    deleteAccess(deleteAccessType, deleteAccessId);
  };

  const getAccessNameById = (selectedAccessId, selectedAccessType) => {
    let access = accessList.find(
      (access) =>
        access.id === selectedAccessId && access.type === selectedAccessType
    );
    return access && access.name;
  };

  const toggleModal = (event) => {
    const resource = JSON.parse(event.target.dataset.resource);
    const { type } = resource;
    setSelectedResource(resource);
    if (type === "Group") {
      toggleGroupModal();
    } else if (type === "Company") {
      toggleCompanyModal();
    } else {
      toggleUserModal();
    }
  };

  const accessColumns = getLabAccessColumns(deleteLabAccess, toggleModal);

  return (
    <FormLayout headerImage={labAccess} headerTitle="Access">
      <div className="access-section">
        {accessList.length ? (
          <div>
            <div className="d-flex justify-content-center">
              <div className="width-95 pt-2 d-flex justify-content-end">
                <Button
                  type="submit"
                  size="sm"
                  variant="outline-primary"
                  className="btn-min-width"
                  onClick={() => {
                    setSelectedResource({ fieldType: "user" });
                    setShowAddUserModal(true);
                  }}
                >
                  Add User
                </Button>
                <Button
                  type="submit"
                  size="sm"
                  variant="outline-primary"
                  className="btn-min-width ml-2"
                  onClick={() => {
                    setSelectedResource({ fieldType: "group" });
                    setShowGroupModal(true);
                  }}
                >
                  Add Group
                </Button>
                <Button
                  type="submit"
                  size="sm"
                  variant="outline-primary"
                  className="btn-min-width ml-2"
                  onClick={() => {
                    setSelectedResource({ fieldType: "company" });
                    setShowCompanyModal(true);
                  }}
                >
                  Add Company
                </Button>
              </div>
            </div>
            <div className="d-flex justify-content-center ">
              <div className="mt-3 mb-5 width-95 custom-border">
                <Table
                  theadStyle={{ backgroundColor: "#f8f9fc" }}
                  columns={accessColumns}
                  data={accessList}
                  paginationOff
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="no-result pt-5 pb-5">
            <div className="d-flex flex-column align-items-center error">
              <img src={noLabAccess} alt="error" height="100px" />
              <p className="mb-0"> No Access Given </p>
              <p>
                Click on{" "}
                <a
                  href="javascript:void(0)"
                  className="underline"
                  onClick={() => {
                    setSelectedResource({ fieldType: "user" });
                    setShowAddUserModal(true);
                  }}
                >
                  Add User
                </a>{" "}
                ,{" "}
                <a
                  href="javascript:void(0)"
                  className="underline"
                  onClick={() => {
                    setSelectedResource({ fieldType: "group" });
                    setShowGroupModal(true);
                  }}
                >
                  Add Group
                </a>{" "}
                or{" "}
                <a
                  href="javascript:void(0)"
                  className="underline"
                  onClick={() => {
                    setSelectedResource({ fieldType: "company" });
                    setShowCompanyModal(true);
                  }}
                >
                  Add Company
                </a>
              </p>
            </div>
          </div>
        )}
        {showAddUserModal && (
          <ModifyResource
            selectedResource={selectedResource}
            modifyList={modifyAccessList}
            setShowModifyResourceModal={toggleUserModal}
          />
        )}
        {showAddGroupModal && (
          <ModifyResource
            selectedResource={selectedResource}
            modifyList={modifyAccessList}
            setShowModifyResourceModal={setShowGroupModal}
          />
        )}
        {showAddCompanyModal && (
          <ModifyResource
            selectedResource={selectedResource}
            modifyList={modifyAccessList}
            setShowModifyResourceModal={setShowCompanyModal}
          />
        )}
        {showDeleteAccessWarning && (
          <WarningModal
            title="Warning"
            onCancelAction={() => setShowDeleteAccessWarning(false)}
            onConfirmAction={deleteAccessConfirm}
          >
            <div className="ml-2">
              <div>Are you sure you want to delete</div>
              <div>
                <b>{getAccessNameById(deleteAccessId, deleteAccessType)}</b>{" "}
                Access?
              </div>
            </div>
          </WarningModal>
        )}
      </div>
    </FormLayout>
  );
};

export default LabAccess;
