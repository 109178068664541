import {
  ADD_GROUPS,
  ADD_GROUP_ROLES,
  DELETE_GROUP,
  FETCH_GROUP_DETAIL,
  DELETE_GROUP_USER,
} from "./reducer";
import { mapGroups, mapGroupRoles } from "src/helpers/groups/";
import { fetchGroups, fetchRoles, fetchGroupById } from "src/api/groups";
import { mapUsers } from "src/helpers/users";

export const getGroups = () => async (dispatch) => {
  let data = await fetchGroups();

  if (data) {
    let groups = mapGroups(data.results);
    dispatch({ type: ADD_GROUPS, payload: groups });
  }
};

export const getGroupRoles = () => async (dispatch) => {
  let data = await fetchRoles();

  if (data) {
    let groupRoles = mapGroupRoles(data);
    dispatch({ type: ADD_GROUP_ROLES, payload: groupRoles });
  }
};

export const deleteGroupAction = (groupId) => {
  return {
    type: DELETE_GROUP,
    payload: groupId,
  };
};

export const getGroupDetails = (groupId) => {
  return async (dispatch) => {
    let data = await fetchGroupById(groupId);
    if (data) {
      let users = mapUsers(data.users);
      let group = mapGroups([data]);
      group[0].users = users;
      dispatch({ type: FETCH_GROUP_DETAIL, payload: group[0] });
    }
  };
};

export const deleteGroupUserAction = (userId) => {
  return {
    type: DELETE_GROUP_USER,
    payload: userId,
  };
};
