import React, { useRef } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { IUser } from "src/state/user/reducer";
import { RootState } from "src/state/rootReducer";
import { useSelector } from "react-redux";
import { postTicket, uploadFile, uploadToS3 } from "src/api/ticket";
import crossIcon from "src/assets/images/cross.svg";
import InputErrorMessage from "src/components/shared/InputErrorMessage";
import "./SubmitTicket.scss";
import RequiredFieldSymbol from "src/components/ui/RequiredFieldSymbol";
import { isEmpty } from "src/utils/common";
import { pushNotification } from "src/components/ui/Notification";

type SubmitTicketProps = {};

const MAX_FILE_SIZE = 1024 * 1024 * 10;

const validationSchema = Yup.object({
  subject: Yup.string().required("Subject is required"),
  description: Yup.string().required("Description is required"),
  // attachments: Yup.mixed().test(
  //   "fileSize",
  //   `File size is too large, max file size allowed is ${Math.trunc(
  //     MAX_FILE_SIZE / 1024 / 1024
  //   )} Mb`,
  //   (files) => {
  //     let l = files?.find((file) => file.size > MAX_FILE_SIZE);
  //     return l;
  //   }
  // ),
});

const intitialValues = {
  subject: "",
  description: "",
  attachments: [],
};

const SubmitTicket: React.FC<SubmitTicketProps> = () => {
  const user: IUser = useSelector((state: RootState) => state.user);
  const fileInputRef: any = useRef();
  const uploadedFiles: Array<Object> = [];

  const fetchAttachmentsLink = async (attachments) => {
    for (const attachment of attachments) {
      const { url: signedUrl } = await uploadFile({
        file_name: attachment.name,
      });
      const url = new URL(signedUrl);
      const data = await uploadToS3(signedUrl, attachment);
      if (data.success) {
        uploadedFiles.push({
          filename: attachment.name,
          url: `${url.origin}${url.pathname}`,
        });
      }
    }
  };

  const submitTicket = async (
    ticket,
    { setFieldValue, setSubmitting, resetForm }
  ) => {
    setSubmitting(true);
    if (!!ticket.attachments) {
      await fetchAttachmentsLink(ticket.attachments);
    }

    const res = await postTicket({
      subject: ticket.subject,
      description: ticket.description,
      attachments: uploadedFiles,
    });

    if (res.success) {
      resetForm();
      setFieldValue("attachments", []);
      setSubmitting(false);
    }
  };

  return (
    <div className="mt-5 container submit-ticket">
      <h4 className="mb-3">
        <b> Submit Ticket </b>
      </h4>
      <div>
        <div className="submit-ticket-strip" />
        <div className="submit-ticket-container">
          <Formik
            initialValues={intitialValues}
            validationSchema={validationSchema}
            onSubmit={submitTicket}
          >
            {(formik: any) => (
              <Form onSubmit={formik.handleSubmit}>
                <Form.Group as={Row} className="mt-4">
                  <Form.Label column sm={2}>
                    Requester
                  </Form.Label>
                  <Col sm={6}>
                    <Form.Control
                      name="requester"
                      type="text"
                      disabled={true}
                      value={user.email}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mt-4">
                  <Form.Label column sm={2}>
                    Subject
                    <RequiredFieldSymbol />
                  </Form.Label>
                  <Col sm={6}>
                    <Form.Control
                      name="subject"
                      type="text"
                      placeholder="Enter subject"
                      {...formik.getFieldProps("subject")}
                    />
                    {formik.touched.subject && formik.errors.subject && (
                      <InputErrorMessage
                        errorMessage={formik.errors.subject || ""}
                      />
                    )}
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mt-4">
                  <Form.Label column sm={2}>
                    Description
                    <RequiredFieldSymbol />
                  </Form.Label>
                  <Col sm={6}>
                    <Form.Control
                      name="description"
                      as="textarea"
                      rows="6"
                      placeholder="Enter description"
                      {...formik.getFieldProps("description")}
                    />
                    {formik.touched.description &&
                      formik.errors.description && (
                        <InputErrorMessage
                          errorMessage={formik.errors.description || ""}
                        />
                      )}
                  </Col>
                </Form.Group>
                <div className="submit-ticket-files d-flex mb-2">
                  {formik.values.attachments.map(({ name, type }) => (
                    <div className="d-flex mr-4 submit-ticket-file center-child">
                      <span className="mr-2"> {name} </span>
                      <div className="center-child">
                        <img
                          className="cursor-pointer"
                          src={crossIcon}
                          alt="remove file"
                          onClick={() => {
                            const fileIdx = formik.values.attachments.findIndex(
                              (file: any) =>
                                file.name === name && file.type === type
                            );
                            formik.values.attachments.splice(fileIdx, 1);
                            formik.setFieldValue(
                              "attachments",
                              formik.values.attachments
                            );
                            let list = new DataTransfer();
                            formik.values.attachments.forEach((file) =>
                              list.items.add(file)
                            );
                            fileInputRef.current.files = list.files;
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <Form.File custom className="submit-ticket-file-input">
                  <Form.File.Label>
                    <span> + Attach a file </span>
                  </Form.File.Label>
                  <Form.File.Input
                    multiple
                    ref={fileInputRef}
                    onChange={(event: any) => {
                      let target = event.target;
                      const largeFiles =
                        [...target.files].length &&
                        [...target.files].find((f) => f.size > MAX_FILE_SIZE);

                      if (!isEmpty(largeFiles)) {
                        pushNotification({
                          type: "error",
                          timer: 5000,
                          message: `Cannot select files. File ${largeFiles.name} is larger than 10MB`,
                        });
                      }

                      formik.setFieldTouched("attachments");
                      formik.setFieldValue("attachments", [
                        ...Array.from(target.files).filter(
                          (file: any) =>
                            file.name !== largeFiles?.name &&
                            !formik.values.attachments.some(
                              ({ name, type }) =>
                                file.name === name && file.type === type
                            )
                        ),
                        ...formik.values.attachments,
                      ]);
                    }}
                  />
                </Form.File>
                {/* {console.log(
                  formik.errors.attachments,
                  formik.values.attachments
                )}
                {formik.errors.attachments &&
                  formik.values.attachments?.length && (
                    <InputErrorMessage
                      className="error-message ml-1"
                      errorMessage={formik.errors.attachments || ""}
                    />
                  )} */}
                <div className="submit-ticket-btns">
                  <Button type="submit">Submit</Button>
                </div>
                {/* Below submit button is temporary hack to stop above submit button from getting out of screen */}
                <div className="submit-ticket-btns visibility-hidden">
                  <Button type="submit">Submit</Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default SubmitTicket;
