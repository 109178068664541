import produce from "immer";
import { ILab } from "../labs/reducer";

export const GET_ADMIN_VOUCHERS_SUCCESS = "GET_ADMIN_VOUCHERS_SUCCESS";
export const ADD_ADMIN_VOUCHER_SUCCESS = "ADD_ADMIN_VOUCHER_SUCCESS";
export const ADD_ADMIN_VOUCHER_FAILURE = "ADD_ADMIN_VOUCHER_FAILURE";
export const GET_VOUCHER_LABS_SUCCESS = "GET_VOUCHER_LABS_SUCCESS";

export interface IAdminVouchers {
  list: Array<IAdminVoucher>;
  labList: Array<ILab>;
  adminVoucherSaveSuccessful: boolean;
  adminVoucherSaveFailure: boolean;
}

export interface IAdminVoucher {
  id: number;
  name: string;
  createdBy: {
    id: number;
    email: string;
    name: string;
  };
  createdOn: string;
  expiresOn: string;
  usageAllowed: number;
  usageCount: number;
  labAssigned: {
    id: number;
    name: string;
  };
  isUsed: boolean;
  isActive: boolean;
  status: boolean;
}

const initialState: IAdminVouchers = {
  list: [],
  labList: [],
  adminVoucherSaveSuccessful: false,
  adminVoucherSaveFailure: false,
};

export default (state = initialState, action): IAdminVouchers =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_ADMIN_VOUCHERS_SUCCESS:
        draft.list = action.payload;
        break;

      case ADD_ADMIN_VOUCHER_SUCCESS:
        draft.adminVoucherSaveSuccessful = action.payload;
        draft.adminVoucherSaveFailure = false;
        break;

      case ADD_ADMIN_VOUCHER_FAILURE:
        draft.adminVoucherSaveFailure = action.payload;
        draft.adminVoucherSaveSuccessful = false;
        break;

      case GET_VOUCHER_LABS_SUCCESS:
        draft.labList = action.payload;
        break;

      default:
        break;
    }
  });
