import React from "react";
import PureModal from "src/components/shared/PureModal";
import { Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import InputErrorMessage from "src/components/shared/InputErrorMessage";
import { useDispatch } from "react-redux";
import RequiredFieldSymbol from "src/components/ui/RequiredFieldSymbol";
import { IStoryline, UPDATE_WARNING_FLAG } from "src/state/storyline/reducer";
import { getPayload } from "src/helpers/stories";
import { updateStorylineVersionDetails } from "src/state/storyline/actions";

type EditStorylineNameProps = {
  setShowModal: (flag: boolean) => void;
  storyLine: IStoryline;
  cancelHandler: () => void;
};

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
});

const EditStorylineName: React.FunctionComponent<EditStorylineNameProps> = ({
  setShowModal,
  storyLine,
  cancelHandler,
  ...props
}) => {
  const dispatch = useDispatch();
  const onSubmitHandle = async (formik) => {
    let payload = getPayload(storyLine, "");
    payload.name = formik.name;
    payload.version = formik.name + "-v1";
    dispatch(updateStorylineVersionDetails(payload));
    dispatch({ type: UPDATE_WARNING_FLAG, payload: true });
    setShowModal(false);
  };

  return (
    <Formik
      initialValues={{ name: "" }}
      validationSchema={validationSchema}
      onSubmit={onSubmitHandle}
    >
      {(formik) => (
        <PureModal
          title={"Create Guide Name"}
          showModal={true}
          closeModal={() => setShowModal(false)}
          primaryBtnLabel={"Save"}
          primaryBtnAction={formik.handleSubmit}
          secondaryBtnLabel="Cancel"
          secondaryBtnAction={cancelHandler}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <Form.Group>
              <Form.Label>
                Name
                <RequiredFieldSymbol />
              </Form.Label>
              <Form.Control
                type="text"
                id="name"
                placeholder="Enter name"
                {...formik.getFieldProps("name")}
                isInvalid={!!(formik.touched.name && formik.errors.name)}
              />
              <Form.Control.Feedback type="invalid">
                <InputErrorMessage errorMessage={formik.errors.name || ""} />
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </PureModal>
      )}
    </Formik>
  );
};

export default EditStorylineName;
