import React, { RefAttributes } from "react";

import "./styles.scss";
import { cx } from "@emotion/css";

declare type BadgeVariant = "count" | "dot" | "neutral";
declare type BadgeStyle = "overlap" | "filled";

declare interface BadgeProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  items?: number;
  onClick?: () => void;
  variant?: BadgeVariant;
  badgeStyle?: BadgeStyle;
}

const VariantComponent: React.FC<Pick<BadgeProps, "variant" | "items">> = ({
  variant,
  items,
  children,
}) => {
  if (variant === "count") {
    return (
      <>
        <span
          className={cx("pure-badge-overlap pure-badge-count", {
            "d-none": items === 0,
          })}
        >
          {" "}
          {items}{" "}
        </span>
        {children}
      </>
    );
  } else if (variant === "dot") {
    return (
      <>
        <span className="pure-badge-overlap pure-badge-dot"> {items} </span>
        <div className="pure-badge-icon">{children}</div>
      </>
    );
  }
  return (
    <>
      <div className="pure-badge-icon">{children}</div>
    </>
  );
};

const Badge: React.ForwardRefExoticComponent<
  BadgeProps & RefAttributes<HTMLButtonElement>
> = React.forwardRef<HTMLButtonElement, BadgeProps>(
  ({ badgeStyle, items, variant = "neutral", children, ...rest }, ref) => {
    return (
      <button className="pure-badge" ref={ref} {...rest}>
        <VariantComponent items={items} variant={variant}>
          {children}
        </VariantComponent>
      </button>
    );
  }
);

export default Badge;
