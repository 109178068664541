import React from "react";
import { Card } from "react-bootstrap";
import styled from "styled-components";
import "./InfographicCard.scss";

export type TInfographicCardPropsType = {
  title: string;
  totalItems: number;
  icon: React.FunctionComponent;
  iconBackgroundColor?: string;
  iconFillColor?: string;
  width: string | number;
};

const InfographicCard = (props: TInfographicCardPropsType) => {
  const Icon = props.icon;
  const IconContainer = styled.div`
    background-color: ${props.iconBackgroundColor};
    & svg {
      height: 30px;
      width: 30px;
    }
    & svg path {
      fill: ${props.iconFillColor};
    }
  `;

  return (
    <Card
      border="light"
      className="infographic-card px-4"
      style={{ width: props.width }}
    >
      <Card.Body className="d-flex justify-content-between align-items-center">
        <div className="infographic-card-body">
          <Card.Text className="infographic-card-text">{props.title}</Card.Text>
          <Card.Text className="infographic-card-total">
            {props.totalItems}
          </Card.Text>
        </div>
        <IconContainer className="infographic-card-icon">
          <Icon />
        </IconContainer>
      </Card.Body>
    </Card>
  );
};

export default InfographicCard;
