import React, { useState, useEffect } from "react";
import PureModal from "src/components/shared/PureModal";
import { Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import InputErrorMessage from "src/components/shared/InputErrorMessage";
import "./ManageGroup.scss";
import { addGroup, editGroup } from "src/api/groups";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/state/rootReducer";
import RequiredFieldSymbol from "src/components/ui/RequiredFieldSymbol";
import { getGroups } from "src/state/groups/actions";
import { toCamelCase } from "src/helpers/common";

type ManageGroupProps = {
  setShowManageGroupModal: (boolean) => void;
  isEditMode: boolean;
  selectedGroupsDetails?: { name: string; role: string };
  selectedGroupId: string;
};

const validationSchema = Yup.object({
  name: Yup.string().required("Group name is required"),
  role: Yup.string().required("Role is required"),
});

const newGroup = { name: "", role: "" };

const ManageGroup: React.FunctionComponent<ManageGroupProps> = ({
  isEditMode,
  selectedGroupsDetails = newGroup,
  selectedGroupId = "",
  ...props
}) => {
  const dispatch = useDispatch();

  const initialValues = isEditMode ? selectedGroupsDetails : newGroup;
  const submitGroup = async (group, { setSubmitting }) => {
    let data;
    let payload = {
      name: group.name,
      role: group.role,
      users: [],
    };

    if (isEditMode) {
      data = await editGroup(payload, selectedGroupId);
    } else {
      data = await addGroup(payload);
    }
    if (data) {
      props.setShowManageGroupModal(false);
      dispatch(getGroups());
    }
  };

  const groupRoles: Array<any> = useSelector(
    (state: RootState) => state.groups.groupRoles
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={submitGroup}
    >
      {(formik) => (
        <PureModal
          title={isEditMode ? "Edit Group" : "Add Group"}
          showModal={true}
          closeModal={() => props.setShowManageGroupModal(false)}
          primaryBtnLabel={isEditMode ? "Save" : "Add"}
          primaryBtnAction={formik.handleSubmit}
          secondaryBtnLabel={"Cancel"}
          secondaryBtnAction={() => props.setShowManageGroupModal(false)}
        >
          <Form>
            <Form.Group controlId="forName">
              <Form.Label htmlFor="name">
                Group Name
                <RequiredFieldSymbol />
              </Form.Label>
              <Form.Control
                type="text"
                id="name"
                className="placeholder-custom-size"
                disabled={isEditMode}
                placeholder="Enter group name"
                {...formik.getFieldProps("name")}
                isInvalid={!!(formik.touched.name && formik.errors.name)}
              />
              <Form.Control.Feedback type="invalid">
                <InputErrorMessage errorMessage={formik.errors.name || ""} />
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="forRole">
              <Form.Label htmlFor="role">
                Role <RequiredFieldSymbol />
              </Form.Label>
              <Form.Control
                type="select"
                as="select"
                id="role"
                {...formik.getFieldProps("role")}
                isInvalid={!!(formik.touched.role && formik.errors.role)}
              >
                {[
                  <option value="">Select</option>,
                  ...groupRoles.map((role) => (
                    <option value={role.id}>
                      {toCamelCase(role.roleName)}
                    </option>
                  )),
                ]}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                <InputErrorMessage errorMessage={formik.errors.role || ""} />
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </PureModal>
      )}
    </Formik>
  );
};

export default ManageGroup;
