import { useEffect, useRef } from "react";

const useInterval = (callback, delay, killInterval) => {
  const savedCallback = useRef<any>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function intervalCallback() {
      savedCallback.current && savedCallback.current();
    }
    if (delay !== null && !killInterval) {
      let intervalId = setInterval(intervalCallback, delay);
      return () => clearInterval(intervalId);
    }
  }, [delay, killInterval]);
};

export default useInterval;
