import React, { useState, useEffect } from "react";
import "./Groups.scss";
import Table from "src/components/shared/Table";
import { ReactComponent as AddIcon } from "src/assets/images/add.svg";
import { getGroupColumns } from "./groupColumns";
import { IGroup } from "src/state/groups/reducer";
import {
  getGroups,
  getGroupRoles,
  deleteGroupAction,
} from "src/state/groups/actions";
import { deleteGroupById } from "src/api/groups";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/state/rootReducer";
import { Button } from "react-bootstrap";
import SearchBox from "src/components/shared/SearchBox";
import ManageGroupUser from "./ManageGroupUser";
import ManageGroup from "src/containers/admin/Groups/ManageGroup";
import NoSearchResultFound from "src/components/ui/NoSearchResultFound";
import WarningModal from "src/components/shared/WarningModal";
const newGroup = {
  name: "",
  role: "",
};

type GroupsProps = {};

const Groups: React.FC<GroupsProps> = () => {
  const [showManageGroupModal, setShowManageGroupModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedGroupDetails, setSelectedGroupDetails] = useState(newGroup);
  const [selectedGroup, setSelectedGroup] = useState({ name: "", roleId: 0 });
  const [filteredGroups, setFilteredGroups] = useState([]);
  const [searchBy, setSearchBy] = useState("");
  const [selectedGroupId, setSelectedGroupId] = useState("");
  const [showDeleteWarningModal, setShowDeleteWarningModal] = useState(false);
  const [showManageGroupUserModal, setShowManageGroupUserModal] = useState(
    false
  );
  const dispatch = useDispatch();
  const groups: Array<IGroup> = useSelector(
    (state: RootState) => state.groups.list
  );
  const loaderCount = useSelector((state: RootState) => state.ui.loaderCount);
  const isLoading = loaderCount > 0;

  useEffect(() => {
    dispatch(getGroups());
    dispatch(getGroupRoles());
  }, []);

  const handleSearchByChange = (updatedSearchQuery) => {
    setSearchBy(updatedSearchQuery);
    filterGroups(groups, updatedSearchQuery);
  };

  const filterGroups = (groups, searchQuery) => {
    let filteredGroups = groups.filter((group) => {
      if (!searchQuery) return true;
      const name = group.name && group.name.toLowerCase();
      const query = searchQuery && searchQuery.toLowerCase();
      return name && name.includes(query);
    });
    setFilteredGroups(filteredGroups);
  };

  useEffect(() => {
    filterGroups(groups, searchBy);
  }, [groups]);

  const getGroupNameById = (groupId) => {
    const group = groups.find((group) => group.id === groupId);
    return group && group.name ? group.name : "";
  };

  const displayAddGroupModal = () => {
    setShowManageGroupModal(true);
    setIsEditMode(false);
    setSelectedGroupDetails(newGroup);
    setSelectedGroupId("");
  };

  const displayEditGroupModal = (groupDetails) => {
    const { name, role } = groupDetails;
    const group = { name, role };

    setShowManageGroupModal(true);
    setIsEditMode(true);
    setSelectedGroupDetails(group);
    setSelectedGroupId(groupDetails.id);
  };

  const displayDeleteGroupModal = (groupDetails) => {
    setSelectedGroupId(groupDetails.id);
    setShowDeleteWarningModal(true);
  };

  const closeDeleteGroupModal = () => {
    setSelectedGroupId("");
    setShowDeleteWarningModal(false);
  };

  const addGroupUser = (groupDetails) => {
    const { name, role } = groupDetails;
    const group = { name, roleId: role };

    setSelectedGroup(group);
    setSelectedGroupId(groupDetails.id);
    setShowManageGroupUserModal(true);
  };

  const groupColumns = getGroupColumns(
    displayEditGroupModal,
    displayDeleteGroupModal,
    addGroupUser
  );

  const deleteGroup = async () => {
    const data = await deleteGroupById(selectedGroupId);
    if (data) {
      dispatch(deleteGroupAction(selectedGroupId));
      closeDeleteGroupModal();
    }
  };

  return (
    <div className="groups-section d-flex flex-column flex-grow-1">
      {showDeleteWarningModal && (
        <WarningModal
          title="Warning"
          onCancelAction={closeDeleteGroupModal}
          onConfirmAction={deleteGroup}
        >
          <div>
            <div>Are you sure ?</div>
            <div>
              Group <b>{getGroupNameById(selectedGroupId)}</b> will be
              permanently deleted
            </div>
          </div>
        </WarningModal>
      )}
      <div className="d-flex flex-row justify-content-sm-between mb-2">
        <div className={`groups-section-header`}>
          <span>
            <b>Groups</b>
          </span>
        </div>
        {showManageGroupUserModal && (
          <ManageGroupUser
            setShowModal={setShowManageGroupUserModal}
            selectedGroupId={selectedGroupId}
            selectedGroupsDetails={selectedGroup}
          />
        )}
        {showManageGroupModal && (
          <ManageGroup
            isEditMode={isEditMode}
            selectedGroupId={selectedGroupId}
            selectedGroupsDetails={selectedGroupDetails}
            setShowManageGroupModal={setShowManageGroupModal}
          />
        )}
        <div className="d-flex justify-content-end ">
          <div className="groups-section-search d-flex align-items-center">
            <SearchBox
              updateSearchQuery={handleSearchByChange}
              placeholder="Search by group name"
            />
          </div>
          <div className={`align-self-end ml-2 d-inline`}>
            <Button
              type="submit"
              size="sm"
              variant="primary"
              className="groups-section-add"
              onClick={displayAddGroupModal}
            >
              <div className="d-flex align-items-center pl-2 pr-2">
                <AddIcon height="10" width="10" />
                <span className="ml-2"> Add Group </span>
              </div>
            </Button>
          </div>
        </div>
      </div>
      {filteredGroups.length ? (
        <Table
          searchBy={searchBy}
          columns={groupColumns}
          data={filteredGroups}
        />
      ) : !!searchBy ? (
        <NoSearchResultFound
          message="Your search was not successful!"
          detail="Try some relevant group name"
          errImgSrc="no-users.svg"
        />
      ) : (
        !isLoading && (
          <NoSearchResultFound
            message="No Groups Added!"
            errImgSrc="no-users.svg"
          />
        )
      )}
    </div>
  );
};

export default Groups;
