import { mapCompanies } from "src/helpers/companies/";
import {
  GET_COMPANIES_SUCCESS,
  GET_TYPE_SUCCESS,
  ADD_COMPANY_SUCCESS,
  ADD_COMPANY_FAILURE,
} from "./reducer";
import { fetchCompanies, fetchTypes } from "src/api/companies";

export const getCompanies = (metaConfig = { showLoader: true }) => async (
  dispatch
) => {
  let data = await fetchCompanies(metaConfig);
  if (data) {
    let companies = mapCompanies(data);
    dispatch({ type: GET_COMPANIES_SUCCESS, payload: companies });
  }
};

export const getTypes = () => async (dispatch) => {
  let data = await fetchTypes();
  if (data) {
    let types = data;
    dispatch({ type: GET_TYPE_SUCCESS, payload: types });
  }
};

export const addCompanyAction = (flag) => {
  if (flag) {
    return { type: ADD_COMPANY_SUCCESS, payload: true };
  } else {
    return { type: ADD_COMPANY_FAILURE, payload: true };
  }
};
