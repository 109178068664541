import * as React from "react";

function SvgComponent(props) {
  return (
    <svg width={21.472} height={27.785} {...props}>
      <g data-name="Group 2774">
        <g data-name="Group 2739">
          <g data-name="Group 1907">
            <path
              data-name="Path 2243"
              d="M17.225 19.973l-4.31-11.494V1.563h1.286V0H3.236v1.563h1.286v6.916l-4.3 11.5a3.536 3.536 0 003.312 4.774h10.38a3.536 3.536 0 003.311-4.777zM4.778 12.259l1.308-3.5V6.747h1.935V5.184H6.089V3.977h1.935V2.413H6.089v-.85h5.267v7.2l1.311 3.5z"
              fill="#86989f"
            />
          </g>
        </g>
        <g data-name="noun_Select All_2770393">
          <g data-name="select all" transform="translate(6.402 12.715)">
            <rect
              data-name="Rectangle 2834"
              width={11}
              height={11}
              rx={1}
              transform="translate(3.785 3.785)"
              fill="#fff"
            />
            <path
              data-name="Path 2539"
              d="M14.57 9.34V4.445A.445.445 0 0014.125 4H9.23v5.34z"
              fill="#86989f"
              stroke="#eff3f9"
            />
            <path
              data-name="Path 2540"
              d="M9.23 14.57h4.895a.445.445 0 00.445-.445V9.23H9.23z"
              fill="#86989f"
              stroke="#eff3f9"
            />
            <path
              data-name="Path 2541"
              d="M9.34 4H4.445A.445.445 0 004 4.445V9.34h5.34z"
              fill="#86989f"
              stroke="#eff3f9"
            />
            <path
              data-name="Path 2542"
              d="M4 9.23v4.895a.445.445 0 00.445.445H9.34V9.23z"
              fill="#86989f"
              stroke="#eff3f9"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;

