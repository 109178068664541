import React from "react";
import { formatDateToLocal, toCamelCase } from "src/helpers/common";
import rightArrow from "src/assets/images/right-arrow-color.svg";
import downArrow from "src/assets/images/sort-down.svg";
import copyCode from "src/assets/images/copy-code.svg";
import edit from "src/assets/images/edit.svg";
import createDraftIcon from "src/assets/images/create-draft-version-enable.svg";
import createDraftIconDisabled from "src/assets/images/create-draft-version-disable.svg";
import deleteIcon from "src/assets/images/delete.svg";
import CustomTooltip from "src/components/ui/CustomTooltip";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { sortColumn, sortByDate } from "src/utils/common";
import addUser from "src/assets/images/add-user.svg";
import viewIcon from "src/assets/images/visibleIcon.svg";
import { pushNotification } from "src/components/ui/Notification";

export const getActiveStoryLineColumns = (
  viewStoryline,
  createDraftVersion,
  displayDeleteStorylineModal,
  showCloneModal,
  editStorylineName,
  prepareStorylineUsers,
  isAdminUser
  // showHideVersionHistory
) => [
  {
    Header: "Name",
    accessor: "name",
    percentWidth: 23,
    Cell: ({ row, value }) =>
      // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
      // to build the toggle for expanding a row
      {
        return row.canExpand ? (
          <span
            {...row.getToggleRowExpandedProps({
              style: {
                // We can even use the row.depth property
                // and paddingLeft to indicate the depth
                // of the row
                paddingLeft: `${row.depth * 2}rem`,
              },
            })}
          >
            <div className="pl-1">
              {row.isExpanded ? (
                <img src={downArrow} alt="expand" width="12" height="12" />
              ) : (
                <img src={rightArrow} alt="collapse" height="12" width="12" />
              )}
              <span className="color-primary ml-2">{row.original.name} </span>
              {/* {row.canExpand ? row.original.name : row.original.version} */}
            </div>
          </span>
        ) : (
          <span className="ml-4">{row.original.version}</span>
        );
      },
  },
  {
    Header: "Last Updated By",
    accessor: "updatedBy",
    Cell: ({ row }) => {
      return (
        <span>
          {!row.canExpand &&
            !!row.original.updatedBy &&
            row.original.updatedBy.name}
        </span>
      );
    },
    percentWidth: 22,
  },
  {
    Header: "Last Updated At",
    accessor: "updatedOn",
    percentWidth: 21,
    Cell: ({ row }) => {
      return (
        <span>
          {" "}
          {!row.canExpand &&
            (row.original.updatedOn
              ? formatDateToLocal(row.original.updatedOn)
              : formatDateToLocal(row.original.createdOn))}{" "}
        </span>
      );
    },
  },
  {
    Header: "Usage",
    accessor: "assignedLabs",
    Cell: ({ row }) => {
      return <>{!row.canExpand && getUsageLabs(row.original.assignedLabs)}</>;
    },
    percentWidth: 18,
  },
  {
    Header: "Action",
    disableSortBy: true,
    accessor: (row) => {
      return (
        <>
          {!row.subRows ? (
            <div className="d-flex" title="">
              <div className="action-icon">
                <CustomTooltip text="View Guide Version">
                  <img
                    data-id={row.id}
                    onClick={viewStoryline}
                    src={viewIcon}
                    width="17"
                    alt="view storyline"
                  />
                </CustomTooltip>
              </div>
              <div className="ml-2 action-icon">
                {!!row.isParentDraftPresent ? (
                  <CustomTooltip text="Draft Version Already Exists">
                    <img
                      onClick={() =>
                        pushNotification({
                          type: "info",
                          message: "Draft for this chapter already exists",
                        })
                      }
                      src={createDraftIconDisabled}
                      alt="createDraftIconDisabled"
                    />
                  </CustomTooltip>
                ) : (
                  <CustomTooltip text="Create Draft Version">
                    <img
                      onClick={() => createDraftVersion(row)}
                      src={createDraftIcon}
                      alt="createDraftIcon"
                    />
                  </CustomTooltip>
                )}
              </div>
              {/* <div className="ml-2 action-icon">
                <CustomTooltip text="History of this storyline">
                  <img
                    onClick={() => showHideVersionHistory(row.id)}
                    src={timeIcon}
                    alt="historyIcon"
                  />
                </CustomTooltip>
              </div> */}
              {isAdminUser && (
                <div className="ml-2 action-icon">
                  <CustomTooltip text="Delete Guide Version">
                    <img
                      src={deleteIcon}
                      alt="deleteIcon"
                      onClick={() => {
                        displayDeleteStorylineModal(row);
                      }}
                    />
                  </CustomTooltip>
                </div>
              )}
            </div>
          ) : (
            isAdminUser && (
              <div className="d-flex" title="">
                <div className="action-icon">
                  <CustomTooltip text="Clone Guide">
                    <img
                      onClick={() => showCloneModal(row.id)}
                      src={copyCode}
                      alt="copyCodeIcon"
                    />
                  </CustomTooltip>
                </div>
                <div className="ml-2 action-icon">
                  <CustomTooltip text="Edit Guide Name">
                    <img
                      onClick={() => editStorylineName(row.id, row.name)}
                      src={edit}
                      alt="editIcon"
                    />
                  </CustomTooltip>
                </div>
                <div className="ml-2 p-0 action-icon">
                  <CustomTooltip text="Manage Editors">
                    <img
                      onClick={() => prepareStorylineUsers(row)}
                      src={addUser}
                      alt="addUser"
                    />
                  </CustomTooltip>
                </div>
              </div>
            )
          )}
        </>
      );
    },
    percentWidth: 16,
  },
];

export const getDraftStoryLineColumns = (
  editStoryline,
  displayDeleteStorylineModal,
  isAdminUser
) => [
  {
    Header: "Name",
    accessor: "version",
    percentWidth: 23,
  },
  {
    Header: "Last Updated By",
    accessor: "updatedBy",
    sortType: (rowA, rowB) => sortColumn(rowA, rowB, "updatedBy.name"),
    Cell: ({ row, value }) => {
      return <span>{!!value && toCamelCase(value.name)}</span>;
    },
    percentWidth: 21,
  },
  {
    Header: "Last Updated At",
    accessor: "updatedOn",
    percentWidth: 21,
    sortType: (rowA, rowB, colId, desc) =>
      sortByDate(rowA, rowB, desc, "updatedOn"),
    Cell: ({ row, value }) => {
      return (
        <span>
          {" "}
          {value
            ? formatDateToLocal(value)
            : formatDateToLocal(row.createdBy)}{" "}
        </span>
      );
    },
  },
  {
    Header: "Usage",
    accessor: "assignedLabs",
    Cell: ({ value }) => {
      return getUsageLabs(value);
    },
    percentWidth: 20,
  },
  {
    Header: "Action",
    disableSortBy: true,
    accessor: (row) => {
      return (
        <div className="d-flex" title="">
          <div className="action-icon">
            <CustomTooltip text="Edit Guide">
              <img
                onClick={() => editStoryline(row.id)}
                src={edit}
                alt="editIcon"
              />
            </CustomTooltip>
          </div>
          {/* <div className="ml-2 action-icon">
            <CustomTooltip text="Clone Storyline">
              <img src={copyCode} alt="copyCodeIcon" />
            </CustomTooltip>
          </div> */}
          {isAdminUser && (
            <div className="ml-2 action-icon">
              <CustomTooltip text="Delete Guide">
                <img
                  src={deleteIcon}
                  alt="deleteIcon"
                  onClick={() => {
                    displayDeleteStorylineModal(row);
                  }}
                />
              </CustomTooltip>
            </div>
          )}
        </div>
      );
    },
    percentWidth: 15,
  },
];

export const getUsageLabs = (labs) => {
  return labs && labs.length ? (
    <div className={`d-flex `}>
      <span>{labs[0].name}</span>
      <div className="ml-1">
        {labs.length > 1 ? (
          <a className="cursor-pointer">
            <OverlayTrigger
              trigger={["hover", "focus"]}
              placement={"top"}
              overlay={
                <Popover id={`popover-positioned-bottom`}>
                  <>
                    <Popover.Content>
                      <div>
                        {labs.map((lab, id) => (
                          <div className="mt-1" key={id}>
                            <div>{lab.name}</div>
                          </div>
                        ))}
                      </div>
                    </Popover.Content>
                  </>
                </Popover>
              }
            >
              <div className="color-primary"> +{labs.length - 1}</div>
            </OverlayTrigger>
          </a>
        ) : null}
      </div>
    </div>
  ) : (
    "--"
  );
};
