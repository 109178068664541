import * as React from "react";

function SvgComponent(props) {
  return (
    <svg width={25.016} height={21.889} {...props}>
      <path
        data-name="Path 4079"
        d="M14.593 5.212A5.212 5.212 0 119.381 0a5.212 5.212 0 015.212 5.212zm0 0"
        fill="#86989f"
      />
      <path
        data-name="Path 4080"
        d="M13.811 12.508h-8.86A4.957 4.957 0 000 17.459v3.649a.782.782 0 00.782.782h17.2a.782.782 0 00.782-.782V17.46a4.957 4.957 0 00-4.953-4.952zm0 0"
        fill="#86989f"
      />
      <path
        data-name="Path 4081"
        d="M24.767 8.807l-3.648-3.388a.783.783 0 00-1.314.573v2.345h-4.17a1.042 1.042 0 000 2.085h4.169v2.345a.783.783 0 001.314.573l3.648-3.388a.784.784 0 000-1.147zm0 0"
        fill="#86989f"
      />
    </svg>
  );
}

export default SvgComponent;

