import React, { ReactNode } from "react";
import { Form } from "react-bootstrap";
import { useField } from "formik";
import InputErrorMessage from "src/components/shared/InputErrorMessage";

type RadioInputProps = {
  options: Array<{
    label?: string;
    id?: string;
    component?: ReactNode;
  }>;
  className?: string;
  label?: string;
  name: string;
};

const RadioButton: React.FC<any> = ({
  label,
  id,
  field: { name, value, onChange, onBlur },
  ...props
}) => (
  <>
    <div className="d-flex m-2">
      <Form.Control
        type="radio"
        name={name}
        id={id}
        checked={id === value}
        value={id}
        onChange={onChange}
        onBlur={onBlur}
        {...props}
      />
      <div className="check">
        <div />
      </div>
      <Form.Label> {label} </Form.Label>
    </div>
    {id === value && props.component && props.component()}
  </>
);

const RadioButtonGroup: React.FC<any> = ({
  label,
  meta,
  children,
  ...props
}) => {
  return (
    <fieldset>
      <Form.Group className={props.className && props.className}>
        {label && <Form.Label as="legend"> {label} </Form.Label>}
        {children}
      </Form.Group>
      {meta.touched && !meta.value && (
        <InputErrorMessage
          className={"error-message"}
          errorMessage={meta.error || ""}
        />
      )}
    </fieldset>
  );
};

const RadioInput: React.FC<RadioInputProps> = ({ options, name, ...props }) => {
  const [field, meta] = useField(name);

  return (
    <RadioButtonGroup {...field} meta={meta} {...props}>
      {options.map(({ ...rest }, index) => (
        <RadioButton key={index} {...rest} field={field} />
      ))}
    </RadioButtonGroup>
  );
};

export { RadioInput };
