import React from "react";
import SearchBox from "src/components/shared/SearchBox";
import { Card } from "react-bootstrap";
import "./InfographicTable.scss";
import InfographicBaseTable from "src/components/Infographics/InfographicsBaseTable";

export declare interface TFilteredDates {
  startDate: any;
  endDate: any;
}

export type TColumns = {
  Header: string;
  id: string;
};

type TInfographicTablePropsType = {
  fetchItems: (offset: number, searchBy?: string) => void;
  items: Array<any>;
  totalItems: number;
  itemColumns: Array<any>;
  title: string;
  fetchAllTableItems: (searchQuery?) => Promise<Array<any>>;
  pageIndex: number;
  setPageIndex: (number) => void;
  onSearch: (searchQuery: string) => void;
  searchBy: string;
  filteredDates: TFilteredDates;
  activeTab: string;
  allColumns?: Array<TColumns>;
};

const InfographicTable = (props: TInfographicTablePropsType) => {
  return (
    <Card border="light">
      <div className="d-flex justify-content-between mb-n3 px-4 infographic-table">
        <h4 className="font-weight-bold"> {props.title} </h4>
        <div className="d-flex infographic-table-search">
          <SearchBox
            searchBy={props.searchBy}
            updateSearchQuery={props.onSearch}
            placeholder="Search by email or name"
          />
        </div>
      </div>
      <InfographicBaseTable
        pageIndex={props.pageIndex}
        setPageIndex={props.setPageIndex}
        fetchItems={props.fetchItems}
        totalItems={props.totalItems}
        data={props.items}
        columns={props.itemColumns}
        fetchAllTableItems={props.fetchAllTableItems}
        searchBy={props.searchBy}
        filteredDates={props.filteredDates}
        activeTab={props.activeTab}
        allColumns={props.allColumns}
      />
    </Card>
  );
};

export default InfographicTable;
