import produce from "immer";

export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";

export interface TSidebarState {
  sidebarOpen: boolean;
}

const initialState: TSidebarState = {
  sidebarOpen: false,
};

export default (state = initialState, action): TSidebarState =>
  produce(state, (draft) => {
    switch (action.type) {
      case TOGGLE_SIDEBAR:
        draft.sidebarOpen = !state.sidebarOpen;
        break;
      default:
        break;
    }
  });
