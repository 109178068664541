import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={32}
      viewBox="0 0 32 32"
      {...props}
    >
      <g data-name="Group 3745">
        <g data-name="Rectangle 3021" fill="#fff" stroke="#cecece">
          <rect width={32} height={32} rx={4} stroke="none" />
          <rect x={0.5} y={0.5} width={31} height={31} rx={3.5} fill="none" />
        </g>
        <path
          data-name="Path 2604"
          d="M12.925 15.17l4.99-4.991a.615.615 0 01.868 0l.363.368a.614.614 0 010 .868l-4.186 4.19 4.2 4.2a.615.615 0 010 .868l-.367.367a.615.615 0 01-.868 0l-4.995-5a.619.619 0 010-.871z"
          fill="#cecece"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
