import React, { useEffect } from "react";
import "./App.scss";
import Routes from "./Routes";
import { BrowserRouter as Router } from "react-router-dom";
import Loader from "src/components/ui/Loader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  registerForRefreshToken,
  registerForUserActivityTracking,
  registerForUserInactivitySession,
} from "src/helpers/common";

function App() {
  useEffect(() => {
    registerForRefreshToken();
    registerForUserInactivitySession();
    registerForUserActivityTracking();
  });


  return (
    <>
      <Loader />
      <ToastContainer />
      <Router>
        <Routes />
      </Router>
    </>
  );
}

export default App;
