import React, { useState } from "react";
import { useParams } from "react-router-dom";
import CredSection from "./CredSection";
import SecretCredSection from "./SecretCredSection";
import { useSelector } from "react-redux";
import { RootState } from "src/state/rootReducer";
import AddCreds from "./AddCreds";
import addIcon from "src/assets/images/add-red.svg";
import "./Credentials.scss";
import { AddSecretKey } from "./AddSecretKey";
import { mode } from "src/constants/storylineList";

const Credentials = () => {
  const { storylineMode } = useParams();
  const [addNewCreds, setAddNewCreds] = useState(false);
  const [secretKetForm, setSecretKeyForm] = useState(false);
  const addCreds = () => setAddNewCreds(!addNewCreds);
  const toggleKeyForm = () => setSecretKeyForm(!secretKetForm);

  const credentials = useSelector(
    (state: RootState) => state.storyline.credentials
  );

  const viewModeClass = storylineMode === mode.view ? "hidden" : "visible";

  return (
    <div className="creds">
      <div className={`creds-btns mb-3 mt-3 ${viewModeClass}`}>
        <div className="creds-btns-btn" onClick={toggleKeyForm}>
          <img src={addIcon} alt="add secret key" width="10" />
          <span> Add Secret Key </span>
        </div>
        <div className="creds-btns-btn" onClick={addCreds}>
          <img src={addIcon} alt="add new creds" width="10" />
          <span> Add New Credentials </span>
        </div>
      </div>
      <div className="my-4">
        {addNewCreds && <AddCreds toggle={addCreds} />}
        {secretKetForm && <AddSecretKey toggle={toggleKeyForm} />}
      </div>
      {credentials?.map((creds) =>
        creds.secretKey ? (
          <SecretCredSection
            id={creds.id}
            headerTitle={creds.name}
            secretKey={creds.secretKey}
          />
        ) : (
          <CredSection
            id={creds.id}
            headerTitle={creds.name}
            username={creds.username}
            password={creds.password}
          />
        )
      )}
    </div>
  );
};

export default Credentials;
