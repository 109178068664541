import Api from "src/helpers/request";

export const getPresentationEndpoint = "/presentations";

export const fetchPresentations = (metaConfig = { showLoader: true }) => {
  return Api.call(
    {
      url: `${getPresentationEndpoint}?pagination=false`,
      method: "GET",
    },
    metaConfig
  );
};

export const fetchFolders = (metaConfig = { showLoader: true }) => {
  return Api.call(
    {
      url: `${getPresentationEndpoint}/root/content?pagination=false`,
      method: "GET",
    },
    metaConfig
  );
};

export const fetchFilesByFolder = (folderId) => {
  return Api.call(
    {
      url: `${getPresentationEndpoint}/${folderId}/content?pagination=false`,
      method: "GET",
    },
    { showLoader: true }
  );
};

export const deletePresentationById = (id) =>
  Api.call(
    {
      url: `${getPresentationEndpoint}/${id}`,
      method: "DELETE",
    },
    { showLoader: true }
  );

export const uploadFile = (data) =>
  Api.call(
    {
      url: `${getPresentationEndpoint}/upload`,
      method: "POST",
      data,
    },
    { showLoader: true }
  );

export const createFolder = (data) =>
  Api.call(
    {
      url: `${getPresentationEndpoint}`,
      method: "POST",
      data,
    },
    { showLoader: true }
  );
