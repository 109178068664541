import React, { useState, useEffect, useMemo } from "react";
import "./StoryLineList.scss";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Table from "src/components/shared/Table";
import { ReactComponent as StoriesActive } from "src/assets/images/stories-active.svg";
import { ReactComponent as StoriesInactive } from "src/assets/images/stories-inactive.svg";
import { ReactComponent as AddIcon } from "src/assets/images/add.svg";
import {
  getActiveStoryLineColumns,
  getDraftStoryLineColumns,
} from "./storyLineColumns";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/state/rootReducer";
import { Button, Form } from "react-bootstrap";
import SearchBox from "src/components/shared/SearchBox";
import NoSearchResultFound from "src/components/ui/NoSearchResultFound";
import { useHistory, useLocation } from "react-router-dom";
import {
  ADMIN_ROUTE,
  ADMIN_NEW_STORYLINE_ROUTE,
  STORYLINES_ROUTE,
  USER_STORYLINE_ROUTE,
  LABS_ROUTE,
} from "src/constants/appRoutes";
import { IStoryline } from "src/state/storyline/reducer";
import { getStorylineList } from "src/state/storylineList/actions";
import WarningModal from "src/components/shared/WarningModal";
import {
  deleteStorylineVersionById,
  updateStoryline,
  // requestStoryLineClone,
  updateStorylineVersion,
} from "src/api/stories";
import { updateStorylineVersionDetails } from "src/state/storyline/actions";
import { ExpandedTable } from "src/components/shared/Table/ExpandedTable";
import { storylineTypeMap, mode } from "src/constants/storylineList";
import { checkAdminUser, isEmpty } from "src/utils/common";
import { getPayload } from "src/helpers/stories";
import EditStorylineName from "./StorylineActions/EditStorylineName";
import EditStorylineCloneName from "./StorylineActions/EditStorylineCloneName";
import PureModal from "src/components/shared/PureModal";
import RequiredFieldSymbol from "src/components/ui/RequiredFieldSymbol";
import InputErrorMessage from "src/components/shared/InputErrorMessage";
import Select from "react-select";
import { useFormik } from "formik";
import { usersAction } from "src/state/users/actions";
import * as Yup from "yup";

type StoryLineListProps = {};
const newStoryline = {
  name: "",
  id: -1,
  version: "",
};

const newStorylineDetail = {
  name: "New Guide",
  id: 1,
  introductoryContent: "",
  version: "New Guide-v1",
  sections: [],
  credentials: [],
  isDraft: false,
  createdOn: "",
  updatedOn: "",
  parent: -1,
  updatedBy: {},
  assignedLabs: [],
  isUpdated: false,
};

declare interface Editor {
  label: string;
  value: string;
}

declare interface UsersStoryline {
  id: number;
  name: string;
  subRows: Array<any>;
  editors: Array<Editor>;
}

const StoryLineList: React.FC<StoryLineListProps> = () => {
  let history = useHistory();
  let [draftStorylineList, setDraftStorylineList] = useState([] as any);
  let [activeStorylineList, setActiveStorylineList] = useState([] as any);

  const [searchBy, setSearchBy] = useState("");
  const [tabKey, setTabKey] = useState("storylines");
  const [showDeleteWarningModal, setShowDeleteWarningModal] = useState(false);
  const [selectedStoryline, setSelectedStoryline] = useState(newStoryline);

  const dispatch = useDispatch();
  const storylineList: Array<IStoryline> | null = useSelector(
    (state: RootState) => state.storylineList.list
  );
  const storylines = storylineList || [];

  const location = useLocation();

  const isAdminUser = React.useMemo(checkAdminUser, []);

  const loaderCount = useSelector((state: RootState) => state.ui.loaderCount);
  const isLoading = loaderCount > 0;

  const [showCloneNameModal, setShowCloneNameModal] = useState(false);
  const [cloneStorylineId, setCloneStorylineId] = useState("");
  const [storylineToEdit, setStorylineToEdit] = useState("");
  const [showStorylineNameModal, setShowStorylineNameModal] = useState(false);

  const [addUsersModal, setAddUsersModal] = React.useState(false);
  const toggleAddUsersModal = () => setAddUsersModal(!addUsersModal);
  const users: Array<any> = useSelector((state: RootState) => state.users.list);

  const filterEditors = (user) =>
    !formik.values.editors.some((editor) => editor.value === user.id);

  const newStorylineUsers: UsersStoryline = {
    name: "",
    id: -1,
    subRows: [],
    editors: [],
  };

  const addUsersToStoryline = async (data) => {
    const payload = {
      ...data,
      editors: data.editors.map((user) => user.value),
    };
    const response = await updateStoryline(payload);
    if ("id" in response) {
      toggleAddUsersModal();
      await dispatch(getStorylineList());
    }
  };

  const validationSchema = Yup.object({
    editors: Yup.array().test(
      "NoEmptyArray",
      "No email or user name is selected",
      (value) => {
        if (!initialEditors.length) {
          return value && value.length > 0;
        }
        return true;
      }
    ),
  });

  const formik = useFormik<UsersStoryline>({
    initialValues: newStorylineUsers,
    onSubmit: addUsersToStoryline,
    validationSchema,
  });

  const corporateUsers = users.filter((user) => user.type === "corporate");
  const [initialEditors, setInitialEditors] = React.useState([]);

  const prepareStorylineUsers = (storyline) => {
    const editors = storyline?.editors || [];
    setInitialEditors(editors);
    formik.setValues({
      ...storyline,
      editors: editors.map((editor) => ({
        value: editor.id,
        label: `${editor.name}, ${editor.email}`,
      })),
    });
    toggleAddUsersModal();
    if (corporateUsers.length === 0) {
      dispatch(usersAction());
    }
  };

  const closeAddUserModal = () => {
    formik.setErrors({});
    formik.setFieldTouched("editors", false);
    toggleAddUsersModal();
  };

  useEffect(() => {
    if (location.pathname.includes(USER_STORYLINE_ROUTE) && isAdminUser) {
      history.push(LABS_ROUTE);
    } else {
      dispatch(getStorylineList());
    }
  }, []);

  const handleSearchByChange = (updatedSearchQuery: string) => {
    setSearchBy(updatedSearchQuery);
    filterStorylines(updatedSearchQuery);
  };

  const filterStorylines = (searchQuery: string) => {
    let filteredStorylines = storylines.filter((storyline: any) => {
      if (!searchQuery) return true;
      const name = storyline.name && storyline.name.toLowerCase();
      const query = searchQuery && searchQuery.toLowerCase();
      const filteredVersions =
        storyline.subRows &&
        storyline.subRows.filter((version) =>
          version.version.toLowerCase().includes(query)
        );

      return (name && name.includes(query)) || filteredVersions.length;
    });
    setActiveStorylineList(
      getStorylinesByType(filteredStorylines, storylineTypeMap.active)
    );
    setDraftStorylineList(
      getStorylinesByType(filteredStorylines, storylineTypeMap.draft)
    );
  };

  const handleTabChange = (key: string | null) => {
    if (key) {
      setTabKey(key);
    }
  };

  const getStorylinesByType = (storylines, type: string) => {
    if (type === storylineTypeMap.active) {
      let activeStorylines = [] as any;
      storylines &&
        storylines.filter((sl) => {
          let activeVersions =
            sl.subRows &&
            sl.subRows.length &&
            sl.subRows.filter((version) => !version.isDraft);
          if (activeVersions.length) {
            activeStorylines.push({
              name: sl.name,
              id: sl.id,
              subRows: activeVersions,
              editors: sl.editors,
            });
          }
        });
      return activeStorylines;
    }

    let draftStorylines =
      storylines && storylines.map((sl) => sl.subRows).flat();

    return draftStorylines && draftStorylines.filter((sl) => sl.isDraft);
  };

  React.useLayoutEffect(() => {
    filterStorylines(searchBy);
  }, [storylineList]);

  const createStoryline = () => {
    dispatch(updateStorylineVersionDetails(newStorylineDetail));
    history.push(`${ADMIN_ROUTE}${ADMIN_NEW_STORYLINE_ROUTE}`);
  };

  const editStoryline = (storylineId) => {
    if (isAdminUser) {
      history.push(
        `${ADMIN_ROUTE}${STORYLINES_ROUTE}/${mode.edit}/${storylineId}`
      );
    } else {
      history.push(`${USER_STORYLINE_ROUTE}/${mode.edit}/${storylineId}`);
    }
  };

  const createDraftVersion = async (version) => {
    let payload = { ...version };
    payload.isDraft = true;
    const response = await updateStorylineVersion(getPayload(payload));
    if (!isEmpty(response) && !response.hasError) {
      handleTabChange("drafts");
      dispatch(getStorylineList());
    }
  };

  const closeDeleteStorylineModal = () => {
    setSelectedStoryline(newStoryline);
    setShowDeleteWarningModal(false);
  };

  const displayDeleteStorylineModal = (storyline) => {
    const { name, id, version } = storyline;
    setSelectedStoryline({ name, id, version });
    setShowDeleteWarningModal(true);
  };

  const deleteStorylineVersion = async () => {
    const data = await deleteStorylineVersionById(selectedStoryline.id);
    if (data) {
      dispatch(getStorylineList());
      closeDeleteStorylineModal();
    }
  };

  // const showHideVersionHistory = (storyLineId) => {
  //   setShowVersionHistory(!showVersionHistory);
  //   setSelectedStoryLineIdForHistory(showVersionHistory ? null : storyLineId);
  // };

  const viewStoryline = (event) => {
    const storylineId = event.target.dataset.id;
    if (isAdminUser) {
      history.push(
        `${ADMIN_ROUTE}${STORYLINES_ROUTE}/${mode.view}/${storylineId}`
      );
    } else {
      history.push(`${USER_STORYLINE_ROUTE}/${mode.view}/${storylineId}`);
    }
  };

  const showCloneModal = (storyLineId) => {
    setShowCloneNameModal(true);
    setCloneStorylineId(storyLineId);
  };

  const showEditStorylineNameModal = (storyLineId, storylineName) => {
    setShowStorylineNameModal(true);
    setStorylineToEdit(storylineName);
    setCloneStorylineId(storyLineId);
  };

  const activeStoryLineColumns = getActiveStoryLineColumns(
    viewStoryline,
    createDraftVersion,
    displayDeleteStorylineModal,
    showCloneModal,
    showEditStorylineNameModal,
    prepareStorylineUsers,
    isAdminUser
    // showHideVersionHistory
  );
  const draftStoryLineColumns = getDraftStoryLineColumns(
    editStoryline,
    displayDeleteStorylineModal,
    isAdminUser
  );

  const customStyles = {
    multiValue: () => ({
      display: "flex",
      borderRadius: "2rem",
      border: "1px solid #A59CF0",
      background: "#E8E8FE",
      paddingLeft: "0.2rem",
    }),
    multiValueLabel: () => ({ color: "#A59CF0", padding: "0 2px" }),

    multiValueRemove: (styles) => ({
      ...styles,
      color: "#fe5000",
      borderRadius: "50%",
    }),
    dropdownIndicator: () => ({ display: "none" }),
    indicatorSeparator: () => ({ display: "none" }),
  };

  return (
    <div className="storylines-section d-flex flex-column flex-grow-1">
      <PureModal
        title="Manage Editors"
        primaryBtnLabel="Add"
        secondaryBtnLabel="Cancel"
        showModal={addUsersModal}
        primaryBtnAction={formik.handleSubmit}
        secondaryBtnAction={closeAddUserModal}
        closeModal={closeAddUserModal}
      >
        <Form>
          <Form.Group>
            <Form.Label>Guide Name</Form.Label>
            <Form.Control
              type="text"
              disabled
              id="usersStorylineName"
              value={formik.values.name}
            />
          </Form.Group>
          <Form.Group controlId="storyline-users">
            <Form.Label htmlFor="storyline-users">
              User Name or Email
              <RequiredFieldSymbol />
            </Form.Label>
            <Select
              id="storyline-users-select"
              className="select-nameEmail"
              size={5}
              placeholder="Search name or email"
              value={formik.values.editors}
              isClearable={true}
              onChange={(data) => {
                formik.setFieldTouched("editors");
                formik.setFieldValue("editors", data ? data : []);
              }}
              options={corporateUsers.filter(filterEditors).map((users) => {
                return {
                  value: users.id,
                  label: `${users.name}, ${users.email}`,
                };
              })}
              styles={customStyles}
              isMulti={true}
              closeMenuOnSelect={false}
            />
            {formik.touched.editors && formik.errors.editors && (
              <InputErrorMessage
                className={"error-message"}
                errorMessage={formik.errors.editors || ""}
              />
            )}
          </Form.Group>
        </Form>
      </PureModal>
      {showDeleteWarningModal && (
        <WarningModal
          title="Warning"
          onCancelAction={closeDeleteStorylineModal}
          onConfirmAction={deleteStorylineVersion}
        >
          <div className="ml-2">
            <div>Are you sure you want to delete</div>
            <div>
              <b>{selectedStoryline.version}</b> ?
            </div>
          </div>
        </WarningModal>
      )}

      {showCloneNameModal && (
        <EditStorylineCloneName
          setShowModal={setShowCloneNameModal}
          storylineId={cloneStorylineId}
          storylines={storylines}
        />
      )}

      {showStorylineNameModal && (
        <EditStorylineName
          setShowModal={setShowStorylineNameModal}
          storylineId={cloneStorylineId}
          storylineName={storylineToEdit}
        />
      )}
      <>
        <h5 className="ml-1 mb-3 mt-2">
          <b>Guides</b>
        </h5>
        <div className="storylines-section-outer d-flex justify-content-end">
          <div className="storylines-section-search d-flex align-items-center">
            <SearchBox
              updateSearchQuery={handleSearchByChange}
              placeholder={"Search by guide name"}
            />
          </div>
          {isAdminUser && (
            <div
              className={`storylines-section-add align-self-end ml-2 d-inline`}
            >
              <Button
                type="submit"
                size="sm"
                variant="primary"
                onClick={createStoryline}
              >
                <div className="d-flex align-items-center pl-2 pr-2">
                  <AddIcon height="10" width="10" />
                  <span className="ml-2"> Create Guide </span>
                </div>
              </Button>
            </div>
          )}
        </div>
        <Tabs
          activeKey={tabKey}
          onSelect={handleTabChange}
          transition={false}
          id="storylines"
        >
          <Tab
            eventKey="storylines"
            title={
              <div className="d-flex align-items-center">
                {tabKey.toLowerCase() === "storylines" ? (
                  <StoriesActive height="18" width="18" />
                ) : (
                  <StoriesInactive height="18" width="18" />
                )}
                <div className="ml-1"> Guides</div>
              </div>
            }
          >
            {activeStorylineList.length ? (
              <ExpandedTable
                theadStyle={{ backgroundColor: "var(--color-white)" }}
                columns={activeStoryLineColumns}
                data={activeStorylineList}
                expandedByDefault
              />
            ) : !!searchBy ? (
              <NoSearchResultFound
                message="Your search was not successful!"
                detail="Try some relevant guide name"
                errImgSrc="no-users.svg"
              />
            ) : (
              !isLoading && (
                <NoSearchResultFound
                  message="No Active Guides Found!"
                  errImgSrc="no-users.svg"
                />
              )
            )}
          </Tab>
          <Tab
            eventKey="drafts"
            title={
              <div className="d-flex align-items-center">
                {tabKey.toLowerCase() === "drafts" ? (
                  <StoriesActive height="18" width="18" />
                ) : (
                  <StoriesInactive height="18" width="18" />
                )}
                <div className="ml-1"> Draft Guides</div>
              </div>
            }
          >
            {draftStorylineList.length ? (
              <Table
                theadStyle={{ backgroundColor: "var(--color-white)" }}
                columns={draftStoryLineColumns}
                data={draftStorylineList}
              />
            ) : !!searchBy ? (
              <NoSearchResultFound
                message="Your search was not successful!"
                detail="Try some relevant guide name"
                errImgSrc="no-users.svg"
              />
            ) : (
              !isLoading && (
                <NoSearchResultFound
                  message="No Draft Guides Found!"
                  errImgSrc="no-users.svg"
                />
              )
            )}
          </Tab>
        </Tabs>
      </>
    </div>
  );
};

export default StoryLineList;
