import produce from "immer";
import { readNotificationHelper } from "src/components/AppHeader/NotificationList/helpers";

export const GET_USER_NOTIFICATIONS_SUCCESS = "GET_USER_NOTIFICATIONS_SUCCESS";
export const GET_USER_NOTIFICATIONS_FAILURE = "GET_USER_NOTIFICATIONS_FAILURE";
export const READ_USER_NOTIFICATIONS_SUCCESS =
  "READ_USER_NOTIFICATIONS_SUCCESS";
export const READ_USER_NOTIFICATIONS_FAILURE =
  "READ_USER_NOTIFICATIONS_FAILURE";

export interface IUserNotifications {
  list: Array<IUserNotification>;
  notificationSaveSuccessful: boolean;
  notificationSaveFailure: boolean;
  notificationReadSuccessful: boolean;
  notificationReadFailure: boolean;
  notificationAddSuccessful: boolean;
  notificationAddFailure: boolean;
  userNotificationCounter: number;
}

export interface IUserNotification {
  id: string;
  header: string;
  body: string;
  displayLocation: string;
  startTime: string;
  endTime: string;
  isEnabled: boolean;
  isDeleted: boolean;
  isRead: boolean;
}

const initialState: IUserNotifications = {
  list: [],
  notificationSaveSuccessful: false,
  notificationSaveFailure: false,
  notificationReadSuccessful: false,
  notificationReadFailure: false,
  notificationAddSuccessful: false,
  notificationAddFailure: false,
  userNotificationCounter: 0,
};

export default (state = initialState, action): IUserNotifications =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_USER_NOTIFICATIONS_SUCCESS:
        draft.list = action.payload;
        draft.notificationSaveSuccessful = true;
        break;

      case READ_USER_NOTIFICATIONS_SUCCESS:
        draft.list = readNotificationHelper(state.list, action.payload.id);
        draft.notificationReadSuccessful = true;
        if (action.payload.incrementCount) {
          draft.userNotificationCounter += 1
        }
        break;

      case READ_USER_NOTIFICATIONS_FAILURE:
        draft.notificationReadSuccessful = false;
        break;

      default:
        break;
    }
  });
