import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24.433}
    height={24.4}
    {...props}
  >
    <g data-name="Group 10350">
      <path
        data-name="Path 2264"
        d="m8.61 19.119.366 2.385 1.725 1.667 2.389.214 1.492-.858 1.156-2v-1.095l-.195-1.028Z"
        fill="#5c7179"
        stroke="#707070"
      />
      <path
        data-name="Path 2263"
        d="m1.846 17.026 2.6-3.524v-5.18l1.271-3.758 3.12-3.012 3.98-.821 3.74 1.357 2.705 3.217L19.84 11l1.183 3.81 2.029 2.644.313 1.182H1.244Z"
        fill="#5c7179"
      />
      <g data-name="Group 3731">
        <g data-name="Group 1905">
          <path
            data-name="Path 2242"
            d="M23.863 17.352c-2.823-2.824-3.246-4.241-3.246-8.752a8.4 8.4 0 1 0-16.8 0 14.191 14.191 0 0 1-.4 4.455 10.292 10.292 0 0 1-2.848 4.3 1.2 1.2 0 0 0 .848 2.045h6.661l-.06.6a4.2 4.2 0 1 0 8.4 0l-.06-.6h6.659a1.2 1.2 0 0 0 .846-2.048ZM12.217 23a3 3 0 0 1-3-3l.06-.6h5.878l.061.6a3 3 0 0 1-2.999 3Zm-10.8-4.8c3.6-3.6 3.6-6 3.6-9.6a7.2 7.2 0 1 1 14.4 0c0 3.6 0 6 3.6 9.6Z"
            fill="#5c7179"
            stroke="#5c7179"
            strokeWidth={0.4}
          />
        </g>
        <g data-name="ring">
          <g data-name="Group 1905">
            <path
              data-name="Path 2242"
              d="M23.863 17.352c-2.823-2.824-3.246-4.241-3.246-8.752a8.4 8.4 0 1 0-16.8 0 14.191 14.191 0 0 1-.4 4.455 10.292 10.292 0 0 1-2.848 4.3 1.2 1.2 0 0 0 .848 2.045h6.661l-.06.6a4.2 4.2 0 1 0 8.4 0l-.06-.6h6.659a1.2 1.2 0 0 0 .846-2.048ZM12.217 23a3 3 0 0 1-3-3l.06-.6h5.878l.061.6a3 3 0 0 1-2.999 3Zm-10.8-4.8c3.6-3.6 3.6-6 3.6-9.6a7.2 7.2 0 1 1 14.4 0c0 3.6 0 6 3.6 9.6Z"
              fill="#5c7179"
              stroke="#5c7179"
              strokeWidth={0.4}
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SvgComponent;

