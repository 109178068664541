import produce from "immer";

export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";
export const VM_POLLING_STATUS = "VM_POLLING_STATUS";

export interface IUi {
  loaderCount: number;
  vmPollingStatus: boolean;
  message: string;
}

const initialState: IUi = {
  loaderCount: 0,
  vmPollingStatus: false,
  message: "",
};

export default (state = initialState, action): IUi =>
  produce(state, (draft) => {
    switch (action.type) {
      case SHOW_LOADER:
        draft.loaderCount = state.loaderCount + 1;
        draft.message = action.payload.message;
        break;
      case HIDE_LOADER:
        draft.loaderCount = state.loaderCount - 1;
        break;

      case VM_POLLING_STATUS:
        draft.vmPollingStatus = action.payload;
        break;

      default:
        break;
    }
  });
