import { GET_NOTIFICATIONS_SUCCESS, NOTIFICATIONS_ACTION } from "./reducer";
import {
  createNotification,
  fetchNotifications,
  removeNotification,
  updateNotification,
} from "src/api/notifications";
import { toCamelCaseKeys } from "src/helpers/common";

export const getNotifications =
  (metaConfig = { showLoader: true }) =>
  async (dispatch) => {
    let data = await fetchNotifications(metaConfig);
    if (data) {
      let notifications = toCamelCaseKeys(data);
      dispatch({ type: GET_NOTIFICATIONS_SUCCESS, payload: notifications });
    }
  };

export const createNotificationThunk =
  (notification, metaConfig = { showLoader: true }) =>
  async (dispatch) => {
    try {
      await createNotification(notification);
      dispatch({ type: NOTIFICATIONS_ACTION });
    } catch (e) {}
  };

export const updateNotificationThunk =
  (
    id,
    notification,
    metaConfig = { showLoader: true, showErrorNotification: false }
  ) =>
  async (dispatch) => {
    try {
      await updateNotification(id, notification, metaConfig);
      dispatch({ type: NOTIFICATIONS_ACTION });
    } catch (e) {}
  };

export const removeNotificationThunk =
  (id, metaConfig = { showLoader: true }) =>
  async (dispatch) => {
    try {
      await removeNotification(id);
      dispatch({ type: NOTIFICATIONS_ACTION });
    } catch (e) {}
  };
