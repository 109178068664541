import { useState, useEffect } from "react";
import useInterval from "../useInterval";
import { computeRemainingTime } from "src/helpers/common";

const useTimer = (time, delay, killInterval, killCallback) => {
  const [timeRemaining, setTimeRemaining] = useState<{
    hours: any;
    minutes: any;
    seconds: any;
  }>({ hours: NaN, minutes: NaN, seconds: NaN });

  if (killInterval === null) {
    killInterval =
      +timeRemaining.hours <= 0 &&
      +timeRemaining.minutes <= 0 &&
      +timeRemaining.seconds <= 0;
  }

  const setTimer = () =>
    killInterval
      ? killCallback && killCallback()
      : time && setTimeRemaining(computeRemainingTime(time.toString()));

  useInterval(setTimer, delay, killInterval);

  useEffect(() => {
    setTimer();
  }, [timeRemaining.seconds]);

  return timeRemaining;
};

export default useTimer;
