import { mapUsers } from "src/helpers/users/";
import { ADD_USERS, ADD_USER } from "./reducer";
import { getUsers } from "src/api/users";

export const usersAction = () => async (dispatch) => {
  let data = await getUsers();
  if (data) {
    let users = mapUsers(data);
    dispatch({ type: ADD_USERS, payload: users });
  }
};

export const addUserAction = (data) => {
  return { type: ADD_USER, payload: data };
};
