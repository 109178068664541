import { createStore, applyMiddleware, compose, Middleware } from 'redux';
import rootReducer from './rootReducer';
import thunk from 'redux-thunk';

const middleware: Middleware[] = [ thunk ];
const { NODE_ENV } = process.env;

const composeEnhancers =
	NODE_ENV !== 'production' && typeof window === 'object' && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
		? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
		: compose;

const enhancers = composeEnhancers(applyMiddleware(...middleware));

const initialState = { users: { list: [] } };

const store = createStore(rootReducer, initialState, enhancers);

// ***** Will enable hot reload soon *****

// if (process.env.NODE_ENV !== 'production') {
// 	if (module.hot) {
// 		module.hot.accept('./rootReducer', () => {
// 			store.replaceReducer(rootReducer);
// 		});
// 	}
// }

export default store;
