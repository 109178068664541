import Api from "src/helpers/request";

export const getStorylineEndpoint = "/storylines";
export const getStorylineVersionEndpoint = "/versions";

export const getStoriesEndpoint = "/stories";
// export const cloneStoryLineEndpoint = "/stories/clone";
export const storyLineHistoryEndpoint = "/storylines/{version}/history";

export const fetchStorylines = (metaConfig = { showLoader: true }) => {
  return Api.call(
    {
      url: `${getStorylineEndpoint}?pagination=false`,
      method: "GET",
    },
    metaConfig
  );
};

export const fetchStorylineVersionById = (storylineId) =>
  Api.call(
    {
      url: `${getStorylineEndpoint}${getStorylineVersionEndpoint}/${storylineId}`,
      method: "GET",
    },
    { showLoader: true }
  );

export const updateStorylineVersion = (version) => {
  return Api.call(
    {
      url: `${getStorylineEndpoint}${getStorylineVersionEndpoint}/${version.id}`,
      method: "PATCH",
      data: version,
    },
    { showLoader: true }
  );
};

export const updateStoryline = (storyLine) => {
  return Api.call(
    {
      url: `${getStorylineEndpoint}/${storyLine.id}`,
      method: "PUT",
      data: storyLine,
    },
    { showLoader: true }
  );
};

export const saveStorylineVersion = (version) => {
  return Api.call(
    {
      url: `${getStorylineEndpoint}${getStorylineVersionEndpoint}`,
      method: "POST",
      data: version,
    },
    { showLoader: true }
  );
};

export const fetchStoryOptions = (metaConfig = { showLoader: true }) => {
  return Api.call(
    {
      url: `${getStoriesEndpoint}?pagination=false`,
      method: "GET",
    },
    metaConfig
  );
};

export const deleteStorylineVersionById = (storylineId) =>
  Api.call(
    {
      url: `${getStorylineEndpoint}${getStorylineVersionEndpoint}/${storylineId}`,
      method: "DELETE",
    },
    { showLoader: true }
  );

export const requestStoryLineClone = (storylineId, storylineName) =>
  Api.call(
    {
      url: `${getStorylineEndpoint}/${storylineId}/clone`,
      method: "POST",
      data: { name: storylineName },
    },
    { showLoader: true }
  );

export const requestStoryLineHistory = (storylineId) =>
  Api.call(
    {
      url: `${storyLineHistoryEndpoint}`.replace("{version}", storylineId),
      method: "GET",
    },
    { showLoader: true }
  );
