import screenfull from "screenfull";
import React, {useState} from "react";
import {extendLabTime, getActiveLabById, getLabVmURL} from "src/api/lab";
import {formatError, isEmpty} from "src/utils/common";
import {useHistory} from "react-router-dom";
import {pushNotification} from "src/components/ui/Notification";
import {LABS_ROUTE} from "src/constants/appRoutes";
import {mapLab} from "src/helpers/labs";

declare var WMKS: any;

const useLaunchedLab = (
  labId: string | null,
  hostElId: string,
  vmPollingStatus: boolean,
  labDetails: any,
  consoleVmName: string | null
) => {
  const history = useHistory()

  const [wmks, setWmks] = React.useState<any>({});
  const [wmksConnectionState, setWmksConnectionState] = useState('')

  const consoleEle = document.getElementById(hostElId);

  const destroyWmks = () => {
    const constEvent = WMKS.CONST.Events;
    wmks?.unregister?.(constEvent.CONNECTION_STATE_CHANGE, onConStateChange)
    wmks?.disconnect?.()
    wmks?.destroy?.()
  }

  const getVraUrl = (wmks: any, ticketUrl: string) => {
    let regexPattern = /(.*)\?vmx=(.*)/g;
    const match = regexPattern.exec(ticketUrl);
    if (match) {
      const vmxString = match[2];
      wmks.wmksData._vncDecoder.options.VCDProxyHandshakeVmxPath =
        decodeURIComponent(vmxString);
    }
    return match?.[1];
  };

  const getWssUrl = (wmksInstance, details) => {
    let wssUrl = details?.consoleUrl;
    if (details?.lab?.labProviderType === "VRA") {
      wssUrl = getVraUrl(wmksInstance, details?.consoleUrl);
    }
    return wssUrl;
  };

  const getLabDetails = async () => {
    let labDetails;
    if (consoleVmName && labId) {
      labDetails = await getLabVmURL(labId, consoleVmName);
    } else if (labId) {
      labDetails = await getActiveLabById(labId);
    }

    if (isEmpty(labDetails) || labDetails?.hasError) {
      pushNotification({
        type: "error",
        message: formatError(labDetails),
      });
      history.push(LABS_ROUTE);
    }

    return mapLab(labDetails)
  }

  const createWmksInstance = (details: any) => {
    const wmksInstance = WMKS.createWMKS(hostElId, {
      rescale: true,
      changeResolution: true,
    });
    const wssUrl = getWssUrl(wmksInstance, details);
    wmksInstance?.connect(wssUrl);
    setWmks(wmksInstance);

    return wmksInstance
  }

  const onConStateChange = async (event, data) => {
    setWmksConnectionState(data.state)
    if (data.state === WMKS.CONST.ConnectionState.DISCONNECTED) {
      const pathname = history?.location?.pathname
      console.log("state disconnected, on console", pathname?.includes("console"))
      if (pathname?.includes("console")) {
        try {
          const details = await getLabDetails()
          createWmksInstance(details)
        } catch {
          // system is in sleep mode, and then comes into the active state
          window.location.reload()
        }
      }
    }
  };

  const loadVCDLab = (details: any) => {
    if (
      !!consoleEle &&
      !vmPollingStatus &&
      !!details &&
      details?.lab?.labProviderType !== "SKYTAP" &&
      details?.consoleUrl
    ) {
      const wmksInstance = createWmksInstance(details)
      const constEvent = WMKS.CONST.Events;
      wmksInstance.register(
        constEvent.CONNECTION_STATE_CHANGE,
        onConStateChange
      );
    }
  };

  const fullScreenVCD = () => {
    if (wmks.isFullScreen?.()) {
      wmks.exitFullScreen();
    } else {
      if (wmks.canFullScreen?.()) {
        wmks.enterFullScreen();
      }
    }
  };

  const fullScreenSkytap = () => {
    if (screenfull.isEnabled) {
      screenfull.toggle();
    }
  };

  const fullScreen = () => {
    if (labDetails?.lab?.labProviderType !== "SKYTAP") {
      fullScreenVCD();
    } else {
      fullScreenSkytap();
    }
  };

  const resizeLab = async (width, height) => {
    const predicate = !vmPollingStatus && width > 100 && height > 100;
    if (
      labDetails?.lab?.labProviderType !== "SKYTAP" &&
      labDetails?.consoleUrl
    ) {
      if (predicate && !!wmks) {
        wmks.setRemoteScreenSize(width, height);
        wmks.updateScreen();
      }
    } else {
      if (predicate) {
        await extendLabTime(
          labId,
          labDetails?.consoleUrl,
          width,
          height,
          true,
          false
        );
      }
    }
  };

  const sendKeys = (key: string | string[]) => {
    if (!!wmks && !isEmpty(wmks)) {
      if (key === "cad") {
        wmks.sendCAD();
      } else if (key instanceof Array) {
        wmks.sendKeyCodes(key);
      }
    }
  };

  const changeKeyboardLayout = (layout: string) => {
    if (!!wmks && !isEmpty(wmks) && !!layout) {
      wmks.setOption("keyboardLayoutId", layout);
    }
  };

  return {
    loadVCDLab,
    fullScreen,
    resizeLab,
    sendKeys,
    changeKeyboardLayout,
    getLabDetails,
    wmksConnectionState,
    setWmksConnectionState,
    destroyWmks
  };
};

export default useLaunchedLab;
