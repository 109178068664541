import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Form } from "react-bootstrap";
import InputErrorMessage from "src/components/shared/InputErrorMessage";
import { useDispatch } from "react-redux";
import CustomTooltip from "src/components/ui/CustomTooltip";
import SectionLayout from "src/containers/admin/StoryLine/SectionLayout";
import {
  updateCredentialAction,
  deleteCredentialAction,
} from "src/state/storyline/actions";
import WarningModal from "src/components/shared/WarningModal";
import editIcon from "src/assets/images/edit.svg";
import deleteIcon from "src/assets/images/delete.svg";
import lockIcon from "src/assets/images/lock.svg";
import { useParams } from "react-router-dom";
import { mode } from "src/constants/storylineList";

const validationSchema = Yup.object({
  name: Yup.string().required("Credential name is required"),
  secretKey: Yup.string().required("Secret Key is required"),
});

type SecretCredSectionProps = {
  id: number;
  headerTitle: string;
  secretKey: string;
};

const SecretCredSection: React.FC<SecretCredSectionProps> = ({
  id,
  headerTitle,
  secretKey,
}) => {
  const { storylineMode }: any = useParams();
  const viewModeClass = storylineMode === mode.view ? "hidden" : "visible";
  const dispatch = useDispatch();
  const initialValues = { name: headerTitle, secretKey };
  const [inFocus, setFocus] = useState<Boolean>(false);
  const toggleFocus = () => setFocus(!inFocus);
  const nameRef = React.useRef<any>();
  const secretKeyRef = React.useRef<any>();
  const [deleteModal, setDeleteModal] = useState<Boolean>(false);
  const toggleDeleteModal = () => setDeleteModal(!deleteModal);

  const deleteSection = () => dispatch(deleteCredentialAction(id));
  const updateSection = (creds, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    dispatch(
      updateCredentialAction({
        id,
        name: creds.name,
        secretKey: creds.secretKey,
      })
    );
    setFocus(!inFocus);
    setSubmitting(false);
    resetForm();
  };

  const focusName = () => {
    setFocus(true);
    nameRef.current.focus();
  };
  const focusSecretKey = () => {
    setFocus(true);
    secretKeyRef.current.focus();
  };

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={updateSection}
    >
      {({ handleSubmit, resetForm, getFieldProps, touched, errors }) => (
        <Form onSubmit={handleSubmit}>
          <div className={`section-layout ${!inFocus && "border-shadow"}`}>
            {deleteModal && (
              <WarningModal
                title="Delete Credential"
                confirmBtnLabel="Yes"
                cancelBtnLabel="No"
                onCancelAction={toggleDeleteModal}
                onConfirmAction={deleteSection}
                closeModal={toggleDeleteModal}
              >
                <div className="ml-2">
                  Are you sure you want to delete <b> {headerTitle} ? </b>
                </div>
              </WarningModal>
            )}
            {inFocus ? (
              <SectionLayout
                toggle={() => {
                  resetForm();
                  toggleFocus();
                }}
                errClassName="creds-nameError"
              />
            ) : (
              <div className="d-flex section-layout-header">
                <span
                  ref={nameRef}
                  className="col-sm-9 section-layout-title transparent-bg"
                >
                  {headerTitle}
                </span>
                <div
                  className={`col-sm-3 d-flex justify-content-end ${viewModeClass}`}
                >
                  <div
                    className="mr-2 action-icon section-action-icon"
                    onClick={focusName}
                  >
                    <CustomTooltip text="Edit Section">
                      <img
                        src={editIcon}
                        alt="editIcon"
                        height="15"
                        width="15"
                      />
                    </CustomTooltip>
                  </div>
                  <div
                    className="mr-2 action-icon section-action-icon"
                    onClick={toggleDeleteModal}
                  >
                    <CustomTooltip text="Delete Section">
                      <img
                        src={deleteIcon}
                        alt="deleteIcon"
                        height="15"
                        width="15"
                      />
                    </CustomTooltip>
                  </div>
                </div>
              </div>
            )}
            <div
              className={`d-flex flex-column section-layout-body ${
                inFocus && "border-shadow"
              }`}
            >
              <div className="section-field">
                <div>
                  <div className="section-field_input">
                    <img
                      src={lockIcon}
                      alt="secret key"
                      height="13"
                      width="13"
                    />
                    <span className="section-field-btn_var">Secret Key</span>
                  </div>
                  <span className="mr-1"> : </span>
                  <input
                    ref={secretKeyRef}
                    disabled={!inFocus}
                    type="text"
                    className={`section-field-btn_val ${
                      !inFocus
                        ? "transparent-bg"
                        : "section-field-btn_val_border"
                    }`}
                    {...getFieldProps("secretKey")}
                  />
                  {touched.secretKey && errors.secretKey && (
                    <InputErrorMessage
                      className="creds-error"
                      errorMessage={errors.secretKey || ""}
                    />
                  )}
                </div>
                <div
                  className={`section-field-btn ${viewModeClass}`}
                  onClick={focusSecretKey}
                >
                  <img src={editIcon} alt="editIcon" height="13" width="13" />
                  <span> Edit Secret Key </span>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SecretCredSection;
