import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.513"
      height="19.176"
      viewBox="0 0 18.513 19.176"
      {...props}
    >
      <path
        className="a"
        d="M1.265,17.466l4.564-4.747a7.739,7.739,0,1,1,5.926,2.765,7.659,7.659,0,0,1-4.436-1.4l-4.6,4.783a1.01,1.01,0,1,1-1.456-1.4ZM11.755,2.02a5.722,5.722,0,1,0,5.722,5.722A5.729,5.729,0,0,0,11.755,2.02Z"
        transform="translate(-0.984)"
        fill="#5c7079"
      />
    </svg>
  );
}

export default SvgComponent;
