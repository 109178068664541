import React, { useState } from "react";
import { useParams } from "react-router-dom";
import "./Section.scss";
import edit from "src/assets/images/edit.svg";
import deleteIcon from "src/assets/images/delete.svg";
import { Form, Button, InputGroup } from "react-bootstrap";
import CustomTooltip from "src/components/ui/CustomTooltip";
import warning from "src/assets/images/cross.svg";
import { ISection } from "src/state/storyline/reducer";
import WarningModal from "src/components/shared/WarningModal";
import addIcon from "src/assets/images/add-red.svg";
import { getNewId, reorderList } from "src/helpers/stories";
// import DragDropList from "src/components/DragDropList";
import { Droppable, Draggable, DragDropContext } from "react-beautiful-dnd";
import styled from "styled-components";
import dragDropIcon from "src/assets/images/drag-drop.svg";
import { mode } from "src/constants/storylineList";

const ItemList = styled.div`
  // padding: 10px;
  // background-color: yellow;
`;

const Item = styled.div`
  // padding: 10px;
  background-color: ${(props) => (props.isDragging ? "#f5f5f5" : "inherit")};
  // margin-bottom: 10px;
  // border: 1px solid blue;
`;

type SectionProps = {
  section: ISection;
  storyOtions: [];
  setShowStory: (flag) => void;
  updateStorySection: (section) => void;
  deleteStorySection: (section) => void;
  setSelectedStoryId: (storyId) => void;
  setSelectedStorySectionId: (sectionId) => void;
  dragHandleProps: any;
};
const newSection = {
  name: "",
  id: 0,
  stories: [],
};

const Section: React.FC<SectionProps> = ({
  section = newSection,
  storyOtions = [] as any,
  updateStorySection,
  deleteStorySection,
  setSelectedStoryId,
  setSelectedStorySectionId,
  dragHandleProps,
  ...props
}) => {
  const { storylineMode } = useParams();
  const [showDeleteWarningModal, setShowDeleteWarningModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<any>("");
  const [itemType, setItemType] = useState("");
  const [sectionEditName, setSectionEditName] = useState(section.name);
  const [editSectionMode, setEditSectionMode] = useState(false);
  const [importStoryOption, showImportStoryOption] = useState(false);

  const toggleImportStoryOption = () => {
    showImportStoryOption(!importStoryOption);
  };

  const setCurrentStory = (currentStory) => {
    setSelectedStoryId(currentStory.id);
    setSelectedStorySectionId(section.id);
    const currentStoryIndex = section.stories.findIndex(
      (story) => story.id === currentStory.id
    );
    currentStoryIndex === -1 && addSelectedStory(currentStory);
    props.setShowStory(true);
  };

  const addSelectedStory = (option) => {
    let updatedSection = { ...section };
    updatedSection.stories = [...section.stories, option];
    updateStorySection(updatedSection);
    toggleImportStoryOption();
  };

  const onDeleteHandler = (itemType, itemToDelete) => {
    setItemType(itemType);
    setItemToDelete(itemToDelete);
    setShowDeleteWarningModal(true);
  };

  const closeDeleteGroupModal = () => {
    setItemType("");
    setItemToDelete("");
    setShowDeleteWarningModal(false);
  };

  const deleteStoryFromSection = (story) => {
    let selectedSection = { ...section };
    let selectedStories = [...section.stories];
    const selectedStoryIdx = selectedStories.findIndex(
      (val) => story.id === val.id
    );
    selectedStories.splice(selectedStoryIdx, 1);
    selectedSection.stories = selectedStories;
    if (selectedStoryIdx === 0) {
      setSelectedStorySectionId(0);
    }
    setSelectedStoryId(0);
    updateStorySection(selectedSection);
  };

  const handleInputChange = (e: any) => {
    const value = e.target.value;
    setSectionEditName(value);
  };

  const editSectionName = () => {
    setEditSectionMode(true);
  };

  const cancelEditMode = () => {
    setSectionEditName("");
    setEditSectionMode(false);
  };

  const saveSectionName = () => {
    if (!!sectionEditName) {
      let selectedSection = { ...section };
      selectedSection.name = sectionEditName;
      updateStorySection(selectedSection);
      cancelEditMode();
    }
  };

  const onDragEnd = (result) => {
    const { source, destination, draggableId } = result;

    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    let sectionCopy = { ...section };
    let stories = sectionCopy.stories.map((story) => {
      return { ...story };
    });

    // let temp = stories[source.index];
    // stories[source.index] = stories[destination.index];
    // stories[destination.index] = temp;
    sectionCopy.stories = reorderList(source, destination, stories);
    updateStorySection(sectionCopy);
  };

  const viewModeClass = storylineMode === mode.view ? "hidden" : "visible";

  return (
    <>
      {showDeleteWarningModal && (
        <WarningModal
          title="Warning"
          onCancelAction={closeDeleteGroupModal}
          onConfirmAction={() => {
            itemType === "section"
              ? deleteStorySection(itemToDelete)
              : deleteStoryFromSection(itemToDelete);
            setShowDeleteWarningModal(false);
          }}
        >
          <div>
            <div className="ml-2">
              Do you want to delete <b>{itemToDelete.name}</b>
            </div>
          </div>
        </WarningModal>
      )}
      {editSectionMode && (
        <div className="row d-flex justify-content-between section-editbar">
          <Form.Group className="col-md-6 section-editbar-grp">
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text id="section">
                  Chapter {section.id} :
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                placeholder="Enter New Chapter Name"
                type="text"
                id="labName"
                className="section-editbar-input"
                autoComplete="off"
                onChange={handleInputChange}
                value={sectionEditName}
              />
            </InputGroup>
          </Form.Group>
          <div className="creds-btns">
            <Button
              type="button"
              size="sm"
              variant="outline-primary"
              className="creds-btns_btn"
              onClick={cancelEditMode}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              size="sm"
              variant="primary"
              className="creds-btns_btn"
              onClick={saveSectionName}
            >
              Save
            </Button>
          </div>
        </div>
      )}
      <div className="form-layout form-layout-section mb-3">
        {!editSectionMode && (
          <div className="d-flex align-items-center form-layout-header">
            <div className="col-sm-9">
              <img
                {...dragHandleProps}
                src={dragDropIcon}
                height="20"
                width="15"
              />
              <span className="ml-2">{section.name}</span>
            </div>

            <div
              className={`col-sm-3 d-flex justify-content-end ${viewModeClass}`}
            >
              <div className="ml-2 action-icon section-action-icon">
                <CustomTooltip text="Edit Chapter">
                  <img
                    src={edit}
                    alt="editIcon"
                    height="15"
                    width="15"
                    onClick={editSectionName}
                  />
                </CustomTooltip>
              </div>
              <div className="ml-2 action-icon section-action-icon">
                <CustomTooltip text="Delete Chapter">
                  <img
                    src={deleteIcon}
                    alt="deleteIcon"
                    height="15"
                    width="15"
                    onClick={() => onDeleteHandler("section", section)}
                  />
                </CustomTooltip>
              </div>
            </div>
          </div>
        )}
        <div className="form-layout-body pt-3 pb-3 ">
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="defaultColumn">
              {(provided, snapshots) => {
                return (
                  <ItemList
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {section.stories &&
                      section.stories.map((story, index) => (
                        <Draggable
                          key={`${story.id}`}
                          draggableId={`${story.id}`}
                          index={index}
                          isDragDisabled={storylineMode === mode.view}
                        >
                          {(provided, snapshot) => {
                            return (
                              <Item
                                {...provided.draggableProps}
                                ref={provided.innerRef}
                                isDragging={snapshot.isDragging}
                              >
                                <div className="d-flex mb-2">
                                  <div className="col-sm-9 d-flex story-heading">
                                    <div>
                                      <img
                                        {...provided.dragHandleProps}
                                        src={dragDropIcon}
                                        height="15"
                                        width="10"
                                      />
                                    </div>
                                    <div
                                      onClick={() => setCurrentStory(story)}
                                      className="ml-2 cursor-pointer"
                                    >
                                      {story.name}
                                    </div>
                                  </div>
                                  <div
                                    className={`col-sm-3 d-flex justify-content-end ${viewModeClass}`}
                                  >
                                    <div className="ml-2 action-icon section-action-icon">
                                      <CustomTooltip text="Edit Section">
                                        <img
                                          src={edit}
                                          alt="editIcon"
                                          height="15"
                                          width="15"
                                          onClick={() => setCurrentStory(story)}
                                        />
                                      </CustomTooltip>
                                    </div>
                                    <div className="ml-2 action-icon section-action-icon">
                                      <CustomTooltip text="Delete Section">
                                        <img
                                          src={deleteIcon}
                                          alt="deleteIcon"
                                          height="15"
                                          width="15"
                                          onClick={() =>
                                            onDeleteHandler("story", story)
                                          }
                                        />
                                      </CustomTooltip>
                                    </div>
                                  </div>
                                </div>
                              </Item>
                            );
                          }}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </ItemList>
                );
              }}
            </Droppable>
          </DragDropContext>
          {importStoryOption && (
            <div className="d-flex import-story-dropdown pl-3 mb-2">
              <Form.Control
                type="select"
                name="role"
                as="select"
                id="role"
                onChange={(event: any) => {
                  const data = event.target.selectedOptions[0];
                  const value = JSON.parse(data.value);
                  if (!!data.id) {
                    const option = {
                      id: getNewId(section.stories),
                      name: value.name,
                      content: value.content,
                    } as any;
                    addSelectedStory(option);
                  }
                }}
              >
                {storyOtions ? (
                  [
                    <option key="default_option">Select section name</option>,
                  ].concat(
                    storyOtions.map((story) => (
                      <option
                        key={story.id}
                        value={JSON.stringify(story)}
                        id={story.id}
                        data-content={story.content}
                      >
                        {story.name}
                      </option>
                    ))
                  )
                ) : (
                  <option key="no_option">No Section available</option>
                )}
              </Form.Control>

              {section.stories.length ? (
                <div className={`ml-2 align-self-center cursor-pointer`}>
                  <CustomTooltip text="Cancel">
                    <img
                      src={warning}
                      alt="warning"
                      height="12"
                      width="12"
                      onClick={toggleImportStoryOption}
                    />
                  </CustomTooltip>
                </div>
              ) : null}
            </div>
          )}
        </div>

        {(section.stories.length ||
          (!section.stories.length && !importStoryOption)) && (
          <div
            className={`d-flex align-items-center  ${
              section.stories.length
                ? "form-layout-footer justify-content-end"
                : "footer justify-content-center pb-4 pt-2"
            } ${viewModeClass}`}
          >
            <div className="d-flex justify-content-end">
              <span
                className="ml-2 mr-3 cursor-pointer"
                onClick={toggleImportStoryOption}
              >
                <img
                  src={addIcon}
                  alt="add secret key"
                  width="10"
                  className="mr-1"
                />{" "}
                Import Existing Section{" "}
              </span>
              {!section.stories.length && (
                <span className="story-heading mr-2"> Or </span>
              )}
              <span
                className="ml-2 mr-3 cursor-pointer"
                onClick={() => {
                  const newStory = {
                    name: "New Section",
                    id: getNewId(section.stories),
                    content: "",
                    isNew: true,
                  };
                  setCurrentStory(newStory);
                }}
              >
                <img
                  src={addIcon}
                  alt="add secret key"
                  width="10"
                  className="mr-1"
                />{" "}
                Create New Section{" "}
              </span>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Section;
