import React from "react";
import editIcon from "src/assets/images/editIcon.svg";
import deleteIcon from "src/assets/images/delete.svg";
import inactiveIcon from "src/assets/images/inactiveIcon.svg";
import visibleIcon from "src/assets/images/visibleIcon.svg";
import { labsStatusMap } from "src/constants/labs";
import { OverlayTrigger, Popover } from "react-bootstrap";
import CustomTooltip from "src/components/ui/CustomTooltip";

export const getLabsColumns = (
  showAccessList,
  activateLabHandler,
  deleteLabHandler,
  editLab
) => [
  {
    Header: "Lab Name",
    accessor: "name",
    percentWidth: 15,
  },

  {
    Header: "Access",
    disableSortBy: true,
    accessor: "groupRole",
    percentWidth: 15,
    Cell: ({ row }) => {
      return <div>{getAccess(row, showAccessList)}</div>;
    },
  },
  {
    Header: "Template Used",
    accessor: "templateName",
    percentWidth: 20,
    Cell: ({ value }) => {
      return <div className="overflow-ellipsis">{value}</div>;
    },
  },
  {
    Header: "Tags",
    disableSortBy: true,
    accessor: "tags",
    percentWidth: 18,
    Cell: ({ value }) => {
      return getTags(value);
    },
  },
  {
    Header: "Duration",
    accessor: "usageTimeout",
    percentWidth: 10,
    Cell: ({ row }) => {
      return !!row.original.usageTimeout ? (
        <span>{row.original.usageTimeout} hrs</span>
      ) : (
        <span>--</span>
      );
    },
  },
  {
    Header: "Status",
    accessor: "status",
    percentWidth: 10,
    Cell: ({ value }) => {
      return (
        <div
          className={`d-flex justify-content-center status status-${
            value && value.toLowerCase()
          }`}
        >
          {value}
        </div>
      );
    },
  },
  {
    Header: "Action",
    disableSortBy: true,
    accessor: (row) => {
      return (
        <div className="d-flex">
          <div title="" className="cursor-pointer">
            <CustomTooltip text="Edit Lab">
              <img
                title="Edit lab"
                src={editIcon}
                alt="editIcon"
                height="30"
                width="30"
                onClick={() => editLab(row.id)}
              />
            </CustomTooltip>
          </div>
          <div
            title=""
            className={
              row.status === labsStatusMap.enabled
                ? "cursor-pointer ml-2"
                : "cursor-pointer action-icon action-icon-lab-list ml-2"
            }
          >
            {row.status === labsStatusMap.enabled ? (
              <CustomTooltip text="Disable Lab">
                <img
                  title=""
                  src={inactiveIcon}
                  alt="inactiveIcon"
                  height="30"
                  width="30"
                  onClick={() => activateLabHandler(row, false)}
                />
              </CustomTooltip>
            ) : (
              <CustomTooltip text="Enable Lab">
                <img
                  title=""
                  src={visibleIcon}
                  alt="visibleIcon"
                  height="20"
                  width="18"
                  onClick={() => activateLabHandler(row, true)}
                />
              </CustomTooltip>
            )}
          </div>
          <div
            title=""
            className="cursor-pointer action-icon action-icon-lab-list ml-2"
          >
            <CustomTooltip text="Delete Lab">
              <img
                title=""
                src={deleteIcon}
                alt="deleteIcon"
                height="16"
                width="16"
                onClick={() => deleteLabHandler(row)}
              />
            </CustomTooltip>
          </div>
        </div>
      );
    },
    percentWidth: 12,
  },
];

const getAccess = (row, showAccessList) => {
  const data = row.original;
  let { assignedUsers, assignedGroups, assignedCompanies } = data;
  if (
    assignedCompanies.length + assignedGroups.length + assignedUsers.length ===
    0
  ) {
    return <span>--</span>;
  }
  if (
    assignedCompanies.length + assignedGroups.length + assignedUsers.length ===
    1
  ) {
    return (
      (assignedCompanies[0] && assignedCompanies[0].name) ||
      (assignedGroups[0] && assignedGroups[0].name) ||
      (assignedUsers[0] && assignedUsers[0].email)
    );
  }

  return (
    <a
      href="javascript:void(0)"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        showAccessList(row);
      }}
    >
      {" "}
      {assignedGroups.length
        ? `${assignedGroups.length} ${
            assignedGroups.length > 1 ? "Groups" : "Group"
          }/`
        : null}
      {assignedCompanies.length
        ? `${assignedCompanies.length} ${
            assignedCompanies.length > 1 ? "Companies" : "Company"
          }/`
        : null}
      {assignedUsers.length
        ? `${assignedUsers.length} ${
            assignedUsers.length > 1 ? "Users" : "User"
          }`
        : null}
    </a>
  );
};

const getTags = (tags) => {
  return tags && tags.length ? (
    <div className={`d-flex `}>
      <div className="tags-pill d-flex justify-content-center">
        <div className="tag">{tags[0]}</div>
      </div>
      <div className="ml-1">
        {tags.length > 1 ? (
          <a className="more-tags cursor-pointer">
            <OverlayTrigger
              trigger={["hover", "focus"]}
              placement={"bottom"}
              overlay={
                <Popover id={`popover-positioned-bottom`}>
                  <>
                    <Popover.Content>
                      <div>
                        {tags.map((tag, id) => (
                          <div className="tags-pill mt-1" key={id}>
                            <div className="tag">{tag}</div>
                          </div>
                        ))}
                      </div>
                    </Popover.Content>
                  </>
                </Popover>
              }
            >
              <div> +{tags.length - 1}</div>
            </OverlayTrigger>
          </a>
        ) : null}
      </div>
    </div>
  ) : null;
};
