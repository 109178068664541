import React from "react";
import PureModal from "src/components/shared/PureModal";
import "./AccessList.scss";

type AccessListProps = {
  setShowModal: (boolean) => void;
  data: any;
};

const AccessList: React.FunctionComponent<AccessListProps> = ({
  data,
  ...props
}) => {
  let { assignedCompanies, assignedGroups, assignedUsers } = data;

  let largeModalRequired = false;
  if (
    assignedCompanies.length &&
    assignedGroups.length &&
    assignedUsers.length
  ) {
    largeModalRequired = true;
  }

  const getHeading = () => {
    const companyCount = assignedCompanies.length;
    const groupsCount = assignedGroups.length;
    const usersCount = assignedUsers.length;
    return (
      <div className="mb-2 access-heading">{`
      ${
        groupsCount
          ? `${groupsCount} ${groupsCount > 1 ? "Groups" : "Group"}`
          : ""
      }
      ${groupsCount && companyCount ? " & " : ""}
      ${
        companyCount
          ? `${companyCount} ${companyCount > 1 ? "Companies" : "Company"}`
          : ""
      }
      ${usersCount && companyCount ? " & " : ""}
    ${
      usersCount ? `${usersCount} ${usersCount > 1 ? "Users" : "User"}` : ""
    }`}</div>
    );
  };

  const getUsersList = () => {
    if (!assignedUsers || !assignedUsers.length) return null;

    const usersList = assignedUsers.map((user) => (
      <li className="">
        <div className="dot align-dot"></div>
        <div className="content">{user.email}</div>
      </li>
    ));

    usersList.unshift(
      <li className="">
        <div className="dot-main align-dot-main"></div>
        Users
      </li>
    );
    return usersList;
  };

  const getGroupsList = () => {
    if (!assignedGroups || !assignedGroups.length) return null;

    const groupList = assignedGroups.map((group) => (
      <li className="">
        <div className="dot align-dot"></div>
        <div className="content">{group.name}</div>
      </li>
    ));

    groupList.unshift(
      <li className="">
        <div className="dot-main align-dot-main"></div>
        Groups
      </li>
    );

    return groupList;
  };

  const getCompanyList = () => {
    if (!assignedCompanies || !assignedCompanies.length) return null;

    const companyList = assignedCompanies.map((company) => (
      <li className="">
        <div className="dot align-dot"></div>
        <div className="content">{company.name}</div>
      </li>
    ));

    companyList.unshift(
      <li className="">
        <div className="dot-main align-dot-main"></div>
        Company
      </li>
    );

    return companyList;
  };

  return (
    <PureModal
      title={data.labName}
      showModal={true}
      size={largeModalRequired ? "lg" : undefined}
      closeModal={() => props.setShowModal(false)}
      primaryBtnLabel="Ok"
      centered
      primaryBtnAction={() => props.setShowModal(false)}
    >
      <div className="access-list">
        <div>{getHeading()}</div>
        <div className="d-flex justify-content-around">
          {assignedGroups.length ? (
            <div className="list">
              <ul>{getGroupsList()}</ul>
            </div>
          ) : null}
          {assignedCompanies.length ? (
            <div className="list">
              <ul>{getCompanyList()}</ul>
            </div>
          ) : null}
          {assignedUsers.length ? (
            <div className="list">
              <ul>{getUsersList()}</ul>
            </div>
          ) : null}
        </div>
      </div>
    </PureModal>
  );
};

export default AccessList;
