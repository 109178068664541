import React from "react";
import { Form, FormControlProps } from "react-bootstrap";
import RequiredFieldSymbol from "src/components/ui/RequiredFieldSymbol";
import InputErrorMessage from "src/components/shared/InputErrorMessage";
import { Formik } from "src/containers/admin/Notifications/Configuration";
import { cx } from "@emotion/css";

declare interface InputProps extends FormControlProps {
  required?: boolean;
  label: string;
  isInvalid?: boolean;
  placeholder?: string;
  error?: string;
  name: string;
  getFieldProps?: Formik["getFieldProps"];
  touched?: Formik["touched"];
  errors?: Formik["errors"];
  row?: boolean;
  rows?: number
}

const Input: React.FC<InputProps> = ({
  required = true,
  label,
  isInvalid,
  getFieldProps,
  touched,
  errors,
  name,
  type = "text",
  row = true,
  children,
  ...rest
}) => {
  return (
    <Form.Group
      className={cx("align-items-center", { "lab-container-inner": row })}
    >
      <Form.Label>
        {label}
        {required && <RequiredFieldSymbol />}
      </Form.Label>
      <div>
        {type !== "text" ? (
          children
        ) : (
          <Form.Control {...rest} {...getFieldProps?.(name)} />
        )}
        {touched?.[name] && errors?.[name] && (
          <InputErrorMessage
            className="ml-1"
            errorMessage={errors?.[name] || ""}
          />
        )}
      </div>
    </Form.Group>
  );
};

export default Input;
