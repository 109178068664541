import React, { useState, useEffect } from "react";
import "./Users.scss";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Table from "src/components/shared/Table";
import { ReactComponent as UserIcon } from "src/assets/images/user.svg";
import { ReactComponent as UsersIcon } from "src/assets/images/users.svg";
import { ReactComponent as WorkIcon } from "src/assets/images/work.svg";
import { ReactComponent as AddIcon } from "src/assets/images/add.svg";
import {
  getAdhocUsersColumns,
  getCorporateUserColumns,
  getPartnerUserColumns,
} from "./userColumns";
import { IUser } from "src/state/users/reducer";
import { usersAction } from "src/state/users/actions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/state/rootReducer";
import {Button, Form} from "react-bootstrap";
import SearchBox from "src/components/shared/SearchBox";
import AddOrEditUser from "src/components/AddOrEditUser";
import { userTypesMap } from "src/constants/users";
import NoSearchResultFound from "src/components/ui/NoSearchResultFound";
import {generateToken} from "src/api/token";
import PureModal from "src/components/shared/PureModal";
import CustomTooltip from "src/components/ui/CustomTooltip";
import { CopyToClipboard } from "react-copy-to-clipboard";
import copy from "src/assets/images/copy-code.svg";
import {pushNotification} from "src/components/ui/Notification";


type UsersProps = {};

const newUser = {
  name: "",
  company: "",
  email: "",
};

const Users: React.FC<UsersProps> = () => {
  let [adhocUsersList, setAdhocUsersList] = useState([]);
  let [partnerUsersList, setPartnerUsersList] = useState([]);
  let [corporateUsersList, setCorporateUsersList] = useState([]);

  const [createTokenModal, setCreateTokenModal] = useState(false);
  const toggleCreateTokenModal = () => setCreateTokenModal(!createTokenModal)
  const [token, setToken] = useState({ id: '', value: '' });

  const [searchBy, setSearchBy] = useState("");
  const [isUserEditMode, setIsUserEditMode] = useState(false);
  const [selectedEditUserDetails, setSelectedEditUserDetails] = useState(
    newUser
  );
  const [selectedUserId, setSelectedUserId] = useState("");
  const [tabKey, setTabKey] = useState("corporateUsers");
  const [showAddOrEditUserModal, setShowAddOrEditUserModal] = useState(false);
  const dispatch = useDispatch();
  const users: Array<IUser> = useSelector(
    (state: RootState) => state.users.list
  );
  const loaderCount = useSelector((state: RootState) => state.ui.loaderCount);
  const isLoading = loaderCount > 0;
  const loggedInUser: IUser = useSelector((state: RootState) => state.user);

  useEffect(() => {
    dispatch(usersAction());
  }, []);

  const handleSearchByChange = (updatedSearchQuery) => {
    setSearchBy(updatedSearchQuery);
    filterUsers(updatedSearchQuery);
  };

  const filterUsers = (searchQuery) => {
    let filteredUsers = users.filter((user) => {
      if (!searchQuery) return true;
      const name = user.name && user.name.toLowerCase();
      const email = user.email && user.email.toLowerCase();
      const status = user.status && user.status.toLowerCase();
      const query = searchQuery && searchQuery.toLowerCase();
      return (
        (name && name.includes(query)) ||
        (email && email.includes(query)) ||
        (status && status.includes(query))
      );
    });
    setAdhocUsersList(getUsersByType(filteredUsers, userTypesMap.adhoc));
    setPartnerUsersList(getUsersByType(filteredUsers, userTypesMap.partner));
    setCorporateUsersList(
      getUsersByType(filteredUsers, userTypesMap.corporate)
    );
  };

  const handleTabChange = (key) => {
    setTabKey(key);
  };

  const getUsersByType = (users, type) =>
    users.filter((user) => user.type === type);

  useEffect(() => {
    filterUsers(searchBy);
  }, [users]);

  const editUser = (userDetails) => {
    const { email, name } = userDetails;
    const user: any = { email, name };
    if (userDetails.company && userDetails.company.name) {
      user.company = userDetails.company.name;
    }
    setSelectedUserId(userDetails.id);
    setSelectedEditUserDetails(user);
    setIsUserEditMode(true);
    setShowAddOrEditUserModal(true);
  };

  const addUser = () => {
    setIsUserEditMode(false);
    setSelectedUserId("");
    setSelectedEditUserDetails(newUser);
    setShowAddOrEditUserModal(true);
  };

  const prepareTokenGeneration = (userId) => {
    toggleCreateTokenModal()
    setToken({ ...token, id: userId })
  }

  const createToken = async () => {
    const { token: generatedToken } = await generateToken(token.id)
    if (generatedToken) {
      setToken({ ...token, value: generatedToken })
    }
  }

  const resetTokenGeneration = () => {
    toggleCreateTokenModal()
    setToken({ id: '', value: '' })
  }

  const pushVoucherCopyNotification = () =>
      pushNotification({
        type: "info",
        message: "Token Copied",
      });

  const adhocUserColumns = getAdhocUsersColumns(editUser, loggedInUser, prepareTokenGeneration);
  const corporateUserColumns = getCorporateUserColumns(loggedInUser);
  const partnerUserColumns = getPartnerUserColumns(loggedInUser);

  return users.length ? (
    <div className="users-section d-flex flex-column flex-grow-1">
      {createTokenModal && (
          <PureModal
              showModal={createTokenModal}
              title="Generate API Token"
              closeModal={toggleCreateTokenModal}
              primaryBtnLabel={token.value ? "" : "Generate"}
              primaryBtnAction={createToken}
              secondaryBtnLabel="Close"
              secondaryBtnAction={resetTokenGeneration}
              className="users-section-token-modal"
              centered={true}
          >
            {token.value ? (
                <>
                <Form.Label className="users-section-token"> Token </Form.Label>
                <div className="d-flex voucher-form-row flex-grow-1">
                  <Form.Control
                      disabled
                      type="text"
                      id="tokenCode"
                      readOnly
                      defaultValue={token.value}
                  />
                  <CustomTooltip text="Copy Token">
                    <CopyToClipboard
                        text={token.value}
                        onCopy={pushVoucherCopyNotification}
                    >
                      <img
                          src={copy}
                          alt="copy-token"
                          className="cursor-pointer"
                      />
                    </CopyToClipboard>
                  </CustomTooltip>
                </div>
                </>
            ) : (
                <div className="users-section-token-warning"> Newly generated token will remove any previous tokens. Are you sure you want to continue ? </div>
            )}
          </PureModal>
      )}
      <h5 className="ml-1 mb-3 mt-2">
        <b>Users</b>
      </h5>
      {showAddOrEditUserModal && (
        <AddOrEditUser
          isEditMode={isUserEditMode}
          selectedEditUserDetails={selectedEditUserDetails}
          selectedUserId={selectedUserId}
          setShowAddOrEditUserModal={setShowAddOrEditUserModal}
        />
      )}
      <div className="users-section-outer d-flex justify-content-end">
        <div className="users-section-search d-flex align-items-center">
          <SearchBox
            updateSearchQuery={handleSearchByChange}
            placeholder={"Search by name, email or status"}
          />
        </div>
        <div
          className={`users-section-add align-self-end ml-2 ${
            tabKey === "adhocUsers" ? "d-inline" : "d-none"
          }`}
        >
          {adhocUsersList.length ? (
            <Button type="submit" size="sm" variant="primary" onClick={addUser}>
              <div className="d-flex align-items-center pl-2 pr-2">
                <AddIcon height="10" width="10" />
                <span className="ml-2"> Add User </span>
              </div>
            </Button>
          ) : null}
        </div>
      </div>
      <Tabs
        activeKey={tabKey}
        onSelect={handleTabChange}
        transition={false}
        id="corporateUsers"
      >
        <Tab
          eventKey="corporateUsers"
          title={
            <div className="d-flex align-items-center">
              <WorkIcon height="20" width="20" />
              <div className="ml-1"> Corporate Users</div>
            </div>
          }
        >
          {corporateUsersList.length ? (
            <Table
              theadStyle={{ backgroundColor: "var(--color-white)" }}
              columns={corporateUserColumns}
              data={corporateUsersList}
            />
          ) : !!searchBy ? (
            <NoSearchResultFound
              message="Your search was not successful!"
              detail="Try some relevant name, email or status"
              errImgSrc="no-users.svg"
            />
          ) : (
            !isLoading && (
              <NoSearchResultFound
                message="No Corporate Users Found!"
                errImgSrc="no-corporate-users.svg"
              />
            )
          )}
        </Tab>
        <Tab
          eventKey="partnerUsers"
          title={
            <div className="d-flex align-items-center">
              <UsersIcon height="20" width="20" />
              <div className="ml-1"> Partner Users</div>
            </div>
          }
        >
          {partnerUsersList.length ? (
            <Table
              theadStyle={{ backgroundColor: "var(--color-white)" }}
              columns={partnerUserColumns}
              data={partnerUsersList}
            />
          ) : !!searchBy ? (
            <NoSearchResultFound
              message="Your search was not successful!"
              detail="Try some relevant name, email or status"
              errImgSrc="no-users.svg"
            />
          ) : (
            !isLoading && (
              <NoSearchResultFound
                message="No Partner Users Found!"
                errImgSrc="no-partner-users.svg"
              />
            )
          )}
        </Tab>
        <Tab
          eventKey="adhocUsers"
          title={
            <div className="d-flex align-items-center">
              <UserIcon height="18" width="18" />
              <div className="ml-1">Ad hoc Users</div>
            </div>
          }
        >
          {adhocUsersList.length ? (
            <Table
              theadStyle={{ backgroundColor: "var(--color-white)" }}
              columns={adhocUserColumns}
              data={adhocUsersList}
            />
          ) : !!searchBy ? (
            <NoSearchResultFound
              message="Your search was not successful!"
              detail="Try some relevant name, email or status"
              errImgSrc="no-users.svg"
            />
          ) : (
            !isLoading && (
              <div style={{ textAlign: "center" }}>
                <NoSearchResultFound
                  message="No Adhoc Users Found!"
                  detail="Click below to add ad hoc users"
                  errImgSrc="no-ad-hoc-users.svg"
                />
                <Button
                  type="submit"
                  size="sm"
                  className="ml-2 no-result-add-btn"
                  variant="primary"
                  onClick={addUser}
                >
                  <div className="d-flex align-items-center pl-2 pr-2">
                    <AddIcon height="10" width="10" />
                    <span className="ml-2"> Add User </span>
                  </div>
                </Button>
              </div>
            )
          )}
        </Tab>
      </Tabs>
    </div>
  ) : null;
};

export default Users;
