import produce from "immer";

export const GET_USER_LABS_SUCCESS = "GET_USER_LABS_SUCCESS";

export interface IUserLabs {
  list: Array<any>;
}

const initialState: IUserLabs = {
  list: [],
};

export default (state = initialState, action): IUserLabs =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_USER_LABS_SUCCESS:
        draft.list = action.payload;
        break;

      default:
        break;
    }
  });
