import React from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useFormik } from "formik";
import rightArrow from "src/assets/images/right-arrow.svg";
import { INotification } from "src/state/notifications/reducer";
import Configuration from "src/containers/admin/Notifications/Configuration";
import Access from "src/containers/admin/Notifications/Access";
import { Button } from "react-bootstrap";
import { ADMIN_ROUTE, NOTIFICATIONS_ROUTE } from "src/constants/appRoutes";
import * as Yup from "yup";
import { fetchNotification } from "src/api/notifications";
import { toCamelCaseKeys, toSnakeCaseKeys } from "src/helpers/common";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/state/rootReducer";
import { getUser } from "src/state/user/actions";
import moment from "moment";
import {
  createNotificationThunk,
  updateNotificationThunk,
} from "src/state/notifications/actions";

export type Notification = Omit<INotification, "id">;

const initialValues: Notification = {
  header: "",
  body: "",
  displayLocation: "",
  startTime: "",
  endTime: "",
  isEnabled: false,
  isDeleted: false,
  assignedUsers: [],
  assignedGroups: [],
  assignedCompanies: [],
};

const validationSchema = Yup.object({
  header: Yup.string()
    .trim()
    .required("Notification header is required")
    .max(100, "Notification heading can't have more than 100 characters."),
  body: Yup.string()
    .trim()
    .required("Notification text is required")
    .max(500, "Notification body can't have more than 500 characters."),
  displayLocation: Yup.string().required("Display location is required"),
  startTime: Yup.string().required("Start date & time is required").nullable(),
  endTime: Yup.string()
    .required("End date & time is required")
    .test(
      "start-time-required",
      "Please select start time first.",
      function (this, value) {
        return !!this.parent.startTime;
      }
    )
    .test(
      "end-time-validation",
      "End Date and Time must be greater than Start Date and Time",
      function (this, value) {
        return moment(value).diff(this.parent.startTime, "seconds") > 0;
      }
    )
    .nullable(),
});

const ManageNotification: React.FC = () => {
  const history = useHistory();
  const { notificationId } = useParams();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);

  const [notification, setNotification] =
    React.useState<Notification>(initialValues);

  React.useEffect(() => {
    if (notificationId) {
      fetchNotification(notificationId).then((notification) => {
        setNotification(toCamelCaseKeys(notification));
      });
    }
  }, []);

  const getAssets = (values) => {
    const assignedUsers = values.assignedUsers.map(({ user }) => ({
      user: user.id,
    }));
    const assignedGroups = values.assignedGroups.map(({ group }) => ({
      group: group.id,
    }));
    const assignedCompanies = values.assignedCompanies.map(({ company }) => ({
      company: company.id,
    }));

    return { assignedUsers, assignedGroups, assignedCompanies };
  };

  const onSubmit = async (values) => {
    try {
      if (!user.id) {
        await dispatch(getUser());
      }
      const assets = getAssets(values);
      const displayLocation = values.displayLocation.value;
      const data = {
        ...values,
        displayLocation,
        createdBy: user.id,
        ...assets,
      };
      if (notificationId) {
        await dispatch(updateNotificationThunk(notificationId, data));
      } else {
        await dispatch(createNotificationThunk(toSnakeCaseKeys(data)));
      }
      goToNotifications();
    } catch (e) {}
  };

  const formik = useFormik<Omit<INotification, "id">>({
    enableReinitialize: true,
    initialValues: notification,
    validationSchema,
    onSubmit,
  });

  const goToNotifications = () =>
    history.push(`${ADMIN_ROUTE}${NOTIFICATIONS_ROUTE}`);

  return (
    <div className="labs-manage-section d-flex flex-column manage-notifications">
      <div className={`labs-list-section-header mb-3`}>
        <b>
          <Link
            className="group-user-header"
            to={`${ADMIN_ROUTE}${NOTIFICATIONS_ROUTE}`}
          >
            Notifications
          </Link>
        </b>
        <img
          height="15"
          width="15"
          src={rightArrow}
          alt="InfoIcon"
          className="mr-1 ml-1"
        />
        <span className="h4">
          {notificationId ? `Edit Notifications` : `Create Notifications`}
        </span>
      </div>
      <Configuration {...formik} />
      <Access {...formik} />
      <div className="mt-2 d-flex justify-content-end">
        <Button
          className="footer-btn lab-cancel color-primary border-primary"
          variant="outline-secondary"
          size="sm"
          onClick={goToNotifications}
        >
          {" "}
          Cancel{" "}
        </Button>
        <Button
          size="sm"
          className="ml-2 footer-btn"
          onClick={() => formik.handleSubmit()}
        >
          {" "}
          Save{" "}
        </Button>
      </div>
    </div>
  );
};

export default ManageNotification;
