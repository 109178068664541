import React from "react";
import { htmlToText } from "src/utils/common";
import { SpanTagRegex } from "src/constants/regex";

type SearchStoryLineProps = {
  section?: Object;
  data: {
    content: string;
    name: string;
  };
  searchBy: string;
  processSearchResult: (param1, param2) => void;
};

const SearchStoryLine: React.FC<SearchStoryLineProps> = ({
  section,
  data,
  searchBy,
  processSearchResult,
}) => {
  let searchResult = "";
  const resultItemLength = 30;
  const text = htmlToText(data.content).replace(/ /gi, " ");

  // let matchIndexes = words.reduce(
  //   (matchedIndexes: Array<number>, word, idx) => {
  //     if (word.toLowerCase().includes(searchBy.toLowerCase())) {
  //       matchedIndexes.push(idx);
  //     }
  //     return matchedIndexes;
  //   },
  //   []
  // );

  const getSearchIndexes = (text) => {
    const matchIndexes: Array<number> = [];
    let currentWord;
    let markedWords: Array<string> = [];
    let word = "";

    for (let index = 0; index < text.length; index++) {
      currentWord = text.substring(index, index + searchBy.length);
      if (currentWord.toLowerCase() === searchBy.toLowerCase()) {
        matchIndexes.push(markedWords.length);
        word += `<span class="highlight">${currentWord}</span>`;
        index += searchBy.length - 1;
      } else if (/\s/.test(text[index])) {
        word += text[index];
        markedWords.push(word);
        word = "";
      } else {
        word += text[index];
      }
    }
    if (SpanTagRegex.test(word)) {
      markedWords.push(word);
    }
    return { matchIndexes, markedWords };
  };

  const { matchIndexes, markedWords } = getSearchIndexes(text);

  // for (const matchIdx of matchIndexes) {
  //   words[matchIdx] = `<span class="highlight">${words[matchIdx]}</span>`;
  // }

  // const totalMatches = matchIndexes.length;
  // let matchIdx;
  // let j = 1;
  // let filteredMatchIndexes: Array<number> = [];
  // for (let i = 0; i < totalMatches; i++) {
  // 	matchIdx = matchIndexes[i];
  // 	if (
  // 		resultItemLength * j > matchIdx &&
  // 		resultItemLength * (j - 1) <= matchIdx
  // 	) {
  // 		j += 1;
  // 		filteredMatchIndexes.push(matchIdx);
  // 	} else if (matchIdx > resultItemLength * j) {
  // 		j = Math.trunc(matchIdx / resultItemLength);
  // 		filteredMatchIndexes.push(matchIdx);
  // 	}
  // }

  const filteredMatchedIdxs = matchIndexes.reduce(
    (filteredIdxs: number[], matchedIdx) => {
      if (!filteredIdxs.length) {
        filteredIdxs.push(matchedIdx);
      } else if (
        matchedIdx - filteredIdxs[filteredIdxs.length - 1] >=
        resultItemLength - 5
      ) {
        filteredIdxs.push(matchedIdx);
      }
      return filteredIdxs;
    },
    []
  );

  // in case if needed to scroll to the matched content
  // const resultItemsRefs = filtetredMatchIndexes.reduce((acc, matchIdx) => {
  //   acc[matchIdx] = React.createRef();
  //   return acc;
  // }, {});

  // const scrollToResult = (id) => {
  //   resultItemsRefs[id].current.scrollIntoView({
  //     behavior: "smooth",
  //     block: "start",
  //   });
  // };

  filteredMatchedIdxs.forEach((matchIdx, index) => {
    if (matchIdx < resultItemLength) {
      searchResult +=
        markedWords.slice(0, resultItemLength).join(" ") + "<br /><br />";
    } else {
      searchResult +=
        markedWords
          .slice(matchIdx - 5, matchIdx + resultItemLength - 5)
          .join(" ") + "<br /><br />";
    }
  });

  // console.log('here ', matchIndexes, filteredMatchedIdxs, markedWords, searchResult)

  // const formatContent = (node, idx) => {
  // 	const span = document.createElement("span");
  // 	span.innerHTML = searchBy;
  // 	span.classList.add("match-highligh");
  // 	if (node.innerHTML || node.innerHTML === "") {
  // 		node.innerHTML = node.innerHTML.substring(0, idx);
  // 		node.appendChild(span);
  // 	} else if (node.data || node.data === "") {
  // 		console.dir(node);
  // 		console.log(node.data);
  // 		node.data = node.data.substring(0, idx);
  // 		node.parentNode.appendChild(span);
  // 	}
  // };

  // const formatChlid2 = (node, nodesToCheck) => {
  // 	nodesToCheck.push(...Array.from(node.childNodes));
  // 	const nodeVal =
  // 		node.innerHTML || node.innerHTML === "" ? node.innerHTML : node.data;
  // 	if (!node?.children?.length && node) {
  // 		for (let i = 0; i < nodeVal.length; i++) {
  // 			if (
  // 				searchBy.toLowerCase() ===
  // 				nodeVal.substring(i, i + searchBy.length).toLowerCase()
  // 			) {
  // 				// console.dir(node);
  // 				// console.log(
  // 				// 	nodeVal,
  // 				// 	node.nodeName,
  // 				// 	searchBy.toLowerCase(),
  // 				// 	nodeVal.substring(i, i + searchBy.length).toLowerCase()
  // 				// );
  // 				// console.dir(node);
  // 				// console.log(node, node.nodeName, node.parentNode);

  // 				formatContent(node, i);
  // 				i += searchBy.length - 1;
  // 			}
  // 		}
  // 	}
  // 	// formatChlid(nodesToCheck[0], nodesToCheck.slice(1));
  // };

  // const formatContentForSearchResult = (content, contnetSetter) => {
  // 	const parser = new DOMParser();
  // 	const parsedContent = parser.parseFromString(content, "text/html");
  // 	const nodesList: any = document
  // 		.getElementById("intro-content")
  // 		?.querySelectorAll("*");
  // 	// const rootChildNodes: any = Array.from(rootNode.childNodes);
  // 	// formatChlid(rootChildNodes[0], rootChildNodes.slice(1));

  // 	[].forEach.call(nodesList, function (node: any) {
  // 		node.classList.add("highlight");
  // 	});
  // 	// console.log(nodeList);

  // 	// contnetSetter(data);
  // };

  return searchResult ? (
    <div
      className="cursor-pointer"
      onClick={() => processSearchResult(section, data)}
    >
      <h6> {data.name} </h6>
      <div dangerouslySetInnerHTML={{ __html: searchResult }} />
    </div>
  ) : null;
};

export default SearchStoryLine;
