import { mapVouchers, mapVoucherLabs } from "src/helpers/vouchers/";
import {
  GET_ADMIN_VOUCHERS_SUCCESS,
  GET_VOUCHER_LABS_SUCCESS,
} from "./reducer";
import { fetchAdminVouchers } from "src/api/vouchers";
import { fetchAdminVoucherLabs } from "src/api/vouchers";

export const getVoucherLabs = (metaConfig = { showLoader: true }) => async (
  dispatch
) => {
  let data = await fetchAdminVoucherLabs(metaConfig);
  if (data) {
    let labs = mapVoucherLabs(data);
    dispatch({ type: GET_VOUCHER_LABS_SUCCESS, payload: labs });
  }
};

export const getVouchers = (metaConfig = { showLoader: true }) => async (
  dispatch
) => {
  let data = await fetchAdminVouchers(metaConfig);
  if (data) {
    let adminVouchers = mapVouchers(data);
    dispatch({ type: GET_ADMIN_VOUCHERS_SUCCESS, payload: adminVouchers });
  }
};
