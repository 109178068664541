import React, { useState } from "react";
import {
  RadialBarChart,
  RadialBar,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { DateRangePicker } from "react-dates";
import { Card } from "react-bootstrap";
import InfographicCard from "src/components/Infographics/InfographicCard";
import InfographicTable from "src/components/Infographics/InfographicTable";
import "../infographics.scss";
import moment from "moment";
import {
  fetchAllLabsLaunched,
  fetchLabsLaunched,
  fetchLabStats,
} from "src/api/dashbaord/lab";
import { labData } from "./labData";
import { getDate, getUTCDateTime } from "src/utils/common";
import debounce from "lodash/debounce";

const radialChartLegendStyles = {
  width: "unset",
  height: "unset",
  left: "unset",
  bottom: 15,
};

const radialChartTooltipStyles = {
  border: "1px solid #ccc",
  borderRadius: 5,
  backgroundColor: "#fff",
  position: "absolute",
  top: "15%",
  left: "10%",
  zIndex: 10,
  padding: "5px 10px",
};

const renderTooltip = ({ payload }) => (
  <span>
    {payload[0]?.payload.name}: {payload[0]?.value}
  </span>
);

type TInfographicsPropsType = {
  activeTab: string;
};

const LabInfographics = (props: TInfographicsPropsType) => {
  const [pageIndex, setPageIndex] = React.useState<number>(0);
  const [searchQuery, setSearchQuery] = useState("");

  const [infographics, setInfographics] = React.useState({
    totalItems: 0,
    items: [],
  });
  const [infographicStats, setInfographicStats] = React.useState({
    active: 0,
    inactive: 0,
    launched: 0,
    barChartStats: [],
  });

  const [filteredDates, setFilteredDates] = React.useState({
    startDate: null,
    endDate: null,
  });
  const [focusedInput, setFocusedInput] = React.useState(null);

  const data = labData(infographicStats);

  const infographicCards = data.cards.map((card) => (
    <InfographicCard {...card} />
  ));

  const bars = data.charts.bar.bars.map((bar) => <Bar {...bar} />);

  const fetchLabs = (offset) => {
    fetchLabsLaunched(
      offset,
      searchQuery,
        getUTCDateTime(filteredDates.startDate),
        getUTCDateTime(filteredDates.endDate, false)
    )
      .then((labs) => labs && setInfographics(labs))
      .catch((error) => console.error(error));
  };

  const getLabStats = () => {
    fetchLabStats(
        getUTCDateTime(filteredDates.startDate),
        getUTCDateTime(filteredDates.endDate, false)
    )
      .then((stats) => stats && setInfographicStats(stats))
      .catch((error) => console.error(error));
  };

  React.useEffect(() => {
    if (!filteredDates.startDate && !filteredDates.endDate) {
      fetchLabs(0);
      getLabStats();
    }
  }, [filteredDates.startDate, filteredDates.endDate]);

  const handleSearch = debounce(async (searchBy) => {
    const data = await fetchLabsLaunched(
      0,
      searchBy,
        getUTCDateTime(filteredDates.startDate),
        getUTCDateTime(filteredDates.endDate, false)
    );
    setPageIndex(0);
    setSearchQuery(searchBy);
    data && setInfographics(data);
  }, 500);

  const fetchAllInfographics = () => {
    if (filteredDates.startDate && filteredDates.endDate) {
      return fetchAllLabsLaunched(
        searchQuery,
          getUTCDateTime(filteredDates.startDate),
          getUTCDateTime(filteredDates.endDate, false)
      );
    } else {
      return fetchAllLabsLaunched(searchQuery);
    }
  };

  React.useEffect(() => {
    if (props.activeTab !== "labs") {
      setFilteredDates({ startDate: null, endDate: null });
      setSearchQuery("");
      fetchLabsLaunched(
        0,
        null,
          getUTCDateTime(filteredDates.startDate),
          getUTCDateTime(filteredDates.endDate, false)
      )
        .then((audits) => audits && setInfographics(audits))
        .catch((error) => console.error(error));
    }
  }, [props.activeTab]);

  const handleDateChange = ({ startDate, endDate }) => {
    const utcStartDate = getUTCDateTime(startDate);
    const utcEndDate = getUTCDateTime(endDate, false);
    if (startDate && endDate) {
      fetchLabsLaunched(0, searchQuery, utcStartDate, utcEndDate)
        .then((labs) => labs && setInfographics(labs))
        .catch((error) => console.error(error));
      fetchLabStats(utcStartDate, utcEndDate)
        .then((stats) => stats && setInfographicStats(stats))
        .catch((error) => console.error(error));

      setPageIndex(0);
    }
  };

  const blockFutureDates = (day) => moment(day).diff(moment()) > 0;

  return (
    <div className="infographic">
      <div className="infographic-datepicker">
        <DateRangePicker
          startDateId="startDate"
          endDateId="endDate"
          startDate={filteredDates.startDate}
          endDate={filteredDates.endDate}
          maxDate={moment()}
          onDatesChange={setFilteredDates}
          focusedInput={focusedInput}
          onFocusChange={setFocusedInput}
          showClearDates
          showDefaultInputIcon
          minimumNights={0}
          isOutsideRange={blockFutureDates}
          noBorder
          small
          anchorDirection="right"
          hideKeyboardShortcutsPanel
          onClose={handleDateChange}
        />
      </div>
      <div className="d-flex flex-column">
        <div className="d-flex justify-content-between">{infographicCards}</div>
        <div className="d-flex justify-content-between">
          <Card border="light" className="infographic-chart-bar">
            <h4 className="font-weight-bold p-4"> {data.charts.bar.title} </h4>
            <ResponsiveContainer width="100%" height="70%">
              <BarChart
                barSize={data.charts.bar.barSize}
                data={infographicStats.barChartStats}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend
                  iconType="circle"
                  formatter={(value) =>
                    "Launched " + value[0].toUpperCase() + value.substring(1)
                  }
                  wrapperStyle={{
                    bottom: -20,
                  }}
                />
                {bars}
              </BarChart>
            </ResponsiveContainer>
          </Card>
          <Card border="light" className="bg-white infographic-chart-radial">
            <h4 className="font-weight-bold p-4">{data.charts.radial.title}</h4>
            <ResponsiveContainer width="100%" height={350}>
              <RadialBarChart
                cx={"50%"}
                cy={"40%"}
                innerRadius={50}
                outerRadius={130}
                startAngle={90}
                endAngle={-270}
                barSize={11}
                data={data.charts.radial.data}
              >
                <RadialBar
                  minAngle={15}
                  legendType="circle"
                  clockWise
                  // label={{ position: "insideStart", fill: "#fff" }}
                  background
                  dataKey={data.charts.radial.dataKey}
                />
                <Tooltip
                  content={renderTooltip}
                  wrapperStyle={radialChartTooltipStyles}
                />
                <Legend
                  iconSize={10}
                  width={120}
                  height={140}
                  layout="horizontal"
                  verticalAlign="bottom"
                  formatter={(value, entry) => (
                    <span className="chart-label">
                      {value} - <b> {entry.payload.value} </b>
                    </span>
                  )}
                  wrapperStyle={radialChartLegendStyles}
                />
              </RadialBarChart>
            </ResponsiveContainer>
            <div className="infographic-chart-radial-text">
              <span
                style={{
                  fontSize:
                    data.charts.radial.count.toString().length <= 4
                      ? "2rem"
                      : "1.5rem",
                }}
              >
                {data.charts.radial.count}
              </span>
            </div>
          </Card>
        </div>
        <InfographicTable
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          fetchItems={fetchLabs}
          title={data.table.title}
          totalItems={infographics.totalItems}
          items={infographics.items}
          itemColumns={data.table.columns}
          fetchAllTableItems={fetchAllInfographics}
          filteredDates={filteredDates}
          searchBy={searchQuery}
          onSearch={handleSearch}
          activeTab={props.activeTab}
        />
      </div>
    </div>
  );
};

export default LabInfographics;
