export const storylineTypeMap = {
  active: "active",
  draft: "draft",
};

export const pageBreak = "<!-- my page break -->";

export const mode = {
  view: "view",
  edit: "edit",
};
