import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={11.912}
      height={15.883}
      viewBox="0 0 11.912 15.883"
      {...props}
    >
      <path
        d="M10.423 5.956h-.5V3.971a3.971 3.971 0 10-7.941 0v1.985h-.5A1.491 1.491 0 000 7.445v6.949a1.491 1.491 0 001.489 1.489h8.934a1.491 1.491 0 001.489-1.489V7.445a1.491 1.491 0 00-1.489-1.489zM3.309 3.971a2.647 2.647 0 015.294 0v1.985H3.309zm0 0"
        fill="#6e8a95"
      />
    </svg>
  );
}

export default SvgComponent;
