import * as React from "react";

function SvgComponent(props) {
  return (
    <svg width={21} height={23.333} {...props}>
      <path
        data-name="bxs-user-rectangle (1)"
        d="M3.5 23.333h15.167A2.334 2.334 0 0021 21V2.333A2.334 2.334 0 0018.667 0H2.333A2.334 2.334 0 000 2.333V21a2.334 2.334 0 002.333 2.333zm7-19.833A3.5 3.5 0 117 7a3.513 3.513 0 013.5-3.5zm-7 14.293c0-2.589 3.156-5.25 7-5.25s7 2.661 7 5.25v.875h-14z"
        fill="#86989f"
      />
    </svg>
  );
}

export default SvgComponent;

