import React from "react";
import "./FormLayout.scss";
import {cx} from "@emotion/css";

type FormLayoutProps = {
  headerImage?: string;
  headerTitle?: string;
  className?: string
};

const FormLayout: React.FC<FormLayoutProps> = ({
  headerImage = "",
  headerTitle = "",
    className,
  ...props
}) => {
  return (
    <div className={cx("form-layout", className)}>
      <div className="d-flex align-items-center form-layout-header ">
        {headerImage && (
          <img
            src={headerImage}
            alt="headerImage"
            className="ml-3"
            height="20"
            width="20"
          />
        )}
        <div className="ml-2">{headerTitle}</div>
      </div>
      <div className="form-layout-body">{props.children}</div>
    </div>
  );
};

export default FormLayout;
