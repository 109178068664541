import React from "react";
import { TNC_URL } from "src/constants/common";

type TNCViewerProps = {};

const TNCViewer: React.FunctionComponent<TNCViewerProps> = () => {
  return (
    <div className="h-100 w-100">
      <embed className="h-100 w-100" src={TNC_URL} type="application/pdf" />
    </div>
  );
};

export default TNCViewer;
