import React from "react";
import CustomTooltip from "src/components/ui/CustomTooltip";
import editIcon from "src/assets/images/editIcon.svg";
import inactiveIcon from "src/assets/images/inactiveIcon.svg";
import visibleIcon from "src/assets/images/visibleIcon.svg";
import deleteIcon from "src/assets/images/delete.svg";
import { formatDateToLocal } from "src/helpers/common";
import { useHistory } from "react-router-dom";
import { ADMIN_ROUTE, EDIT_NOTIFICATIONS_ROUTE } from "src/constants/appRoutes";
import { sortColumn } from "src/utils/common";

export const useFetchColumns = (
  setSelectedNotification,
  updateNotification
) => {
  const history = useHistory();
  const onEditNotification = (notificationId) =>
    history.push(
      `${ADMIN_ROUTE}${EDIT_NOTIFICATIONS_ROUTE}`.replace(
        ":notificationId",
        notificationId
      )
    );

  const onDeleteNotification = (notification) =>
    setSelectedNotification({ show: true, notification });

  const onUpdateNotification = (notification) =>
    updateNotification(notification.id, { isEnabled: !notification.isEnabled });

  return [
    {
      Header: "Notifications Heading",
      accessor: "header",
      percentWidth: 20,
      sortType: (rowA, rowB) => sortColumn(rowA, rowB, "header"),
    },
    {
      Header: "Notifications Body",
      accessor: "body",
      percentWidth: 20,
      sortType: (rowA, rowB) => sortColumn(rowA, rowB, "body"),
    },
    {
      Header: "Start Date & Time",
      accessor: "startTime",
      percentWidth: 20,
      Cell: ({ value }) => {
        return <span> {formatDateToLocal(value)} </span>;
      },
    },
    {
      Header: "End Date & Time",
      accessor: "endTime",
      percentWidth: 20,
      Cell: ({ value }) => {
        return <span> {formatDateToLocal(value)} </span>;
      },
    },
    {
      Header: "Status",
      accessor: ({ isEnabled }) => {
        return (
          <div
            title=""
            className={`d-flex justify-content-center status status-${
              isEnabled ? "active" : "disabled"
            }`}
          >
            {isEnabled ? "Enabled" : "Disabled"}
          </div>
        );
      },
      percentWidth: 10,
    },
    {
      Header: "Action",
      disableSortBy: true,
      percentWidth: 10,
      accessor: (row) => {
        return (
          <div className="d-flex">
            <div title="" className="cursor-pointer">
              <CustomTooltip text="Edit Notification">
                <img
                  title="Edit notification"
                  src={editIcon}
                  alt="editIcon"
                  height="30"
                  width="30"
                  onClick={onEditNotification.bind(null, row.id)}
                />
              </CustomTooltip>
            </div>
            <div
              title=""
              className={
                row.isEnabled
                  ? "cursor-pointer ml-2"
                  : "cursor-pointer action-icon action-icon-lab-list ml-2"
              }
            >
              {row.isEnabled ? (
                <CustomTooltip text="Disable Notification">
                  <img
                    title=""
                    src={inactiveIcon}
                    alt="inactiveIcon"
                    height="30"
                    width="30"
                    onClick={onUpdateNotification.bind(null, row)}
                  />
                </CustomTooltip>
              ) : (
                <CustomTooltip text="Enable Notification">
                  <img
                    title=""
                    src={visibleIcon}
                    alt="visibleIcon"
                    height="20"
                    width="18"
                    onClick={onUpdateNotification.bind(null, row)}
                  />
                </CustomTooltip>
              )}
            </div>
            <div
              title=""
              className="cursor-pointer action-icon action-icon-lab-list ml-2"
            >
              <CustomTooltip text="Delete Notification">
                <img
                  title=""
                  src={deleteIcon}
                  alt="deleteIcon"
                  height="16"
                  width="16"
                  onClick={onDeleteNotification.bind(null, row)}
                />
              </CustomTooltip>
            </div>
          </div>
        );
      },
    },
  ];
};
