import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router";
import {getLocalStorageItem, setLocalStorageItem} from "src/utils/common";
import { LABS_ROUTE } from "src/constants/appRoutes";
import {oktaUrl} from "src/constants/urls";

type OktaUserLandingPageProps = {} & RouteComponentProps;

const OktaUserLandingPage: React.FunctionComponent<OktaUserLandingPageProps> = (
  props
) => {

  React.useEffect(() => {
    const token = getLocalStorageItem("token");
    if (token) {
      props.history.push(LABS_ROUTE)
    } else {
      const urlParams = new URLSearchParams(props.location.search);
      const issuer = urlParams.get("iss");
      if (issuer && issuer.endsWith("okta.com")) {
        window.location.href = oktaUrl;
      }
    }
  }, [])


  useEffect(() => {
    const urlParams = new URLSearchParams(props.location.search);
    const token = urlParams.get("token");
    if (token) {
      setLocalStorageItem("token", token);
      setLocalStorageItem("lastLoginTimestamp", `${Date.now()}`);
      setLocalStorageItem("showNotifications", "1");
      props.history.push(LABS_ROUTE);
    }
  }, []);

  return null;
};

export default OktaUserLandingPage;
