import React, { useState, useEffect } from "react";
import "./ManageStoryLine.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/state/rootReducer";
import { Button, Tabs, Tab } from "react-bootstrap";
import PreviewSection from "./../PreviewSection";
import Credentials from "./Credentials";
import {
  useHistory,
  RouteComponentProps,
  useParams,
  Prompt,
  useLocation,
} from "react-router-dom";
import {
  getStorylineVersionDetails,
  updateStorylineVersionDetails,
} from "src/state/storyline/actions";
import {
  ADMIN_ROUTE,
  LABS_ROUTE,
  STORYLINES_ROUTE,
  USER_STORYLINE_ROUTE,
} from "src/constants/appRoutes";
import { updateStorylineVersion, saveStorylineVersion } from "src/api/stories";
import StorylineHeader from "../HeaderSection";
import IndexSection from "./IndexSection";
import { getPayload } from "src/helpers/stories";
import { sortBy } from "lodash";
import { mode } from "src/constants/storylineList";
import rightArrow from "src/assets/images/right-arrow.svg";
import { UPDATE_WARNING_FLAG } from "src/state/storyline/reducer";
import { useBeforeunload } from "react-beforeunload";
import EditStorylineName from "./StorylineActions/EditStorylineName";
import { checkAdminUser } from "src/utils/common";

type StoryLineProps = {} & RouteComponentProps<any>;

const ManageStoryLine: React.FC<StoryLineProps> = (props) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { storylineMode } = useParams();
  const [showStory, setShowStory] = useState(false);
  const [editStorylineName, setEditStorylineName] = useState(false);
  const [storyLineEditedName, setStorylineEditedName] = useState("");
  const [selectedStoryId, setSelectedStoryId] = useState<number>(0);
  const [selectedStorySectionId, setSelectedStorySectionId] = useState<number>(
    0
  );

  const isAdminUser = React.useMemo(checkAdminUser, []);

  const storylineId = props.match.params.storylineId;
  const storyLineData = useSelector((state: RootState) => state.storyline);

  const [showStorylineNameModal, setShowStorylineNameModal] = useState(
    storyLineData.parent === -1 &&
      storylineMode !== mode.view &&
      storylineMode !== mode.edit &&
      !storylineId
      ? true
      : false
  );

  let storyLine = { ...storyLineData };
  let sections = sortBy(storyLine.sections, (section) => section.id).map(
    (sec) => {
      let section = { ...sec };
      let sortedStories = sortBy(section.stories, (story) => story.id);
      section.stories = sortedStories;
      return section;
    }
  );
  storyLine.sections = sections;
  const newStory = {
    id: 0,
    name: "New Section",
    content: "",
  };

  const sectionIndex = selectedStorySectionId
    ? storyLine.sections.findIndex(
        (section) => section.id === selectedStorySectionId
      )
    : 0;

  const storyIndex = selectedStoryId
    ? storyLine.sections[sectionIndex].stories.findIndex(
        (story) => story.id === selectedStoryId
      )
    : 0;

  const selectedStory = useSelector((state: RootState) =>
    storyLine.sections.length &&
    !!storyLine.sections[sectionIndex].stories[storyIndex] &&
    sectionIndex !== -1 &&
    storyIndex !== -1
      ? state.storyline.sections[sectionIndex].stories[storyIndex]
      : newStory
  );

  const beforeUnloadListener = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  useBeforeunload((event) => {
    if (storylineMode !== mode.view && storyLineData.isUpdated) {
      return beforeUnloadListener(event);
    }
  });

  const getStorylineVersionDetailsHandler = async () => {
    await dispatch(getStorylineVersionDetails(storylineId));

    setTimeout(() => {
      dispatch({ type: UPDATE_WARNING_FLAG });
    }, 500);
  };

  useEffect(() => {
    if (location.pathname.includes(USER_STORYLINE_ROUTE) && isAdminUser) {
      history.push(LABS_ROUTE);
    } else {
      (!!storylineId || storylineId === 0) &&
        getStorylineVersionDetailsHandler();
    }
  }, []);

  const [tabKey, setTabKey] = useState("index");

  const handleTabChange = (key: string | null) => {
    if (key) {
      setTabKey(key);
    }
  };

  const toggleShowStoryPage = (flag: boolean) => {
    setShowStory(flag);
  };

  const showStorylineListing = () => {
    if (isAdminUser) {
      history.push(`${ADMIN_ROUTE}${STORYLINES_ROUTE}`);
    } else {
      history.push(USER_STORYLINE_ROUTE);
    }
  };

  // const confirmChangePage = () => {
  //   // if (storyLineData.isUpdated && storylineMode !== mode.view) {
  //   //   setShowPageChangeConfirmModal(true);
  //   //   return;
  //   // }
  //   showStorylineListing();
  // };

  const onCancelHandler = () => {
    if (showStory) {
      toggleShowStoryPage(false);
    } else {
      showStorylineListing();
      // confirmChangePage();
    }
  };

  const saveDraftStoryline = async () => {
    !showStory && dispatch({ type: UPDATE_WARNING_FLAG });
    const payload = getPayload(storyLine);
    payload.is_draft = true;
    !!storyLineEditedName && (payload.name = storyLineEditedName);
    let response;
    if (!!storylineId || (!!storyLine.parent && storyLine.parent !== -1)) {
      response = await updateStorylineVersion(payload);
    } else {
      delete payload.parent;
      response = await saveStorylineVersion(payload);
    }
    if (!response.isAxiosError) {
      dispatch(updateStorylineVersionDetails(response));
      !showStory ? showStorylineListing() : setShowStory(false);
    }
  };

  const autoSave = async () => {
    const payload = getPayload(storyLine);
    if (!storyLine.assignedLabs?.length) {
      payload.is_draft = true;
      let response;
      if (!!storylineId || (!!storyLine.parent && storyLine.parent !== -1)) {
        response = await updateStorylineVersion(payload);
      } else {
        delete payload.parent;
        response = await saveStorylineVersion(payload);
      }
      !!response && dispatch(updateStorylineVersionDetails(response));
    }
  };

  // useInterval(autoSave, 10000, false);

  const viewModeClass = storylineMode === mode.view ? "hidden" : "visible";

  return (
    <div className="ml-2">
      {showStorylineNameModal && (
        <EditStorylineName
          setShowModal={setShowStorylineNameModal}
          storyLine={storyLineData}
          cancelHandler={showStorylineListing}
        />
      )}
      <Prompt
        when={storyLineData.isUpdated && storylineMode !== mode.view}
        message="There are unsaved changes. Are you sure you want to leave?"
      />
      <h5 className="ml-2 pl-1 mt-4 mb-0">
        <b>
          <span
            className="h5 color-primary cursor-pointer"
            onClick={showStorylineListing}
          >
            Guides
          </span>
          <img
            height="15"
            width="15"
            src={rightArrow}
            alt="InfoIcon"
            className="mr-1 ml-1"
          />
          <span className="h5">
            {storyLineData?.parent === -1 || !storylineId
              ? `Create Guide`
              : storylineMode === mode.view
              ? `View Guide`
              : `Edit Guide`}
          </span>
        </b>
      </h5>

      <div className="storyline-section row">
        <div className="storyline-section-left mr-4">
          <StorylineHeader
            storyLine={storyLine}
            storylineId={storylineId}
            showStorylineListing={showStorylineListing}
            setEditStorylineName={setEditStorylineName}
          />
          <div className="storyline-section-left-content p-4">
            <div
              className={`d-flex flex-row justify-content-sm-between ${
                storyLine.assignedLabs && storyLine.assignedLabs.length
                  ? "mb-4"
                  : "mb-2"
              }`}
            >
              <div className="content-heading">{storyLine.version}</div>
              <div className="d-flex justify-content-end">
                <div className={`align-items-center ${viewModeClass}`}>
                  <Button
                    type="button"
                    size="sm"
                    variant="primary"
                    className="storyline-section-add-existing"
                    onClick={onCancelHandler}
                  >
                    <div className="d-flex align-items-center pl-2 pr-2">
                      <span>Cancel</span>
                    </div>
                  </Button>
                </div>
                <div className={`align-self-end ml-2 mb-2 ${viewModeClass}`}>
                  <Button
                    type="submit"
                    size="sm"
                    variant="primary"
                    className="storyline-section-add"
                    onClick={saveDraftStoryline}
                    disabled={editStorylineName}
                  >
                    <div className="d-flex align-items-center pl-2 pr-2">
                      <span> {showStory ? "Save Section" : "Save Draft"} </span>
                    </div>
                  </Button>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end mb-n4 mt-2">
              {!!storyLine.createdOn && (
                <span className="timestamp mb-n1">
                  Last Saved :{" "}
                  {storyLine.updatedOn
                    ? storyLine.updatedOn
                    : storyLine.createdOn}
                </span>
              )}
            </div>
            <Tabs
              activeKey={tabKey}
              className="storyline-tabs"
              defaultActiveKey="index"
              transition={false}
              id="index"
              onSelect={handleTabChange}
            >
              <Tab eventKey="index" title="Index">
                <IndexSection
                  storyLine={storyLine}
                  sections={storyLine.sections}
                  showStory={showStory}
                  setShowStory={toggleShowStoryPage}
                  setSelectedStoryId={setSelectedStoryId}
                  setSelectedStorySectionId={setSelectedStorySectionId}
                  selectedStory={selectedStory}
                  selectedStorySectionId={selectedStorySectionId}
                  selectedStoryId={selectedStoryId}
                />
              </Tab>
              <Tab eventKey="credentials" title="Credentials">
                <Credentials />
              </Tab>
            </Tabs>
          </div>
        </div>
        <div className="storyline-section-right">
          <span>Preview</span>
          <PreviewSection
            showStory={showStory}
            currentLeftTab={tabKey}
            selectedSection={storyLine.sections[sectionIndex]}
            selectedStory={selectedStory}
            height="38rem"
          />
        </div>
      </div>
    </div>
  );
};

export default ManageStoryLine;
