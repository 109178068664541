import React, { useState, useEffect, useRef } from "react";
import { Form } from "react-bootstrap";
import uploadFileIcon from "src/assets/images/upload-file.svg";
import redCrossIcon from "src/assets/images/cross-red.svg";
import "./DragAndDrop.scss";
import { isEmpty } from "src/utils/common";

const DragAndDrop = ({
  value,
  setInitialValue,
  handleFileBrowse,
  handleDragAndDrop,
}) => {
  const [isDragging, setDragging] = useState(false);
  let dragCounter = 0;
  const dropRef: any = useRef();

  const handleDrag = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDragIn = (event) => {
    event.preventDefault();
    event.stopPropagation();
    dragCounter++;
    if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
      setDragging(true);
    }
  };

  const handleDragOut = (event) => {
    event.preventDefault();
    event.stopPropagation();
    dragCounter--;
    if (dragCounter === 0) {
      setDragging(false);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    dragCounter++;
    setDragging(false);
    if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
      handleDragAndDrop(event.dataTransfer.files);
      event.dataTransfer.clearData();
      dragCounter = 0;
    }
  };

  useEffect(() => {
    const element: any = dropRef.current;
    element.addEventListener("dragenter", handleDragIn);
    element.addEventListener("dragleave", handleDragOut);
    element.addEventListener("dragover", handleDrag);
    element.addEventListener("drop", handleDrop);

    return () => {
      element.removeEventListener("dragenter", handleDragIn);
      element.removeEventListener("dragleave", handleDragOut);
      element.removeEventListener("dragover", handleDrag);
      element.removeEventListener("drop", handleDrop);
    };
  }, []);

  return (
    <div
      className="upload"
      style={{ display: "inline-block", position: "relative", height: "100%" }}
      ref={dropRef}
    >
      {isDragging && (
        <div className="dragging">
          <div className="dragging-container">
            <div>drop here :)</div>
          </div>
        </div>
      )}
      {!isEmpty(value) ? (
        <div className="upload-image-label">
          <img
            src={typeof value === "string" ? value : URL.createObjectURL(value)}
            alt="lab"
          />
          <span
            className="upload-image-selected ml-2 cursor-pointer"
            onClick={setInitialValue}
          >
            <img src={redCrossIcon} alt="remove file" />
          </span>
        </div>
      ) : (
        <Form.Group>
          <Form.File custom>
            <Form.File.Label>
              <div className="upload-image-label flex-column align-items-center">
                <img
                  src={uploadFileIcon}
                  alt="upload file"
                  className="mb-3 mt-4"
                  height="100"
                />
                <span> Drag & drop image here </span>
                <span> or </span>
                <span className="upload-image-text">Browse</span>
              </div>
            </Form.File.Label>
            <Form.File.Input onChange={handleFileBrowse} />
          </Form.File>
        </Form.Group>
      )}
    </div>
  );
};

export default DragAndDrop;
