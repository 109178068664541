import { toCamelCase } from "src/helpers/common";
export { fetchLabStats, fetchLabsLaunched, fetchAllLabsLaunched } from "./lab";
export { fetchUserLogins, fetchAllUserLogins, fetchLoginStats } from "./login";
export { fetchUserAudits, fetchAllUserAudits } from "./audit";

export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const sortMonthlyStats = (month1, month2) => {
  const year1 = +month1.substr(-2);
  const year2 = +month2.substr(-2);
  if (year1 > year2) {
    return 1;
  } else if (year1 < year2) {
    return -1;
  } else {
    const month1Idx = months.findIndex(
      (month) => month === toCamelCase(month1.substr(0, 3))
    );
    const month2Idx = months.findIndex(
      (month) => month === toCamelCase(month2.substr(0, 3))
    );
    if (month1Idx > month2Idx) {
      return 1;
    } else if (month1Idx < month2Idx) {
      return -1;
    } else {
      return 0;
    }
  }
};
