import Api from "src/helpers/request";

export const getCompaniesEndpoint = "/companies";

/* API to fetch companies list */
export const fetchCompanies = (metaConfig = { showLoader: true }) => {
  return Api.call(
    {
      url: `${getCompaniesEndpoint}?pagination=false`,
      method: "GET",
    },
    metaConfig
  );
};

/* API to fetch types  list in company modal */
export const fetchTypes = () => {
  return Api.call(
    {
      url: `${getCompaniesEndpoint}/types`,
      method: "GET",
    },
    { showLoader: true }
  );
};

/* API to add company details */
export const addCompany = (data) => {
  return Api.call(
    { url: `${getCompaniesEndpoint}`, method: "post", data },
    { showLoader: true, showErrorNotification: true }
  );
};

//TODO - write a patch request to update company partitally
/* API to update company details */
export const updateCompany = (data, id) => {
  return Api.call(
    { url: `${getCompaniesEndpoint}/${id}`, method: "put", data },
    { showLoader: true }
  );
};
