import React, { useState, useEffect } from "react";
import "./Notifications.scss";
import Table from "src/components/shared/Table";
import { ReactComponent as AddIcon } from "src/assets/images/add.svg";
import { useFetchColumns } from "./notificationsColumns";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/state/rootReducer";
import { Button } from "react-bootstrap";
import SearchBox from "src/components/shared/SearchBox";
import NoSearchResultFound from "src/components/ui/NoSearchResultFound";
import { INotification } from "src/state/notifications/reducer";
import { ADD_NOTIFICATIONS_ROUTE, ADMIN_ROUTE } from "src/constants/appRoutes";
import { useHistory } from "react-router-dom";
import {
  getNotifications,
  removeNotificationThunk,
  updateNotificationThunk,
} from "src/state/notifications/actions";
import WarningModal from "src/components/shared/WarningModal";

export interface BlankNotification {
  show: boolean;
  notification: Partial<INotification>;
}

const blankNotification: BlankNotification = { show: false, notification: {} };

const Notifications: React.FC = () => {
  const history = useHistory();
  const [selectedNotification, setSelectedNotification] =
    useState<BlankNotification>(blankNotification);

  const resetSelectedNotification = () =>
    setSelectedNotification(blankNotification);

  const [searchBy, setSearchBy] = useState("");
  let [notificationList, setNotificationList] = useState<INotification[]>([]);
  const dispatch = useDispatch();
  const notifications: Array<INotification> = useSelector(
    (state: RootState) => state.notifications.list
  );
  const loaderCount = useSelector((state: RootState) => state.ui.loaderCount);
  const isLoading = loaderCount > 0;

  const filterNotifications = (searchQuery) => {
    let filteredNotifications = notifications.filter((notification) => {
      if (!searchQuery) return true;
      const header = notification.header && notification.header.toLowerCase();
      const query = searchQuery && searchQuery.toLowerCase();
      return header && header.includes(query);
    });
    setNotificationList(filteredNotifications);
  };

  useEffect(() => {
    dispatch(getNotifications());
  }, []);

  useEffect(() => {
    filterNotifications(searchBy);
  }, [notifications]);

  const handleSearchByChange = (updatedSearchQuery) => {
    setSearchBy(updatedSearchQuery);
    filterNotifications(updatedSearchQuery);
  };

  const createNotification = () => {
    history.push(`${ADMIN_ROUTE}${ADD_NOTIFICATIONS_ROUTE}`);
  };

  const deleteNotification = async () => {
    await dispatch(
      removeNotificationThunk(selectedNotification.notification?.id)
    );
    resetSelectedNotification();
    dispatch(getNotifications());
  };

  const patchNotification = async (id, data) => {
    await dispatch(updateNotificationThunk(id, data));
    dispatch(getNotifications());
  };

  const notificationColumns = useFetchColumns(
    setSelectedNotification,
    patchNotification
  );

  return (
    <div className="companies-section d-flex flex-column flex-grow-1">
      <div className="d-flex flex-row justify-content-sm-between">
        <div className={`companies-section-header`}>
          <h5 className="">
            <b>Notifications</b>
          </h5>
        </div>
        <div className="d-flex justify-content-end ">
          <div className="companies-section-search d-flex align-items-baseline">
            <SearchBox
              updateSearchQuery={handleSearchByChange}
              placeholder="Search by notification"
            />
          </div>
          {notificationList.length ? (
            <div className="companies-section-add align-self-end ml-2 d-inline">
              <Button
                type="submit"
                size="sm"
                variant="primary"
                onClick={createNotification}
              >
                <div className="d-flex align-items-center pl-2 pr-2">
                  <AddIcon height="10" width="10" />
                  <span className="ml-2"> Create Notification </span>
                </div>
              </Button>
            </div>
          ) : null}
        </div>
      </div>
      {notificationList.length ? (
        <Table
          searchBy={searchBy}
          columns={notificationColumns}
          data={notificationList}
        />
      ) : !!searchBy ? (
        <NoSearchResultFound
          message="Your search was not successful!"
          detail="Try some relevant notification"
          errImgSrc="no-users.svg"
        />
      ) : (
        !isLoading && (
          <>
            <NoSearchResultFound
              message="You haven't configured any notification till now!"
              errImgSrc="no-users.svg"
            />
            <Button
              type="submit"
              size="sm"
              variant="primary"
              onClick={createNotification}
              className="no-result-add-btn"
            >
              <div className="d-flex align-items-center pl-2 pr-2">
                <AddIcon height="10" width="10" />
                <span className="ml-2"> Create Notification </span>
              </div>
            </Button>
          </>
        )
      )}
      <WarningModal
        showModal={selectedNotification.show}
        title="Warning"
        onCancelAction={resetSelectedNotification}
        onConfirmAction={deleteNotification}
      >
        <div className="ml-2">
          <div>Are you sure you want to delete notification</div>
          <div>
            <b>{selectedNotification.notification?.header}</b>?
          </div>
        </div>
      </WarningModal>
    </div>
  );
};

export default Notifications;
