import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import rightArrow from "src/assets/images/right-arrow.svg";
import Editor from "./../ManageStoryLine/Editor";
import { IStory } from "src/state/storyline/reducer";
import { useDispatch } from "react-redux";
import { updateSectionStory } from "src/state/storyline/actions";
import edit from "src/assets/images/edit.svg";
import checkIcon from "src/assets/images/check.svg";
import cancelIcon from "src/assets/images/cross.svg";
import CustomTooltip from "src/components/ui/CustomTooltip";
import TableOfContent from "./TableOfContent";
import "./StoryPage.scss";
import closedToc from "src/assets/images/closed-toc.svg";
import openToc from "src/assets/images/open-toc.svg";
import { mode } from "src/constants/storylineList";

type StoryPageProps = {
  changeStoryContent: (content) => void;
  selectedStory: IStory;
  setShowStory: (flag) => void;
  selectedStorySectionId?: number;

  //setSelectedStoryId: (storyId) => void;
  //setSelectedStorySectionId: (sectionId) => void;
};

export const getHeaderElements = () => {
  let elementList: any[] = [];
  let headerElements = getHeaderElementsList();

  headerElements.forEach((element, index) => {
    if (element.tagName === "H3" && element.textContent) {
      elementList.push({
        name: element.textContent,
        id: index,
        subItems: [],
      });
    } else if (
      element.tagName === "H4" &&
      elementList.length &&
      element.textContent
    ) {
      elementList[elementList.length - 1].subItems.push({
        name: element.textContent,
        id: index,
      });
    }
  });

  return elementList;
};

export const getHeaderElementsList = () => {
  let editorElement: any = document.getElementsByClassName(
    "tox-edit-area__iframe"
  )[0];
  return (
    (editorElement &&
      editorElement.contentWindow.document &&
      editorElement.contentWindow.document.querySelectorAll("h3, h4")) ||
    []
  );
};

const StoryPage: React.FC<StoryPageProps> = ({
  changeStoryContent,
  selectedStory,
  setShowStory,
  selectedStorySectionId,
  // setSelectedStorySectionId,
  // setSelectedStoryId,
}) => {
  //const [storyContent, setStoryContent] = useState("");
  const { storylineMode } = useParams();
  const [tocList, setTocList] = useState<any>([]);
  const [showToc, setShowToc] = useState(false);

  const dispatch = useDispatch();

  const updateTableOfContent = () => {
    setTocList(getHeaderElements());
  };

  useEffect(() => {
    setTimeout(() => updateTableOfContent(), 2000);
  }, []);
  const updateStoryContent = (content) => {
    changeStoryContent(content);
    updateTableOfContent();
    // getTocList();
    // const currentStory = {
    //   name: selectedStory.name,
    //   id: selectedStory.id,
    //   content,
    // };
    // setSelectedStoryId(currentStory.id);
    // setSelectedStorySectionId(section.id);
  };

  const [editStoryNameMode, setEditStoryNameMode] = useState(false);
  const togglEditeStoryNameMode = () =>
    setEditStoryNameMode(!editStoryNameMode);
  const [storyName, setStoryName] = useState<any>(
    selectedStory && selectedStory.name ? selectedStory.name : "New Section"
  );

  const saveStoryName = () => {
    dispatch(
      updateSectionStory(selectedStorySectionId, {
        ...selectedStory,
        name: storyName,
      })
    );
    togglEditeStoryNameMode();
  };

  const handleStoryNameChange = (e) => {
    let value = e.target.value;
    setStoryName(value);
  };

  const viewModeClass = storylineMode === mode.view ? "hidden" : "visible";

  return (
    <div>
      <div className="row d-flex justify-content-between mt-3">
        <div className="d-flex align-items-center ml-3">
          <span
            className="footer cursor-pointer"
            onClick={() => setShowStory(false)}
          >
            Index
          </span>
          <img
            height="10"
            width="10"
            src={rightArrow}
            alt="InfoIcon"
            className="ml-1 mr-1"
          />
          <span className="secondary-text-color storypage-text">Section:</span>
          <input
            type="text"
            className="storypage-input"
            value={storyName}
            disabled={!editStoryNameMode}
            onChange={handleStoryNameChange}
          />
          {!editStoryNameMode ? (
            <div
              className={`d-flex align-items-center cursor-pointer ${viewModeClass}`}
            >
              <CustomTooltip text="Edit Section Name">
                <img
                  src={edit}
                  alt="editIcon"
                  height="15"
                  width="15"
                  className="storyline-action"
                  onClick={togglEditeStoryNameMode}
                />
              </CustomTooltip>
            </div>
          ) : (
            <div className={`d-flex align-items-center ${viewModeClass}`}>
              <CustomTooltip text="Cancel">
                <img
                  src={cancelIcon}
                  alt="cancel"
                  height="12"
                  width="12"
                  className="storyline-action cursor-pointer"
                  onClick={togglEditeStoryNameMode}
                />
              </CustomTooltip>
              <CustomTooltip text="Save">
                <img
                  src={checkIcon}
                  alt="save"
                  height="15"
                  width="15"
                  className="storyline-action cursor-pointer"
                  onClick={saveStoryName}
                />
              </CustomTooltip>
            </div>
          )}
        </div>
      </div>
      <div className="mt-4">
        <div className="d-flex">
          <div
            className={`toc-wrapper d-flex justify-content-between ${
              showToc ? "w-25" : ""
            }`}
          >
            {showToc && <TableOfContent tocList={tocList} />}
            <div onClick={() => setShowToc(!showToc)}>
              <img src={showToc ? openToc : closedToc} width="25" height="25" />
            </div>
          </div>
          <div className={`ml-1 editor-wrapper  ${showToc ? "w-75" : "w-100"}`}>
            <Editor
              updateEditorContent={updateStoryContent}
              height={"80vh"}
              initialContent={selectedStory.content}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoryPage;
