import { toCamelCaseKeys } from "../common";

export const mapGroups = (groups) =>
  groups.map(toCamelCaseKeys).map((group) => group);

export const mapGroupRoles = (roles) =>
  roles.map(toCamelCaseKeys).map((role) => role);

export const filterActiveUsers = (users = [] as any) =>
  users && users.filter((user) => user.isActive || user.isInvited);
