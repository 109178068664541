import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Form } from "react-bootstrap";
import SectionLayout from "src/containers/admin/StoryLine/SectionLayout";
import InputErrorMessage from "src/components/shared/InputErrorMessage";
import editIcon from "src/assets/images/edit.svg";
import lockIcon from "src/assets/images/lock.svg";
import emailIcon from "src/assets/images/invitation.svg";
import deleteIcon from "src/assets/images/delete.svg";
import CustomTooltip from "src/components/ui/CustomTooltip";
import WarningModal from "src/components/shared/WarningModal";
import {
  updateCredentialAction,
  deleteCredentialAction,
} from "src/state/storyline/actions";
import { useDispatch } from "react-redux";
import "./CredSection.scss";
import { useParams } from "react-router-dom";
import { mode } from "src/constants/storylineList";

const validationSchema = Yup.object({
  name: Yup.string().required("Credential name is required"),
  username: Yup.string().required("Username is required"),
  password: Yup.string().required("Password is required"),
});

type CredSectionProps = {
  id: number;
  headerTitle: string;
  username: string | undefined;
  password: string | undefined;
};

const CredSection: React.FC<CredSectionProps> = ({
  id,
  headerTitle,
  username,
  password,
}) => {
  const { storylineMode } = useParams();
  const viewModeClass = storylineMode === mode.view ? "hidden" : "visible";
  const dispatch = useDispatch();
  const initialValues = { name: headerTitle, username, password };
  const usernameRef = React.useRef<any>();
  const passwordRef = React.useRef<any>();
  const nameRef = React.useRef<any>();
  const [deleteModal, setDeleteModal] = useState<Boolean>(false);
  const toggleDeleteModal = () => setDeleteModal(!deleteModal);
  const [inFocus, setFocus] = useState<Boolean>(false);
  const toggleFocus = () => setFocus(!inFocus);

  const focusName = () => {
    setFocus(true);
    nameRef.current.focus();
  };
  const focusUsername = () => {
    setFocus(true);
    usernameRef.current.focus();
  };
  const focusPassword = () => {
    setFocus(true);
    passwordRef.current.focus();
  };

  const deleteSection = () => dispatch(deleteCredentialAction(id));

  const updateSection = (creds, { setSubmitting, resetForm }) => {
    setSubmitting(true);

    dispatch(
      updateCredentialAction({
        id,
        name: creds.name,
        username: creds.username,
        password: creds.password,
      })
    );
    setFocus(!inFocus);
    setSubmitting(false);
    resetForm();
  };

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={updateSection}
    >
      {({
        handleSubmit,
        resetForm,
        isSubmitting,
        getFieldProps,
        touched,
        errors,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div className={`section-layout ${!inFocus && "border-shadow"}`}>
            {deleteModal && (
              <WarningModal
                title="Delete Credential"
                confirmBtnLabel="Yes"
                cancelBtnLabel="No"
                onCancelAction={toggleDeleteModal}
                onConfirmAction={deleteSection}
                closeModal={toggleDeleteModal}
              >
                <div className="ml-2">
                  Are you sure you want to delete <b> {headerTitle} ? </b>
                </div>
              </WarningModal>
            )}
            {inFocus ? (
              <SectionLayout
                toggle={() => {
                  resetForm();
                  toggleFocus();
                }}
                errClassName="creds-nameError"
              />
            ) : (
              <div className="d-flex section-layout-header">
                <span
                  ref={nameRef}
                  className="col-sm-9 section-layout-title transparent-bg"
                >
                  {headerTitle}
                </span>
                <div
                  className={`col-sm-3 d-flex justify-content-end ${viewModeClass}`}
                >
                  <div
                    className="mr-2 action-icon section-action-icon"
                    onClick={focusName}
                  >
                    <CustomTooltip text="Edit Section">
                      <img
                        src={editIcon}
                        alt="editIcon"
                        height="15"
                        width="15"
                      />
                    </CustomTooltip>
                  </div>
                  <div
                    className="mr-2 action-icon section-action-icon"
                    onClick={toggleDeleteModal}
                  >
                    <CustomTooltip text="Delete Section">
                      <img
                        src={deleteIcon}
                        alt="deleteIcon"
                        height="15"
                        width="15"
                      />
                    </CustomTooltip>
                  </div>
                </div>
              </div>
            )}
            <div
              className={`d-flex flex-column section-layout-body ${
                inFocus && "border-shadow"
              }`}
            >
              <div className="section-field">
                <div>
                  <div className="section-field_input">
                    <img
                      src={emailIcon}
                      alt="username"
                      height="13"
                      width="13"
                    />
                    <span className="section-field-btn_var">Username</span>
                  </div>
                  <span className="mr-1"> : </span>
                  <input
                    ref={usernameRef}
                    disabled={!inFocus}
                    type="text"
                    className={`section-field-btn_val ${
                      !inFocus
                        ? "transparent-bg"
                        : "section-field-btn_val_border"
                    }`}
                    {...getFieldProps("username")}
                  />
                  {touched.username && errors.username && (
                    <InputErrorMessage
                      className="creds-error"
                      errorMessage={errors.username || ""}
                    />
                  )}
                </div>
                <div
                  className={`section-field-btn ${viewModeClass}`}
                  onClick={focusUsername}
                >
                  <img src={editIcon} alt="editIcon" height="13" width="13" />
                  <span> Edit Username </span>
                </div>
              </div>
              <div className="section-field">
                <div>
                  <div className="section-field_input">
                    <img src={lockIcon} alt="editIcon" height="13" width="13" />
                    <span className="section-field-btn_var">Password</span>
                  </div>
                  <span className="mr-1"> : </span>
                  <input
                    ref={passwordRef}
                    disabled={!inFocus}
                    type="text"
                    className={`section-field-btn_val ${
                      !inFocus
                        ? "transparent-bg"
                        : "section-field-btn_val_border"
                    }`}
                    {...getFieldProps("password")}
                  />
                  {touched.password && errors.password && (
                    <InputErrorMessage
                      className="creds-error"
                      errorMessage={errors.password || ""}
                    />
                  )}
                </div>
                <div
                  className={`section-field-btn ${viewModeClass}`}
                  onClick={focusPassword}
                >
                  <img src={editIcon} alt="editIcon" height="13" width="13" />
                  <span> Edit Password </span>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CredSection;
