import React from "react";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import notFoundErrorImg from "src/assets/images/error404.svg";
import "./PageNotFound.scss";

type PageNotFoundProps = {
  message?: "string";
  btnLabel?: "string";
};

const PageNotFound: React.FC<PageNotFoundProps> = ({
  message = "WE CAN'T FIND THE PAGE THAT YOU'RE LOOKING FOR",
  btnLabel = "Go Back Home",
}) => {
  return (
    <div className="page-not-found-container">
      <img height="400" src={notFoundErrorImg} alt="Page Not Found" />
      <h4> {message} </h4>
      <Link to="/">
        <Button> {btnLabel} </Button>
      </Link>
    </div>
  );
};

export default PageNotFound;
