import produce from 'immer';

export const ADD_USERS = 'ADD_USERS';
export const ADD_USER = 'ADD_USER';

export interface IUsers {
	list: Array<IUser>;
}

export interface IUser {
	id: string;
	email: string;
	name: string;
	userType: number;
	type: string;
	userRole: number;
	role: string;
	isActive: boolean;
	isInvited: boolean;
	labsLaunched: number;
	status: string;
	company: {
		id: string;
		name: string;
	};
	lastLogin: string;
}

const initialState: IUsers = {
	list: []
};

export default (state = initialState, action): IUsers =>
	produce(state, (draft) => {
		switch (action.type) {
			case ADD_USERS:
				draft.list = action.payload;
				break;
			default:
				break;
		}
	});
