import { toCamelCaseKeys } from "../common";

export const mapUsers = (users) =>
  users &&
  users.map(toCamelCaseKeys).map((user) => {
    user.status =
      (user.isInvited && "Invited") ||
      (user.isActive && "Active") ||
      (!user.isActive && "Disabled");
    return user;
  });

export const mapUser = (data) => {
  const user = toCamelCaseKeys(data);
  user.status =
    (user.isInvited && "Invited") ||
    (user.isActive && "Active") ||
    (!user.isActive && "Disabled");
  return user;
};

export const getUserInitials = (user) => {
  let userName = "";
  if (user) {
    const [firstName, lastName] = user.split(" ");
    if (firstName) {
      userName += firstName[0].toUpperCase();
    }
    if (lastName) {
      userName += lastName[0].toUpperCase();
    }
  }
  return userName;
};
