import React, { useMemo } from "react";
import { useTable, useExpanded } from "react-table";
import "./Table.scss";
import sortAsc from "src/assets/images/sort-up.svg";
import sortDesc from "src/assets/images/sort-down.svg";

type TableProps = {
  searchBy?: string;
  columns: Array<any>;
  data: Array<any>;
  theadStyle?: any;
  expandedByDefault?: boolean;
};

const getTotalRecords = (records) => {
  let count = records.length;
  records.forEach((record) => {
    count = count + record.subRows.length;
  });
  return count;
};

export const ExpandedTable: React.FC<TableProps> = (props) => {
  const memoizedColumns = useMemo(() => props.columns, [props.columns]);
  const memoizedData = useMemo(() => props.data, [props.data]);
  let expandedRows;
  if (props.expandedByDefault) {
    expandedRows = props.data.reduce((acc, element, index) => {
      acc[index] = true;
      return acc;
    }, {});
  }

  let totalRecordCount = useMemo(() => getTotalRecords(memoizedData), [
    memoizedData,
  ]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { expanded },
  } = useTable(
    {
      columns: memoizedColumns,
      data: memoizedData,
      initialState: {
        expanded: !!expandedRows && expandedRows,
      },
    },
    useExpanded // Use the useExpanded plugin hook
  );

  const ShowTableData = () => (
    <>
      <div className="pure-table">
        <div>
          <table {...getTableProps()} className="table">
            <colgroup>
              {props.columns.map((column, index) => {
                return (
                  <col
                    key={index}
                    className={`table-col-${column.percentWidth}`}
                  />
                );
              })}
            </colgroup>
            <thead>
              {headerGroups.map((headerGroup, index) => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  style={props.theadStyle}
                  key={index}
                >
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <img
                              src={sortDesc}
                              className="ml-1"
                              alt="sortDesc"
                            />
                          ) : (
                            <img src={sortAsc} className="ml-1" alt="sortAsc" />
                          )
                        ) : (
                          ""
                        )}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    className={row.canExpand ? "expanded-row-header" : ""}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );

  return (
    <div className="pure-table">
      {totalRecordCount ? <ShowTableData /> : null}
    </div>
  );
};
