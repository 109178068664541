import React, { useState } from "react";
import crossIcon from "src/assets/images/cross.svg";
import search from "src/assets/images/search.svg";
import SearchIcon from "src/assets/svgrImages/SearchIcon";
import { InputGroup, FormControl } from "react-bootstrap";
import "./SearchBox.scss";

type SearchBoxProps = {
  updateSearchQuery: (query: string) => void;
  placeholder?: string;
  searchBy?: string;
};

const SearchBox: React.FC<SearchBoxProps> = (props) => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchQueryChange = (e) => {
    let updatedSearchQuery = e.target.value;
    setSearchQuery(updatedSearchQuery);
    props.updateSearchQuery(updatedSearchQuery);
  };

  const clearSearchQuery = () => {
    setSearchQuery("");
    props.updateSearchQuery("");
  };

  React.useEffect(() => {
    if (!props.searchBy) {
      setSearchQuery("");
    }
  }, [props.searchBy]);

  return (
    <div className="search d-flex align-items-center">
      <InputGroup
        size="sm"
        className="position-relative d-flex align-items-center"
      >
        <InputGroup.Prepend className="search-icon position-absolute align-items-center justify-content-center">
          <SearchIcon height="15" width="15" />
          {/*<img src={search} height="15" width="15" alt="search" />*/}
        </InputGroup.Prepend>
        <FormControl
          style={{ paddingLeft: "2.2rem", paddingRight: "2.2rem" }}
          value={searchQuery}
          onChange={handleSearchQueryChange}
          aria-label="Small"
          className="search-box"
          aria-describedby="inputGroup-sizing-sm"
          placeholder={props.placeholder ? props.placeholder : ""}
        />
        <InputGroup.Append
          onClick={clearSearchQuery}
          className="search-cancel position-absolute align-items-center justify-content-center"
        >
          <img src={crossIcon} height="12" width="12" alt="search" />
        </InputGroup.Append>
      </InputGroup>
    </div>
  );
};

export default SearchBox;
