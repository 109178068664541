import React, { useEffect } from "react";
import PureModal from "src/components/shared/PureModal";
import { Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import InputErrorMessage from "src/components/shared/InputErrorMessage";
import "./ManageCompany.scss";
import { addCompany, updateCompany } from "src/api/companies";
import { useDispatch, useSelector } from "react-redux";
import { addCompanyAction } from "src/state/companies/actions";
import { RootState } from "src/state/rootReducer";
import { getTypes, getCompanies } from "src/state/companies/actions";
import { pushNotification } from "src/components/ui/Notification/index";
import { DomainNameRegex } from "src/constants/regex";
import RequiredFieldSymbol from "src/components/ui/RequiredFieldSymbol";
import { isEmpty } from "src/utils/common";

type ManageCompanyProps = {
  setShowModal: (boolean) => void;
  isEditMode: boolean;
  selectedCompany?: { name: string; domain: string; type: string };
  selectCompanyId?: number;
};

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  domain: Yup.string()
    .matches(DomainNameRegex, { message: "Enter valid domain name" })
    .required("Domain is required"),
  type: Yup.string().required("Type is required"),
});

const newCompany = { name: "", domain: "", type: "" };

const ManageCompany: React.FunctionComponent<ManageCompanyProps> = ({
  isEditMode,
  selectedCompany = newCompany,
  selectCompanyId,
  ...props
}) => {
  const initialValues = !!isEditMode ? selectedCompany : newCompany;
  const modalTitle = !!isEditMode ? "Edit Company" : "Add Company";
  const saveBtnText = !!isEditMode ? "Save" : "Add ";
  const dispatch = useDispatch();
  const typeList: Array<any> = useSelector(
    (state: RootState) => state.companies.typeList
  );

  useEffect(() => {
    dispatch(getTypes());
  }, []);

  const onSubmitHandle = async (company, { setSubmitting }) => {
    const payload = {
      name: company.name,
      domain: company.domain,
      type: typeList.find((val) => val.name === company.type).id,
    };
    setSubmitting(true);
    let data;
    let successMsg = "Company added successfully!";
    if (!!isEditMode) {
      data = await updateCompany(payload, selectCompanyId);
      successMsg = "Company updated successfully!";
    } else {
      data = await addCompany(payload);
    }
    setSubmitting(false);
    if (isEmpty(data) || data.hasError) {
      const errorKey = Object.keys(payload).find((val) =>
        data.response.data.hasOwnProperty(val)
      );
      errorKey &&
        pushNotification({
          type: "error",
          message: "Operation not successfull!",
        });
      dispatch(addCompanyAction(false));
    }
    if (!!data && data.id) {
      props.setShowModal(false);
      dispatch(addCompanyAction(true));
      pushNotification({
        type: "success",
        message: successMsg,
      });
      dispatch(getCompanies());
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmitHandle}
    >
      {(formik) => (
        <PureModal
          title={modalTitle}
          showModal={true}
          closeModal={() => props.setShowModal(false)}
          primaryBtnLabel={saveBtnText}
          primaryBtnAction={formik.handleSubmit}
          secondaryBtnLabel="Cancel"
          secondaryBtnAction={() => props.setShowModal(false)}
        >
          <Form>
            <Form.Group>
              <Form.Label>
                Name
                <RequiredFieldSymbol />
              </Form.Label>
              <Form.Control
                type="text"
                id="name"
                placeholder="Enter name"
                {...formik.getFieldProps("name")}
                isInvalid={!!(formik.touched.name && formik.errors.name)}
              />
              <Form.Control.Feedback type="invalid">
                <InputErrorMessage errorMessage={formik.errors.name || ""} />
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Domain
                <RequiredFieldSymbol />
              </Form.Label>
              <Form.Control
                type="text"
                id="domain"
                placeholder="Enter Domain"
                {...formik.getFieldProps("domain")}
                isInvalid={!!(formik.touched.domain && formik.errors.domain)}
              />
              <Form.Control.Feedback type="invalid">
                <InputErrorMessage errorMessage={formik.errors.domain || ""} />
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Type
                <RequiredFieldSymbol />
              </Form.Label>
              <select
                className="form-control"
                defaultValue={formik.values.type}
                onChange={(event) => {
                  event.preventDefault();
                  const data = event.target.selectedOptions[0];
                  formik.setFieldTouched("type");
                  formik.setFieldValue("type", data.id ? data.value : "");
                }}
              >
                {typeList ? (
                  [<option key="default_option">Select</option>].concat(
                    typeList.map((val) => (
                      <option key={val.id} value={val.name} id={val.id}>
                        {val.name}
                      </option>
                    ))
                  )
                ) : (
                  <option key="no_option">No type available</option>
                )}
              </select>
              {formik.touched.type && formik.errors.type && (
                <InputErrorMessage
                  className={"error-message"}
                  errorMessage={formik.errors.type || ""}
                />
              )}
            </Form.Group>
          </Form>
        </PureModal>
      )}
    </Formik>
  );
};

export default ManageCompany;
