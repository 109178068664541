import { mapStorylines } from "src/helpers/stories";
import { GET_STORYLINE_LIST } from "./reducer";
import { fetchStorylines } from "src/api/stories";

export const getStorylineList = (
  metaConfig = { showLoader: true, showErrorNotification: false }
) => async (dispatch) => {
  let data = await fetchStorylines(metaConfig);
  if (data) {
    let storylines = mapStorylines(data);
    dispatch({ type: GET_STORYLINE_LIST, payload: storylines });
  }
};

// export const createStoryLineClone = (storylineId: number) => {
//   return async (dispatch) => {
//     let data = await requestStoryLineClone(storylineId);
//     if (data) {
//       dispatch(getStorylineList());
//     }
//   };
// };
