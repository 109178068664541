import { SHOW_LOADER, HIDE_LOADER, VM_POLLING_STATUS } from "./reducer";

export const showLoaderAction = (message = "") => {
  return { type: SHOW_LOADER, payload: { message: message } };
};

export const hideLoaderAction = () => {
  return { type: HIDE_LOADER };
};

export const startVmPollingAction = () => {
  return {
    type: VM_POLLING_STATUS,
    payload: true,
  };
};

export const stopVmPollingAction = () => {
  return {
    type: VM_POLLING_STATUS,
    payload: false,
  };
};
